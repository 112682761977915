import { ISyncParams } from './syncParams'
import { setGlobal } from '../../../global/useGlobal'
import { FeatureManager } from '../../FeatureManager'
import { mockExtranetSync } from './mockEndpoint'
import { extranetSyncRequest } from './request'
import { displayError } from '../../../utils/displayError'

export const extranetSync = async (payload: ISyncParams) => {
  await setGlobal({ isSyncLoading: true })

  try {
    FeatureManager.shouldMockExtranetSync ? await mockExtranetSync(payload) : await extranetSyncRequest(payload)
    return setGlobal({ isSyncLoading: false })
  } catch (e) {
    displayError(e.error)
  }
}
