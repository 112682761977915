import { SettlementRequestsSchema } from './interfaces'
import { ColumnNames } from '../../../constants/columnNames'
export const settlementRequestsDefaultColumnOrder: (keyof SettlementRequestsSchema)[] = [
  ColumnNames.Checkbox,
  ColumnNames.InternalStatus,
  ColumnNames.CreationDate,
  ColumnNames.ContractCode,
  ColumnNames.InstrumentSymbol,
  ColumnNames.Quantity,
  ColumnNames.EffectiveQuantity,
  ColumnNames.Side,
  ColumnNames.IssuerAccountCode,
  ColumnNames.IssuerAliasOrName,
  ColumnNames.IssuerExecutionBrokerCode,
  ColumnNames.LastUpdateTime,
  ColumnNames.ExternalStatus,
  ColumnNames.OriginType,
  ColumnNames.NextPendingApproval,
  ColumnNames.NextPendingApprovingParticipant,
  ColumnNames.Id,
  ColumnNames.ExchangeTransactionId,
  ColumnNames.OriginTransactionId,
  ColumnNames.ErrorRuleIds,
]
