export const contractCodeMask = /^\b(\d+)\b-?(\w)?$/
export const formatMaskChars = {
  '9': '[0-9]',
  a: '[A-Za-z]',
  '*': '[A-Za-z0-9]',
  '+': '[0-9+-]',
}

// jQuery masks for jexcel
export const intMask = '#,##'
export const floatMask = '#,##.000'
export const dateMask = '0000-00-00'
export const datetimeMask = '0000-00-00 00:00:00'
export const accountMask = '000000000'
