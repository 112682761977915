import { Filter, FilterSchema } from '../constants/filters/filterTypes'

const mountDefaultFilters = (schema: FilterSchema[], ...args: { name: string; value: string }[]) => {
  let defaultFilters: Filter[] = []
  args.forEach((config) => {
    const matchingFilter = schema.find(({ name }) => name === config.name)
    if (matchingFilter) {
      const newFilter: Filter = {
        isActive: true,
        label: matchingFilter.label,
        name: matchingFilter.name,
        type: matchingFilter.filterType,
        value: config.value,
      }
      defaultFilters.push(newFilter)
    }
  })
  return defaultFilters
}

export default mountDefaultFilters
