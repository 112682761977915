import React from 'react'
import { IFavoriteFiltersModal } from './interfaces'
import { ModalContainer, StyledModal } from './style'
import { ModalHeader } from '../ModalHeader'
import { strings } from '../../resources/strings/strings'
import { FF_MODAL_WIDTH } from '../../constants/dimensions'

export const FavoriteFiltersModalView: React.FC<IFavoriteFiltersModal> = ({ isOpen, children, closeModal }) => {
  return (
    <StyledModal onBackdropClick={closeModal} open={isOpen}>
      <>
        <ModalHeader
          title={strings.homeScreen.labels.favoriteButtonLabel}
          handleClose={closeModal}
          customWidth={FF_MODAL_WIDTH}
        />
        <ModalContainer>{children}</ModalContainer>
      </>
    </StyledModal>
  )
}
