import React from 'react'
import styled from 'styled-components'
import { colors } from '../../../styles/themes/light'
import { SvgComponent } from './interfaces'

export const ApprovalDivergencesCreateIcon: React.FC<SvgComponent> = (props) => {
  return (
    <SvgContainer>
      <svg
        {...props}
        preserveAspectRatio="xMaxYMax meet"
        viewBox="0 0 24 24"
        fill="white"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14.99 13V16L11 12L14.99 8V11H22V13H14.99ZM2 17H9.01V14L13 18L9.01 22V19H2V17Z"
          fill="white"
        />
        <path d="M11 8H8V11H6V8H3V6H6V3H8V6H11V8Z" fill="white" />
      </svg>
    </SvgContainer>
  )
}

ApprovalDivergencesCreateIcon.defaultProps = {
  fill: colors.moduleAssetSelectIcon,
  height: 24,
  width: 24,
}

const SvgContainer = styled.div`
  background-color: ${colors.primary};
  border: 1px solid ${colors.primary};
  height: 40px;
  width: 40px;
  border-radius: 4px;
  padding: 7px 8px;
`
