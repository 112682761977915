import React from 'react'
import { IModalHeaderView } from './interfaces'
import { HeaderContainer, StyledTitle, StyledIconButton } from './style'

export const ModalHeaderView: React.FC<IModalHeaderView> = ({ title, handleClose, customWidth }) => {
  return (
    <HeaderContainer customWidth={customWidth}>
      <StyledTitle>{title}</StyledTitle>
      <StyledIconButton onClick={handleClose} />
    </HeaderContainer>
  )
}
