import { HttpMethods } from '../apiService/baseEndpoint'
import { ContractsEndpoints } from './endpoint'

export interface ContractsRequest {
  filters: { filterType: string; value: string | string[] }
}

export const contractsRequest = async (input: any): Promise<any> => {
  const endpoint = new ContractsEndpoints<ContractsRequest, undefined>('', HttpMethods.GET, input)
  return endpoint.execute()
}
