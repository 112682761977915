import React from 'react'
import { IWorkspaceWrapperView, IWorkspaceWrapper } from './interfaces'
import { CssBaseline } from '@material-ui/core'
import { WorkspaceMenu } from '../WorkspaceMenu'

export const WorkspaceWrapperView: React.FC<IWorkspaceWrapperView & IWorkspaceWrapper> = ({ children, classes }) => {
  return (
    <div className={classes.root}>
      <CssBaseline />
      <WorkspaceMenu />
      <div className={classes.content} id="workspace-wrapper">
        {children}
      </div>
    </div>
  )
}
