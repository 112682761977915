import styled from 'styled-components'
import { colors } from '../../styles/themes/light'

export const StyledContextMenuItem = styled.div`
  width: 144px;
  height: 32px;
  padding: 8px;
  background-color: ${colors.surface};
  :hover {
    background-color: ${colors.grayBackground};
  }
`
