import { RefObject } from 'react'
import { Options } from '../components/jexcel-interfaces'
import { getFullDateString } from './getFullDateString'
import { DATE_PATTERN } from '../constants/filters/filterTypes'
import { setValueFromCoords } from './jexcelMethods/setValueFromCoords'
import { openSnackBar } from '../services/pageStateService'
import { strings } from '../resources/strings/strings'

export interface TreatValueInDateColumnInput {
  tableRef: RefObject<{ jexcel: Options } & HTMLDivElement> | undefined
  content: string
  rowIndex: number
  columnIndex: number
}

export const treatValueInDateColumn = (input: TreatValueInDateColumnInput) => {
  const { tableRef, content, rowIndex, columnIndex } = input
  const isValidDate = DATE_PATTERN.test(content)
  if (!isValidDate) {
    setValueFromCoords(tableRef, columnIndex, rowIndex, '')
    openSnackBar(strings.alerts.invalidDateInput, true)
    return
  }
  const fullDate = getFullDateString(content)
  setValueFromCoords(tableRef, columnIndex, rowIndex, fullDate)
}
