import { RefObject } from 'react'
import { CellValue, Options } from '../../components/jexcel-interfaces'
import { approvalDivergencesDefaultColumnOrder } from '../../services/dataSchemas/approvalDivergences/approvalDivergencesDefaultColumnOrder'
import { ApprovalDivergencesColumnsSchema } from '../../services/dataSchemas/approvalDivergences/interfaces'
import { getPersistedData } from '../../utils/localStorageManager'

interface ApprovalDivergencesStateType {
  approvalDivergencesData: CellValue[][]
  approvalDivergencesColumns: (keyof ApprovalDivergencesColumnsSchema)[]
  approvalDivergencesTableRef?: RefObject<{ jexcel: Options } & HTMLDivElement>
  approvalDivergencesColumnWidths?: number[]
  currentApprovalDivergencesPage: number
  approvalDivergencesPaginationArgs: any
  approvalDivergencesAppliedSorting: string[]
}

export const approvalDivergencesState: ApprovalDivergencesStateType = {
  approvalDivergencesData: [],
  approvalDivergencesColumns: getPersistedData('approvalDivergencesColumns') || approvalDivergencesDefaultColumnOrder,
  approvalDivergencesTableRef: undefined,
  approvalDivergencesColumnWidths: getPersistedData('approvalDivergencesColumnWidths'),
  currentApprovalDivergencesPage: 1,
  approvalDivergencesPaginationArgs: {},
  approvalDivergencesAppliedSorting: [],
}
