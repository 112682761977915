import {
  DATE_SHORTCUT_PATTERN,
  FilterPatterns,
  FilterSchema,
  FilterType,
  InputStyle,
} from '../constants/filters/filterTypes'
import { ACCOUNT_CODE_MAX_LENGTH, DATE_LENGTH, QUANTITY_MAX_LENGTH } from '../constants/dimensions'
import { strings } from '../resources/strings/strings'
import { stockLoanSide } from '../constants/contracts/stockLoanSide'
import { isContractCode, isOnlyLetter, isOnlyNumber, isSymbol } from '../constants/regex/regex'
import { isValidDate } from './validators/isValidDate'

const getSuitablePatterns = (input: string) => {
  let suitablePatterns: FilterPatterns[] = []
  const isSide =
    stockLoanSide.borrower.toLowerCase().includes(input.toLowerCase()) ||
    stockLoanSide.lender.toLowerCase().includes(input.toLowerCase())
  if (input.length && isSymbol.test(input)) {
    suitablePatterns.push(FilterPatterns.Symbol)
  }
  if (isOnlyNumber.test(input)) {
    suitablePatterns.push(FilterPatterns.OnlyNumbers)
  }
  if (input.length && isContractCode.test(input)) {
    suitablePatterns.push(FilterPatterns.ContractCode)
  }
  if (isOnlyNumber.test(input) && input.length <= ACCOUNT_CODE_MAX_LENGTH) {
    suitablePatterns.push(FilterPatterns.AccountCode)
  }
  if (isOnlyNumber.test(input) && input.length <= QUANTITY_MAX_LENGTH) {
    suitablePatterns.push(FilterPatterns.Quantity)
  }
  if (isValidDate(input) || DATE_SHORTCUT_PATTERN.test(input)) {
    if (input.length <= DATE_LENGTH) {
      suitablePatterns.push(FilterPatterns.Date)
    }
    suitablePatterns.push(FilterPatterns.Datetime)
  }
  if (input.length && isSide) {
    suitablePatterns.push(FilterPatterns.Side)
  }
  if (isOnlyLetter.test(input)) {
    suitablePatterns.push(FilterPatterns.OnlyLetters)
  }
  return suitablePatterns
}

export const getPossibleFieldsFromString = (input: string, fields: FilterSchema[]): FilterSchema[] => {
  const suitablePatterns = getSuitablePatterns(input)
  if (!suitablePatterns.length) {
    return [
      {
        name: 'noOptions' as any,
        label: strings.general.emptyOptions,
        value: '',
        size: 6,
        inputStyle: InputStyle.textField,
        filterType: FilterType.multiple,
        filterPattern: '' as any,
      },
    ]
    // This is being done because react-select stops working if we have no options at some point
  }
  return fields.filter((value) => suitablePatterns.includes(value.filterPattern))
}
