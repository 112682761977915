import React from 'react'
import { SnackBarView } from './view'
import { useGlobal } from 'reactn'
import { ISnackBar } from './interfaces'
import { AUTO_HIDE_DURATION } from '../../constants/dimensions'

const SnackBar: React.FC<ISnackBar> = (_) => {
  const [snackBarState, setSnackBar] = useGlobal('snackBar')
  const { isOpen, isError: error, message, isWarning: warning } = snackBarState || {}

  const handleClose = () => {
    setSnackBar({
      isOpen: false,
      isError: error,
      isWarning: warning,
      message: '',
    })
  }

  return (
    <SnackBarView
      message={message}
      autoHideDuration={AUTO_HIDE_DURATION}
      open={isOpen}
      error={error}
      onClose={handleClose}
      warning={warning}
    />
  )
}

export default SnackBar
