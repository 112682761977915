import React from 'react'
import { ActionAreaWrapper, ConfirmButton, FormWrapper, MockCaptcha, Subtitle } from '../style'
import Typography from '@material-ui/core/Typography'
import { CustomTextFieldFormInput } from '../../../components/CustomTextFieldFormInput'
import { Button } from '@material-ui/core'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'

interface IConfirmEmail {
  Form: any
  onSubmit: () => void
  errorMessage: any
  screenStrings: any
  handleBack: () => void
}

export const ConfirmEmailScreen: React.FC<IConfirmEmail> = ({
  Form,
  onSubmit,
  errorMessage,
  screenStrings,
  handleBack,
}) => {
  return (
    <Form>
      <FormWrapper>
        <Subtitle>
          <Typography variant="subtitle1">{screenStrings.labels.firstStepSubtitle}</Typography>
        </Subtitle>
        <CustomTextFieldFormInput
          name={'email'}
          variant="filled"
          label={screenStrings.labels.userEmailInputField}
          helperText={errorMessage.email}
          error={errorMessage.email !== ''}
          fullWidth
        />
        <MockCaptcha />
        <ActionAreaWrapper>
          <Button onClick={handleBack} color="primary">
            <ArrowBackIosIcon />
            {screenStrings.labels.backButtonLabel}
          </Button>
          <ConfirmButton color="primary" variant="contained" onClick={onSubmit}>
            {screenStrings.labels.sendButtonLabel}
          </ConfirmButton>
        </ActionAreaWrapper>
      </FormWrapper>
    </Form>
  )
}
