import React from 'react'
import { IWorkspaceMenu } from './interfaces'
import { WorkspaceMenuUseStyles } from './styles'
import { WorkspaceMenuView } from './view'
import { setGlobal, useGlobal } from '../../global/useGlobal'
import { CellValue } from '../jexcel-interfaces'
import { settleDefaultColumnOrder } from '../../services/dataSchemas/settle/settleDefaultColumnOrder'
import { openSnackBar } from '../../services/pageStateService'
import { strings } from '../../resources/strings/strings'
import { doDeleteSettlementRequests } from '../../services/settlementRequests/delete/deleteSettlementRequests'
import { usePromise } from '../../utils/hooks/usePromise'
import { createGetSettlementRequests } from '../../services/settlementRequests/get/createGetSettlementRequests'
import { getApprovalInstructions } from '../../services/approvalInstructions/get/getApprovalInstructions'
import { deleteApprovalInstructions } from '../../services/approvalInstructions/delete/deleteApprovalInstructions'
import { updateAIDefaultColumnOrder } from '../../services/dataSchemas/updateApprovalInstructions/updateAIDefaultColumnOrder'
import { AIRequestTypes, RequestTypeModalTitle } from '../../constants/approvalInstructions/requestType'
import { useLocation } from 'react-router'
import { Routes } from '../../routes'
import { createAIDefaultColumnOrder } from '../../services/dataSchemas/createApprovalInstructions/createAIDefaultColumnOrder'
import { virtualSettleDefaultColumnOrder } from '../../services/dataSchemas/virtualSettle/virtualSettleDefaultColumnOrder'
import { consolidateVirtualSettlementPosition } from '../../services/virtualSettle/consolidatedPosition/consolidateVirtualSettlementPosition'

export const WorkspaceMenu: React.FunctionComponent<IWorkspaceMenu> = ({ ...props }) => {
  const { pathname } = useLocation()
  const classes = WorkspaceMenuUseStyles()
  const [isSettleModalOpen, setIsSettleModalOpen] = useGlobal('isSettleModalOpen')
  const [isVirtualSettleModalOpen, setIsVirtualSettleModalOpen] = useGlobal('isVirtualSettleModalOpen')
  const [isCreateApprovalInstructionsModalOpen, setIsCreateApprovalInstructionsModalOpen] = useGlobal(
    'isCreateApprovalInstructionsModalOpen'
  )
  const [isUpdateApprovalInstructionsModalOpen, setIsUpdateApprovalInstructionsModalOpen] = useGlobal(
    'isUpdateApprovalInstructionsModalOpen'
  )
  const [contractsTableRef] = useGlobal('contractsTableRef')
  const [contractsColumns] = useGlobal('contractsColumns')
  const [approvalInstructionsTableRef] = useGlobal('approvalInstructionsTableRef')
  const [approvalInstructionsColumns] = useGlobal('approvalInstructionsColumns')
  const [approvalDivergencesTableRef] = useGlobal('approvalDivergencesTableRef')
  const [approvalDivergencesColumns] = useGlobal('approvalDivergencesColumns')
  const [settlementRequestsTableRef] = useGlobal('settlementRequestsTableRef')
  const [settlementRequestsColumns] = useGlobal('settlementRequestsColumns')
  const [isLoadingConsolidatedPosition, setIsLoadingConsolidatedPosition] = useGlobal('isLoadingConsolidatedPosition')
  const [isDataLoading] = useGlobal('isDataLoading')
  const openSettleModal = () => setIsSettleModalOpen(true)
  const openCreateAIModal = () => setIsCreateApprovalInstructionsModalOpen(true)
  const openUpdateAIModal = () => setIsUpdateApprovalInstructionsModalOpen(true)
  const openVirtualSettleModal = () => {
    setIsVirtualSettleModalOpen(true)
    setGlobal({
      isVirtualSettleResponse: false,
      virtualSettleData: [],
      isLoadingConsolidatedPosition: false,
    })
  }

  const onDeleteSuccess = () => {
    openSnackBar(strings.alerts.deleteSuccess)
    createGetSettlementRequests()
  }
  const { request: deleteSR, loading: isDeleting } = usePromise(doDeleteSettlementRequests, [], {
    onSuccess: onDeleteSuccess,
    onError: (err) => openSnackBar(err.message, true),
  })

  const onDeleteAISuccess = () => {
    openSnackBar(strings.alerts.deleteSuccess)
    getApprovalInstructions()
  }

  const { request: deleteAI, loading: isDeletingAI } = usePromise(deleteApprovalInstructions, [], {
    onSuccess: onDeleteAISuccess,
    onError: (err) => openSnackBar(err.message, true),
  })

  const settle = () => {
    if (!contractsTableRef) return
    let data: any[] = []
    contractsTableRef?.current?.jexcel.getData!().forEach((row: CellValue[]) => {
      if (row[contractsColumns.indexOf('checkbox')]) {
        const rowSettleData = settleDefaultColumnOrder.map((settleColumn) => {
          return row[contractsColumns.indexOf(settleColumn)]
        })
        data.push(rowSettleData)
      }
    })
    setGlobal({
      settleData: data,
    }).then(openSettleModal)
  }

  const virtualSettle = () => {
    if (!contractsTableRef) return

    const data: CellValue[][] = (contractsTableRef?.current?.jexcel.getData!() as any[])
      .filter((row: CellValue[]) => !!row[contractsColumns.indexOf('checkbox')])
      .map((row: CellValue[]) => {
        return virtualSettleDefaultColumnOrder.map((virtualSettleColumn) => {
          return row[contractsColumns.indexOf(virtualSettleColumn)]
        })
      })
    const hasContractsSelected = data.length
    setIsLoadingConsolidatedPosition(true)
    hasContractsSelected ? consolidateVirtualSettlementPosition(data) : openVirtualSettleModal()
  }

  const handleDeleteSelectedAI = async () => {
    if (!approvalInstructionsTableRef) return
    let ids: any[] = []
    approvalInstructionsTableRef.current?.jexcel.getData!().forEach((row: CellValue[]) => {
      if (row[approvalInstructionsColumns.indexOf('checkbox')]) {
        ids.push(row[approvalInstructionsColumns.indexOf('id')])
      }
    })
    if (!ids.length) {
      openSnackBar(strings.alerts.selectAIData, true)
    } else {
      await setGlobal({
        dialog: {
          isOpen: true,
          title: strings.approvalInstructions.deleteDialog.title,
          text: strings.approvalInstructions.deleteDialog.description,
          handleAction: () => deleteAI(ids),
        },
      })
    }
  }

  const updateApprovalInstructions = () => {
    if (!approvalInstructionsTableRef) return
    let data: any[] = []
    approvalInstructionsTableRef?.current?.jexcel.getData!().forEach((row: CellValue[]) => {
      if (row[approvalInstructionsColumns.indexOf('checkbox')]) {
        const rowUpdateAIData = updateAIDefaultColumnOrder.map((updateAIColumn) => {
          return row[approvalInstructionsColumns.indexOf(updateAIColumn)]
        })
        data.push(rowUpdateAIData)
      }
    })
    if (!data.length) {
      openSnackBar(strings.alerts.selectAIDataToUpdate, true)
    } else {
      setGlobal({
        updateApprovalInstructionsData: data,
        isResponseApprovalInstructionsModalOpen: false,
        requestType: undefined,
      }).then(openUpdateAIModal)
    }
  }

  const createApprovalInstructions = (modalName?: AIRequestTypes) => {
    if (pathname === Routes.approvalDivergences) {
      if (!approvalDivergencesTableRef) return
      let data: any[] = []

      approvalDivergencesTableRef?.current?.jexcel.getData!().forEach((row: CellValue[]) => {
        if (row[approvalDivergencesColumns.indexOf('checkbox')]) {
          const rowCreateAIData = createAIDefaultColumnOrder.map((createAIColumn) => {
            if (approvalDivergencesColumns.findIndex(() => createAIColumn) !== -1) {
              return row[approvalDivergencesColumns.indexOf(createAIColumn)]
            }
          })
          data.push(rowCreateAIData)
        }
      })

      if (!data.length) {
        openSnackBar(strings.alerts.selectADData, true)
        return
      }

      setGlobal({
        requestType: undefined,
        modalTitle: RequestTypeModalTitle.Create,
        createApprovalInstructionsData: data,
        isResponseApprovalInstructionsModalOpen: false,
      }).then(openCreateAIModal)
      return
    }

    if (modalName) {
      setGlobal({
        requestType: AIRequestTypes[modalName],
        modalTitle: RequestTypeModalTitle[modalName],
        createApprovalInstructionsData: undefined,
        isResponseApprovalInstructionsModalOpen: false,
      }).then(openCreateAIModal)
    } else {
      setGlobal({
        requestType: undefined,
        modalTitle: RequestTypeModalTitle.Create,
        createApprovalInstructionsData: undefined,
        isResponseApprovalInstructionsModalOpen: false,
      }).then(openCreateAIModal)
    }
  }

  const handleDeleteSelectedSR = async () => {
    if (!settlementRequestsTableRef) return
    let data: any[] = []
    settlementRequestsTableRef.current?.jexcel.getData!().forEach((row: CellValue[]) => {
      if (row[settlementRequestsColumns.indexOf('checkbox')]) {
        data.push(row[settlementRequestsColumns.indexOf('id')])
      }
    })
    if (!data.length) {
      openSnackBar(strings.alerts.selectSRData, true)
    } else {
      await setGlobal({
        dialog: {
          isOpen: true,
          title: strings.settlementRequests.deleteDialog.title,
          text: strings.settlementRequests.deleteDialog.description,
          handleAction: () => deleteSR(data),
        },
      })
    }
  }

  return (
    <WorkspaceMenuView
      isLoading={isDataLoading}
      classes={classes}
      settle={settle}
      virtualSettle={virtualSettle}
      handleDeleteSR={handleDeleteSelectedSR}
      handleDeleteAI={handleDeleteSelectedAI}
      createApprovalInstructions={createApprovalInstructions}
      updateApprovalInstructions={updateApprovalInstructions}
      isLoadingConsolidatedPosition={isLoadingConsolidatedPosition}
      {...props}
    />
  )
}
