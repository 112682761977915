import styled from 'styled-components'
import { Tooltip } from '@material-ui/core'
import { FF_MODAL_WIDTH } from '../../constants/dimensions'
import Divider from '@material-ui/core/Divider'

export const FavoriteItemWrapper = styled.div`
  width: 90%;
  max-width: ${FF_MODAL_WIDTH}px;
  margin: 8px auto;
  display: flex;
  height: 64px;
  align-items: center;
  padding-left: 16px;
`

export const ButtonsWrapper = styled.div`
  margin-left: auto;
  margin-right: 16px;
  justify-content: space-between;
  display: flex;
  width: 64px;
  min-width: 64px;
`

export const StyledTooltip = styled(Tooltip)`
  cursor: pointer;
`

export const StyledDivider = styled(Divider)`
  width: ${FF_MODAL_WIDTH * 0.8}px;
  margin-left: auto !important;
  margin-right: auto !important;
  display: flex;
`
