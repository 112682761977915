import React from 'react'
import { ActionAreaWrapper, ButtonWrapper, ConfirmButton, Container, Logo, Title } from '../style'
import { btgLogo } from '../../../resources/assets'
import Typography from '@material-ui/core/Typography'

interface IResetPasswordDoneScreen {
  handleRedirect: () => void
  screenStrings: any
}

export const ResetPasswordDoneScreen: React.FC<IResetPasswordDoneScreen> = ({ handleRedirect, screenStrings }) => {
  return (
    <Container>
      <Logo src={btgLogo} alt={'btg-logo'} />
      <Title>
        <Typography variant="h5">{screenStrings.labels.secondScreenTitle}</Typography>
      </Title>
      <ActionAreaWrapper>
        <ButtonWrapper>
          <ConfirmButton color="primary" variant="contained" onClick={handleRedirect}>
            {screenStrings.labels.redirectButton}
          </ConfirmButton>
        </ButtonWrapper>
      </ActionAreaWrapper>
    </Container>
  )
}
