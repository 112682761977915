import React from 'react'
import { CustomButtonGroupView } from './CustomButtonGroupView'
import { ICustomButtonGroupView } from './interfaces'

export const CustomButtonGroup: React.FC<ICustomButtonGroupView> = ({
  leftButtonLabel,
  rightButtonLabel,
  onClickLeftButton,
  onClickRightButton,
}) => {
  return (
    <CustomButtonGroupView
      leftButtonLabel={leftButtonLabel}
      rightButtonLabel={rightButtonLabel}
      onClickLeftButton={onClickLeftButton}
      onClickRightButton={onClickRightButton}
    />
  )
}
