import { useGlobal as useGlobalReactN, setGlobal as setGlobalReactN, getGlobal as getGlobalReactN } from 'reactn'
import { GlobalEntityState, GlobalEntityStateKeys, GlobalState, InitialGlobalState } from './InitialGlobalState'
import { useLayoutEffect, useState } from 'react'

/**
 * Use Global from React N. Use this to subscribe to a simple state
 */
export const useGlobal: typeof useGlobalReactN = useGlobalReactN

/**
 * Use global entity. Use this hook when you need to subscribe to changes in a nested entity state
 * @param state
 * @param id
 */
export const useGlobalEntity = <Type extends GlobalEntityStateKeys>(
  state: Partial<{ [key in Type]: boolean }>,
  id: string
) => {
  const keys = Object.keys(state)
  if (keys.length > 1) {
    throw new Error('useGlobalEntity accepts a single entity as configuration')
  }
  const entityKey = keys[0]
  const [global, setGlobal] = useGlobal<any>(`${entityKey}.${id}`)
  const proxySetGlobal = (state: GlobalEntityState[Type]) => setGlobal({ [`${entityKey}.${id}`]: state })
  return ([global, proxySetGlobal] as any) as [GlobalEntityState[Type], (state: GlobalEntityState[Type]) => void]
}

export type GlobalNestedStateSetGlobal<T extends GlobalEntityStateKeys> = {
  entity: T
  entityId: string
} & Partial<GlobalEntityState[T]>

export const setGlobal: <T extends GlobalEntityStateKeys>(
  state: Partial<GlobalState> & Partial<GlobalEntityState>
) => Promise<GlobalState> = (state) => {
  return setGlobalReactN(state)
}

export const getGlobal = getGlobalReactN

export const buildEntityState = (
  state: Partial<{ [key in GlobalEntityStateKeys]: GlobalEntityState[key] }>,
  entityId: string
) => {
  return Object.keys(state).reduce((acc, curr) => {
    return {
      ...acc,
      // @ts-ignore
      [`${curr}.${entityId}`]: state[curr],
    }
  }, {}) as Partial<{ [key in GlobalEntityStateKeys]: GlobalEntityState[key] }>
}

export const loadPersistedState = async () => {
  const loadedState: any = await new Promise(async (resolve, reject) => {
    const allStoredKeys = Object.keys(window.localStorage)
    const allPersistedStateKeys = allStoredKeys.filter((storedKey) => InitialGlobalState.hasOwnProperty(storedKey))
    const state: any = { ...InitialGlobalState }
    allPersistedStateKeys.forEach((key) => {
      try {
        const jsonItem = JSON.parse(window.localStorage.getItem(key) || '')
        state[key] = jsonItem
      } catch (e) {
        state[key] = window.localStorage.getItem(key)
      }
    })
    resolve(state)
  })
  return setGlobal(loadedState as any)
}

export const useLoadPersistedState = () => {
  const [loading, setLoading] = useState(true)
  useLayoutEffect(() => {
    loadPersistedState().finally(() => setLoading(false))
  }, [])
  return loading
}
