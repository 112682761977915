import React from 'react'
import { CheckboxProps, Checkbox } from '@material-ui/core'
import { useFormInput } from 'react-simple-hooks-forms'
import { ICheckboxInput } from './interface'

export const CheckboxInput: React.FC<CheckboxProps & ICheckboxInput> = ({ checked, name, ...props }) => {
  const { value, setValue } = useFormInput({ name })

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.checked)
  }
  return <Checkbox checked={Boolean(value)} onChange={onChange} {...props} />
}

export default CheckboxInput
