import React, { Fragment, useEffect, useMemo } from 'react'
import { AuthRoutes, OpenRoutes, Routes, UnauthRoutes } from '../../routes'
import { defaultRedirect, loginHandler, openHandler, unauthHandler } from './RedirectRoute'
import { Redirect, Route, RouteProps, Switch, useLocation, withRouter } from 'react-router'
import { getToken } from '../../utils/localStorageManager'
import { checkToken } from '../../services/auth/CheckToken/checkToken'
import { useParams } from '../../screens/useParams'
import { validateUserToken } from '../../utils/validateUserToken'
import { isTokenExpired } from '../../utils/isTokenExpired'
import { getGlobal } from '../../global/useGlobal'
import { FeatureManager } from '../../services/FeatureManager'

const AllRoutesComponent: React.FunctionComponent<RouteProps> = ({ location }) => {
  const queryParamToken = useParams(useLocation())
  const accessToken = getToken()
  const hasTokenExpired = isTokenExpired(accessToken)
  const isLogged = getGlobal().isLoggedIn

  useEffect(() => {
    if (queryParamToken && !FeatureManager.shouldUseLoginMock) {
      checkToken(queryParamToken)
    }
  }, [queryParamToken])

  useEffect(() => {
    redirectToLogin()
  }, [accessToken, isLogged])

  const redirectToLogin = () => {
    if (!accessToken || !isLogged) {
      return <Redirect to={Routes.login} />
    }
  }

  const isLoggedIn = useMemo(() => {
    if (!accessToken) return false
    const isAuthenticated = validateUserToken(accessToken)
    return !!isAuthenticated
  }, [hasTokenExpired, accessToken])

  return (
    <Switch>
      <Route key={'/'} exact path={'/'} render={defaultRedirect(isLoggedIn)} />
      {Object.keys(AuthRoutes).map((route) => (
        <Route key={route} exact path={route} render={loginHandler(AuthRoutes[route], isLoggedIn)} />
      ))}
      {Object.keys(UnauthRoutes).map((route) => (
        <Route key={route} exact path={route} render={unauthHandler(UnauthRoutes[route], isLoggedIn)} />
      ))}
      {Object.keys(OpenRoutes).map((route) => (
        <Route key={route} exact path={route} render={openHandler(OpenRoutes[route])} />
      ))}
    </Switch>
  )
}

const AllRoutes = (withRouter as any)(AllRoutesComponent)

export const RootRouter: React.FunctionComponent<{ history: any }> = ({ history: propHistory }) => {
  return (
    <Fragment>
      <AllRoutes history={propHistory} />
    </Fragment>
  )
}
