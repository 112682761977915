import { ContractsColumnsSchema } from '../contracts/interfaces'

export const settleDefaultColumnOrder: (keyof ContractsColumnsSchema)[] = [
  'code',
  'instrumentSymbol',
  'effectiveQuantity',
  'quantity',
  'side',
  'issuerCarryingBrokerCode',
  'issuerAccountCode',
]
