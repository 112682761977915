import { RefObject } from 'react'
import { Options } from '../../components/jexcel-interfaces'

export const getValueFromCoords = (
  tableRef: RefObject<{ jexcel: Options } & HTMLDivElement> | undefined,
  columnIndex: number | undefined,
  rowIndex: number | undefined
) => {
  return tableRef?.current?.jexcel.getValueFromCoords!(rowIndex, columnIndex)
}
