import { RefObject } from 'react'
import { Options } from '../components/jexcel-interfaces'
import { setOrderSideColumnStyle } from './jexcelMethods/setOrderSideColumnStyle'
import { setValueFromCoords } from './jexcelMethods/setValueFromCoords'
import { openSnackBar } from '../services/pageStateService'
import { strings } from '../resources/strings/strings'

const sideBorrower = 'borrower'
const sideLender = 'lender'
const lenderValue = 'Lender'
const borrowerValue = 'Borrower'

export interface sideColumnAutoCompleteInput {
  tableRef: RefObject<{ jexcel: Options } & HTMLDivElement> | undefined
  content: string
  rowIndex: number
  columnIndex: number
}

export const sideColumnAutoComplete = (input: sideColumnAutoCompleteInput) => {
  const { tableRef, content, rowIndex, columnIndex } = input
  const lowerCaseContent = content.toLowerCase()
  const isBorrower = sideBorrower.startsWith(lowerCaseContent)
  const isLender = sideLender.startsWith(lowerCaseContent)

  if (!isLender && !isBorrower) {
    setValueFromCoords(tableRef, columnIndex, rowIndex, '')
    openSnackBar(strings.alerts.invalidSide, true)
    return
  }
  const value = isLender ? lenderValue : borrowerValue
  setValueFromCoords(tableRef, columnIndex, rowIndex, value)
  setOrderSideColumnStyle(tableRef, columnIndex)
}
