import { getGlobal, setGlobal } from '../../global/useGlobal'

export const openSnackBar = (newMessage: string, error?: boolean, warning?: boolean) => {
  const { isOpen } = getGlobal().snackBar || {}
  return isOpen
    ? null
    : setGlobal({
        snackBar: {
          isOpen: true,
          isError: !!error,
          isWarning: !!warning,
          message: newMessage,
        },
      })
}

export const handleSnackBar = (condition: boolean, success: string, failure: string) => {
  condition ? openSnackBar(success) : openSnackBar(failure, true)
}
