import { ForgotPasswordEndpoint } from './endpoint'
import { HttpMethods } from '../../apiService/baseEndpoint'

interface IForgotPasswordParams {
  email: string
}

export const forgotPasswordRequest = async (payload: IForgotPasswordParams) => {
  const endpoint = new ForgotPasswordEndpoint<undefined, IForgotPasswordParams>(
    'forgot-password',
    HttpMethods.POST,
    undefined,
    payload
  )

  return await endpoint.execute()
}
