import { AUTOFIT_COLUMN_MAX_WIDTH } from '../constants/dimensions'

export const getColumnWidths = (tableHeaderEl: Element | undefined, isAutoFit?: boolean) => {
  let columnWidths: number[] = []
  if (tableHeaderEl) {
    Array.from(tableHeaderEl.children).forEach((column: any, index: number) => {
      // At index 0 we have the index column which is not reachable through jexcel methods
      if (index !== 0) {
        const width = isAutoFit ? Math.min(column.offsetWidth, AUTOFIT_COLUMN_MAX_WIDTH) : column.offsetWidth
        columnWidths.push(width)
      }
    })
    return columnWidths
  }
}
