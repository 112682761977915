import React from 'react'
import { colors } from '../../../styles/themes/light'
import { SvgComponent } from './interfaces'
import { useLocation } from 'react-router'
import { Routes } from '../../../routes'

export const ApprovalDivergencesIcon: React.FC<SvgComponent> = (props) => {
  const { pathname } = useLocation()
  const isCurrentPage = pathname === Routes.approvalDivergences
  const fill = isCurrentPage ? colors.moduleIconHighlight : colors.moduleAssetSelectIcon

  return (
    <>
      <svg {...props} fill={fill} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14.99 11V8H22V6H14.99V3L11 7L14.99 11ZM9.01 12H2V14H9.01V17L13 13L9.01 9V12ZM12.2426 15.4853L10.8284 16.8995L15.0711 21.1421L22.1421 14.0711L20.7279 12.6569L15.0711 18.3137L12.2426 15.4853Z"
          fill="fill"
          fill-opacity="1"
        />
      </svg>
    </>
  )
}

ApprovalDivergencesIcon.defaultProps = {
  height: 24,
  width: 24,
}
