export enum ColumnNames {
  Checkbox = 'checkbox',
  Code = 'code',
  InstrumentSymbol = 'instrumentSymbol',
  Quantity = 'quantity',
  OriginalQuantity = 'originalQuantity',
  EffectiveQuantity = 'effectiveQuantity',
  ProjectedQuantity = 'projectedQuantity',
  SettledQuantity = 'settledQuantity',
  CoveredQuantity = 'coveredQuantity',
  UncoveredQuantity = 'uncoveredQuantity',
  RenewalQuantity = 'renewalQuantity',
  RequestedRenewalQuantity = 'requestedRenewalQuantity',
  RequestedSettledQuantity = 'requestedSettledQuantity',
  TotalVolume = 'totalVolume',
  BasePrice = 'basePrice',
  BrokerageFee = 'brokerageFee',
  Side = 'side',
  PaymentType = 'paymentType',
  MarketModality = 'marketModality',
  SecurityLendingContractType = 'securityLendingContractType',
  MasterAccountCode = 'masterAccountCode',
  MasterOrSelfAliasOrName = 'masterOrSelfAliasOrName',
  IssuerAccountCode = 'issuerAccountCode',
  IssuerAliasOrName = 'issuerAliasOrName',
  IssuerExecutionBrokerCode = 'issuerExecutionBrokerCode',
  IssuerCarryingBrokerCode = 'issuerCarryingBrokerCode',
  IssuerCustodianCode = 'issuerCustodianCode',
  IssuerCustodyAccountCode = 'issuerCustodyAccountCode',
  IssuerPortfolio = 'issuerPortfolio',
  NegotiationFee = 'negotiationFee',
  LenderReversible = 'lenderReversible',
  TenderOfferLenderReversible = 'tenderOfferLenderReversible',
  InternalStatus = 'internalStatus',
  TradeDate = 'tradeDate',
  StartDate = 'startDate',
  LockUpDate = 'lockUpDate',
  ExpireDate = 'expireDate',
  CreationDate = 'creationDate',
  LastExchangeUpdate = 'lastExchangeUpdate',
  ContractCode = 'contractCode',
  LastUpdateTime = 'lastUpdatetime',
  ExternalStatus = 'externalStatus',
  OriginType = 'originType',
  NextPendingApproval = 'nextPendingApproval',
  NextPendingApprovingParticipant = 'nextPendingApprovingParticipant',
  Id = 'id',
  ExchangeTransactionId = 'exchangeTransactionId',
  OriginTransactionId = 'originTransactionId',
  ErrorRuleIds = 'errorRuleIds',
  SettlementDate = 'settlementDate',
  IssuerBrokerCode = 'issuerBrokerCode',
  IssuerGrossValue = 'issuerGrossValue',
  IssuerNetValue = 'issuerNetValue',
  ShownIssuerComission = 'shownIssuerComission',
  IssuerWithheldTax = 'issuerWithheldTax',
  SettlementValue = 'settlementValue',
  ExternalId = 'externalId',
  RequestType = 'requestType',
  MatchedQuantity = 'matchedQuantity',
  ApprovedQuantity = 'approvedQuantity',
  PendingQuantity = 'pendingQuantity',
  CommissionFee = 'commissionFee',
  InterBrokerFee = 'interBrokerFee',
  ReferenceDate = 'referenceDate',
  TargetBrokerCode = 'targetBrokerCode',
  DeskId = 'deskId',
  Memo = 'memo',
  DivergentFields = 'divergentFields',
  ObjectExchangeId = 'objectExchangeId',
  Feedback = 'feedback',
  AverageLenderInterest = 'averageLenderInterest',
  AverageBorrowerCharge = 'averageBorrowerCharge',
  ContractInternalStatus = 'contractInternalStatus',
  EffectiveVolume = 'effectiveVolume',
  RejectMessage = 'rejectMessage',
}
