import React, { useEffect, useMemo, useRef } from 'react'
import { Options } from '../jexcel-interfaces'
import { ModalHeader } from '../ModalHeader'
import { ButtonsContainer, TableLabel } from './style'
import { IVirtualSettleModal } from './interfaces'
import { strings } from '../../resources/strings/strings'
import { Button } from '../Button'
import SaveIcon from '@material-ui/icons/Save'
import { virtualSettleSchema, failedSchema, succeededSchema } from './schema'
import { useWindowSize } from '../../utils/hooks/useWindowSize'
import {
  MODAL_RELATIVE_HEIGHT,
  MODAL_RELATIVE_WIDTH,
  TABLE_TO_MODAL_HEIGHT_PROPORTION,
  TABLE_TO_MODAL_WIDTH_PROPORTION,
  MODAL_TABLE_DIMENSIONS,
} from '../../constants/dimensions'
import { createVirtualSettlementRequest } from '../../services/virtualSettle/createVirtualSettlement'
import { setGlobal, useGlobal } from '../../global/useGlobal'
import { CustomDialog } from '../CustomDialog'
import { usePromise } from '../../utils/hooks/usePromise'
import Table from '../CustomTable'

const VirtualSettleContent: React.FC<IVirtualSettleModal> = ({ handleClose }) => {
  const [tableRef] = useGlobal('virtualSettleTableRef')
  const [succeededTableRef] = useGlobal('succeededTableRef')
  const [failedTableRef] = useGlobal('failedTableRef')
  const virtualSettleTableRef = useRef<({ jexcel: Options } & HTMLDivElement) | null>(null)
  const virtualSettleSucceededTableRef = useRef<({ jexcel: Options } & HTMLDivElement) | null>(null)
  const virtualSettleFailedTableRef = useRef<({ jexcel: Options } & HTMLDivElement) | null>(null)

  const [isResponse] = useGlobal('isVirtualSettleResponse')
  const { windowWidth, windowHeight } = useWindowSize()
  const { virtualSettle, alerts, general, homeScreen } = strings

  const height = useMemo(() => TABLE_TO_MODAL_HEIGHT_PROPORTION * MODAL_RELATIVE_HEIGHT * windowHeight, [windowHeight])

  const width = useMemo(() => TABLE_TO_MODAL_WIDTH_PROPORTION * MODAL_RELATIVE_WIDTH * windowWidth, [windowWidth])

  const RESPONSE_TABLE_HEIGHT: number = height / 2.5

  const tableStyle = {
    height: isResponse ? RESPONSE_TABLE_HEIGHT : height,
    maxHeight: isResponse ? RESPONSE_TABLE_HEIGHT : height,
    width,
    overflow: 'auto',
    position: 'relative',
    display: 'flex',
    margin: `${isResponse ? '25' : '32'}px auto auto`,
  } as React.CSSProperties

  useEffect(() => {
    if (tableRef) {
      virtualSettleTableRef.current = tableRef.current
    }
  }, [tableRef])

  useEffect(() => {
    if (succeededTableRef) {
      virtualSettleSucceededTableRef.current = succeededTableRef.current
    }
  }, [succeededTableRef])

  useEffect(() => {
    if (failedTableRef) {
      virtualSettleFailedTableRef.current = failedTableRef.current
    }
  }, [failedTableRef])

  const onSaveVirtualSettle = async () => {
    const data = virtualSettleTableRef.current?.jexcel.getData!()
    const hasDataForRequest = data.some((row: string[]) => {
      return row.every(Boolean)
    })
    if (!hasDataForRequest) {
      setGlobal({
        dialog: {
          isOpen: true,
          title: alerts.tableEmpty,
          text: alerts.insertDataInTable,
        },
      })
      return
    }
    setGlobal({ virtualSettleData: data })
    await createVirtualSettlementRequest()
  }

  const { loading: isSaving, request: doSaveVirtualSettle } = usePromise(onSaveVirtualSettle, [], {})

  return (
    <React.Fragment>
      <ModalHeader
        title={isResponse ? virtualSettle.responseTitle : virtualSettle.requestTitle}
        handleClose={handleClose}
      />
      {isResponse ? (
        <>
          <TableLabel>{virtualSettle.successTableName}</TableLabel>
          <Table
            tableRefGlobalKey={'succeededTableRef'}
            tableColumnsGlobalKey={'succeededColumns'}
            columnWidthsGlobalKey={'succeededColumnWidths'}
            tableDataSchema={succeededSchema}
            dataGlobalKey={'virtualSettleSucceeded'}
            tableHeight={RESPONSE_TABLE_HEIGHT}
            containerHeight={RESPONSE_TABLE_HEIGHT}
            hasFooter={false}
            tableStyle={tableStyle}
            hasTransparentBackground
            shouldBlockColumnDrag
          />
          <TableLabel>{virtualSettle.failedTableName}</TableLabel>
          <Table
            tableRefGlobalKey={'failedTableRef'}
            tableColumnsGlobalKey={'failedColumns'}
            columnWidthsGlobalKey={'failedColumnWidths'}
            tableDataSchema={failedSchema}
            dataGlobalKey={'virtualSettleFailed'}
            tableHeight={RESPONSE_TABLE_HEIGHT}
            containerHeight={RESPONSE_TABLE_HEIGHT}
            hasFooter={false}
            tableStyle={tableStyle}
            hasTransparentBackground
            shouldBlockColumnDrag
          />
        </>
      ) : (
        <Table
          tableRefGlobalKey={'virtualSettleTableRef'}
          tableColumnsGlobalKey={'virtualSettleColumns'}
          columnWidthsGlobalKey={'virtualSettleColumnWidths'}
          tableDataSchema={virtualSettleSchema}
          dataGlobalKey={'virtualSettleData'}
          tableHeight={height}
          containerHeight={height}
          hasFooter={false}
          tableStyle={tableStyle}
          hasTransparentBackground
          minDimensions={MODAL_TABLE_DIMENSIONS}
          hasInsertRow
          hasDeleteRow
          isEditable
          shouldBlockColumnDrag
        />
      )}
      <CustomDialog />
      <ButtonsContainer>
        {!isResponse && <Button color="primary" type="outlined" text={general.cancel} onClick={handleClose} />}
        <Button
          color="primary"
          type="contained"
          text={isResponse ? general.close : general.save}
          startIcon={!isResponse && <SaveIcon />}
          onClick={isResponse ? handleClose : doSaveVirtualSettle}
          loading={isSaving}
        />
      </ButtonsContainer>
    </React.Fragment>
  )
}

export default VirtualSettleContent
