import styled from 'styled-components'
import { InputAdornment, TextField } from '@material-ui/core'

export const Adornment = styled(InputAdornment)`
  > svg {
    fill: ${(props: any) => props.color};
    opacity: ${(props: any) => (props.color ? '1' : '0.6')};
  }
`
export default Adornment

export const StyledTextField = styled(TextField)`
  .MuiAutocomplete-inputRoot[class*='MuiFilledInput-root'] {
    padding: ${(props: any) => (props.label ? undefined : '8px 4px')};
  }

  .MuiAutocomplete-inputRoot.MuiFilledInput-underline::before {
    border-bottom: none;
  }
  .MuiAutocomplete-inputRoot.MuiFilledInput-underline::after {
    border-bottom: none;
  }
  .MuiAutocomplete-inputRoot.MuiFilledInput-underline:hover:before {
    border-bottom: none;
  }
`
