import { BaseEndpoint, HttpMethods } from '../apiService/baseEndpoint'
import { config } from '../../constants/config'

const { BTG_CONTRACTS_URL } = config

export class DefaultDatesEndpoint<Params = undefined, Body = undefined> extends BaseEndpoint<Body, Params> {
  constructor(path: string, method: HttpMethods, params?: Params, body?: Body) {
    super({
      method,
      url: BTG_CONTRACTS_URL,
      path: `${path}extranet/domain/default-dates`,
      auth: true,
    })

    this.body = body
    this.params = params
  }
}
