import React from 'react'
import { ResetPasswordVM } from './ResetPasswordVM'
import { ResetPasswordWrapper } from './style'
import { ResetPasswordScreen } from './components/ResetPasswordScreen'
import { ResetPasswordDoneScreen } from './components/ResetPasswordDoneScreen'

interface IResetPassword {
  history?: any
}

const ResetPassword: React.FC<IResetPassword> = ({ history }) => {
  const viewModel = ResetPasswordVM.initVM()
  const { Form, onSubmit, errorMessage, screenStrings, isPasswordResetDone } = viewModel.useDataHook()

  const handleRedirect = () => {
    history.push('/login')
  }

  return (
    <ResetPasswordWrapper>
      {!isPasswordResetDone ? (
        <ResetPasswordScreen
          Form={Form}
          onSubmit={onSubmit}
          errorMessage={errorMessage}
          screenStrings={screenStrings}
        />
      ) : (
        <ResetPasswordDoneScreen handleRedirect={handleRedirect} screenStrings={screenStrings} />
      )}
    </ResetPasswordWrapper>
  )
}

export default ResetPassword
