import React from 'react'
import { CustomTextFieldView } from './CustomTextFieldView'
import { ICustomTextField } from './interfaces'

export const CustomTextField: React.FC<ICustomTextField> = ({
  name,
  mask,
  label,
  value,
  handleChange,
  onClick,
  placeholder,
  ...props
}) => {
  return (
    <CustomTextFieldView
      name={name}
      mask={mask}
      value={value}
      label={label}
      handleChange={handleChange}
      onClick={onClick}
      placeholder={placeholder}
      {...props}
    />
  )
}
