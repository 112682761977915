import { DeleteSettlementRequestEndpoint } from '../endpoint'
import { HttpMethods } from '../../apiService/baseEndpoint'

export interface SettlementRequestsDelete {
  ids: string[]
}

export const deleteSettlementRequests = async (input: SettlementRequestsDelete): Promise<any> => {
  const endpoint = new DeleteSettlementRequestEndpoint<SettlementRequestsDelete, undefined[]>(
    '',
    HttpMethods.DELETE,
    input
  )
  return endpoint.execute()
}
