import React from 'react'
import { Button } from '../Button'
import { ITableFooter } from './interfaces'
import { StyledFooter, StyledResult } from './style'

export const TableFooterView: React.FC<ITableFooter> = ({
  label,
  resultCount,
  width,
  loadMore,
  firstText,
  secondText,
  isLoadingMore,
  loadAll,
  isLoadingAll,
  hasPagination,
}) => {
  return (
    <StyledFooter width={width}>
      <StyledResult>{`${label}: ${resultCount}`}</StyledResult>
      <>
        <Button
          size={'small'}
          type={'text'}
          color={'primary'}
          text={firstText}
          loading={isLoadingMore}
          onClick={loadMore}
          disabled={!hasPagination}
        />
        <Button
          size={'small'}
          type={'text'}
          color={'primary'}
          text={secondText}
          loading={isLoadingAll}
          onClick={loadAll}
          disabled={!hasPagination}
        />
      </>
    </StyledFooter>
  )
}
