import { RefObject } from 'react'
import { Options } from '../components/jexcel-interfaces'
import { setValueFromCoords } from './jexcelMethods/setValueFromCoords'
import { openSnackBar } from '../services/pageStateService'
import { strings } from '../resources/strings/strings'

export interface enumColumnAutoCompleteInput {
  tableRef: RefObject<{ jexcel: Options } & HTMLDivElement> | undefined
  content: string
  rowIndex: number
  columnIndex: number
  options: object
}

export const enumColumnAutoComplete = (input: enumColumnAutoCompleteInput) => {
  const { tableRef, content, rowIndex, columnIndex, options } = input
  const lowerCaseContent = content.toLowerCase()
  const objectEntries = Object.entries(options)
  // Creates array of object entries where each entry is an array in the format [key, value]

  let autoCompleteContent: string = ''

  objectEntries.forEach((value, entryIndex) => {
    const lowercaseValue = value.map((item) => {
      return item.toLowerCase()
    })
    lowercaseValue.forEach((lcValue) => {
      if (lcValue.startsWith(lowerCaseContent)) {
        autoCompleteContent = objectEntries[entryIndex][0]
        // If input content matches any key or value from the object (non-case sensitive),
        // we autofill cell with corresponding key.
      }
    })
  })
  if (autoCompleteContent) {
    setValueFromCoords(tableRef, columnIndex, rowIndex, autoCompleteContent)
  } else {
    openSnackBar(strings.alerts.invalidEnumValue, false, true)
    setValueFromCoords(tableRef, columnIndex, rowIndex, '')
  }
}
