import { columnIdentificationLetters } from '../../constants/columnIdentificationLetters'
import { setCellTextColor } from './setCellTextColor'
import { colors } from '../../styles/themes/light'

export const setOrderSideColumnStyle = (tableRef: any, indexOfSideColumn: number) => {
  tableRef.current?.jexcel.getColumnData!(indexOfSideColumn).forEach((value: string, index: number) => {
    const cellIndex = index + 1
    const cellName = `${columnIdentificationLetters[indexOfSideColumn]}${cellIndex}`
    if (value === 'Borrower') {
      setCellTextColor(tableRef, cellName, colors.tableColumnVariationDown)
    }
    if (value === 'Lender') {
      setCellTextColor(tableRef, cellName, colors.tableColumnVariationUp)
    }
  })
}
