// styles/index.ts
import { createGlobalStyle } from 'styled-components'
import { normalize } from 'styled-normalize'

export const GlobalStyle = createGlobalStyle`
  ${normalize}
  body {
  	font-family: roboto, sans-serif;
  }
  // You can continue writing global styles here
  *,
  *:after,
  *:before,
  * + * {
    padding: 0;
    margin: 0;
  }
`
