import React from 'react'
import { ICustomAutoComplete } from './interfaces'
import { CustomAutoCompleteView } from './view'
import { useGlobal } from '../../global/useGlobal'
import { Instrument } from '../../services/instruments/interfaces/instrumentSymbolsFieldsDTO'

export const CustomAutoComplete: React.FC<ICustomAutoComplete> = ({
  optionsName,
  disabled,
  asset,
  label,
  name,
  placeholder,
  mask,
  value,
  onSelect,
  handleChange,
  onClick,
}) => {
  const [options] = useGlobal(optionsName)

  // @ts-ignore because TS thinks map is not callable on type (string[] | Instrument[] | AccountNumber[])
  const formattedOptions = options?.map((option: string | Instrument | AccountNumber) => {
    if (typeof option === 'string') {
      return option
    } else if ('symbol' in option) {
      return option.symbol
    } else if ('accountNumber' in option) {
      return option.accountNumber
    }
  })

  return (
    <CustomAutoCompleteView
      options={formattedOptions || []}
      disabled={disabled}
      asset={asset}
      label={label}
      name={name}
      placeholder={placeholder}
      mask={mask}
      value={value || ''}
      onSelect={onSelect}
      handleChange={handleChange}
      onClick={onClick}
    />
  )
}
