import React from 'react'
import { ICustomBoxView } from './interfaces'
import { StyledBox, StyledLabel, ChipContainer } from './style'

export const CustomBoxView: React.FC<ICustomBoxView> = ({ label, children }) => {
  return (
    <StyledBox>
      <StyledLabel>{label}</StyledLabel>
      <ChipContainer>{children}</ChipContainer>
    </StyledBox>
  )
}
