import { FeatureManager } from '../FeatureManager'
import { virtualSettlementRequest } from './request'
import { mockVirtualSettleRequest } from './mockEndpoint'
import { setGlobal } from '../../global/useGlobal'
import { ResponseData, VirtualSettlementRequest } from './interfaces/virtualSettlementsDTO'
import { getGlobal } from 'reactn'
import { displayError } from '../../utils/displayError'
import { mountRequestData } from '../../utils/mountRequestData'
import { virtualSettleColumnOrder } from '../../components/VirtualSettle/virtualSettleColumnOrder'
import { VIRTUAL_SETTLE_COLUMNS } from '../../constants/dimensions'

export const createVirtualSettlementRequest = async (): Promise<any> => {
  try {
    const virtualSettleData = getGlobal().virtualSettleData as any[]
    const body = mapTableDataToRequest(virtualSettleData)
    const response = FeatureManager.shouldMockVirtualSettlement
      ? await mockVirtualSettleRequest()
      : await virtualSettlementRequest(body)
    mapResponseToTableData(response)
    setGlobal({ isVirtualSettleResponse: true })
  } catch (error) {
    setGlobal({ isVirtualSettleResponse: false })
    displayError(error.error)
  }
}

const mapTableDataToRequest = (tableData: [][]) => {
  const filterData = tableData
    .map((row) => {
      const filterRow = row.filter(Boolean)
      if (filterRow.length === VIRTUAL_SETTLE_COLUMNS) {
        return row
      }
    })
    .filter(Boolean)

  const legs: VirtualSettlementRequest[] = mountRequestData(filterData, virtualSettleColumnOrder)

  const dataRequest = {
    legs,
  }

  return dataRequest
}

const mapResponseToTableData = (response: ResponseData) => {
  let successTableData: any[] = []
  let failedTableData: any[] = []

  response.success.forEach((item) => {
    const row = {
      contractLegCode: item.contractLegCode,
      instrumentSymbol: item.contractLeg.instrumentSymbol,
      effectiveQuantity: item.contractLeg.effectiveQuantity,
      quantity: item.quantity,
      side: item.side,
      issuerCarryingBrokerCode: item.contractLeg.issuerCarryingBrokerCode,
      issuerAccountCode: item.contractLeg.issuerAccountCode,
      internalStatus: item.internalStatus,
      errorRuleIds: item.errorRuleIds,
    }
    successTableData.push(Object.values(row))
  })

  response.failed.forEach((item) => {
    failedTableData.push(Object.values(item))
  })

  setGlobal({
    virtualSettleSucceeded: successTableData,
    virtualSettleFailed: failedTableData,
  })
}
