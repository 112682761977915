import React from 'react'
import { ITableFooter } from './interfaces'
import { TableFooterView } from './view'

const TableFooter: React.FC<ITableFooter> = ({
  label,
  resultCount,
  width,
  firstText,
  secondText,
  loadMore,
  isLoadingMore,
  loadAll,
  isLoadingAll,
  hasPagination,
}) => {
  return (
    <TableFooterView
      label={label}
      resultCount={resultCount}
      width={width}
      firstText={firstText}
      secondText={secondText}
      loadMore={loadMore}
      isLoadingMore={isLoadingMore}
      loadAll={loadAll}
      isLoadingAll={isLoadingAll}
      hasPagination={hasPagination}
    />
  )
}

export default TableFooter
