import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import { baseColors } from '../../styles/themes/baseTheme'
import styled from 'styled-components'
import { colors } from '../../styles/themes/light'
import { Tooltip } from '@material-ui/core'

const drawerWidth = 240
export const CustomDivider = withStyles({
  root: {
    backgroundColor: baseColors.customDividerBackground,
    margin: '0 8px',
  },
})(Divider)

export const ClickableTooltip = styled(Tooltip)`
  cursor: pointer;
`

export const WorkspaceMenuUseStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      position: 'fixed',
      zIndex: 1200,
    },
    drawerOpen: {
      paddingTop: 140,
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    drawerClose: {
      paddingTop: 140,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: 56,
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    primaryIcon: {
      color: colors.background,
      backgroundColor: colors.primary,
      cursor: 'pointer',
      borderRadius: 4,
      width: 40,
      height: 40,
      padding: 8,
    },
    secondaryIcon: {
      color: colors.background,
      backgroundColor: colors.secondary,
      cursor: 'pointer',
      borderRadius: 4,
      width: 40,
      height: 40,
      padding: 8,
    },
    defaultIcon: {
      color: colors.primary,
      backgroundColor: colors.background,
      cursor: 'pointer',
      borderRadius: 4,
      border: `1px solid ${colors.lightGray}`,
      width: 40,
      height: 40,
      padding: 8,
    },
    selectedIcon: {
      color: colors.black,
      backgroundColor: colors.surface,
      cursor: 'pointer',
      borderRadius: 4,
      width: 40,
      height: 40,
      padding: 8,
    },
    icon: {
      color: colors.moduleAssetSelectIcon,
      backgroundColor: colors.surface,
      cursor: 'pointer',
      borderRadius: 4,
      width: 40,
      height: 40,
      padding: 8,
    },
  })
)

export const IconWrapper = styled.div<any>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 8px;
  pointer-events: ${({ isLoading }) => (isLoading ? 'none' : 'auto')};
  opacity: ${({ isLoading }) => (isLoading ? 0.3 : 1)};
`
