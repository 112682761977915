import { InstrumentsFieldsDTO } from './interfaces/instrumentSymbolsFieldsDTO'

export const mockInstrumentSymbolsRequest = async (): Promise<InstrumentsFieldsDTO> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const assets = {
        count: 636,
        instruments: [
          {
            symbol: 'AALR3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.65,
              avgLenderInterest: 0.28,
              avgBorrowerCharge: 1.01,
            },
          },
          {
            symbol: 'AAPL34',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 5.15,
              avgLenderInterest: 4.8,
              avgBorrowerCharge: 5.5,
            },
          },
          {
            symbol: 'ABCB4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.19,
              avgLenderInterest: 0.14,
              avgBorrowerCharge: 0.24,
            },
          },
          {
            symbol: 'ABEV3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.11,
              avgLenderInterest: 0.04,
              avgBorrowerCharge: 0.18,
            },
          },
          {
            symbol: 'AGRO3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.2,
              avgLenderInterest: 0.13,
              avgBorrowerCharge: 0.26,
            },
          },
          {
            symbol: 'ALPA4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.66,
              avgLenderInterest: 0.33,
              avgBorrowerCharge: 0.99,
            },
          },
          {
            symbol: 'ALSO3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.13,
              avgLenderInterest: 0.1,
              avgBorrowerCharge: 0.15,
            },
          },
          {
            symbol: 'ALUP11',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.06,
              avgLenderInterest: 0.04,
              avgBorrowerCharge: 0.08,
            },
          },
          {
            symbol: 'AMAR3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.51,
              avgLenderInterest: 0.34,
              avgBorrowerCharge: 0.67,
            },
          },
          {
            symbol: 'AMBP3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 1.98,
              avgLenderInterest: 1.67,
              avgBorrowerCharge: 2.29,
            },
          },
          {
            symbol: 'ANIM3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.34,
              avgLenderInterest: 0.16,
              avgBorrowerCharge: 0.51,
            },
          },
          {
            symbol: 'ARZZ3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.31,
              avgLenderInterest: 0.25,
              avgBorrowerCharge: 0.37,
            },
          },
          {
            symbol: 'AURA32',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 2.98,
              avgLenderInterest: 2.45,
              avgBorrowerCharge: 3.5,
            },
          },
          {
            symbol: 'AZEV4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 4,
              avgLenderInterest: 3,
              avgBorrowerCharge: 5,
            },
          },
          {
            symbol: 'AZUL4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.33,
              avgLenderInterest: 0.25,
              avgBorrowerCharge: 0.41,
            },
          },
          {
            symbol: 'B3SA3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.12,
              avgLenderInterest: 0.08,
              avgBorrowerCharge: 0.16,
            },
          },
          {
            symbol: 'BBAS3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.11,
              avgLenderInterest: 0.05,
              avgBorrowerCharge: 0.16,
            },
          },
          {
            symbol: 'BBDC3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.13,
              avgLenderInterest: 0.09,
              avgBorrowerCharge: 0.16,
            },
          },
          {
            symbol: 'BBDC4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.04,
              avgLenderInterest: 0.02,
              avgBorrowerCharge: 0.06,
            },
          },
          {
            symbol: 'BBRK3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.58,
              avgLenderInterest: 0.36,
              avgBorrowerCharge: 0.79,
            },
          },
          {
            symbol: 'BBSE3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.21,
              avgLenderInterest: 0.17,
              avgBorrowerCharge: 0.24,
            },
          },
          {
            symbol: 'BEEF3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 1.88,
              avgLenderInterest: 1.83,
              avgBorrowerCharge: 1.92,
            },
          },
          {
            symbol: 'BIDI11',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 3.58,
              avgLenderInterest: 2.39,
              avgBorrowerCharge: 4.77,
            },
          },
          {
            symbol: 'BIDI3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 9.9,
              avgLenderInterest: 7.42,
              avgBorrowerCharge: 12.37,
            },
          },
          {
            symbol: 'BIDI4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 2.51,
              avgLenderInterest: 2.07,
              avgBorrowerCharge: 2.94,
            },
          },
          {
            symbol: 'BKBR3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 2.67,
              avgLenderInterest: 2.28,
              avgBorrowerCharge: 3.06,
            },
          },
          {
            symbol: 'BMGB4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 1.51,
              avgLenderInterest: 1.1,
              avgBorrowerCharge: 1.91,
            },
          },
          {
            symbol: 'BOVA11',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 1.24,
              avgLenderInterest: 1.13,
              avgBorrowerCharge: 1.34,
            },
          },
          {
            symbol: 'BOVB11',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.08,
              avgLenderInterest: 0.06,
              avgBorrowerCharge: 0.09,
            },
          },
          {
            symbol: 'BOVV11',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 1.28,
              avgLenderInterest: 1.17,
              avgBorrowerCharge: 1.39,
            },
          },
          {
            symbol: 'BPAC11',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.29,
              avgLenderInterest: 0.2,
              avgBorrowerCharge: 0.37,
            },
          },
          {
            symbol: 'BPAC5',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.08,
              avgLenderInterest: 0.05,
              avgBorrowerCharge: 0.1,
            },
          },
          {
            symbol: 'BPAN4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.92,
              avgLenderInterest: 0.63,
              avgBorrowerCharge: 1.21,
            },
          },
          {
            symbol: 'BRAP3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.28,
              avgLenderInterest: 0.25,
              avgBorrowerCharge: 0.3,
            },
          },
          {
            symbol: 'BRAP4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.15,
              avgLenderInterest: 0.1,
              avgBorrowerCharge: 0.19,
            },
          },
          {
            symbol: 'BRDT3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.08,
              avgLenderInterest: 0.07,
              avgBorrowerCharge: 0.09,
            },
          },
          {
            symbol: 'BRFS3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.13,
              avgLenderInterest: 0.11,
              avgBorrowerCharge: 0.14,
            },
          },
          {
            symbol: 'BRKM5',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.45,
              avgLenderInterest: 0.37,
              avgBorrowerCharge: 0.52,
            },
          },
          {
            symbol: 'BRML3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.14,
              avgLenderInterest: 0.11,
              avgBorrowerCharge: 0.17,
            },
          },
          {
            symbol: 'BRPR3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.88,
              avgLenderInterest: 0.72,
              avgBorrowerCharge: 1.03,
            },
          },
          {
            symbol: 'BRSR6',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.76,
              avgLenderInterest: 0.56,
              avgBorrowerCharge: 0.96,
            },
          },
          {
            symbol: 'BSEV3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 5.42,
              avgLenderInterest: 3.7,
              avgBorrowerCharge: 7.14,
            },
          },
          {
            symbol: 'BTOW3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.32,
              avgLenderInterest: 0.2,
              avgBorrowerCharge: 0.44,
            },
          },
          {
            symbol: 'CAMB3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 5.1,
              avgLenderInterest: 4.2,
              avgBorrowerCharge: 6,
            },
          },
          {
            symbol: 'CAML3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.72,
              avgLenderInterest: 0.64,
              avgBorrowerCharge: 0.79,
            },
          },
          {
            symbol: 'CARD3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 4.71,
              avgLenderInterest: 2.97,
              avgBorrowerCharge: 6.44,
            },
          },
          {
            symbol: 'CCPR3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.08,
              avgLenderInterest: 0.04,
              avgBorrowerCharge: 0.11,
            },
          },
          {
            symbol: 'CCRO3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.16,
              avgLenderInterest: 0.13,
              avgBorrowerCharge: 0.19,
            },
          },
          {
            symbol: 'CEAB3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.46,
              avgLenderInterest: 0.4,
              avgBorrowerCharge: 0.52,
            },
          },
          {
            symbol: 'CEDO4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 1.75,
              avgLenderInterest: 1,
              avgBorrowerCharge: 2.5,
            },
          },
          {
            symbol: 'CESP3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 3.73,
              avgLenderInterest: 1.46,
              avgBorrowerCharge: 6,
            },
          },
          {
            symbol: 'CESP6',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.18,
              avgLenderInterest: 0.15,
              avgBorrowerCharge: 0.2,
            },
          },
          {
            symbol: 'CGRA4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 4,
              avgLenderInterest: 3,
              avgBorrowerCharge: 5,
            },
          },
          {
            symbol: 'CIEL3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 4.43,
              avgLenderInterest: 4.08,
              avgBorrowerCharge: 4.78,
            },
          },
          {
            symbol: 'CMIG3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 1.73,
              avgLenderInterest: 1.21,
              avgBorrowerCharge: 2.24,
            },
          },
          {
            symbol: 'CMIG4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.08,
              avgLenderInterest: 0.05,
              avgBorrowerCharge: 0.1,
            },
          },
          {
            symbol: 'CNTO3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.25,
              avgLenderInterest: 0.17,
              avgBorrowerCharge: 0.33,
            },
          },
          {
            symbol: 'COGN3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 1.15,
              avgLenderInterest: 0.8,
              avgBorrowerCharge: 1.49,
            },
          },
          {
            symbol: 'CPFE3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.12,
              avgLenderInterest: 0.09,
              avgBorrowerCharge: 0.14,
            },
          },
          {
            symbol: 'CPLE3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 1.5,
              avgLenderInterest: 1.12,
              avgBorrowerCharge: 1.87,
            },
          },
          {
            symbol: 'CPLE6',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.34,
              avgLenderInterest: 0.22,
              avgBorrowerCharge: 0.46,
            },
          },
          {
            symbol: 'CRFB3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.34,
              avgLenderInterest: 0.3,
              avgBorrowerCharge: 0.37,
            },
          },
          {
            symbol: 'CSAN3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 1.83,
              avgLenderInterest: 1.76,
              avgBorrowerCharge: 1.89,
            },
          },
          {
            symbol: 'CSMG3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.35,
              avgLenderInterest: 0.24,
              avgBorrowerCharge: 0.46,
            },
          },
          {
            symbol: 'CSNA3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.24,
              avgLenderInterest: 0.17,
              avgBorrowerCharge: 0.3,
            },
          },
          {
            symbol: 'CTNM4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 2.31,
              avgLenderInterest: 1.04,
              avgBorrowerCharge: 3.58,
            },
          },
          {
            symbol: 'CURY3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 13,
              avgLenderInterest: 10,
              avgBorrowerCharge: 16,
            },
          },
          {
            symbol: 'CVCB3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 4.55,
              avgLenderInterest: 3.93,
              avgBorrowerCharge: 5.16,
            },
          },
          {
            symbol: 'CVSH34',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 5.05,
              avgLenderInterest: 3.6,
              avgBorrowerCharge: 6.5,
            },
          },
          {
            symbol: 'CYRE3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.19,
              avgLenderInterest: 0.15,
              avgBorrowerCharge: 0.22,
            },
          },
          {
            symbol: 'DIRR3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.67,
              avgLenderInterest: 0.3,
              avgBorrowerCharge: 1.03,
            },
          },
          {
            symbol: 'DIVO11',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 3.2,
              avgLenderInterest: 2.4,
              avgBorrowerCharge: 4,
            },
          },
          {
            symbol: 'DMMO3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 7.46,
              avgLenderInterest: 5.47,
              avgBorrowerCharge: 9.44,
            },
          },
          {
            symbol: 'DMVF3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 5.7,
              avgLenderInterest: 3.7,
              avgBorrowerCharge: 7.69,
            },
          },
          {
            symbol: 'DOHL4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 3,
              avgLenderInterest: 3,
              avgBorrowerCharge: 3,
            },
          },
          {
            symbol: 'DTEX3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.31,
              avgLenderInterest: 0.18,
              avgBorrowerCharge: 0.43,
            },
          },
          {
            symbol: 'ECOR3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.16,
              avgLenderInterest: 0.12,
              avgBorrowerCharge: 0.19,
            },
          },
          {
            symbol: 'EGIE3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.2,
              avgLenderInterest: 0.11,
              avgBorrowerCharge: 0.29,
            },
          },
          {
            symbol: 'ELET3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.12,
              avgLenderInterest: 0.1,
              avgBorrowerCharge: 0.13,
            },
          },
          {
            symbol: 'ELET6',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.18,
              avgLenderInterest: 0.13,
              avgBorrowerCharge: 0.22,
            },
          },
          {
            symbol: 'EMBR3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 4.57,
              avgLenderInterest: 3.8,
              avgBorrowerCharge: 5.34,
            },
          },
          {
            symbol: 'ENAT3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.21,
              avgLenderInterest: 0.11,
              avgBorrowerCharge: 0.31,
            },
          },
          {
            symbol: 'ENBR3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.13,
              avgLenderInterest: 0.08,
              avgBorrowerCharge: 0.17,
            },
          },
          {
            symbol: 'ENEV3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.46,
              avgLenderInterest: 0.45,
              avgBorrowerCharge: 0.47,
            },
          },
          {
            symbol: 'ENGI11',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.25,
              avgLenderInterest: 0.19,
              avgBorrowerCharge: 0.3,
            },
          },
          {
            symbol: 'EQTL3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.22,
              avgLenderInterest: 0.18,
              avgBorrowerCharge: 0.25,
            },
          },
          {
            symbol: 'ETER3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.73,
              avgLenderInterest: 0.44,
              avgBorrowerCharge: 1.02,
            },
          },
          {
            symbol: 'EVEN3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.69,
              avgLenderInterest: 0.64,
              avgBorrowerCharge: 0.73,
            },
          },
          {
            symbol: 'EZTC3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.17,
              avgLenderInterest: 0.14,
              avgBorrowerCharge: 0.19,
            },
          },
          {
            symbol: 'FBOK34',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 2.37,
              avgLenderInterest: 2.21,
              avgBorrowerCharge: 2.53,
            },
          },
          {
            symbol: 'FESA4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.32,
              avgLenderInterest: 0.29,
              avgBorrowerCharge: 0.35,
            },
          },
          {
            symbol: 'FHER3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 2.83,
              avgLenderInterest: 2.1,
              avgBorrowerCharge: 3.56,
            },
          },
          {
            symbol: 'FLRY3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.29,
              avgLenderInterest: 0.23,
              avgBorrowerCharge: 0.35,
            },
          },
          {
            symbol: 'GFSA3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 3.3,
              avgLenderInterest: 2.3,
              avgBorrowerCharge: 4.3,
            },
          },
          {
            symbol: 'GGBR3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 1.54,
              avgLenderInterest: 1.03,
              avgBorrowerCharge: 2.04,
            },
          },
          {
            symbol: 'GGBR4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 2.36,
              avgLenderInterest: 2.04,
              avgBorrowerCharge: 2.67,
            },
          },
          {
            symbol: 'GNDI3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.18,
              avgLenderInterest: 0.11,
              avgBorrowerCharge: 0.25,
            },
          },
          {
            symbol: 'GOAU3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 1.97,
              avgLenderInterest: 1.28,
              avgBorrowerCharge: 2.66,
            },
          },
          {
            symbol: 'GOAU4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.11,
              avgLenderInterest: 0.07,
              avgBorrowerCharge: 0.14,
            },
          },
          {
            symbol: 'GOLL4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 1.52,
              avgLenderInterest: 1.38,
              avgBorrowerCharge: 1.65,
            },
          },
          {
            symbol: 'GPCP3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.56,
              avgLenderInterest: 0.4,
              avgBorrowerCharge: 0.72,
            },
          },
          {
            symbol: 'GRND3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.26,
              avgLenderInterest: 0.2,
              avgBorrowerCharge: 0.32,
            },
          },
          {
            symbol: 'GSHP3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 1.2,
              avgLenderInterest: 1.17,
              avgBorrowerCharge: 1.22,
            },
          },
          {
            symbol: 'GUAR3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.47,
              avgLenderInterest: 0.41,
              avgBorrowerCharge: 0.52,
            },
          },
          {
            symbol: 'HAPV3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.16,
              avgLenderInterest: 0.14,
              avgBorrowerCharge: 0.18,
            },
          },
          {
            symbol: 'HBOR3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.79,
              avgLenderInterest: 0.6,
              avgBorrowerCharge: 0.98,
            },
          },
          {
            symbol: 'HGTX3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 1.58,
              avgLenderInterest: 1.22,
              avgBorrowerCharge: 1.93,
            },
          },
          {
            symbol: 'HYPE3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.42,
              avgLenderInterest: 0.39,
              avgBorrowerCharge: 0.44,
            },
          },
          {
            symbol: 'IGBR3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 3.95,
              avgLenderInterest: 2.3,
              avgBorrowerCharge: 5.59,
            },
          },
          {
            symbol: 'IGTA3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.28,
              avgLenderInterest: 0.24,
              avgBorrowerCharge: 0.32,
            },
          },
          {
            symbol: 'IRBR3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 6.83,
              avgLenderInterest: 5.89,
              avgBorrowerCharge: 7.76,
            },
          },
          {
            symbol: 'ITSA3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.67,
              avgLenderInterest: 0.36,
              avgBorrowerCharge: 0.98,
            },
          },
          {
            symbol: 'ITSA4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.07,
              avgLenderInterest: 0.05,
              avgBorrowerCharge: 0.09,
            },
          },
          {
            symbol: 'ITUB3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.44,
              avgLenderInterest: 0.25,
              avgBorrowerCharge: 0.63,
            },
          },
          {
            symbol: 'ITUB4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.05,
              avgLenderInterest: 0.03,
              avgBorrowerCharge: 0.06,
            },
          },
          {
            symbol: 'IVVB11',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 4.02,
              avgLenderInterest: 2.17,
              avgBorrowerCharge: 5.87,
            },
          },
          {
            symbol: 'JBSS3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.64,
              avgLenderInterest: 0.46,
              avgBorrowerCharge: 0.82,
            },
          },
          {
            symbol: 'JFEN3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 3.81,
              avgLenderInterest: 2.21,
              avgBorrowerCharge: 5.4,
            },
          },
          {
            symbol: 'JHSF3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 1.13,
              avgLenderInterest: 0.69,
              avgBorrowerCharge: 1.56,
            },
          },
          {
            symbol: 'JPSA3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.31,
              avgLenderInterest: 0.19,
              avgBorrowerCharge: 0.42,
            },
          },
          {
            symbol: 'KEPL3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 3.54,
              avgLenderInterest: 2.11,
              avgBorrowerCharge: 4.96,
            },
          },
          {
            symbol: 'KLBN11',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.2,
              avgLenderInterest: 0.15,
              avgBorrowerCharge: 0.25,
            },
          },
          {
            symbol: 'KLBN3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 1.07,
              avgLenderInterest: 0.78,
              avgBorrowerCharge: 1.35,
            },
          },
          {
            symbol: 'KLBN4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.17,
              avgLenderInterest: 0.11,
              avgBorrowerCharge: 0.23,
            },
          },
          {
            symbol: 'LAME3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.23,
              avgLenderInterest: 0.14,
              avgBorrowerCharge: 0.32,
            },
          },
          {
            symbol: 'LAME4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.18,
              avgLenderInterest: 0.16,
              avgBorrowerCharge: 0.2,
            },
          },
          {
            symbol: 'LAVV3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 2.46,
              avgLenderInterest: 2.19,
              avgBorrowerCharge: 2.73,
            },
          },
          {
            symbol: 'LCAM3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.13,
              avgLenderInterest: 0.1,
              avgBorrowerCharge: 0.16,
            },
          },
          {
            symbol: 'LEVE3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.3,
              avgLenderInterest: 0.15,
              avgBorrowerCharge: 0.44,
            },
          },
          {
            symbol: 'LIGT3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.16,
              avgLenderInterest: 0.08,
              avgBorrowerCharge: 0.23,
            },
          },
          {
            symbol: 'LINX3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 1.43,
              avgLenderInterest: 1.38,
              avgBorrowerCharge: 1.48,
            },
          },
          {
            symbol: 'LJQQ3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 1.02,
              avgLenderInterest: 0.77,
              avgBorrowerCharge: 1.27,
            },
          },
          {
            symbol: 'LLIS3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 2.86,
              avgLenderInterest: 2.09,
              avgBorrowerCharge: 3.62,
            },
          },
          {
            symbol: 'LOGG3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.29,
              avgLenderInterest: 0.23,
              avgBorrowerCharge: 0.35,
            },
          },
          {
            symbol: 'LOGN3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.33,
              avgLenderInterest: 0.22,
              avgBorrowerCharge: 0.43,
            },
          },
          {
            symbol: 'LPSB3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 1.96,
              avgLenderInterest: 1.47,
              avgBorrowerCharge: 2.45,
            },
          },
          {
            symbol: 'LREN3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.13,
              avgLenderInterest: 0.09,
              avgBorrowerCharge: 0.17,
            },
          },
          {
            symbol: 'LUPA3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 1.01,
              avgLenderInterest: 0.74,
              avgBorrowerCharge: 1.28,
            },
          },
          {
            symbol: 'LWSA3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.62,
              avgLenderInterest: 0.4,
              avgBorrowerCharge: 0.83,
            },
          },
          {
            symbol: 'MATB11',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 4.8,
              avgLenderInterest: 3.6,
              avgBorrowerCharge: 6,
            },
          },
          {
            symbol: 'MDIA3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.29,
              avgLenderInterest: 0.2,
              avgBorrowerCharge: 0.37,
            },
          },
          {
            symbol: 'MDNE3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 1.85,
              avgLenderInterest: 1.6,
              avgBorrowerCharge: 2.09,
            },
          },
          {
            symbol: 'MEAL3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 1.77,
              avgLenderInterest: 0.9,
              avgBorrowerCharge: 2.64,
            },
          },
          {
            symbol: 'MGLU3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.17,
              avgLenderInterest: 0.1,
              avgBorrowerCharge: 0.24,
            },
          },
          {
            symbol: 'MILS3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.36,
              avgLenderInterest: 0.16,
              avgBorrowerCharge: 0.55,
            },
          },
          {
            symbol: 'MMXM3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 4,
              avgLenderInterest: 3,
              avgBorrowerCharge: 5,
            },
          },
          {
            symbol: 'MOVI3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.2,
              avgLenderInterest: 0.14,
              avgBorrowerCharge: 0.25,
            },
          },
          {
            symbol: 'MRFG3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.33,
              avgLenderInterest: 0.25,
              avgBorrowerCharge: 0.41,
            },
          },
          {
            symbol: 'MRVE3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.66,
              avgLenderInterest: 0.62,
              avgBorrowerCharge: 0.7,
            },
          },
          {
            symbol: 'MTRE3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 6.13,
              avgLenderInterest: 3.25,
              avgBorrowerCharge: 9.01,
            },
          },
          {
            symbol: 'MULT3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.32,
              avgLenderInterest: 0.21,
              avgBorrowerCharge: 0.43,
            },
          },
          {
            symbol: 'MYPK3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.36,
              avgLenderInterest: 0.24,
              avgBorrowerCharge: 0.47,
            },
          },
          {
            symbol: 'NEOE3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.25,
              avgLenderInterest: 0.1,
              avgBorrowerCharge: 0.39,
            },
          },
          {
            symbol: 'NTCO3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.11,
              avgLenderInterest: 0.1,
              avgBorrowerCharge: 0.12,
            },
          },
          {
            symbol: 'ODPV3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.73,
              avgLenderInterest: 0.66,
              avgBorrowerCharge: 0.79,
            },
          },
          {
            symbol: 'OIBR3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 1.14,
              avgLenderInterest: 0.94,
              avgBorrowerCharge: 1.33,
            },
          },
          {
            symbol: 'OIBR4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 8.08,
              avgLenderInterest: 4.44,
              avgBorrowerCharge: 11.71,
            },
          },
          {
            symbol: 'OMGE3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.34,
              avgLenderInterest: 0.21,
              avgBorrowerCharge: 0.46,
            },
          },
          {
            symbol: 'PARD3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.3,
              avgLenderInterest: 0.24,
              avgBorrowerCharge: 0.35,
            },
          },
          {
            symbol: 'PCAR3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.44,
              avgLenderInterest: 0.26,
              avgBorrowerCharge: 0.61,
            },
          },
          {
            symbol: 'PDGR3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 3.49,
              avgLenderInterest: 2.27,
              avgBorrowerCharge: 4.71,
            },
          },
          {
            symbol: 'PETR3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.03,
              avgLenderInterest: 0.02,
              avgBorrowerCharge: 0.04,
            },
          },
          {
            symbol: 'PETR4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.2,
              avgLenderInterest: 0.06,
              avgBorrowerCharge: 0.33,
            },
          },
          {
            symbol: 'PETZ3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 5.91,
              avgLenderInterest: 3.14,
              avgBorrowerCharge: 8.68,
            },
          },
          {
            symbol: 'PFRM3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 3.16,
              avgLenderInterest: 1.86,
              avgBorrowerCharge: 4.45,
            },
          },
          {
            symbol: 'PGMN3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.98,
              avgLenderInterest: 0.67,
              avgBorrowerCharge: 1.28,
            },
          },
          {
            symbol: 'PIBB11',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 3.85,
              avgLenderInterest: 3.09,
              avgBorrowerCharge: 4.6,
            },
          },
          {
            symbol: 'PINE4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 2.14,
              avgLenderInterest: 1.85,
              avgBorrowerCharge: 2.42,
            },
          },
          {
            symbol: 'PLPL3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 5.02,
              avgLenderInterest: 4.73,
              avgBorrowerCharge: 5.3,
            },
          },
          {
            symbol: 'PMAM3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.6,
              avgLenderInterest: 0.43,
              avgBorrowerCharge: 0.76,
            },
          },
          {
            symbol: 'PNVL3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.2,
              avgLenderInterest: 0.11,
              avgBorrowerCharge: 0.29,
            },
          },
          {
            symbol: 'POMO3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 3.04,
              avgLenderInterest: 1.97,
              avgBorrowerCharge: 4.11,
            },
          },
          {
            symbol: 'POMO4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.68,
              avgLenderInterest: 0.44,
              avgBorrowerCharge: 0.92,
            },
          },
          {
            symbol: 'POSI3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 3.51,
              avgLenderInterest: 2.44,
              avgBorrowerCharge: 4.58,
            },
          },
          {
            symbol: 'PRIO3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 1.13,
              avgLenderInterest: 0.36,
              avgBorrowerCharge: 1.89,
            },
          },
          {
            symbol: 'PRNR3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 4.54,
              avgLenderInterest: 2.68,
              avgBorrowerCharge: 6.4,
            },
          },
          {
            symbol: 'PSSA3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.44,
              avgLenderInterest: 0.37,
              avgBorrowerCharge: 0.51,
            },
          },
          {
            symbol: 'PTBL3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 2.02,
              avgLenderInterest: 1.25,
              avgBorrowerCharge: 2.79,
            },
          },
          {
            symbol: 'PTNT4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 3.64,
              avgLenderInterest: 2.68,
              avgBorrowerCharge: 4.6,
            },
          },
          {
            symbol: 'QUAL3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.29,
              avgLenderInterest: 0.2,
              avgBorrowerCharge: 0.38,
            },
          },
          {
            symbol: 'RADL3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.24,
              avgLenderInterest: 0.17,
              avgBorrowerCharge: 0.31,
            },
          },
          {
            symbol: 'RAIL3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.29,
              avgLenderInterest: 0.13,
              avgBorrowerCharge: 0.44,
            },
          },
          {
            symbol: 'RANI3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 3.45,
              avgLenderInterest: 1.6,
              avgBorrowerCharge: 5.29,
            },
          },
          {
            symbol: 'RAPT4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.14,
              avgLenderInterest: 0.09,
              avgBorrowerCharge: 0.19,
            },
          },
          {
            symbol: 'RCSL4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 3.54,
              avgLenderInterest: 2.7,
              avgBorrowerCharge: 4.37,
            },
          },
          {
            symbol: 'RDNI3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 3.27,
              avgLenderInterest: 2.45,
              avgBorrowerCharge: 4.08,
            },
          },
          {
            symbol: 'RENT3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.22,
              avgLenderInterest: 0.16,
              avgBorrowerCharge: 0.27,
            },
          },
          {
            symbol: 'RLOG3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.15,
              avgLenderInterest: 0.12,
              avgBorrowerCharge: 0.18,
            },
          },
          {
            symbol: 'RNEW11',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 3.75,
              avgLenderInterest: 2.5,
              avgBorrowerCharge: 5,
            },
          },
          {
            symbol: 'ROMI3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 1.29,
              avgLenderInterest: 0.37,
              avgBorrowerCharge: 2.2,
            },
          },
          {
            symbol: 'RSID3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.94,
              avgLenderInterest: 0.56,
              avgBorrowerCharge: 1.31,
            },
          },
          {
            symbol: 'SANB11',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.21,
              avgLenderInterest: 0.17,
              avgBorrowerCharge: 0.24,
            },
          },
          {
            symbol: 'SANB4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 1.47,
              avgLenderInterest: 0.76,
              avgBorrowerCharge: 2.17,
            },
          },
          {
            symbol: 'SAPR11',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.38,
              avgLenderInterest: 0.19,
              avgBorrowerCharge: 0.57,
            },
          },
          {
            symbol: 'SAPR4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.81,
              avgLenderInterest: 0.59,
              avgBorrowerCharge: 1.02,
            },
          },
          {
            symbol: 'SBSP3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.17,
              avgLenderInterest: 0.15,
              avgBorrowerCharge: 0.19,
            },
          },
          {
            symbol: 'SCAR3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 1.23,
              avgLenderInterest: 1.2,
              avgBorrowerCharge: 1.25,
            },
          },
          {
            symbol: 'SEER3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 1.59,
              avgLenderInterest: 1.1,
              avgBorrowerCharge: 2.08,
            },
          },
          {
            symbol: 'SGPS3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 2.44,
              avgLenderInterest: 1.8,
              avgBorrowerCharge: 3.07,
            },
          },
          {
            symbol: 'SHOW3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 2.92,
              avgLenderInterest: 1.56,
              avgBorrowerCharge: 4.27,
            },
          },
          {
            symbol: 'SHUL4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 2.18,
              avgLenderInterest: 1.59,
              avgBorrowerCharge: 2.77,
            },
          },
          {
            symbol: 'SIMH3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 1.59,
              avgLenderInterest: 1.24,
              avgBorrowerCharge: 1.93,
            },
          },
          {
            symbol: 'SLCE3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.5,
              avgLenderInterest: 0.46,
              avgBorrowerCharge: 0.53,
            },
          },
          {
            symbol: 'SLED4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 1.63,
              avgLenderInterest: 1.2,
              avgBorrowerCharge: 2.05,
            },
          },
          {
            symbol: 'SMAC11',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 1.91,
              avgLenderInterest: 1.79,
              avgBorrowerCharge: 2.02,
            },
          },
          {
            symbol: 'SMAL11',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 3.78,
              avgLenderInterest: 3.41,
              avgBorrowerCharge: 4.14,
            },
          },
          {
            symbol: 'SMLS3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.48,
              avgLenderInterest: 0.37,
              avgBorrowerCharge: 0.58,
            },
          },
          {
            symbol: 'SMTO3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.46,
              avgLenderInterest: 0.41,
              avgBorrowerCharge: 0.5,
            },
          },
          {
            symbol: 'SOMA3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.57,
              avgLenderInterest: 0.5,
              avgBorrowerCharge: 0.64,
            },
          },
          {
            symbol: 'SPXI11',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 3.02,
              avgLenderInterest: 2.28,
              avgBorrowerCharge: 3.76,
            },
          },
          {
            symbol: 'SQIA3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.33,
              avgLenderInterest: 0.2,
              avgBorrowerCharge: 0.46,
            },
          },
          {
            symbol: 'STBP3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.77,
              avgLenderInterest: 0.45,
              avgBorrowerCharge: 1.08,
            },
          },
          {
            symbol: 'SULA11',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.33,
              avgLenderInterest: 0.15,
              avgBorrowerCharge: 0.5,
            },
          },
          {
            symbol: 'SUZB3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.2,
              avgLenderInterest: 0.14,
              avgBorrowerCharge: 0.26,
            },
          },
          {
            symbol: 'TAEE11',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 2.46,
              avgLenderInterest: 1.89,
              avgBorrowerCharge: 3.03,
            },
          },
          {
            symbol: 'TAEE3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 2.72,
              avgLenderInterest: 2.24,
              avgBorrowerCharge: 3.2,
            },
          },
          {
            symbol: 'TAEE4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 1.6,
              avgLenderInterest: 1.2,
              avgBorrowerCharge: 2,
            },
          },
          {
            symbol: 'TASA3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 5.26,
              avgLenderInterest: 4.97,
              avgBorrowerCharge: 5.54,
            },
          },
          {
            symbol: 'TASA4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 4.25,
              avgLenderInterest: 3.13,
              avgBorrowerCharge: 5.36,
            },
          },
          {
            symbol: 'TCSA3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.49,
              avgLenderInterest: 0.26,
              avgBorrowerCharge: 0.71,
            },
          },
          {
            symbol: 'TECN3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.49,
              avgLenderInterest: 0.4,
              avgBorrowerCharge: 0.58,
            },
          },
          {
            symbol: 'TELB4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 3.99,
              avgLenderInterest: 2.8,
              avgBorrowerCharge: 5.18,
            },
          },
          {
            symbol: 'TEND3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.18,
              avgLenderInterest: 0.1,
              avgBorrowerCharge: 0.26,
            },
          },
          {
            symbol: 'TESA3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 2.06,
              avgLenderInterest: 2.01,
              avgBorrowerCharge: 2.11,
            },
          },
          {
            symbol: 'TGMA3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 1.77,
              avgLenderInterest: 0.57,
              avgBorrowerCharge: 2.96,
            },
          },
          {
            symbol: 'TIET11',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.19,
              avgLenderInterest: 0.06,
              avgBorrowerCharge: 0.32,
            },
          },
          {
            symbol: 'TIET3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 1.13,
              avgLenderInterest: 0.67,
              avgBorrowerCharge: 1.58,
            },
          },
          {
            symbol: 'TIET4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 1.89,
              avgLenderInterest: 1.17,
              avgBorrowerCharge: 2.6,
            },
          },
          {
            symbol: 'TIMP3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.06,
              avgLenderInterest: 0.03,
              avgBorrowerCharge: 0.08,
            },
          },
          {
            symbol: 'TOTS3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.28,
              avgLenderInterest: 0.25,
              avgBorrowerCharge: 0.31,
            },
          },
          {
            symbol: 'TPIS3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 1.54,
              avgLenderInterest: 1.13,
              avgBorrowerCharge: 1.94,
            },
          },
          {
            symbol: 'TRIS3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.91,
              avgLenderInterest: 0.58,
              avgBorrowerCharge: 1.23,
            },
          },
          {
            symbol: 'TRPL4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.89,
              avgLenderInterest: 0.52,
              avgBorrowerCharge: 1.26,
            },
          },
          {
            symbol: 'TSLA34',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 5.16,
              avgLenderInterest: 4.94,
              avgBorrowerCharge: 5.38,
            },
          },
          {
            symbol: 'TUPY3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.15,
              avgLenderInterest: 0.12,
              avgBorrowerCharge: 0.17,
            },
          },
          {
            symbol: 'UCAS3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 1.78,
              avgLenderInterest: 0.96,
              avgBorrowerCharge: 2.6,
            },
          },
          {
            symbol: 'UGPA3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 1.11,
              avgLenderInterest: 0.87,
              avgBorrowerCharge: 1.35,
            },
          },
          {
            symbol: 'UNIP6',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.16,
              avgLenderInterest: 0.12,
              avgBorrowerCharge: 0.2,
            },
          },
          {
            symbol: 'USIM3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 2.04,
              avgLenderInterest: 1.1,
              avgBorrowerCharge: 2.98,
            },
          },
          {
            symbol: 'USIM5',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.55,
              avgLenderInterest: 0.42,
              avgBorrowerCharge: 0.68,
            },
          },
          {
            symbol: 'VALE3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.14,
              avgLenderInterest: 0.11,
              avgBorrowerCharge: 0.17,
            },
          },
          {
            symbol: 'VIVA3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.09,
              avgLenderInterest: 0.06,
              avgBorrowerCharge: 0.11,
            },
          },
          {
            symbol: 'VIVR3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 1.23,
              avgLenderInterest: 0.9,
              avgBorrowerCharge: 1.55,
            },
          },
          {
            symbol: 'VIVT3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 1.12,
              avgLenderInterest: 0.55,
              avgBorrowerCharge: 1.68,
            },
          },
          {
            symbol: 'VIVT4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.18,
              avgLenderInterest: 0.16,
              avgBorrowerCharge: 0.19,
            },
          },
          {
            symbol: 'VLID3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.2,
              avgLenderInterest: 0.14,
              avgBorrowerCharge: 0.25,
            },
          },
          {
            symbol: 'VULC3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.4,
              avgLenderInterest: 0.21,
              avgBorrowerCharge: 0.59,
            },
          },
          {
            symbol: 'VVAR3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.29,
              avgLenderInterest: 0.08,
              avgBorrowerCharge: 0.49,
            },
          },
          {
            symbol: 'WEGE3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.14,
              avgLenderInterest: 0.09,
              avgBorrowerCharge: 0.18,
            },
          },
          {
            symbol: 'WIZS3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.47,
              avgLenderInterest: 0.19,
              avgBorrowerCharge: 0.75,
            },
          },
          {
            symbol: 'WLMM4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 2.1,
              avgLenderInterest: 1,
              avgBorrowerCharge: 3.2,
            },
          },
          {
            symbol: 'WSON33',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 1.75,
              avgLenderInterest: 0.5,
              avgBorrowerCharge: 3,
            },
          },
          {
            symbol: 'YDUQ3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.12,
              avgLenderInterest: 0.1,
              avgBorrowerCharge: 0.13,
            },
          },
          {
            symbol: 'CRPG5',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'FRAS3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 1.45,
              avgLenderInterest: 0.82,
              avgBorrowerCharge: 2.07,
            },
          },
          {
            symbol: 'SMAL11F',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'NATU3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'CGAS5',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'WHRL4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'SANB3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'EMBR3G',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'CRDE3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'JSLG3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'PCAR4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'BNBR3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'LIQO3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'BBSD11',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'MATB11F',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'HAGA4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 4,
              avgLenderInterest: 2.01,
              avgBorrowerCharge: 5.99,
            },
          },
          {
            symbol: 'GBIO33',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'PPAR3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'ESTR3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'ALPA3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'BEES3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'UNIP3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'EMAE3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'ALPK3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 2.33,
              avgLenderInterest: 2.3,
              avgBorrowerCharge: 2.35,
            },
          },
          {
            symbol: 'AMZO34',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 5.15,
              avgLenderInterest: 5,
              avgBorrowerCharge: 5.3,
            },
          },
          {
            symbol: 'ATOM3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 4.79,
              avgLenderInterest: 3.59,
              avgBorrowerCharge: 5.99,
            },
          },
          {
            symbol: 'BIOM3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 4,
              avgLenderInterest: 3,
              avgBorrowerCharge: 5,
            },
          },
          {
            symbol: 'BMIN4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 4,
              avgLenderInterest: 3,
              avgBorrowerCharge: 5,
            },
          },
          {
            symbol: 'CEPE5',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 1.05,
              avgLenderInterest: 0.5,
              avgBorrowerCharge: 1.6,
            },
          },
          {
            symbol: 'CLSC4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 2.65,
              avgLenderInterest: 2,
              avgBorrowerCharge: 3.3,
            },
          },
          {
            symbol: 'CMCS34',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 4.8,
              avgLenderInterest: 3.6,
              avgBorrowerCharge: 6,
            },
          },
          {
            symbol: 'COTY34',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 6.4,
              avgLenderInterest: 4.8,
              avgBorrowerCharge: 8,
            },
          },
          {
            symbol: 'CTSA3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 4,
              avgLenderInterest: 3,
              avgBorrowerCharge: 5,
            },
          },
          {
            symbol: 'CTSA4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 4,
              avgLenderInterest: 3,
              avgBorrowerCharge: 5,
            },
          },
          {
            symbol: 'EUCA4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 2.42,
              avgLenderInterest: 2.39,
              avgBorrowerCharge: 2.44,
            },
          },
          {
            symbol: 'F1RI34',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 5.38,
              avgLenderInterest: 4.75,
              avgBorrowerCharge: 6,
            },
          },
          {
            symbol: 'GEPA4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 1.55,
              avgLenderInterest: 0.5,
              avgBorrowerCharge: 2.6,
            },
          },
          {
            symbol: 'GOGL34',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 5.15,
              avgLenderInterest: 5,
              avgBorrowerCharge: 5.3,
            },
          },
          {
            symbol: 'GOVE11',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 4.8,
              avgLenderInterest: 3.6,
              avgBorrowerCharge: 6,
            },
          },
          {
            symbol: 'GPIV33',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 4.8,
              avgLenderInterest: 3.6,
              avgBorrowerCharge: 6,
            },
          },
          {
            symbol: 'IDVL3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 4.8,
              avgLenderInterest: 3.6,
              avgBorrowerCharge: 6,
            },
          },
          {
            symbol: 'INEP4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 2.38,
              avgLenderInterest: 0.75,
              avgBorrowerCharge: 4,
            },
          },
          {
            symbol: 'JSLG11',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 4.8,
              avgLenderInterest: 3.6,
              avgBorrowerCharge: 6,
            },
          },
          {
            symbol: 'MELI34',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 2.5,
              avgLenderInterest: 2,
              avgBorrowerCharge: 3,
            },
          },
          {
            symbol: 'MSFT34',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 5.15,
              avgLenderInterest: 5,
              avgBorrowerCharge: 5.3,
            },
          },
          {
            symbol: 'OFSA3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.1,
              avgLenderInterest: 0.08,
              avgBorrowerCharge: 0.11,
            },
          },
          {
            symbol: 'RAPT3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 3.43,
              avgLenderInterest: 0.35,
              avgBorrowerCharge: 6.5,
            },
          },
          {
            symbol: 'RNEW4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 4.8,
              avgLenderInterest: 3.6,
              avgBorrowerCharge: 6,
            },
          },
          {
            symbol: 'ROST34',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 4.8,
              avgLenderInterest: 3.6,
              avgBorrowerCharge: 6,
            },
          },
          {
            symbol: 'RPMG3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 4.8,
              avgLenderInterest: 3.6,
              avgBorrowerCharge: 6,
            },
          },
          {
            symbol: 'SAPR3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 0.98,
              avgLenderInterest: 0.71,
              avgBorrowerCharge: 1.25,
            },
          },
          {
            symbol: 'SPRI3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 3,
              avgLenderInterest: 3,
              avgBorrowerCharge: 3,
            },
          },
          {
            symbol: 'UPSS34',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 6.4,
              avgLenderInterest: 4.8,
              avgBorrowerCharge: 8,
            },
          },
          {
            symbol: 'WHRL3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 1.2,
              avgLenderInterest: 0.8,
              avgBorrowerCharge: 1.6,
            },
          },
          {
            symbol: 'XBOV11',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: {
              avgInterBrokerFee: 1,
              avgLenderInterest: 1,
              avgBorrowerCharge: 1,
            },
          },
          {
            symbol: 'DOLF25',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DI1F24',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'INDZ19',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'INKZ19',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'OC1J20',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DI1F22',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DDIF24',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DCOJ21',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DDIN21',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DI1F28',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'OC1F30',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'OC1F20',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DCOF21',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DOLN24',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'WDON21',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'OC1F27',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DI1G20',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DAPK23',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DAPQ30',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DAPQ20',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DCOV20',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'ICFZ19',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DI1F20',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DI1F30',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DDIF22',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'OC1N24',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'IMVZ19',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DI1U20',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DOLF20',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'COCE5',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DAPQ28',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'WING20',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DOLJ20',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'OC1F29',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'INDF20',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DAPQ22',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'WINF20',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DI1N23',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'BGIX22',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'OC1F23',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DDIF20',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DDIF30',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DAPQ24',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DDIF26',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'OC1V22',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DDIF29',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'OC1N20',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DI1J22',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DDIV21',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DCON21',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DDIJ21',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DOLJ22',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DDIH20',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DI1F27',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'OC1F25',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'WINZ19',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DAPQ26',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DCOF26',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DI1K20',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DOLN21',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'ARSJ20',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'OC1N22',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DI1J20',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'BGIQ21',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DCON23',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'EUPF20',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DI1F25',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'ISPZ19',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'ARSH20',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'OC1J21',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DI1F23',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DDIQ20',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DCOJ22',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DDIN20',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DCOF28',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DOLN23',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'OC1F21',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'INDQ23',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DOLG20',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DI1V22',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DCOF22',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'WDON20',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DI1N24',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'OC1V20',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DOLQ20',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'OZ1Z19',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DCOV21',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'T11Z98',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DI1F21',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DI1M20',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DDIF21',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'IMVF20',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DI1V20',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DDIV22',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'WDOF21',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'ISPH20',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DDIN24',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DCOF24',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DOLH20',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DOLJ21',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DDIK20',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DI1N20',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'BRIN3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DDIF25',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'OC1N21',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DDIV20',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DCOJ20',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DDIN22',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DDIJ20',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DOLV22',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'WDOF25',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DCOF20',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DCOF30',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'WDON22',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'WDOJ20',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'OC1F26',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DCOF27',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DOLN22',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DDIF23',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'OC1N23',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DI1J21',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'BGIQ20',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DCON24',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DI1F29',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'INDG20',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'ABCP11',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'OC1J22',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'OC1F28',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DCOF29',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DDIG20',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DI1N22',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'OC1F22',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DCOF23',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DOLF21',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DDIU20',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DDIF27',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'OC1V21',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DAPK21',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DCOV22',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DCON20',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'BGIJ21',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DDIJ22',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DI1F26',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DOLV20',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DI1Q20',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'WDOJ22',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'OC1F24',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'WSPZ19',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DI1V21',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'BGIN20',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DDIN23',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'OZ1H20',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DCOF25',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DOLN20',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DDIM20',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DDIF28',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DI1H20',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DI1N21',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DCON22',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'AETB34',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'AFLT3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'AFLU3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'AHEB5',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'AHEB6',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'AIGB34',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'ALSC3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'ALUP3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'ALUP4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'BAHI3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'BALM4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'BAUH4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'BAZA3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'BBTG11',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'BBTG12',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'BDLL4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'BEES4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'BGIP3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'BGIP4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'BMEB3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'BMEB4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'BMKS3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'BMTO3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'BMTO4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'BPHA3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'BRAX11',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'BRGE12',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'BRGE3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'BRIV3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'BRIV4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'BRKM3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'BRSR3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'BRSR5',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'BSAN33',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'BTTL3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'BTTL4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'BVMF3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'CAMB4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'CBEE3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'CCXC3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'CEBR3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'CEBR5',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'CEBR6',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'CEDO3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'CEED3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'CELP3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'CELP5',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'CGAS3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'CGRA3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'COCE3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'CPRE3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'CREM3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'CRIV3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'CRIV4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'CRPG6',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'CSAB4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'CSRN3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'CTAX3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'CTKA4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'CZLT33',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DAGB33',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DASA3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DTCY3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'DUKB34',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'EALT4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'ECOO11',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'EEEL3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'EKTR4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'ELEK3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'ELEK4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'ELET5',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'ELPL4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'EMAE4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'ENGI3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'ENGI4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'ENMT3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'ESTC3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'ESTR4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'FESA3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'FIBR3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'FIND11',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'FJTA3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'FJTA4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'FRIO3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'FRTA3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'GEPA3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'GPAR3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'GUAR4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'HBTS5',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'HOOT4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'HPIA3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'IBOV11',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'IBXL11',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'IBXX11',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'ICON11',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'IDIV11',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'IDNT3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'IDVL4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'IFNC11',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'IGCT11',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'IMAT11',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'IMOB11',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'INEP3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'ISUS11',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'IVBX11',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'JBDU3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'JOPA3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'JOPA4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'KROT3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'LATM33',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'LIXC3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'LIXC4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'MAGG3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'MEND5',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'MGEL4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'MLCX11',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'MLFT4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'MNDL3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'MNPR3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'MPLU3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'MTSA4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'MWET4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'OSXB3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'PATI3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'PATI4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'PEAB4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'PLAS3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'PNVL4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'PRBC4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'PRML3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'PTPA4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'QGEP3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'RANI4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'RCSL3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'REDE3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'REDE4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'RPAD3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'RPAD5',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'RPAD6',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'SEDU3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'SFSA4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'SLED3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'SMLE3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'SMLL11',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'SNSL3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'SOND5',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'SSBR3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'SUZB5',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'TCNO3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'TCNO4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'TEKA4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'TELB3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'TERI3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'TGLT32',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'TKNO4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'TOYB3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'TOYB4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'TRPL3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'TRPN3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'TXRX4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'UNIP5',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'USIM6',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'UTIL11',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'VALE5',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'VVAR11',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'VVAR4',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'WLMM3',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'XPOM11',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
          {
            symbol: 'XPTD11',
            lastAveragePrice: null,
            lastClosingPrice: null,
            refData: null,
          },
        ],
      }
      resolve(assets)
    }, 1000)
  })
}
