import React from 'react'
import { IDropdownStatus } from './interfaces'
import { Select, IconButton, MenuItem } from '@material-ui/core'
import AddBoxIcon from '@material-ui/icons/AddBox'
import { strings } from '../../resources/strings/strings'
import { StyledFieldset, StyledLegend, useStyles, DropdownContainer } from './style'

export const DropdownStatusView: React.FC<IDropdownStatus> = ({
  name,
  label,
  options,
  handleChange,
  status,
  onClick,
}) => {
  const { general } = strings
  const styles = useStyles()
  return (
    <StyledFieldset>
      <StyledLegend>{label}</StyledLegend>
      <DropdownContainer>
        <Select
          name={name}
          value={status}
          onChange={handleChange}
          label={label}
          className={styles.root}
          MenuProps={{ classes: { paper: styles.menuList } }}
        >
          <MenuItem value="">
            <em>{general.none}</em>
          </MenuItem>
          {options &&
            Object.keys(options).map((option) => (
              //@ts-ignore - does not recognize the option as an index
              <MenuItem key={options[option]} value={options[option]}>
                {options[option]}
              </MenuItem>
            ))}
        </Select>
        <IconButton onClick={onClick} edge="end">
          <AddBoxIcon />
        </IconButton>
      </DropdownContainer>
    </StyledFieldset>
  )
}
