export enum DateTime {
  FirstSecond = '00:00:00',
  LastSecond = '23:59:59',
}

export const currentDateToString = (input?: DateTime) => {
  const today = new Date()
  let dd: string | number = today.getDate()
  let mm: string | number = today.getMonth() + 1
  const yyyy: number = today.getFullYear()

  dd = `0${dd}`.slice(-2)
  mm = `0${mm}`.slice(-2)

  const currentDateString = `${yyyy}-${mm}-${dd}${input ? ' ' + input : ''}`
  return currentDateString
}
