import { IFavoriteItemsList } from './interface'
import React from 'react'
import DeleteIcon from '@material-ui/icons/Delete'
import ApplyIcon from '@material-ui/icons/AddBox'
import { ButtonsWrapper, FavoriteItemWrapper, StyledDivider, StyledTooltip } from './styles'
import { strings } from '../../resources/strings/strings'
import { Typography } from '@material-ui/core'

export const FavoriteItemsTypesView: React.FC<IFavoriteItemsList> = ({ item, deleteItem, applyItem, isLastItem }) => {
  return (
    <>
      <FavoriteItemWrapper>
        <Typography noWrap variant={'h6'}>
          {item?.identifier}
        </Typography>
        <ButtonsWrapper>
          <StyledTooltip onClick={applyItem} title={strings.general.apply}>
            <ApplyIcon color="action" style={{ width: 24, height: 24 }} />
          </StyledTooltip>
          <StyledTooltip onClick={deleteItem} title={strings.general.delete}>
            <DeleteIcon color="action" style={{ width: 24, height: 24 }} />
          </StyledTooltip>
        </ButtonsWrapper>
      </FavoriteItemWrapper>
      {!isLastItem && <StyledDivider />}
    </>
  )
}
