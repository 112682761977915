import { useMemo, useState } from 'react'
import { useForm } from 'react-simple-hooks-forms'
import { login } from '../../services/auth/login'
import { logout } from '../../utils/localStorageManager'
import { handleSnackBar } from '../../services/pageStateService'
import { strings } from '../../resources/strings/strings'
import { loginConfig } from '../../constants/formsConfig/login'
import { loginSuccess } from '../../utils/authUtils'
import { useGlobal } from '../../global/useGlobal'
import { openSnackBar } from '../../services/pageStateService'

export const MESSAGE_CODES: { [key: number]: string } = {
  1: 'Password Changed.',
  2: 'User invalid.',
  3: 'Password Invalid.',
  4: 'User created.',
  5: 'User already exists.',
  6: 'User not authenticated.',
  7: 'New password is not valid, need to be strongest.',
  8: 'New password not updated.',
  9: 'Missing current password or new password.',
  10: 'New password Invalid ',
  11: 'Password not changed.',
  12: 'Invalid user or password.',
  13: 'An error occurred when trying to reset password.',
  14: 'User disable.',
  15: 'User enable.',
  200: 'OK.',
  201: 'Created.',
  202: 'Accepted.',
  204: 'No Content.',
  400: 'Bad Request.',
  401: 'Unauthorized.',
  403: ' Forbidden.',
  404: 'Not Found.',
  408: 'Request Timeout.',
}

export class LoginVM {
  static initVM() {
    return useMemo(() => new LoginVM(), [])
  }

  useDataHooks() {
    const { Form, submit } = useForm({ ...loginConfig })
    const { loginScreen: screenStrings } = strings
    const [errorMessage, setErrorMessage] = useState({
      email: '',
      password: '',
    })

    const onSubmit = async () => {
      return await submit(
        (values) => {
          const { email, password, rememberCredentials } = values
          login(email, password, rememberCredentials).then((response) => {
            handleSnackBar(response.isLogged, response.successMessage, response.errorMessage)

            if (response.shouldNotifyAboutToExpireLogin) {
              openSnackBar(strings.loginScreen.messages.aboutToExpireNotification(response.logonExpired))
            }
            if (response.isLogged || response.successMessage || response.errorMessage) {
              handleSnackBar(response.isLogged, response.successMessage, response.errorMessage)
            }
          })
        },
        (error) => {
          setErrorMessage({
            email: error.email,
            password: error.password,
          })
        }
      )
    }

    return {
      Form,
      onSubmit,
      logout,
      errorMessage,
      screenStrings,
    }
  }
}
