import React from 'react'
import { IButtonView } from './interfaces'
import { ButtonView } from './ButtonView'

export const Button: React.FC<IButtonView> = ({
  text,
  loading,
  color,
  type,
  onClick,
  startIcon,
  svgIcon,
  size,
  disabled,
  materialIcon,
}) => {
  return (
    <ButtonView
      loading={loading}
      svgIcon={svgIcon}
      startIcon={startIcon}
      text={text}
      color={color}
      type={type}
      onClick={onClick}
      size={size}
      disabled={disabled}
      materialIcon={materialIcon}
    />
  )
}
