export const settleColumnOrder: string[] = [
  'contractCode',
  'instrumentSymbol',
  'effectiveQuantity',
  'quantity',
  'side',
  'carryingBroker',
  'accountCode',
  'internalStatus',
  'errorRuleIds',
]
