import { createMuiTheme } from '@material-ui/core/styles'
import { baseTheme } from './baseTheme'

export const colors = {
  background: '#FFFFFF',
  black: '#000000',
  error: '#B00020',
  primary: '#418FDE',
  primaryVariant: '#00447C',
  secondary: '#4C9DD0',
  surface: '#FFFFFF',
  tableBorder: 'rgba(0, 0, 0, 0.12)',
  tableHeaderAsk: '#A73444',
  tableHeaderBid: '#0D719E',
  grayBackground: '#E3E3E3',
  tableColumnVariationDown: '#EF6C00',
  tableColumnVariationUp: '#2E7D32',
  moduleAssetSelectIcon: 'rgba(0, 0, 0, 0.6)',
  moduleIconHighlight: '#4190df',
  white: '#ffffff',
  gray: '#EDEDED',
  mediumGray: 'rgba(0, 0, 0, 0.38)',
  lightGray: 'rgba(0, 0, 0, 0.18)',
  opacityBlack: 'rgba(0, 0, 0, 0.7)',
}

export const light = createMuiTheme({
  overrides: {
    MuiButton: {
      outlinedPrimary: {
        border: `null`,
        '&:hover': {
          border: 'null',
        },
      },
      containedPrimary: {
        backgroundColor: colors.primary,
        '&:hover': {
          backgroundColor: colors.primary,
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: colors.primary,
          },
        },
        '&:active': {
          boxShadow: 'null',
        },
      },
    },
  },
  palette: {
    type: 'light',
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    background: {
      paper: colors.surface,
      default: colors.background,
    },
    error: {
      main: colors.error,
    },
  },
  typography: baseTheme.typography,
})

export default light
