import { strings } from '../../resources/strings/strings'
import { stockLoanContractStatus } from '../contracts/stockLoanContractStatus'
import { stockLoanSide } from '../contracts/stockLoanSide'
import { FilterPatterns, FilterSchema, FilterType, InputStyle, AutoCompleteOptions } from './filterTypes'
import { contractCodeMask } from '../masks/masks'
import { ColumnNames } from '../../constants/columnNames'

export interface ContractsFilterInputs {
  ContractCode: string
  AccountCode: string
  InstrumentSymbol: string
  OrderSide: string
  ContractInternalStatuses: string
  MinExpireDate: string
  MaxExpireDate: string
  MinCreationDate: string
  MaxCreationDate: string
  [key: string]: string
  ExecutionBrokerCode: string
}

export enum ContractsFilterNames {
  ContractCode = 'Code',
  AccountCode = 'IssuerAccountCode',
  InstrumentSymbol = 'InstrumentSymbol',
  MinExpireDate = 'MinExpireDate',
  MaxExpireDate = 'MaxExpireDate',
  MinCreationDate = 'MinCreationDate',
  MaxCreationDate = 'MaxCreationDate',
  OrderSide = 'Side',
  ContractInternalStatuses = 'InternalStatus',
  ExecutionBrokerCode = 'ExecutionBrokerCode',
}

const { filters } = strings.homeScreen

export const ContractsFilterSchema: FilterSchema[] = [
  {
    name: ContractsFilterNames.AccountCode,
    label: 'Account',
    mask: filters.accountMask,
    value: '',
    size: 3,
    inputStyle: InputStyle.autoComplete,
    filterType: FilterType.multiple,
    filterPattern: FilterPatterns.AccountCode,
    autoCompleteOptions: AutoCompleteOptions.accounts,
    columnReference: ColumnNames.IssuerAccountCode,
  },
  {
    name: ContractsFilterNames.InstrumentSymbol,
    label: 'Symbol',
    mask: filters.symbolMask,
    value: '',
    size: 3,
    inputStyle: InputStyle.autoComplete,
    filterType: FilterType.multiple,
    filterPattern: FilterPatterns.Symbol,
    autoCompleteOptions: AutoCompleteOptions.instruments,
    columnReference: ColumnNames.InstrumentSymbol,
  },
  {
    name: ContractsFilterNames.ContractCode,
    label: 'Contract Code',
    regexMask: contractCodeMask,
    value: '',
    size: 6,
    inputStyle: InputStyle.textField,
    filterType: FilterType.multiple,
    filterPattern: FilterPatterns.ContractCode,
    columnReference: ColumnNames.Code,
  },
  {
    name: ContractsFilterNames.ContractInternalStatuses,
    label: 'Status',
    value: '',
    size: 6,
    inputStyle: InputStyle.dropdown,
    options: stockLoanContractStatus,
    filterType: FilterType.unique,
    filterPattern: FilterPatterns.OnlyLetters,
    columnReference: ColumnNames.InternalStatus,
  },
  {
    name: ContractsFilterNames.OrderSide,
    label: 'Side',
    value: '',
    size: 6,
    inputStyle: InputStyle.radio,
    radios: stockLoanSide,
    filterType: FilterType.unique,
    filterPattern: FilterPatterns.Side,
    columnReference: ColumnNames.Side,
  },
  {
    name: ContractsFilterNames.MinExpireDate,
    label: 'Min Expire Date',
    mask: filters.dateMask,
    value: '',
    size: 3,
    inputStyle: InputStyle.textField,
    filterType: FilterType.unique,
    placeholder: filters.datePlaceholder,
    filterPattern: FilterPatterns.Date,
  },
  {
    name: ContractsFilterNames.MaxExpireDate,
    label: 'Max Expire Date',
    mask: filters.dateMask,
    value: '',
    size: 3,
    inputStyle: InputStyle.textField,
    filterType: FilterType.unique,
    placeholder: filters.datePlaceholder,
    filterPattern: FilterPatterns.Date,
  },
  {
    name: ContractsFilterNames.MinCreationDate,
    label: 'Min Creation Date',
    mask: filters.dateTimeMask,
    value: '',
    size: 3,
    inputStyle: InputStyle.textField,
    filterType: FilterType.unique,
    placeholder: filters.dateTimePlaceholder,
    filterPattern: FilterPatterns.Datetime,
  },
  {
    name: ContractsFilterNames.MaxCreationDate,
    label: 'Max Creation Date',
    mask: filters.dateTimeMask,
    value: '',
    size: 3,
    inputStyle: InputStyle.textField,
    filterType: FilterType.unique,
    placeholder: filters.dateTimePlaceholder,
    filterPattern: FilterPatterns.Datetime,
  },
  {
    name: ContractsFilterNames.ExecutionBrokerCode,
    label: 'Execution Broker Code',
    mask: filters.executionBrokerCode,
    value: '',
    size: 3,
    inputStyle: InputStyle.textField,
    filterType: FilterType.unique,
    filterPattern: FilterPatterns.OnlyNumbers,
    columnReference: ColumnNames.IssuerExecutionBrokerCode,
  },
]
