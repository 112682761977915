import { getGlobal } from 'reactn'
import { SelectOptionType } from '../components/SearchBar/interfaces'
import { stockLoanSide } from '../constants/contracts/stockLoanSide'
import { DATE_SHORTCUT_PATTERN, FilterPatterns, FilterSchema } from '../constants/filters/filterTypes'
import { getShortcutDate } from './getShortcutDate'
import { getAllFullDates, getFullDateString } from './getFullDateString'
import { getStockLoanByScreen } from './getStockLoanByScreen'

export const getOptionsByFilterType = (
  search: string,
  filterTypes: FilterSchema[],
  action: (filterSchema: FilterSchema, value: string) => () => Promise<void>,
  pathname: string
) => {
  const suggestedOptions: SelectOptionType[] = []

  const emptySearch = [
    {
      label: '',
      content: '',
      action: () => {},
    },
  ]

  filterTypes.forEach((fieldSchema) => {
    let content
    let searchResult

    const isDateShortcut = DATE_SHORTCUT_PATTERN.test(search)
    const filterName = fieldSchema.name
    switch (fieldSchema.filterPattern) {
      case FilterPatterns.Symbol:
        content = getGlobal().instruments
        searchResult = content
          ?.filter((value) => filterOptions(value.symbol, search))
          .map((instrument) => {
            return instrument.symbol
          })
        break
      case FilterPatterns.AccountCode:
        content = getGlobal().accounts
        searchResult = content
          ?.filter((value) => filterOptions(value.accountNumber, search))
          .map((account) => {
            return account.accountNumber
          })
        break
      case FilterPatterns.Side:
        content = [stockLoanSide.borrower, stockLoanSide.lender]
        searchResult = content.filter((value) => filterOptions(value, search))
        break
      case FilterPatterns.OnlyLetters:
        content = Object.values(getStockLoanByScreen(pathname, fieldSchema.name))
        searchResult = content?.filter((value) => filterOptions(value, search))
        break
      case FilterPatterns.ContractCode:
        searchResult = [search]
        break
      case FilterPatterns.Date:
        searchResult = isDateShortcut ? [getShortcutDate(search)] : [search]
        break
      case FilterPatterns.Datetime:
        searchResult = getAllFullDates(isDateShortcut ? getShortcutDate(search) : search, filterName)
        break
      default:
        return {
          label: fieldSchema.label,
          content: '',
          action: () => {},
        }
    }

    addSuggestion(suggestedOptions, fieldSchema, action, searchResult)
  })
  return suggestedOptions.length ? suggestedOptions : emptySearch
}

export const filterOptions = (value: string, search: string) => {
  const initialLetters = value.toLowerCase().substring(0, search.length)
  if (initialLetters === search.toLowerCase()) {
    return value
  }
}

export const addSuggestion = (
  suggestedOptions: SelectOptionType[],
  fieldSchema: FilterSchema,
  action: (filterSchema: FilterSchema, value: string) => () => Promise<void>,
  searchResult?: string[]
) => {
  searchResult?.forEach((result) => {
    suggestedOptions.push({
      label: fieldSchema.label,
      content: result,
      action: action(fieldSchema, result),
      filterSchema: fieldSchema,
    })
  })
}
