import { IconButton } from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { CustomTextFieldFormInput } from '../CustomTextFieldFormInput'
import { IPasswordfieldView } from './interfaces'
import React from 'react'

const PasswordFieldView: React.FC<IPasswordfieldView> = ({
  isPasswordVisible,
  togglePasswordVisibility,
  name,
  label,
  errorMessage,
  onKeyPress,
}) => {
  return (
    <CustomTextFieldFormInput
      name={name}
      variant="filled"
      label={label}
      type={isPasswordVisible ? 'text' : 'password'}
      error={errorMessage !== ''}
      helperText={errorMessage}
      endAdornment={
        <IconButton onClick={togglePasswordVisibility}>
          {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      }
      onKeyPress={onKeyPress}
      fullWidth
    />
  )
}

export default PasswordFieldView
