import React from 'react'
import { colors } from '../../../styles/themes/light'
import { SvgComponent } from './interfaces'
import { useLocation } from 'react-router'
import { Routes } from '../../../routes'

export const ApprovalInstructionsIcon: React.FC<SvgComponent> = (props) => {
  const { pathname } = useLocation()
  const isCurrentPage = pathname === Routes.approvalInstructions
  const fill = isCurrentPage ? colors.moduleIconHighlight : colors.moduleAssetSelectIcon

  return (
    <>
      <svg {...props} fill={fill} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 13H5V11H3V13Z" fill="fill" fill-opacity="1" />
        <path d="M3 17H5V15H3V17Z" fill="fill" fill-opacity="1" />
        <path d="M3 9H5V7H3V9Z" fill="fill" fill-opacity="1" />
        <path d="M7 7V9H21V7H7Z" fill="fill" fill-opacity="1" />
        <path
          d="M10.8285 16.8994L12.2427 15.4852L15.0711 18.3137L20.728 12.6568L22.1422 14.071L15.0711 21.1421L10.8285 16.8994Z"
          fill="fill"
          fill-opacity="1"
        />
        <path d="M17.5564 13H7V11H19.5564L17.5564 13Z" fill="fill" fill-opacity="1" />
        <path d="M9.89951 15H7V17H7.89951L9.89951 15Z" fill="fill" fill-opacity="1" />
      </svg>
    </>
  )
}

ApprovalInstructionsIcon.defaultProps = {
  height: 24,
  width: 24,
}
