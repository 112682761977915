import { ContractsFieldsDTO, ContractsLegs } from '../services/contracts/interfaces/contractsFieldsDTO'
import { mapISODateToString } from './mapISODateToString'
import { SettlementLegs, SettlementsFieldsDTO } from '../services/settlements/interfaces/settlementsFieldsDTO'
import { numberFormatter } from './numberFormatter'
import { isISODate } from './isISODate'
import {
  SettlementRequestLegs,
  SettlementRequestsFieldsDTO,
} from '../services/settlementRequests/get/interfaces/settlementRequestsFieldsDTO'
import {
  ApprovalInstructions,
  ApprovalInstructionsFieldsDTO,
} from '../services/approvalInstructions/get/interfaces/approvalInstructionsFieldsDTO'
import { getGlobal } from '../global/useGlobal'
import {
  ApprovalDivergences,
  ApprovalDivergencesFieldsDTO,
} from '../services/approvalDivergences/interfaces/approvalDivergencesFieldsDTO'

export const mapDTOResponseToTableData = (
  dto:
    | ContractsFieldsDTO
    | SettlementsFieldsDTO
    | SettlementRequestsFieldsDTO
    | ApprovalInstructionsFieldsDTO
    | ApprovalDivergencesFieldsDTO
) => {
  let response: any[] = []
  let objectValues: any[] = []
  let dataLegs: (
    | ContractsLegs
    | SettlementLegs
    | SettlementRequestLegs
    | ApprovalInstructions
    | ApprovalDivergences
  )[] = []
  let dataOrder: string[] = []
  const CONTRACTS_TOTAL_FIELDS = 40
  const SETTLEMENTS_TOTAL_FIELDS = 20
  const SETTLEMENT_REQUESTS_TOTAL_FIELDS = 20
  const APPROVAL_INSTRUCTIONS_TOTAL_FIELDS = 30
  const APPROVAL_DIVERGENCES_TOTAL_FIELDS = 25
  const {
    contractsColumns: contractsDataOrder,
    settlementsColumns: settlementsDataOrder,
    settlementRequestsColumns: settlementRequestsDataOrder,
    approvalInstructionsColumns: approvalInstructionsDataOrder,
    approvalDivergencesColumns: approvalDivergencesDataOrder,
  } = getGlobal()
  if ('contractLegs' in dto) {
    dataLegs = dto.contractLegs
    dataOrder = [...contractsDataOrder]
  }
  if ('settlementLegs' in dto) {
    dataLegs = dto.settlementLegs
    dataOrder = [...settlementsDataOrder]
  }
  if ('settlementRequestLegs' in dto) {
    dataLegs = dto.settlementRequestLegs
    dataOrder = [...settlementRequestsDataOrder]
  }
  if ('approvalInstructions' in dto) {
    dataLegs = dto.approvalInstructions
    dataOrder = [...approvalInstructionsDataOrder]
  }
  if ('approvalDivergences' in dto) {
    dataLegs = dto.approvalDivergences
    dataOrder = [...approvalDivergencesDataOrder]
  }

  dataLegs.forEach(
    (item: ContractsLegs | SettlementLegs | SettlementRequestLegs | ApprovalInstructions | ApprovalDivergences) => {
      dataOrder.forEach((column) => {
        let value
        const isQuantity = column.toLowerCase().includes('quantity')

        if (column === 'checkbox') {
          value = false
        } else {
          //(item as any) is being used so we can access its property.
          value = (item as any)[column]
        }

        if (isISODate(value)) {
          value = mapISODateToString(value)
        }
        if (typeof value === 'boolean') {
          value ? (value = 'Yes') : (value = 'No')
        }
        if (value === null) {
          value = ''
        }
        if (typeof value === 'number') {
          if (!isQuantity) {
            value = numberFormatter(value)
          }

          if (isQuantity) {
            value = value.toString().toLocaleString()
          }
        }
        objectValues.push(value)
        if (objectValues.length === CONTRACTS_TOTAL_FIELDS && 'contractLegs' in dto) {
          response.push(objectValues)
          objectValues = []
        }
        if (objectValues.length === SETTLEMENTS_TOTAL_FIELDS && 'settlementLegs' in dto) {
          response.push(objectValues)
          objectValues = []
        }
        if (objectValues.length === SETTLEMENT_REQUESTS_TOTAL_FIELDS && 'settlementRequestLegs' in dto) {
          response.push(objectValues)
          objectValues = []
        }
        if (objectValues.length === APPROVAL_INSTRUCTIONS_TOTAL_FIELDS && 'approvalInstructions' in dto) {
          response.push(objectValues)
          objectValues = []
        }
        if (objectValues.length === APPROVAL_DIVERGENCES_TOTAL_FIELDS && 'approvalDivergences' in dto) {
          response.push(objectValues)
          objectValues = []
        }
      })
    }
  )
  return response
}
