enum SettleColumnIndexes {
  'ContractCode',
  'Symbol',
  'EffectiveQty',
  'Qty',
  'Side',
  'CarryingBroker',
  'IssuerAccount',
  'InternalStatus',
  'ErrorRuleIds',
}

export default SettleColumnIndexes
