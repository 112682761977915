import { AppBar, Divider, IconButton } from '@material-ui/core'
import { IWorkspaceAppbarView } from './interfaces'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import React, { RefObject } from 'react'
import { StyledLeftContainer, StyledLogoWrapper, StyledRightContainer, StyledToolbar, StyledUsername } from './styles'
import { SearchArea } from '../SearchArea'
import { useGlobal } from '../../global/useGlobal'
import { useLocation } from 'react-router'
import { openSnackBar } from '../../services/pageStateService'
import { strings } from '../../resources/strings/strings'
import { Options } from '../jexcel-interfaces'
import { logout } from '../../utils/localStorageManager'
import { getGlobalKeys } from '../../utils/getGlobalKeys'
import { downloadWorksheet } from '../../utils/downloadWorksheets/downloadWorksheet'

export const WorkspaceAppbarView: React.FC<IWorkspaceAppbarView> = ({ logo, classes, children, ...props }) => {
  const { pathname } = useLocation()
  const { tableRefGlobalKey } = getGlobalKeys(pathname)

  const [username] = useGlobal('username')
  const [isDataLoading] = useGlobal('isDataLoading')
  const [tableRef] = useGlobal(tableRefGlobalKey)

  const handleDownload = (ref?: RefObject<{ jexcel: Options } & HTMLDivElement>, isCsv?: boolean) => {
    if (ref?.current?.jexcel) {
      downloadWorksheet(ref, pathname, isCsv)
    } else {
      openSnackBar(strings.alerts.unknownError, true)
    }
  }

  const saveCsv = () => {
    handleDownload(tableRef, true)
  }

  const saveXlsx = () => {
    handleDownload(tableRef)
  }

  return (
    <AppBar className={classes.appBar} {...props}>
      <StyledToolbar>
        <StyledLeftContainer>
          <StyledLogoWrapper>
            <img src={logo} />
          </StyledLogoWrapper>

          <Divider className={classes.divider} orientation="vertical" />
          {children}
        </StyledLeftContainer>

        <StyledRightContainer>
          <Divider className={classes.divider} orientation="vertical" />
          <StyledUsername variant="body2" color="textPrimary">
            {username || ''}
          </StyledUsername>
          <IconButton onClick={logout}>
            <ExitToAppIcon />
          </IconButton>
        </StyledRightContainer>
      </StyledToolbar>
      <SearchArea isLoading={isDataLoading} saveCsv={saveCsv} saveXlsx={saveXlsx} />
    </AppBar>
  )
}
