import React, { useEffect, useState } from 'react'
import { SearchBarView } from './SearchBarView'
import { ISearchBar, SelectOptionType } from './interfaces'
import { useDebounce } from '../../utils/hooks/useDebounce'
import { SEARCH_DEBOUNCE_DELAY } from '../../constants/dimensions'
import SearchBarCustomOption from './SearchBarCustomOption'
import { getGlobalKeys } from '../../utils/getGlobalKeys'
import { useLocation } from 'react-router'
import { getGlobal, useGlobal } from '../../global/useGlobal'
import { DATE_SHORTCUT_PATTERN, Filter, FilterSchema, FilterType } from '../../constants/filters/filterTypes'
import { strings } from '../../resources/strings/strings'
import { getPossibleFieldsFromString } from '../../utils/getPossibleFieldsFromString'
import { getOptionsByFilterType } from '../../utils/getOptionsByFilterType'
import { getAccounts } from '../../services/accounts/getAccounts'
import { getInstruments } from '../../services/instruments/getInstruments'

export const SearchBar: React.FC<ISearchBar> = ({}) => {
  const { pathname } = useLocation()
  const { appliedFiltersGlobalKey, selectedFiltersGlobalKey, filterSchema } = getGlobalKeys(pathname)

  const accounts = getGlobal().accounts
  const instruments = getGlobal().instruments

  const [appliedFilters, setAppliedFilters] = useGlobal(appliedFiltersGlobalKey)
  const [selectedFilters, setSelectedFilters] = useGlobal(selectedFiltersGlobalKey)

  const [searchInput, setSearchInput] = useState<string>('')
  const [possibleFields, setPossibleFields] = useState<FilterSchema[]>(filterSchema)
  const [options, setOptions] = useState<SelectOptionType[]>([])

  const { debouncedValue: debouncedSearchText, isDebouncing } = useDebounce(searchInput, SEARCH_DEBOUNCE_DELAY)

  const addFilter = (filterSchema: FilterSchema, value: string) => async () => {
    const newFilter: Filter = {
      name: filterSchema.name,
      label: filterSchema.label,
      value,
      type: filterSchema.filterType,
      isActive: true,
    }
    const newState = appliedFilters
    const oldFilterIndex = newState.findIndex(({ name }) => name === filterSchema.name)
    const isFilterUnique = filterSchema.filterType === FilterType.unique
    const hasOldFilter = oldFilterIndex !== -1

    if (isFilterUnique && hasOldFilter) {
      newState.splice(oldFilterIndex, 1, newFilter)
      await setAppliedFilters([...newState])
      await setSelectedFilters([...newState])
      return
    }
    newState.push(newFilter)
    await setAppliedFilters([...newState])
    await setSelectedFilters([...newState])
  }

  useEffect(() => {
    if (!accounts) {
      getAccounts()
    }
  }, [])

  useEffect(() => {
    if (!instruments) {
      getInstruments()
    }
  }, [])

  useEffect(() => {
    const newPossibleFields = getPossibleFieldsFromString(debouncedSearchText, filterSchema)
    const suggestedOptions: SelectOptionType[] = getOptionsByFilterType(
      debouncedSearchText,
      newPossibleFields,
      addFilter,
      pathname
    )

    setOptions(suggestedOptions)
    setPossibleFields([...newPossibleFields])
  }, [debouncedSearchText])

  const customFilter = (option: any, searchText: string) => {
    setSearchInput(searchText)
    if (!option || !option.data || !option.data.content) return false
    const isDateShortcut = DATE_SHORTCUT_PATTERN.test(searchText)
    return option.data.content.toLowerCase().includes(searchText.toLowerCase()) || isDateShortcut
  }

  const customNoOptionsMessage = (input: any) => {
    if (isDebouncing) return strings.homeScreen.searchBar.loading
    if (!searchInput) return strings.homeScreen.searchBar.emptyOptions
    return `${strings.homeScreen.searchBar.searchBarNoOptions}${input.inputValue}`
  }

  const selectOption = (data: SelectOptionType) => data.action()

  const onKeyDown = (e: React.KeyboardEvent) => e.stopPropagation()

  return (
    <SearchBarView
      getNoOptionsMessage={customNoOptionsMessage}
      CustomOption={SearchBarCustomOption}
      options={options}
      customFilter={customFilter}
      onChange={selectOption}
      onKeyDown={onKeyDown}
    />
  )
}
