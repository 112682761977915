import React from 'react'
import ReactDOM from 'react-dom'
import 'reflect-metadata'
import App from './App'
import 'typeface-roboto-mono'
import { GlobalStyle } from './styles/GlobalStyle'

const Root = () => {
  return (
    <React.Fragment>
      <GlobalStyle />
      <App />
    </React.Fragment>
  )
}

ReactDOM.render(<Root />, document.getElementById('root'))
