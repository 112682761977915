import { ApprovalInstructionsColumnsSchema } from './interfaces'
import { ColumnNames } from '../../../constants/columnNames'

export const approvalInstructionsDefaultColumnOrder: (keyof ApprovalInstructionsColumnsSchema)[] = [
  ColumnNames.Checkbox,
  ColumnNames.Id,
  ColumnNames.ExternalId,
  ColumnNames.RequestType,
  ColumnNames.InstrumentSymbol,
  ColumnNames.Quantity,
  ColumnNames.MatchedQuantity,
  ColumnNames.ApprovedQuantity,
  ColumnNames.PendingQuantity,
  ColumnNames.Side,
  ColumnNames.IssuerAccountCode,
  ColumnNames.IssuerAliasOrName,
  ColumnNames.MasterAccountCode,
  ColumnNames.NegotiationFee,
  ColumnNames.CommissionFee,
  ColumnNames.InterBrokerFee,
  ColumnNames.LenderReversible,
  ColumnNames.TenderOfferLenderReversible,
  ColumnNames.LockUpDate,
  ColumnNames.ExpireDate,
  ColumnNames.IssuerBrokerCode,
  ColumnNames.IssuerCustodianCode,
  ColumnNames.IssuerCustodyAccountCode,
  ColumnNames.IssuerPortfolio,
  ColumnNames.ReferenceDate,
  ColumnNames.TargetBrokerCode,
  ColumnNames.InternalStatus,
  ColumnNames.DeskId,
  ColumnNames.Memo,
  ColumnNames.CreationDate,
]
