import { Routes } from '../routes'
import { stockLoanContractStatus } from '../constants/contracts/stockLoanContractStatus'
import { settlementRequestInternalStatus } from '../constants/contracts/settlementRequestInternalStatus'
import { stockLoanApprovalInstructionStatus } from '../constants/approvalInstructions/StockLoanApprovalInstructionStatus'
import { stockLoanApprovalInstructionRequestType } from '../constants/approvalInstructions/StockLoanApprovalInstructionsRequestType'
import { ApprovalInstructionsFilterNames } from '../constants/filters/approvalInstructionsFilters'
import { ApprovalDivergencesFilterNames } from '../constants/filters/approvalDivergencesFilters'
import { SettlementsFilterNames } from '../constants/filters/settlementsFilters'
import { stockLoanSettlementInternalStatus } from '../constants/settlements/stockLoanSettlementInternalStatus'

export const getStockLoanByScreen = (pathname: string, filterName: string) => {
  const isSettlementsScreen = pathname === Routes.settlements
  const isSettlementRequestsScreen = pathname === Routes.settlementRequests
  const isApprovalInstructionsScreen = pathname === Routes.approvalInstructions
  const isApprovalDivergencesScreen = pathname === Routes.approvalDivergences

  if (isSettlementRequestsScreen) {
    return settlementRequestInternalStatus
  }

  if (isSettlementsScreen) {
    if (filterName === SettlementsFilterNames.InternalStatus) {
      return stockLoanSettlementInternalStatus
    }
  }

  if (isApprovalInstructionsScreen || isApprovalDivergencesScreen) {
    if (
      filterName === ApprovalInstructionsFilterNames.InstructionStatuses ||
      filterName === ApprovalDivergencesFilterNames.RequestInternalStatuses
    ) {
      return stockLoanApprovalInstructionStatus
    }
    if (filterName === ApprovalInstructionsFilterNames.RequestType) {
      return stockLoanApprovalInstructionRequestType
    }
  }

  return stockLoanContractStatus
}
