import { getNavigatorLanguage } from './getNavigatorLanguage'

// this function formats a number bigger than 999 separating in commas (eg.: 1,000)
// and formats a float number with point (eg.: 0.23)

export const numberFormatter = (value: number): string => {
  const valueAsString = value.toString()
  if (valueAsString.length > 3) {
    const locale = getNavigatorLanguage()
    if (isFloat(value)) {
      return parseFloat(parseFloat(valueAsString).toFixed(2)).toLocaleString(locale)
    }
    return formatThousandSeparatorNumber(value, locale)
  } else {
    return formatDecimalNumbers(value)
  }
}

const formatThousandSeparatorNumber = (value: number, locale: string): string => {
  return value.toLocaleString(locale)
}

const formatDecimalNumbers = (value: number): string => {
  return value.toFixed(2)
}

const isFloat = (value: number): boolean => {
  return Number(value) === value && value % 1 !== 0
}
