import { FeatureManager } from '../../FeatureManager'
import { mockGetSettlementRequests } from './mockEndpoint'
import { getSettlementRequests } from './request'
import { Filter } from '../../../constants/filters/filterTypes'
import { isActiveFilter } from '../../../utils/isActiveFilter'
import { formatFilter } from '../../../utils/formatFilter'
import { formatDateFilter } from '../../../utils/formatDateFilter'
import { INITIAL_PAGE_NUMBER, PAGE_SIZE } from '../../../constants/pagination'
import { numberOfPagesCalculator } from '../../../utils/numberOfPagesCalculator'
import { SettlementRequestsFieldsDTO } from './interfaces/settlementRequestsFieldsDTO'
import { getGlobal } from 'reactn'
import { mapDTOResponseToTableData } from '../../../utils/mapDTOResponseToTableData'
import { setGlobal } from '../../../global/useGlobal'
import { SettlementRequestsFilterNames } from '../../../constants/filters/settlementRequestsFilters'
import { displayError } from '../../../utils/displayError'

export const createGetSettlementRequests = async (page?: number, allData?: boolean): Promise<any> => {
  await setGlobal({
    isDataLoading: true,
  })

  try {
    const appliedFilters = getGlobal().settlementRequestsAppliedFilters
    const appliedSorting = getGlobal().settlementRequestsAppliedSorting
    const oldData = getGlobal().settlementRequestsData
    const filters = mountFilters(appliedFilters, page, appliedSorting, allData)
    const response = FeatureManager.shouldMockSettlementRequests
      ? await mockGetSettlementRequests()
      : await getSettlementRequests(filters)
    const apiResponse = mapResponseToColumnSchema(response)
    const tableData = mapDTOResponseToTableData(apiResponse)
    const settlementRequestsPaginationArgs = mapDTOResponseToPaginationSummary(response)
    let settlementRequestsData: any[]
    if (page) {
      settlementRequestsData = oldData.concat(tableData)
    } else {
      settlementRequestsData = tableData
    }
    await setGlobal({
      settlementRequestsData,
      settlementRequestsPaginationArgs,
      isDataLoading: false,
    })
  } catch (error) {
    await setGlobal({
      isDataLoading: false,
    })
    displayError(error.error)
  }
}

const mountFilters = (filters: Filter[], page?: number, sorting?: string[], allData?: boolean) => {
  const activeFilters = isActiveFilter(filters)
  const filtersObject = {
    ContractCode: formatFilter(activeFilters, SettlementRequestsFilterNames.ContractCode),
    AccountCode: formatFilter(activeFilters, SettlementRequestsFilterNames.Account),
    InstrumentSymbol: formatFilter(activeFilters, SettlementRequestsFilterNames.InstrumentSymbol),
    MinCreationDate: formatDateFilter(activeFilters, SettlementRequestsFilterNames.MinCreationDate),
    MaxCreationDate: formatDateFilter(activeFilters, SettlementRequestsFilterNames.MaxCreationDate),
    OrderSide: formatFilter(activeFilters, SettlementRequestsFilterNames.OrderSide),
    RequestInternalStatuses: formatFilter(activeFilters, SettlementRequestsFilterNames.RequestInternalStatuses),
    PageSize: allData ? -1 : PAGE_SIZE,
    PageNum: allData ? -1 : page || INITIAL_PAGE_NUMBER,
    RequireAllData: !!allData,
    Sorting: sorting?.join(',') || undefined,
  }
  return JSON.parse(JSON.stringify(filtersObject))
}

const mapDTOResponseToPaginationSummary = (dto: SettlementRequestsFieldsDTO) => {
  return {
    pageNum: dto.paginationSummary.pageNum,
    pageSize: dto.paginationSummary.pageSize,
    sorting: dto.paginationSummary.sorting,
    count: dto.paginationSummary.count,
    totalCount: dto.paginationSummary.totalCount,
    numberOfPages: numberOfPagesCalculator(dto.paginationSummary.pageSize, dto.paginationSummary.totalCount),
  }
}

const mapResponseToColumnSchema = (dto: SettlementRequestsFieldsDTO) => {
  const settlementRequestLegs: any[] = []

  dto.settlementRequestLegs.map((item) => {
    const rows = {
      id: item.id,
      exchangeTransactionId: item.exchangeTransactionId,
      originTransactionId: item.originTransactionId,
      contractCode: item.contractCode,
      instrumentSymbol: item.contractLeg.instrumentSymbol,
      effectiveQuantity: item.contractLeg.effectiveQuantity,
      quantity: item.quantity,
      side: item.side,
      issuerExecutionBrokerCode: item.contractLeg.issuerExecutionBrokerCode,
      issuerAccountCode: item.contractLeg.issuerAccountCode,
      issuerAliasOrName: item.contractLeg.issuerAliasOrName,
      originType: item.originType,
      creationDate: item.creationDate,
      lastUpdatetime: item.lastUpdatetime,
      externalStatus: item.externalStatus,
      internalStatus: item.internalStatus,
      nextPendingApproval: item.nextPendingApproval,
      nextPendingApprovingParticipant: item.nextPendingApprovingParticipant,
      errorRuleIds: item.errorRuleIds,
    }

    settlementRequestLegs.push(rows)
  })

  return {
    paginationSummary: dto.paginationSummary,
    settlementRequestLegs,
  }
}
