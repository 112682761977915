import { FeatureManager } from '../../FeatureManager'
import { getGlobal, setGlobal } from '../../../global/useGlobal'
import { ApprovalInstructionsUpdateRequest } from './request'
import { UpdateApprovalInstructionsRequest, ResponseData } from './interfaces/updateApprovalInstructionsDTO'
import { mockCreateUpdateApprovalInstructions } from '../../approvalInstructions/post/mockEndpoint'
import { mapISODateToString } from '../../../utils/mapISODateToString'
import { displayError } from '../../../utils/displayError'
import { mountRequestData } from '../../../utils/mountRequestData'
import { updateApprovalInstructionsOrder } from '../../../components/UpdateApprovalInstructions/updateApprovalInstructionsOrder'
import { ColumnNames } from '../../../constants/columnNames'

export const updateApprovalInstructions = async (): Promise<any> => {
  try {
    const updateApprovalInstructionsData = getGlobal().updateApprovalInstructionsData as any[]
    const body = mapTableDataToRequest(updateApprovalInstructionsData)
    const response = FeatureManager.shouldMockCreateUpdateApprovalInstructions
      ? await mockCreateUpdateApprovalInstructions()
      : await ApprovalInstructionsUpdateRequest(body)
    mapResponseToTableData(response)
    setGlobal({ isResponseApprovalInstructionsModalOpen: true })
  } catch (error) {
    setGlobal({ isResponseApprovalInstructionsModalOpen: false })
    displayError(error.error)
  }
}

const mapTableDataToRequest = (tableData: [][]) => {
  const filterData = tableData
    .map((row) => {
      const hasIdColumn = row[updateApprovalInstructionsOrder.indexOf(ColumnNames.Id)]
      if (hasIdColumn) {
        return row
      }
    })
    .filter(Boolean)

  const approvalInstructions: UpdateApprovalInstructionsRequest[] = mountRequestData(
    filterData,
    updateApprovalInstructionsOrder
  )

  const updateApprovalInstructionsRequest = {
    approvalInstructions,
  }

  return updateApprovalInstructionsRequest
}

const mapResponseToTableData = (response: ResponseData) => {
  let newTableData: any[] = []

  response.approvalInstructions.forEach((item) => {
    const row = {
      externalId: item.externalId,
      requestType: item.requestType,
      instrumentSymbol: item.instrumentSymbol,
      quantity: item.quantity,
      side: item.side,
      issuerAccountCode: item.issuerAccountCode,
      negotiationFee: item.negotiationFee,
      commissionFee: item.commissionFee,
      interBrokerFee: item.interBrokerFee,
      lenderReversible: item.lenderReversible ? 'Yes' : 'No',
      tenderOfferLenderReversible: item.tenderOfferLenderReversible ? 'Yes' : 'No',
      lockUpDate: mapISODateToString(item.lockUpDate),
      expireDate: mapISODateToString(item.expireDate),
      issuerCustodianCode: item.issuerCustodianCode,
      issuerCustodyAccountCode: item.issuerCustodyAccountCode,
      issuerPortfolio: item.issuerPortfolio,
      referenceDate: mapISODateToString(item.referenceDate),
      targetBrokerCode: item.targetBrokerCode,
      internalStatus: item.internalStatus,
      deskId: item.deskId,
      memo: item.memo,
      feedback: item.feedback,
    }
    newTableData.push(Object.values(row))
  })

  setGlobal({
    responseApprovalInstructionsData: newTableData,
    updateApprovalInstructionsData: undefined,
  })
}
