import { ApprovalInstructionsFieldsDTO } from '../get/interfaces/approvalInstructionsFieldsDTO'

export const mockApprovalInstructionsRequest = async (): Promise<ApprovalInstructionsFieldsDTO> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const assets = {
        paginationSummary: {
          count: 100,
          totalCount: 569,
          pageNum: 1,
          pageSize: 100,
          sorting: null,
        },
        approvalInstructions: [
          {
            id: '3a899f2e9d604c21bf27f507d6cab1d7',
            externalId: null,
            requestType: 'APosteriori',
            instrumentSymbol: 'AZUL4',
            quantity: 42,
            matchedQuantity: 42,
            approvedQuantity: 42,
            pendingQuantity: 0,
            side: 'Borrower',
            issuerAccountCode: '2056639',
            issuerAliasOrName: 'NOME DE CLIENTE',
            masterAccountCode: '186235',
            negotiationFee: 0.09,
            commissionFee: null,
            interBrokerFee: 0.09,
            lenderReversible: true,
            tenderOfferLenderReversible: true,
            creationDate: '2019-12-17T14:40:12.93Z',
            lockUpDate: '2019-12-18T00:00:00Z',
            expireDate: '2020-12-29T00:00:00Z',
            issuerBrokerCode: '1026',
            issuerCustodianCode: '2028',
            issuerCustodyAccountCode: '8108',
            issuerPortfolio: '21016',
            referenceDate: '2019-12-17T00:00:00Z',
            targetBrokerCode: null,
            internalStatus: 'Fulfilled',
            deskId: null,
            memo: null,
          },
          {
            id: '7e5f4699d6a546728d7a115ae6cf6716',
            externalId: null,
            requestType: 'APosteriori',
            instrumentSymbol: 'AZUL4',
            quantity: 100,
            matchedQuantity: 100,
            approvedQuantity: 100,
            pendingQuantity: 0,
            side: 'Borrower',
            issuerAccountCode: '2056639',
            issuerAliasOrName: 'NOME DE CLIENTE',
            masterAccountCode: '186235',
            negotiationFee: 0.1,
            commissionFee: null,
            interBrokerFee: 0.1,
            lenderReversible: true,
            tenderOfferLenderReversible: true,
            creationDate: '2019-12-17T14:40:16.163Z',
            lockUpDate: '2019-12-18T00:00:00Z',
            expireDate: '2020-12-29T00:00:00Z',
            issuerBrokerCode: '1026',
            issuerCustodianCode: '2028',
            issuerCustodyAccountCode: '8108',
            issuerPortfolio: '21016',
            referenceDate: '2019-12-17T00:00:00Z',
            targetBrokerCode: null,
            internalStatus: 'Fulfilled',
            deskId: null,
            memo: null,
          },
          {
            id: '59afffce46c74a6dbd7b18b1dae38b4d',
            externalId: null,
            requestType: 'APosteriori',
            instrumentSymbol: 'EQTL3',
            quantity: 139,
            matchedQuantity: 139,
            approvedQuantity: 139,
            pendingQuantity: 0,
            side: 'Borrower',
            issuerAccountCode: '98018',
            issuerAliasOrName: 'NOME DE CLIENTE',
            masterAccountCode: '98018',
            negotiationFee: 0.07,
            commissionFee: null,
            interBrokerFee: 0.07,
            lenderReversible: true,
            tenderOfferLenderReversible: true,
            creationDate: '2020-01-21T14:38:52.063Z',
            lockUpDate: '2020-01-22T00:00:00Z',
            expireDate: '2020-12-28T00:00:00Z',
            issuerBrokerCode: '1026',
            issuerCustodianCode: '1026',
            issuerCustodyAccountCode: '98018',
            issuerPortfolio: '21016',
            referenceDate: '2020-01-21T00:00:00Z',
            targetBrokerCode: null,
            internalStatus: 'Fulfilled',
            deskId: null,
            memo: null,
          },
          {
            id: 'f4eced6d60f44e39b7bbce4a1bf00976',
            externalId: null,
            requestType: 'APosteriori',
            instrumentSymbol: 'EQTL3',
            quantity: 45,
            matchedQuantity: 45,
            approvedQuantity: 45,
            pendingQuantity: 0,
            side: 'Borrower',
            issuerAccountCode: '98018',
            issuerAliasOrName: 'NOME DE CLIENTE',
            masterAccountCode: '98018',
            negotiationFee: 0.07,
            commissionFee: null,
            interBrokerFee: 0.07,
            lenderReversible: true,
            tenderOfferLenderReversible: true,
            creationDate: '2020-01-21T14:38:52.14Z',
            lockUpDate: '2020-01-22T00:00:00Z',
            expireDate: '2020-12-29T00:00:00Z',
            issuerBrokerCode: '1026',
            issuerCustodianCode: '1026',
            issuerCustodyAccountCode: '98018',
            issuerPortfolio: '21016',
            referenceDate: '2020-01-21T00:00:00Z',
            targetBrokerCode: null,
            internalStatus: 'Fulfilled',
            deskId: null,
            memo: null,
          },
          {
            id: 'bedca9c4ffad406b924ca407d6200552',
            externalId: null,
            requestType: 'APosteriori',
            instrumentSymbol: 'EQTL3',
            quantity: 175,
            matchedQuantity: 175,
            approvedQuantity: 175,
            pendingQuantity: 0,
            side: 'Borrower',
            issuerAccountCode: '98018',
            issuerAliasOrName: 'NOME DE CLIENTE',
            masterAccountCode: '98018',
            negotiationFee: 0.1,
            commissionFee: null,
            interBrokerFee: 0.1,
            lenderReversible: false,
            tenderOfferLenderReversible: true,
            creationDate: '2020-01-21T14:38:52.187Z',
            lockUpDate: '2020-01-22T00:00:00Z',
            expireDate: '2020-12-29T00:00:00Z',
            issuerBrokerCode: '1026',
            issuerCustodianCode: '1026',
            issuerCustodyAccountCode: '98018',
            issuerPortfolio: '21016',
            referenceDate: '2020-01-21T00:00:00Z',
            targetBrokerCode: null,
            internalStatus: 'Fulfilled',
            deskId: null,
            memo: null,
          },
        ],
      }
      resolve(assets)
    }, 1000)
  })
}
