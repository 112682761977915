import { ContractsFilterSchema, ContractsFilterNames } from '../filters/contractsFilters'
import mountDefaultFilters from '../../utils/mountDefaultFilter'

const contractsDefaultConfig = [
  {
    name: ContractsFilterNames.ContractInternalStatuses,
    value: 'Is Active',
  },
]

export const contractsDefaultFilters = mountDefaultFilters(ContractsFilterSchema, ...contractsDefaultConfig)
