import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import { baseTheme } from '../../styles/themes/baseTheme'
import { device } from '../../styles/media-queries'
import { makeStyles } from '@material-ui/core'

export const StyledButton = styled(Button)<any>`
  width: ${({ icon, materialIcon }) => (icon || materialIcon ? 40 : 156)}px;
  height: 36px;
  font-size: ${baseTheme.typography.button.fontSize};
  margin: 0 8px;
  .MuiButton-label {
    font-size: ${baseTheme.typography.button.fontSize};
    ${device.xl} {
      font-size: ${baseTheme.typography.caption.fontSize};
    }
  }
`

export const SvgIcon = styled.img`
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
`

export const StyledLoadingButtonContainer = styled.div<any>`
  width: ${({ isIconButton }) => (isIconButton ? 40 : 156)}px;
`

export const useStyles = makeStyles({
  root: {
    'min-width': '40px',
    margin: ' 0 4px',
  },
})
