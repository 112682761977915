import React from 'react'
import { colors } from '../../../styles/themes/light'
import { SvgComponent } from './interfaces'
import { useLocation } from 'react-router'
import { Routes } from '../../../routes'

export const SettlementsIcon: React.FC<SvgComponent> = (props) => {
  const { pathname } = useLocation()
  const isCurrentPage = pathname === Routes.settlements
  const fill = isCurrentPage ? colors.moduleIconHighlight : colors.moduleAssetSelectIcon

  return (
    <>
      <svg {...props} fill={fill} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M20.5375 13.5313L17 10L13.4625 13.5313C12.4875 14.5063 12 15.8063 12 17.0563C12 18.3063 12.4875 19.625 13.4625 20.6C14.4375 21.575 15.7188 22.0688 17 22.0688C18.2812 22.0688 19.5625 21.575 20.5375 20.6C21.5125 19.625 22 18.3063 22 17.0563C22 15.8063 21.5125 14.5063 20.5375 13.5313Z"
          fill="fill"
          fill-opacity="1"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5 3H20C21.1 3 22 3.9 22 5V8H18.0018L17 7L15.9982 8H3V5C3 3.9 3.9 3 5 3ZM13.9747 10.02H10V16.8787V21H11.3334C10.4445 19.7659 10 18.291 10 16.8787C10 15.1287 10.6825 13.3088 12.0475 11.9438L13.9747 10.02ZM22 11.9917V10H20.0053L21.9525 11.9438C21.9684 11.9597 21.9843 11.9757 22 11.9917ZM3 19C3 20.1 3.9 21 5 21H8V10H3V19Z"
          fill="fill"
          fill-opacity="1"
        />
      </svg>
    </>
  )
}

SettlementsIcon.defaultProps = {
  height: 24,
  width: 24,
}
