import clsx from 'clsx'
import React from 'react'
import Drawer from '@material-ui/core/Drawer'
import { IWorkspaceMenuView } from './interfaces'
import SettleIcon from '@material-ui/icons/Opacity'
import DeleteIcon from '@material-ui/icons/DeleteForever'
import { CustomDivider } from './styles'
import { ContractsIcon } from '../../resources/assets/drawerButtons/ContractsIcon'
import { ApprovalInstructionsIcon } from '../../resources/assets/drawerButtons/ApprovalInstructionsIcon'
import { ApprovalDivergencesIcon } from '../../resources/assets/drawerButtons/ApprovalDivergencesIcon'
import { SettlementsIcon } from '../../resources/assets/drawerButtons/SettlementsIcon'
import { SettleRequestsIcon } from '../../resources/assets/drawerButtons/SettleRequestsIcon'
import { strings } from '../../resources/strings/strings'
import NavigationButton from '../NavigationButton'
import { Routes } from '../../routes'
import { useLocation } from 'react-router'
import { VirtualSettleIcon } from '../../resources/assets/drawerButtons/VirtualSettleIcon'
import { CustomDialog } from '../CustomDialog'
import NoteAddIcon from '@material-ui/icons/NoteAdd'
import DeleteCleanIcon from '@material-ui/icons/Delete'
import UpdateIcon from '@material-ui/icons/Update'
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat'
import AutorenewIcon from '@material-ui/icons/Autorenew'
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart'
import { ApprovalInstructionsSettleIcon } from '../../resources/assets/drawerButtons/ApprovalInstructionsSettle'
import { colors } from '../../styles/themes/light'
import { AIRequestTypes } from '../../constants/approvalInstructions/requestType'
import { ApprovalDivergencesCreateIcon } from '../../resources/assets/drawerButtons/ApprovalDivergencesCreateAI'

export const WorkspaceMenuView: React.FC<IWorkspaceMenuView> = ({
  //TODO: refactor 'isLoading' prop? it should be called 'isButtonDisable' to better reflect this behavior
  isLoading,
  handleDeleteSR,
  handleDeleteAI,
  classes,
  settle,
  virtualSettle,
  createApprovalInstructions,
  updateApprovalInstructions,
  isLoadingConsolidatedPosition,
}) => {
  const { navigationButtonsLabels: labels } = strings
  const { pathname } = useLocation()
  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerClose]: true,
      })}
      classes={{
        paper: clsx({
          [classes.drawerClose]: true,
        }),
      }}
    >
      {pathname === Routes.contracts && (
        <>
          <NavigationButton isLoading={isLoading} label={labels.settle} onClick={settle}>
            <SettleIcon className={classes.primaryIcon} />
          </NavigationButton>
          <NavigationButton
            isButtonLoading={isLoadingConsolidatedPosition}
            isLoading={isLoading}
            label={labels.virtualSettle}
            onClick={virtualSettle}
          >
            <VirtualSettleIcon />
          </NavigationButton>
          <CustomDivider />
        </>
      )}
      {pathname === Routes.settlementRequests && (
        <>
          <NavigationButton isLoading={isLoading} label={labels.deleteSettlementRequests} onClick={handleDeleteSR}>
            <DeleteIcon className={classes.primaryIcon} />
          </NavigationButton>
          <CustomDivider />
        </>
      )}
      {pathname === Routes.approvalInstructions && (
        <>
          <NavigationButton
            isLoading={isLoading}
            label={labels.createApprovalInstructions}
            onClick={() => createApprovalInstructions()}
          >
            <NoteAddIcon className={classes.primaryIcon} />
          </NavigationButton>
          <NavigationButton
            isLoading={isLoading}
            label={labels.updateApprovalInstructions}
            onClick={updateApprovalInstructions}
          >
            <UpdateIcon className={classes.secondaryIcon} />
          </NavigationButton>
          <NavigationButton isLoading={isLoading} label={labels.deleteApprovalInstructions} onClick={handleDeleteAI}>
            <DeleteCleanIcon className={classes.secondaryIcon} />
          </NavigationButton>
          <NavigationButton
            isLoading={isLoading}
            label={labels.carryingApprovalInstructions}
            onClick={() => createApprovalInstructions(AIRequestTypes.CarryingPosition)}
          >
            <TrendingFlatIcon className={classes.defaultIcon} />
          </NavigationButton>
          <NavigationButton
            isLoading={isLoading}
            label={labels.renewApprovalInstructions}
            onClick={() => createApprovalInstructions(AIRequestTypes.RenewalRequest)}
          >
            <AutorenewIcon className={classes.defaultIcon} />
          </NavigationButton>
          <NavigationButton
            isLoading={isLoading}
            label={labels.settleApprovalInstructions}
            onClick={() => createApprovalInstructions(AIRequestTypes.SettlementRequest)}
          >
            <ApprovalInstructionsSettleIcon fill={colors.primary} />
          </NavigationButton>
          <NavigationButton
            isLoading={isLoading}
            label={labels.addOfferApprovalInstructions}
            onClick={() => createApprovalInstructions(AIRequestTypes.OfferInclusion)}
          >
            <AddShoppingCartIcon className={classes.defaultIcon} />
          </NavigationButton>
          <CustomDivider />
        </>
      )}
      {pathname === Routes.approvalDivergences && (
        <NavigationButton
          isLoading={isLoading}
          label={labels.createAIFromApprovalDivergences}
          onClick={() => createApprovalInstructions()}
        >
          <ApprovalDivergencesCreateIcon fill={colors.white} />
        </NavigationButton>
      )}
      <NavigationButton label={labels.contracts} route={Routes.contracts}>
        <ContractsIcon />
      </NavigationButton>
      <NavigationButton label={labels.settlementRequests} route={Routes.settlementRequests}>
        <SettleRequestsIcon />
      </NavigationButton>
      <NavigationButton label={labels.settlements} route={Routes.settlements}>
        <SettlementsIcon />
      </NavigationButton>
      <NavigationButton label={labels.approvalInstructions} route={Routes.approvalInstructions}>
        <ApprovalInstructionsIcon />
      </NavigationButton>
      <NavigationButton label={labels.approvalDivergences} route={Routes.approvalDivergences}>
        <ApprovalDivergencesIcon />
      </NavigationButton>
      <CustomDialog />
    </Drawer>
  )
}
