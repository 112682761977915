import { Routes } from '../routes'
import { FilterSchema } from '../constants/filters/filterTypes'
import { ContractsFilterSchema } from '../constants/filters/contractsFilters'
import { ApprovalInstructionsFilterSchema } from '../constants/filters/approvalInstructionsFilters'
import { ApprovalDivergencesFilterSchema } from '../constants/filters/approvalDivergencesFilters'
import { SettlementRequestsFilterSchema } from '../constants/filters/settlementRequestsFilters'
import { SettlementsFilterSchema } from '../constants/filters/settlementsFilters'
import { GlobalState } from '../global/InitialGlobalState'
import { FavoriteFiltersStateType } from '../global/components/favoriteFiltersState'
import {
  AppliedFiltersStateType,
  FilterModalControllersStateType,
  SelectedFiltersStateType,
} from '../global/components/filtersState'

class GlobalKeysPerPath {
  [key: string]: string | FilterSchema[]

  public selectedFiltersGlobalKey: keyof SelectedFiltersStateType
  public appliedFiltersGlobalKey: keyof AppliedFiltersStateType
  public isFilterModalOpenGlobalKey: keyof FilterModalControllersStateType
  public tableRefGlobalKey: keyof GlobalState
  public favoriteFiltersGlobalKey: keyof FavoriteFiltersStateType
  public columnWidthsGlobalKey: keyof GlobalState
  public columnsGlobalKey: keyof GlobalState
  public schema: FilterSchema[]

  constructor(public path: string, public filterSchema: FilterSchema[]) {
    this.tableRefGlobalKey = `${path}TableRef` as keyof GlobalState
    this.selectedFiltersGlobalKey = `${path}SelectedFilters` as keyof SelectedFiltersStateType
    this.appliedFiltersGlobalKey = `${path}AppliedFilters` as keyof AppliedFiltersStateType
    this.isFilterModalOpenGlobalKey = `${path}FilterModalOpen` as keyof FilterModalControllersStateType
    this.favoriteFiltersGlobalKey = `${path}FavoriteFilters` as keyof FavoriteFiltersStateType
    this.columnWidthsGlobalKey = `${path}ColumnWidths` as keyof GlobalState
    this.columnsGlobalKey = `${path}Columns` as keyof GlobalState
    this.schema = filterSchema
  }
}

interface AllGlobalKeysType {
  [key: string]: GlobalKeysPerPath
}

const AllGlobalKeys: () => AllGlobalKeysType = (): AllGlobalKeysType => {
  const contractsGlobalKeys = new GlobalKeysPerPath('contracts', ContractsFilterSchema)
  const settlementsGlobalKeys = new GlobalKeysPerPath('settlements', SettlementsFilterSchema)
  const settlementRequestsGlobalKeys = new GlobalKeysPerPath('settlementRequests', SettlementRequestsFilterSchema)
  const approvalInstructionsGlobalKeys = new GlobalKeysPerPath('approvalInstructions', ApprovalInstructionsFilterSchema)
  const approvalDivergencesGlobalKeys = new GlobalKeysPerPath('approvalDivergences', ApprovalDivergencesFilterSchema)

  return {
    [Routes.contracts]: contractsGlobalKeys,
    [Routes.settlements]: settlementsGlobalKeys,
    [Routes.settlementRequests]: settlementRequestsGlobalKeys,
    [Routes.approvalInstructions]: approvalInstructionsGlobalKeys,
    [Routes.approvalDivergences]: approvalDivergencesGlobalKeys,
  }
}

export const getGlobalKeys = (path: string): GlobalKeysPerPath => {
  return AllGlobalKeys()[path]
}
