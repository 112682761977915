import { strings } from '../../resources/strings/strings'
import { stockLoanApprovalInstructionRequestType } from '../approvalInstructions/StockLoanApprovalInstructionsRequestType'
import { stockLoanApprovalInstructionStatus } from '../approvalInstructions/StockLoanApprovalInstructionStatus'
import { stockLoanSide } from '../contracts/stockLoanSide'
import { FilterPatterns, FilterSchema, FilterType, InputStyle, AutoCompleteOptions } from './filterTypes'
import { ColumnNames } from '../../constants/columnNames'

export interface ApprovalDivergencesFilterInputs {
  accountCode: string
  instrumentSymbol: string
  requestType: string
  requestInternalStatuses: string
  orderSide: string
  expireDate: string
  [key: string]: string
}

export enum ApprovalDivergencesFilterNames {
  AccountCode = 'AccountCode',
  InstrumentSymbol = 'InstrumentSymbol',
  RequestType = 'RequestType',
  RequestInternalStatuses = 'RequestInternalStatuses',
  OrderSide = 'OrderSide',
  ExpireDate = 'ExpireDate',
}

const { filters } = strings.homeScreen

export const ApprovalDivergencesFilterSchema: FilterSchema[] = [
  {
    name: ApprovalDivergencesFilterNames.AccountCode,
    label: 'Account',
    mask: filters.accountMask,
    value: '',
    size: 3,
    inputStyle: InputStyle.autoComplete,
    filterType: FilterType.multiple,
    filterPattern: FilterPatterns.AccountCode,
    autoCompleteOptions: AutoCompleteOptions.accounts,
    columnReference: ColumnNames.IssuerAccountCode,
  },
  {
    name: ApprovalDivergencesFilterNames.InstrumentSymbol,
    label: 'Symbol',
    mask: filters.symbolMask,
    value: '',
    size: 3,
    inputStyle: InputStyle.autoComplete,
    filterType: FilterType.multiple,
    filterPattern: FilterPatterns.Symbol,
    autoCompleteOptions: AutoCompleteOptions.instruments,
    columnReference: ColumnNames.InstrumentSymbol,
  },
  {
    name: ApprovalDivergencesFilterNames.RequestType,
    label: 'Request Type',
    value: '',
    size: 6,
    inputStyle: InputStyle.dropdown,
    options: stockLoanApprovalInstructionRequestType,
    filterType: FilterType.unique,
    filterPattern: FilterPatterns.OnlyLetters,
    columnReference: ColumnNames.RequestType,
  },
  {
    name: ApprovalDivergencesFilterNames.RequestInternalStatuses,
    label: 'Status',
    value: '',
    size: 6,
    inputStyle: InputStyle.dropdown,
    options: stockLoanApprovalInstructionStatus,
    filterType: FilterType.unique,
    filterPattern: FilterPatterns.OnlyLetters,
  },
  {
    name: ApprovalDivergencesFilterNames.OrderSide,
    label: 'Side',
    value: '',
    size: 6,
    inputStyle: InputStyle.radio,
    radios: stockLoanSide,
    filterType: FilterType.unique,
    filterPattern: FilterPatterns.Side,
    columnReference: ColumnNames.Side,
  },
  {
    name: ApprovalDivergencesFilterNames.ExpireDate,
    label: 'Expire Date',
    mask: filters.dateMask,
    value: '',
    size: 3,
    inputStyle: InputStyle.textField,
    filterType: FilterType.unique,
    filterPattern: FilterPatterns.Date,
    placeholder: filters.datePlaceholder,
  },
]
