import { HttpMethods } from '../../apiService/baseEndpoint'
import { SettlementRequestEndpoint } from '../endpoint'
import { CreateSettlementRequestFields } from './interfaces/createSettlementRequestFields'

export const postSettlementRequests = async (input: { legs: CreateSettlementRequestFields[] }): Promise<any> => {
  const endpoint = new SettlementRequestEndpoint<undefined, { legs: CreateSettlementRequestFields[] }>(
    '',
    HttpMethods.POST,
    undefined,
    input
  )
  return endpoint.execute()
}
