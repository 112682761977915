import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Button } from '../Button'
import { ModalHeader } from '../ModalHeader'
import { IAutocompleteCellEditedByUser, ICreateApprovalInstructionsModal } from './interfaces'
import SaveIcon from '@material-ui/icons/Save'
import { ButtonsContainer } from './style'
import { strings } from '../../resources/strings/strings'
import Table from '../CustomTable'
import { createApprovalInstructionsSchemaGetter } from './schema'
import { useWindowSize } from '../../utils/hooks/useWindowSize'
import {
  MODAL_RELATIVE_HEIGHT,
  MODAL_RELATIVE_WIDTH,
  TABLE_TO_MODAL_HEIGHT_PROPORTION,
  TABLE_TO_MODAL_WIDTH_PROPORTION,
} from '../../constants/dimensions'
import { createApprovalInstructions } from '../../services/approvalInstructions/post/createApprovalInstructions'
import { usePromise } from '../../utils/hooks/usePromise'
import { setGlobal, useGlobal } from '../../global/useGlobal'
import { responseApprovalInstructionsSchema } from '../../services/dataSchemas/approvalInstructions/responseSchema'
import { getApprovalInstructions } from '../../services/approvalInstructions/get/getApprovalInstructions'
import { AutocompleteFieldsIndex } from '../../utils/onSymbolInput'

const CreateApprovalInstructions: React.FC<ICreateApprovalInstructionsModal> = ({ handleClose }) => {
  const [createApprovalInstructionsTableRef] = useGlobal('createApprovalInstructionsTableRef')
  const [requestType] = useGlobal('requestType')
  const [requestTypeModalTitle] = useGlobal('modalTitle')
  const [isResponse] = useGlobal('isResponseApprovalInstructionsModalOpen')
  const [autocompleteCellsEditedByUser, setAutocompleteCellsEditedByUser] = useState<IAutocompleteCellEditedByUser[]>(
    []
  )

  const createTableRef = useRef(createApprovalInstructionsTableRef)

  useEffect(() => {
    createTableRef.current = createApprovalInstructionsTableRef
  }, [createApprovalInstructionsTableRef])

  const autofillColumns = [
    {
      index: 1,
      content: requestType || '',
    },
  ]

  const { general, approvalInstructions, alerts } = strings
  const { windowWidth, windowHeight } = useWindowSize()

  const height = useMemo(() => TABLE_TO_MODAL_HEIGHT_PROPORTION * MODAL_RELATIVE_HEIGHT * windowHeight, [windowHeight])

  const width = useMemo(() => TABLE_TO_MODAL_WIDTH_PROPORTION * MODAL_RELATIVE_WIDTH * windowWidth, [windowHeight])

  const tableStyle = {
    height,
    maxHeight: height,
    width,
    overflow: 'auto',
    position: 'relative',
    display: 'flex',
    margin: '32px auto auto',
  } as React.CSSProperties

  const tableDimensions: [number, number] = [0, 10]

  const onSaveCreateApprovalInstructions = async () => {
    const data = createApprovalInstructionsTableRef?.current?.jexcel.getData!()
    const request = data[0][1]
    const issuer = data[0][5]
    const invalidData = !request || !issuer

    if (invalidData) {
      setGlobal({
        dialog: {
          isOpen: true,
          title: alerts.tableEmpty,
          text: alerts.insertDataInTable,
          handleAction: undefined,
        },
      })
      return
    }
    setGlobal({ createApprovalInstructionsData: data })
    await createApprovalInstructions()
    await getApprovalInstructions()
  }

  const { loading: isSaving, request: doSaveCreateAI } = usePromise(
    onSaveCreateApprovalInstructions,
    [createApprovalInstructionsTableRef?.current],
    {}
  )

  const requestTypeHeader = requestTypeModalTitle
    ? requestTypeModalTitle
    : approvalInstructions.createApprovalInstructions.title

  const approvalInstructionsTitle = isResponse
    ? approvalInstructions.resultApprovalInstructions.title
    : requestTypeHeader

  const tableSchema = createApprovalInstructionsSchemaGetter(!!requestType)

  const tableGlobalKey = requestType ? 'requestApprovalInstructionsColumns' : 'createApprovalInstructionsColumns'

  const onEditionStart = (
    tableElement: HTMLElement,
    cellElement: HTMLElement,
    columnIndex: number,
    rowIndex: number,
    cellContent: string
  ) => {
    if (columnIndex in AutocompleteFieldsIndex) {
      const cellChanged = { x: rowIndex, y: columnIndex }
      autocompleteCellsEditedByUser.push(cellChanged)
    }
  }

  return (
    <React.Fragment>
      <ModalHeader title={approvalInstructionsTitle} handleClose={handleClose} />
      {isResponse ? (
        <>
          <Table
            tableRefGlobalKey={'responseApprovalInstructionsTableRef'}
            tableColumnsGlobalKey={'responseApprovalInstructionsColumns'}
            columnWidthsGlobalKey={'responseApprovalInstructionsColumnWidths'}
            tableDataSchema={responseApprovalInstructionsSchema}
            dataGlobalKey={'responseApprovalInstructionsData'}
            tableHeight={height}
            containerHeight={height}
            hasFooter={false}
            tableStyle={tableStyle}
            hasTransparentBackground
            hasInsertRow
            hasDeleteRow
            shouldBlockColumnDrag
          />
        </>
      ) : (
        <Table
          tableRefGlobalKey={'createApprovalInstructionsTableRef'}
          tableColumnsGlobalKey={tableGlobalKey}
          tableDataSchema={tableSchema}
          dataGlobalKey={'createApprovalInstructionsData'}
          columnWidthsGlobalKey={'createApprovalInstructionsColumnWidths'}
          tableHeight={height}
          containerHeight={height}
          hasFooter={false}
          tableStyle={tableStyle}
          hasTransparentBackground
          shouldBlockColumnDrag
          minDimensions={tableDimensions}
          hasInsertRow
          hasDeleteRow
          isEditable
          autoCompleteColumns
          autofillColumns={autofillColumns}
          autocompleteCellsEditedByUser={autocompleteCellsEditedByUser}
          onEditionStart={onEditionStart}
        />
      )}
      <ButtonsContainer>
        {!isResponse && <Button color="primary" type="outlined" text={general.cancel} onClick={handleClose} />}
        <Button
          color="primary"
          type="contained"
          text={isResponse ? general.close : general.save}
          startIcon={!isResponse && <SaveIcon />}
          onClick={isResponse ? handleClose : doSaveCreateAI}
          loading={isSaving}
        />
      </ButtonsContainer>
    </React.Fragment>
  )
}

export default CreateApprovalInstructions
