import { AppBarProps } from '@material-ui/core'
import { WorkspaceAppbarView } from './view'
import { workspaceAppbarUseStyles } from './styles'
import { IWorkspaceAppbar } from './interfaces'
import { useGlobal } from 'reactn'
import React from 'react'
import { btgBlueLogo, btgWhiteLogo } from '../../resources/assets'

export const WorkspaceAppbar: React.FC<AppBarProps & IWorkspaceAppbar> = ({ ...props }) => {
  const [theme] = useGlobal('theme')
  const classes = workspaceAppbarUseStyles()
  const logo = theme && theme.palette.type === 'dark' ? btgWhiteLogo : btgBlueLogo
  return <WorkspaceAppbarView logo={logo} classes={classes} {...props} />
}
