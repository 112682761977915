import { MIN_COLUMN_WIDTH } from '../../constants/dimensions'
import { intMask } from '../../constants/masks/masks'
import { DataSchema } from '../../services/dataSchemas/interfaces'
import { sideDropdownSource } from '../../constants/dropdownSources'

export const virtualSettleSchema: { [key: string]: DataSchema } = {
  issuerAccountCode: {
    title: 'Account',
    type: 'text',
    tooltipName: 'Account Code',
  },
  side: {
    title: 'Side',
    type: 'dropdown',
    tooltipName: 'Side',
    source: sideDropdownSource,
  },
  instrumentSymbol: {
    title: 'Symbol',
    type: 'text',
    tooltipName: 'Instrument Symbol',
  },
  quantity: {
    title: 'Qty',
    type: 'text',
    tooltipName: 'Quantity',
    mask: intMask,
  },
}

export const succeededSchema: { [key: string]: DataSchema } = {
  contractCode: {
    title: 'Contract Code',
    type: 'text',
    tooltipName: 'Contract Code',
    readOnly: true,
  },
  instrumentSymbol: {
    title: 'Symbol',
    tooltipName: 'Instrument Symbol',
    type: 'text',
    readOnly: true,
  },
  effectiveQuantity: {
    title: 'Effec. Qty',
    type: 'text',
    tooltipName: 'Effective Quantity',
    readOnly: true,
    width: MIN_COLUMN_WIDTH,
  },
  quantity: {
    title: 'Qty',
    type: 'text',
    tooltipName: 'Quantity',
    width: MIN_COLUMN_WIDTH,
  },
  side: {
    title: 'Side',
    type: 'text',
    tooltipName: 'Side',
    readOnly: true,
  },
  issuerCarryingBrokerCode: {
    title: 'Carrying Broker',
    type: 'text',
    tooltipName: 'Carrying Broker',
    readOnly: true,
  },
  issuerAccountCode: {
    title: 'Account',
    type: 'text',
    tooltipName: 'Account Code',
    readOnly: true,
  },
  internalStatus: {
    title: 'Status',
    type: 'text',
    tooltipName: 'Internal Status',
    readOnly: true,
  },
  errorRuleIds: {
    title: 'Error Rule IDs',
    type: 'text',
    tooltipName: 'Error Rule IDs',
    readOnly: true,
  },
}

export const failedSchema: { [key: string]: DataSchema } = {
  issuerAccountCode: {
    title: 'Account',
    type: 'text',
    tooltipName: 'Account Code',
    readOnly: true,
  },
  side: {
    title: 'Side',
    type: 'text',
    tooltipName: 'Side',
    readOnly: true,
  },
  instrumentSymbol: {
    title: 'Symbol',
    type: 'text',
    tooltipName: 'Instrument Symbol',
    readOnly: true,
  },
  quantity: {
    title: 'Qty',
    type: 'text',
    tooltipName: 'Quantity',
    mask: intMask,
    readOnly: true,
  },
  rejectMessage: {
    title: 'Reject Message',
    type: 'text',
    tooltipName: 'Reject Message',
    readOnly: true,
  },
}
