import { ApprovalDivergencesColumnsSchema } from './interfaces'
import { datetimeMask, floatMask, intMask } from '../../../constants/masks/masks'
import { getDropdownSourceFromObject } from '../../../utils/getDropdownSourceFromObject'
import { stockLoanApprovalInstructionRequestType } from '../../../constants/approvalInstructions/StockLoanApprovalInstructionsRequestType'

export const approvalDivergencesSchema: ApprovalDivergencesColumnsSchema = {
  checkbox: {
    title: '',
    tooltipName: '',
    type: 'checkbox',
    index: 0,
  },
  requestType: {
    title: 'Request Type',
    tooltipName: 'Request Type',
    type: 'dropdown',
    source: getDropdownSourceFromObject(stockLoanApprovalInstructionRequestType),
  },
  divergentFields: {
    title: 'Divergent Fields',
    tooltipName: 'Divergent Fields',
    type: 'text',
    index: 4,
  },
  exchangeTransactionId: {
    title: 'Exchange Transaction ID',
    tooltipName: 'Exchange Transaction ID',
    type: 'text',
    index: 5,
  },
  objectExchangeId: {
    title: 'Object Exchange ID',
    tooltipName: 'Object Exchange ID',
    type: 'text',
    index: 6,
  },
  instrumentSymbol: {
    title: 'Symbol',
    tooltipName: 'Instrument Symbol',
    type: 'text',
    index: 7,
  },
  quantity: {
    title: 'Qty',
    tooltipName: 'Quantity',
    type: 'numeric',
    index: 8,
    mask: intMask,
  },
  matchedQuantity: {
    title: 'Matched Qty',
    tooltipName: 'Matched Quantity',
    type: 'numeric',
    index: 9,
    mask: intMask,
  },
  approvedQuantity: {
    title: 'Approved Qty',
    tooltipName: 'Approved Quantity',
    type: 'numeric',
    index: 10,
    mask: intMask,
  },
  pendingQuantity: {
    title: 'Pending Qty',
    tooltipName: 'Pending Quantity',
    type: 'numeric',
    index: 11,
    mask: intMask,
  },
  side: {
    title: 'Side',
    tooltipName: 'Side',
    type: 'text',
    index: 12,
  },
  issuerAccountCode: {
    title: 'Account',
    tooltipName: 'Issuer Account',
    type: 'text',
    index: 13,
  },
  issuerAliasOrName: {
    title: 'Name',
    tooltipName: 'Issuer Alias Or Name',
    type: 'text',
    index: 14,
  },
  masterAccountCode: {
    title: 'Master Account',
    tooltipName: 'Master Account',
    type: 'text',
    index: 15,
  },
  negotiationFee: {
    title: 'Negotiation Fee',
    tooltipName: 'Negotiation Fee',
    type: 'numeric',
    index: 16,
    mask: floatMask,
  },
  commissionFee: {
    title: 'Commission Fee',
    tooltipName: 'Commission Fee',
    type: 'numeric',
    index: 17,
    mask: floatMask,
  },
  interBrokerFee: {
    title: 'Inter Broker Fee',
    tooltipName: 'Inter Broker Fee',
    type: 'numeric',
    index: 18,
    mask: floatMask,
  },
  lenderReversible: {
    title: 'Callable?',
    tooltipName: 'Lender Reversible?',
    type: 'text',
    index: 19,
  },
  tenderOfferLenderReversible: {
    title: 'TOLR?',
    tooltipName: 'Tender Offer Lender Reversible?',
    type: 'text',
    index: 20,
  },
  lockUpDate: {
    title: 'Lock Up Date',
    tooltipName: 'Lock Up Date',
    type: 'text',
    index: 21,
    mask: datetimeMask,
  },
  expireDate: {
    title: 'Expire Date',
    tooltipName: 'Expire Date',
    type: 'text',
    index: 22,
    mask: datetimeMask,
  },
  issuerBrokerCode: {
    title: 'Issuer Broker Code',
    tooltipName: 'Issuer Broker Code',
    type: 'text',
    index: 23,
  },
  issuerCustodianCode: {
    title: 'Issuer Custodian Code',
    tooltipName: 'Issuer Custodian Code',
    type: 'text',
    index: 24,
  },
  issuerCustodyAccountCode: {
    title: 'Issuer Custody Account Code',
    tooltipName: 'Issuer Custody Account Code',
    type: 'text',
    index: 25,
  },
  issuerPortfolio: {
    title: 'Issuer Portfolio',
    tooltipName: 'Issuer Portfolio',
    type: 'text',
    index: 26,
  },
}
