import { StyledContextMenu } from './style'
import React, { useEffect } from 'react'
import { IContextMenu } from './interfaces'
import ContextMenuItem from '../CustomContextMenuItem'

const ContextMenu: React.FC<IContextMenu> = ({ top, left, actions, dismiss }) => {
  useEffect(() => {
    window.addEventListener('click', dismiss)
    return () => window.removeEventListener('click', dismiss)
  }, [])

  return (
    <StyledContextMenu top={top} left={left}>
      {actions.map((action) => {
        return <ContextMenuItem onClick={action.onClick} label={action.label} />
      })}
    </StyledContextMenu>
  )
}

export default ContextMenu
