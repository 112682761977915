import styled from 'styled-components'
import { colors } from '../../styles/themes/light'

export const TableContainer = styled.div<any>`
  background-color: ${(props) => (props.hasTransparentBackground ? 'transparent' : colors.grayBackground)};
  height: ${(props) => props.height}px;

  .jexcel.fixedLayout {
    width: 100%;
  }
`
