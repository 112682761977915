import { IPassword } from './interfaces'
import PasswordFieldView from './view'
import React, { useState } from 'react'

const PasswordField: React.FC<IPassword> = ({ name, label, errorMessage = '', onKeyPress }) => {
  const [isVisible, setPasswordVisible] = useState(false)
  const togglePasswordVisibility = () => {
    setPasswordVisible(!isVisible)
  }

  return (
    <div>
      <PasswordFieldView
        isPasswordVisible={isVisible}
        togglePasswordVisibility={togglePasswordVisibility}
        name={name}
        label={label}
        errorMessage={errorMessage}
        onKeyPress={onKeyPress}
      />
    </div>
  )
}

export default PasswordField
