import React from 'react'
import { ITextFieldFormInput } from './interfaces'
import { StyledTextField } from './style'

export const TextFieldView: React.FC<ITextFieldFormInput> = ({
  startAdornment,
  endAdornment,
  adornmentColor,
  error,
  helperText,
  ...props
}) => {
  return (
    <StyledTextField
      InputProps={{
        startAdornment: startAdornment,
        endAdornment: endAdornment,
      }}
      error={error}
      helperText={helperText}
      {...props}
    />
  )
}
