export const getColumnContentFromPaste = (
  selectedColumns: number[],
  columnIndex: number,
  pastedContent: string[][]
) => {
  const positionOfSideColumn = selectedColumns.indexOf(columnIndex)
  return pastedContent.map((value) => {
    return value[positionOfSideColumn]
  })
}
