import { DAY_IN_MILLISECONDS } from '../constants/parameters'

export const ADD_OPERATOR = '+'
export const TIME_LETTER = 'T'

export const getShortcutDate = (shortcut: string) => {
  const operator = shortcut.split('')[1]
  const days = Number(shortcut.slice(2))
  const currentDate = new Date()
  const currentTime = currentDate.getTime()
  const timezone = currentDate.getTimezoneOffset() * 60000

  const result =
    operator === ADD_OPERATOR
      ? new Date(currentTime + days * DAY_IN_MILLISECONDS - timezone)
      : new Date(currentTime - days * DAY_IN_MILLISECONDS - timezone)

  return result.toISOString().split(TIME_LETTER)[0]
}
