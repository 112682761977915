import { FeatureManager } from '../../FeatureManager'
import { consolidateVirtualSettlementPositionRequest } from './request'
import { mockConsolidateVirtualSettlementPosition } from './mockEndpoint'
import {
  ConsolidateVirtualSettlementPositionRequest,
  ResponseData,
} from '../interfaces/consolidateVirtualSettlementPositionDTO'
import { displayError } from '../../../utils/displayError'
import { setGlobal } from '../../../global/useGlobal'
import { CellValue } from '../../../components/jexcel-interfaces'
import { ColumnNames } from '../../../constants/columnNames'
import { consolidatedVirtualSettleColumnOrder } from '../../../components/VirtualSettle/virtualSettleColumnOrder'

export const consolidateVirtualSettlementPosition = async (data: any[][]): Promise<any> => {
  try {
    const body = mapTableDataToRequest(data)
    const response = FeatureManager.shouldMockConsolidateVirtualSettlementPosition
      ? await mockConsolidateVirtualSettlementPosition()
      : await consolidateVirtualSettlementPositionRequest(body)
    const tableData = mapResponseToTableData(response)

    setGlobal({
      virtualSettleData: tableData,
      isVirtualSettleResponse: false,
      isVirtualSettleModalOpen: true,
      isLoadingConsolidatedPosition: false,
    })
  } catch (error) {
    displayError(error.error)
  }
}

const mapTableDataToRequest = (tableData: string[][]) => {
  const contracts: ConsolidateVirtualSettlementPositionRequest[] = []

  tableData.forEach((row) => {
    const contractCode = row[consolidatedVirtualSettleColumnOrder.indexOf(ColumnNames.ContractCode)]
    const side = row[consolidatedVirtualSettleColumnOrder.indexOf(ColumnNames.Side)]

    const data = {
      contractCode,
      side,
    }

    contracts.push(data)
  })

  return {
    contracts,
  }
}

const mapResponseToTableData = (response: ResponseData) => {
  const consolidatedVirtualSettlements: CellValue[][] = []

  response.virtualSettlementRequests.forEach((row) => {
    consolidatedVirtualSettlements.push(Object.values(row))
  })

  return consolidatedVirtualSettlements
}
