import { useEffect } from 'react'
import Cookies from 'js-cookie'
import { setGlobal } from 'reactn'

const authTokenCookieKey = 'btg_solutions_user_session'

export const useAuthCookies = () => {
  useEffect(() => {
    const token = Cookies.get(authTokenCookieKey)
    if (token) {
      setGlobal({
        accessToken: token,
      })
    }
  }, [])
}
