export interface StockLoanContractStatusType {
  effective: string
  partiallyEffective: string
  forRenewal: string
  processed: string
  finalized: string
  rejected: string
  disposable: string
  executorsRenewing: string
  awaitingSettlement: string
  totalExternalLenderRecall: string
  toExpire: string
  isRenewable: string
  isActive: string
  [key: string]: string
}

export const stockLoanContractStatus: StockLoanContractStatusType = {
  effective: 'Effective',
  partiallyEffective: 'Partially Effective',
  forRenewal: 'For Renewal',
  processed: 'Processed',
  finalized: 'Finalized',
  rejected: 'Rejected',
  disposable: 'Disposable',
  executorsRenewing: 'Executors Renewing',
  awaitingSettlement: 'Awaiting Settlement',
  totalExternalLenderRecall: 'Total External LenderRecall',
  toExpire: 'To Expire',
  isRenewable: 'Is Renewable',
  isActive: 'Is Active',
}
