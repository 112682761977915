import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import { AlertProps } from '@material-ui/lab/Alert'
import { ISnackBarView } from './interfaces'
import { StyledMuiAlert } from './style'

const Alert = (props: AlertProps) => {
  return <StyledMuiAlert elevation={6} variant="filled" {...props} />
}

enum SnackBarSeverity {
  Error = 'error',
  Warning = 'warning',
  Success = 'success',
}

export const SnackBarView: React.FC<ISnackBarView> = ({ error, message, open, onClose, autoHideDuration, warning }) => {
  const type = error ? SnackBarSeverity.Error : SnackBarSeverity.Success
  const severity = warning ? SnackBarSeverity.Warning : type
  return (
    <Snackbar open={open} autoHideDuration={autoHideDuration} onClose={onClose}>
      <Alert onClose={onClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  )
}
