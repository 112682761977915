import React from 'react'
import { StyledButton, StyledLoadingButtonContainer, useStyles, SvgIcon } from './style'
import { IButtonView } from './interfaces'
import { LocalLoading } from '../Loading'
import { colors } from '../../styles/themes/light'

export const ButtonView: React.FC<IButtonView> = ({
  text,
  loading,
  color,
  type,
  onClick,
  startIcon,
  svgIcon,
  size,
  disabled,
  materialIcon,
}) => {
  const buttonClass = useStyles().root
  return (
    <>
      {loading ? (
        <StyledLoadingButtonContainer isIconButton={svgIcon}>
          <LocalLoading size={20} thickness={2} color={colors.secondary} />
        </StyledLoadingButtonContainer>
      ) : (
        <StyledButton
          className={buttonClass}
          startIcon={startIcon}
          onClick={onClick}
          color={color}
          variant={type}
          icon={svgIcon}
          size={size}
          disabled={disabled}
          materialIcon={materialIcon}
        >
          {text}
          {svgIcon && <SvgIcon src={svgIcon} />}
          {materialIcon && materialIcon}
        </StyledButton>
      )}
    </>
  )
}
