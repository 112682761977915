import React from 'react'
import { useGlobal } from 'reactn'
import { CustomDialogView } from './view'

export const CustomDialog: React.FC = () => {
  const [dialogState, setDialogState] = useGlobal('dialog')
  const { isOpen, title, text, handleAction } = dialogState || {}

  const handleClose = () => {
    setDialogState({
      isOpen: false,
      title: '',
      text: '',
      handleAction: undefined,
    })
  }

  return (
    <CustomDialogView title={title} text={text} isOpen={isOpen} handleClose={handleClose} handleAction={handleAction} />
  )
}
