import { SettlementRequestsFilterSchema, SettlementRequestsFilterNames } from '../filters/settlementRequestsFilters'
import mountDefaultFilters from '../../utils/mountDefaultFilter'
import { currentDateToString, DateTime } from '../../utils/currentDateToString'

const settlementRequestsDefaultConfig = [
  {
    name: SettlementRequestsFilterNames.MinCreationDate,
    value: currentDateToString(DateTime.FirstSecond),
  },
  {
    name: SettlementRequestsFilterNames.MaxCreationDate,
    value: currentDateToString(DateTime.LastSecond),
  },
]

export const settlementRequestsDefaultFilters = mountDefaultFilters(
  SettlementRequestsFilterSchema,
  ...settlementRequestsDefaultConfig
)
