import React from 'react'
import { ICustomModalView } from './interfaces'
import { ModalContainer, StyledModal } from './style'

export const CustomModalView: React.FC<ICustomModalView> = ({ isOpen, children, closeModal, filters }) => {
  return (
    <StyledModal onBackdropClick={closeModal} open={isOpen}>
      <ModalContainer filters={filters}>{children}</ModalContainer>
    </StyledModal>
  )
}
