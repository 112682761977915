import React from 'react'
import Login from './screens/Login'
import ResetPassword from './screens/ResetPassword'
import ForgotPassword from './screens/ForgotPassword'
import Contracts from './screens/Contracts'
import Settlements from './screens/Settlements'
import SettlementRequests from './screens/SettlementRequests'
import ApprovalInstructions from './screens/ApproveInstructions'
import ApprovalDivergences from './screens/ApprovalDivergences'

/**
 * List all possible routes here
 */

export const Routes = {
  contracts: '/contracts',
  settlements: '/settlements',
  login: '/login',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  settlementRequests: '/settlement-requests',
  approvalInstructions: '/approval-instructions',
  approvalDivergences: '/approval-divergences',
}

export const AuthRoutes: { [path: string]: React.ComponentType } = {
  [Routes.contracts]: Contracts,
  [Routes.settlements]: Settlements,
  [Routes.settlementRequests]: SettlementRequests,
  [Routes.approvalInstructions]: ApprovalInstructions,
  [Routes.approvalDivergences]: ApprovalDivergences,
}

export const UnauthRoutes: { [path: string]: React.ComponentType } = {
  [Routes.login]: Login,
  [Routes.forgotPassword]: ForgotPassword,
  [Routes.resetPassword]: ResetPassword,
}

export const OpenRoutes: { [path: string]: React.ComponentType } = {}
