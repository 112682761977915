import { FeatureManager } from '../../FeatureManager'
import { getGlobal, setGlobal } from '../../../global/useGlobal'
import { ApprovalInstructionsCreateRequest } from './request'
import { CreateApprovalInstructionsRequest, ResponseData } from './interfaces/createApprovalInstructionsDTO'
import { mockCreateUpdateApprovalInstructions } from './mockEndpoint'
import { mapISODateToString } from '../../../utils/mapISODateToString'
import { displayError } from '../../../utils/displayError'
import { mountRequestData } from '../../../utils/mountRequestData'
import { createApprovalInstructionsOrder } from '../../../components/CreateApprovalInstructions/createApprovalInstructionsOrder'
import { ApprovalInstructionsReferenceColumns } from '../../../constants/columnTypes'
import { ColumnNames } from '../../../constants/columnNames'

export const createApprovalInstructions = async (): Promise<any> => {
  try {
    const createApprovalInstructionsData = getGlobal().createApprovalInstructionsData as any[]
    const body = mapTableDataToRequest(createApprovalInstructionsData)
    const response = FeatureManager.shouldMockCreateUpdateApprovalInstructions
      ? await mockCreateUpdateApprovalInstructions()
      : await ApprovalInstructionsCreateRequest(body)
    mapResponseToTableData(response)
    setGlobal({ isResponseApprovalInstructionsModalOpen: true })
  } catch (error) {
    setGlobal({ isResponseApprovalInstructionsModalOpen: false })
    displayError(error.error)
  }
}

const mapTableDataToRequest = (tableData: [][]) => {
  const filterData = tableData
    .map((row) => {
      const hasRequestType = row[createApprovalInstructionsOrder.indexOf(ColumnNames.RequestType)]
      const hasAccount = row[createApprovalInstructionsOrder.indexOf(ColumnNames.IssuerAccountCode)]

      if (hasRequestType && hasAccount) {
        return row
      }
    })
    .filter(Boolean)

  const approvalInstructions: CreateApprovalInstructionsRequest[] = mountRequestData(
    filterData,
    createApprovalInstructionsOrder,
    ApprovalInstructionsReferenceColumns
  )

  const createApprovalInstructionsRequest = {
    approvalInstructions,
  }

  return createApprovalInstructionsRequest
}

const mapResponseToTableData = (response: ResponseData) => {
  let newTableData: any[] = []

  response.approvalInstructions.forEach((item) => {
    const row = {
      externalId: item.externalId,
      requestType: item.requestType,
      instrumentSymbol: item.instrumentSymbol,
      quantity: item.quantity,
      side: item.side,
      issuerAccountCode: item.issuerAccountCode,
      negotiationFee: item.negotiationFee,
      commissionFee: item.commissionFee,
      interBrokerFee: item.interBrokerFee,
      lenderReversible: item.lenderReversible ? 'Yes' : 'No',
      tenderOfferLenderReversible: item.tenderOfferLenderReversible ? 'Yes' : 'No',
      lockUpDate: mapISODateToString(item.lockUpDate),
      expireDate: mapISODateToString(item.expireDate),
      issuerCustodianCode: item.issuerCustodianCode,
      issuerCustodyAccountCode: item.issuerCustodyAccountCode,
      issuerPortfolio: item.issuerPortfolio,
      referenceDate: mapISODateToString(item.referenceDate),
      targetBrokerCode: item.targetBrokerCode,
      internalStatus: item.internalStatus,
      deskId: item.deskId,
      memo: item.memo,
      feedback: item.feedback,
    }
    newTableData.push(Object.values(row))
  })

  setGlobal({
    responseApprovalInstructionsData: newTableData,
    createApprovalInstructionsData: undefined,
  })
}
