import React from 'react'
import { StyledAutocomplete } from './style'
import { ICustomAutoCompleteView } from './interfaces'
import { CustomTextField } from '../CustomTextField'
import AddBoxIcon from '@material-ui/icons/AddBox'

export const CustomAutoCompleteView: React.FC<ICustomAutoCompleteView> = ({
  options,
  label,
  name,
  placeholder,
  mask,
  value,
  onSelect,
  handleChange,
  onClick,
}) => {
  return (
    <StyledAutocomplete
      id={`autocomplete-${name}`}
      fullWidth
      filterSelectedOptions
      options={options}
      clearOnBlur
      onInputChange={onSelect(name)}
      inputValue={value}
      clearOnEscape
      clearText={''}
      closeIcon={<AddBoxIcon onClick={onClick} />}
      renderInput={(params: any) => (
        <CustomTextField
          {...params}
          name={name}
          label={label}
          placeholder={placeholder}
          mask={mask}
          value={value}
          handleChange={handleChange}
          onClick={onClick}
        />
      )}
    />
  )
}
