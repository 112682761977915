import { ApprovalDivergencesColumnsSchema } from './interfaces'
import { ColumnNames } from '../../../constants/columnNames'

export const approvalDivergencesDefaultColumnOrder: (keyof ApprovalDivergencesColumnsSchema)[] = [
  ColumnNames.Checkbox,
  ColumnNames.RequestType,
  ColumnNames.DivergentFields,
  ColumnNames.ExchangeTransactionId,
  ColumnNames.ObjectExchangeId,
  ColumnNames.InstrumentSymbol,
  ColumnNames.Quantity,
  ColumnNames.MatchedQuantity,
  ColumnNames.ApprovedQuantity,
  ColumnNames.PendingQuantity,
  ColumnNames.Side,
  ColumnNames.IssuerAccountCode,
  ColumnNames.IssuerAliasOrName,
  ColumnNames.MasterAccountCode,
  ColumnNames.NegotiationFee,
  ColumnNames.CommissionFee,
  ColumnNames.InterBrokerFee,
  ColumnNames.LenderReversible,
  ColumnNames.TenderOfferLenderReversible,
  ColumnNames.LockUpDate,
  ColumnNames.ExpireDate,
  ColumnNames.IssuerBrokerCode,
  ColumnNames.IssuerCustodianCode,
  ColumnNames.IssuerCustodyAccountCode,
  ColumnNames.IssuerPortfolio,
]
