export class FeatureManager {
  static shouldUseLoginMock: boolean = false
  static shouldLoginExpire: boolean = false
  static shouldMockContracts: boolean = false
  static shouldMockSettlementRequests: boolean = false
  static shouldMockSettlements: boolean = false
  static shouldMockVirtualSettlement: boolean = false
  static shouldMockSettle: boolean = false
  static shouldMockApprovalInstructions: boolean = false
  static shouldMockCreateUpdateApprovalInstructions: boolean = false
  static shouldMockCheckToken: boolean = false
  static shouldMockRefreshedTokenResponse: boolean = false
  static shouldMockAccounts: boolean = false
  static shouldMockInstrumentSymbols: boolean = false
  static shouldMockDefaultDates: boolean = false
  static shouldMockApprovalDivergences: boolean = false
  static shouldMockConsolidateVirtualSettlementPosition: boolean = false
  static shouldMockExtranetSync: boolean = false
}
