import { getUsernameInfo } from '../../utils/localStorageManager'
import validate from 'validate.js'

const constraints = {
  password: {
    presence: {
      message: '^',
    },
  },
  email: {
    presence: {
      message: '^',
    },
  },
}

const validator = (values: any) => {
  return validate(values, constraints)
}

export const loginConfig = {
  initialValues: {
    email: getUsernameInfo(),
    rememberCredentials: !!getUsernameInfo(),
  },
  formName: 'login',
  validator,
}
