import { FeatureManager } from '../FeatureManager'
import { contractsRequest } from './request'
import { mockContractsRequest } from './mockEndpoint'
import { setGlobal } from '../../global/useGlobal'
import { ContractsFieldsDTO } from './interfaces/contractsFieldsDTO'
import { getGlobal } from 'reactn'
import { numberOfPagesCalculator } from '../../utils/numberOfPagesCalculator'
import { mapDTOResponseToTableData } from '../../utils/mapDTOResponseToTableData'
import { isActiveFilter } from '../../utils/isActiveFilter'
import { INITIAL_PAGE_NUMBER, PAGE_SIZE } from '../../constants/pagination'
import { Filter } from '../../constants/filters/filterTypes'
import { formatFilter } from '../../utils/formatFilter'
import { formatDateFilter } from '../../utils/formatDateFilter'
import { ContractsFilterNames } from '../../constants/filters/contractsFilters'
import { displayError } from '../../utils/displayError'

export const getContracts = async (page?: number, allData?: boolean): Promise<any> => {
  await setGlobal({
    isDataLoading: true,
  })

  try {
    const appliedFilters = getGlobal().contractsAppliedFilters
    const appliedSorting = getGlobal().contractsAppliedSorting
    const oldData = getGlobal().contractsTableData
    const filters = mountFilters(appliedFilters, page, appliedSorting, allData)
    const response: ContractsFieldsDTO = FeatureManager.shouldMockContracts
      ? await mockContractsRequest()
      : await contractsRequest(filters)
    const apiDataContractsResponse = mapDTOResponseToTableData(response)
    const contractsPaginationArgs = mapDTOResponseToPaginationSummary(response)
    let contractsTableData: any[]
    if (page) {
      contractsTableData = oldData.concat(apiDataContractsResponse)
    } else {
      contractsTableData = apiDataContractsResponse
    }
    await setGlobal({
      contractsTableData,
      contractsPaginationArgs,
      isDataLoading: false,
    })
  } catch (error) {
    await setGlobal({
      isDataLoading: false,
    })
    displayError(error.error)
  }
}

const mountFilters = (filters: Filter[], page?: number, sorting?: string[], allData?: boolean) => {
  const activeFilters = isActiveFilter(filters)
  const filtersObject = {
    ContractCode: formatFilter(activeFilters, ContractsFilterNames.ContractCode),
    AccountCode: formatFilter(activeFilters, ContractsFilterNames.AccountCode),
    InstrumentSymbol: formatFilter(activeFilters, ContractsFilterNames.InstrumentSymbol),
    MinExpireDate: formatDateFilter(activeFilters, ContractsFilterNames.MinExpireDate),
    MaxExpireDate: formatDateFilter(activeFilters, ContractsFilterNames.MaxExpireDate),
    MinCreationDate: formatDateFilter(activeFilters, ContractsFilterNames.MinCreationDate),
    MaxCreationDate: formatDateFilter(activeFilters, ContractsFilterNames.MaxCreationDate),
    ContractInternalStatuses: formatFilter(activeFilters, ContractsFilterNames.ContractInternalStatuses),
    OrderSide: formatFilter(activeFilters, ContractsFilterNames.OrderSide),
    ExecutionBrokerCode: formatFilter(activeFilters, ContractsFilterNames.ExecutionBrokerCode),
    PageSize: allData ? -1 : PAGE_SIZE,
    PageNum: allData ? -1 : page || INITIAL_PAGE_NUMBER,
    RequireAllData: !!allData,
    Sorting: sorting?.join(',') || undefined,
  }
  return JSON.parse(JSON.stringify(filtersObject))
}

const mapDTOResponseToPaginationSummary = (dto: ContractsFieldsDTO) => {
  return {
    pageNum: dto.paginationSummary.pageNum,
    pageSize: dto.paginationSummary.pageSize,
    sorting: dto.paginationSummary.sorting,
    count: dto.paginationSummary.count,
    totalCount: dto.paginationSummary.totalCount,
    numberOfPages: numberOfPagesCalculator(dto.paginationSummary.pageSize, dto.paginationSummary.totalCount),
  }
}
