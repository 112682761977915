import { Filter } from '../../constants/filters/filterTypes'
import { getPersistedData } from '../../utils/localStorageManager'

export interface FilterSet {
  identifier: string
  filters: Filter[]
}

export interface FavoriteFiltersStateType {
  contractsFavoriteFilters: FilterSet[]
  settlementsFavoriteFilters: FilterSet[]
  settlementRequestsFavoriteFilters: FilterSet[]
  approvalInstructionsFavoriteFilters: FilterSet[]
  approvalDivergencesFavoriteFilters: FilterSet[]
}

export const favoriteFiltersState: FavoriteFiltersStateType = {
  contractsFavoriteFilters: getPersistedData('contractsFavoriteFilters') || [],
  settlementsFavoriteFilters: getPersistedData('settlementsFavoriteFilters') || [],
  settlementRequestsFavoriteFilters: getPersistedData('settlementRequestsFavoriteFilters') || [],
  approvalInstructionsFavoriteFilters: getPersistedData('approvalInstructionsFavoriteFilters') || [],
  approvalDivergencesFavoriteFilters: getPersistedData('approvalDivergencesFavoriteFilters') || [],
}
