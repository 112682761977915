import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { RootRouter } from './components/RootRouter/RootRouter'
import { createBrowserHistory } from 'history'
import { useLoadPersistedState } from './global/useGlobal'
import Layout from './components/Layout'
import { useAuthCookies } from './utils/hooks/useAuthCookies'
import { Home } from './screens/Home'

export const history = createBrowserHistory()

const App = () => {
  useAuthCookies()
  const loadingPersistedState = useLoadPersistedState()
  if (loadingPersistedState) {
    return null
  }

  return (
    <Layout>
      <Router>
        <Route exact path="/" component={Home} />
        <RootRouter history={history} />
      </Router>
    </Layout>
  )
}

export default App
