import { CellValue, Options } from '../../components/jexcel-interfaces'
import { RefObject } from 'react'
import { SettlementsColumnsSchema } from '../../services/dataSchemas/settlements/interfaces'
import { settlementsDefaultColumnOrder } from '../../services/dataSchemas/settlements/settlementsDefaultColumnOrder'
import { getPersistedData } from '../../utils/localStorageManager'

interface SettlementsStateType {
  settlementsData: CellValue[][]
  settlementsTableRef?: RefObject<{ jexcel: Options } & HTMLDivElement>
  settlementsColumnWidths?: number[]
  currentSettlementsPage: number
  settlementsPaginationArgs: any
  settlementsAppliedSorting: string[]
  settlementsColumns: (keyof SettlementsColumnsSchema)[]
}

export const settlementsState: SettlementsStateType = {
  settlementsData: [],
  settlementsTableRef: undefined,
  currentSettlementsPage: 1,
  settlementsPaginationArgs: {},
  settlementsColumnWidths: getPersistedData('settlementsColumnWidths'),
  settlementsAppliedSorting: [],
  settlementsColumns: getPersistedData('settlementsColumns') || settlementsDefaultColumnOrder,
}
