import { decode } from 'jsonwebtoken'
import { IUserInfo } from '../services/auth/CheckToken/interfaces'

export const isTokenExpired = (token: string) => {
  try {
    const { exp } = decode(token) as IUserInfo
    const currentTimestamp = Math.floor(new Date().getTime() / 1000)
    const hasExpired = currentTimestamp > exp

    return hasExpired ? true : false
  } catch (e) {
    return true
  }
}
