import { ContractsColumnsSchema } from '../../services/dataSchemas/contracts/interfaces'
import { contractsDefaultColumnOrder } from '../../services/dataSchemas/contracts/contractsDefaultColumnOrder'
import { RefObject } from 'react'
import { Options } from '../../components/jexcel-interfaces'
import { getPersistedData } from '../../utils/localStorageManager'

interface ContractsStateType {
  contractsTableData: any[]
  contractsColumns: (keyof ContractsColumnsSchema)[]
  contractsTableRef?: RefObject<{ jexcel: Options } & HTMLDivElement>
  contractsColumnWidths?: number[]
  currentContractsPage: number
  contractsPaginationArgs: any
  contractsAppliedSorting: string[]
}

export const contractsState: ContractsStateType = {
  contractsTableData: [],
  contractsColumns: getPersistedData('contractsColumns') || contractsDefaultColumnOrder,
  contractsTableRef: undefined,
  currentContractsPage: 1,
  contractsColumnWidths: getPersistedData('contractsColumnWidths'),
  contractsPaginationArgs: {},
  contractsAppliedSorting: [],
}
