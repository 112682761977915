export interface EnvironmentType {
  NEW_ENV: string
  BTG_CONTRACTS_URL: string
  BASE_URL: string
  BTG_BASE_AUTH_URL: string
  BTG_TOKEN_URL: string
}

export interface EnvConfigType {
  [key: string]: EnvironmentType
}

export const envconfig: EnvConfigType = {
  development: {
    NEW_ENV: 'development',
    BASE_URL: 'https://middleware-dev-etd-solutions.pactual.net:4000',
    BTG_CONTRACTS_URL: 'https://mbsapi-dev.pactual.net',
    BTG_BASE_AUTH_URL: 'https://auth-dev-etd-solutions.pactual.net:5000/api/Auth',
    BTG_TOKEN_URL: 'https://auth-dev-etd-solutions.pactual.net:5000/api/Token',
  } as EnvironmentType,
  staging: {
    NEW_ENV: 'staging',
    BASE_URL: 'https://middleware-uat-etd-solutions.pactual.net:4000/',
    BTG_CONTRACTS_URL: 'https://mbsapi-uat.pactual.net',
    BTG_BASE_AUTH_URL: 'https://auth-uat-etd-solutions.pactual.net:5000/api/Auth',
    BTG_TOKEN_URL: 'https://auth-uat-etd-solutions.pactual.net:5000/api/Token',
  } as EnvironmentType,
  production: {
    NEW_ENV: 'production',
    BASE_URL: 'https://authservice.btgpactualsolutions.com',
    BTG_CONTRACTS_URL: 'https://mbsapi.btgpactual.com',
    BTG_BASE_AUTH_URL: 'https://auth.btgpactualsolutions.com/api/Auth',
    BTG_TOKEN_URL: 'https://auth.btgpactualsolutions.com/api/Token',
  } as EnvironmentType,
}

//TODO: set this up properly (we can check btg-solutions-web for reference)
