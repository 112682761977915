import React from 'react'
import { CustomCheckboxFieldView } from './CustomCheckboxFieldView'
import { ICustomCheckboxField } from './interfaces'

export const CustomCheckboxField: React.FC<ICustomCheckboxField> = ({
  label,
  name,
  firstRadioLabel,
  secondRadioLabel,
  handleChange,
  onClick,
  ...props
}) => {
  return (
    <CustomCheckboxFieldView
      label={label}
      name={name}
      firstRadioLabel={firstRadioLabel}
      secondRadioLabel={secondRadioLabel}
      onClick={onClick}
      handleChange={handleChange}
      {...props}
    />
  )
}
