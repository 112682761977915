import { ApprovalInstructionsFieldsDTO } from '../get/interfaces/approvalInstructionsFieldsDTO'

export const mockDeleteApprovalInstructionsRequest = async (): Promise<ApprovalInstructionsFieldsDTO> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const assets = {
        paginationSummary: {
          count: 100,
          totalCount: 195,
          pageNum: 1,
          pageSize: 100,
          sorting: null,
        },
        approvalInstructions: [
          {
            id: '7e5f4699d6a546728d7a115ae6cf6716',
            externalId: null,
            requestType: 'APosteriori',
            instrumentSymbol: 'AZUL4',
            quantity: 100,
            matchedQuantity: 100,
            approvedQuantity: 100,
            pendingQuantity: 0,
            side: 'Borrower',
            issuerAccountCode: '2056639',
            issuerAliasOrName: 'RPS PREV ABSOLUTO FIM PREVIDENCIA MULTIMERCADO MASTER',
            masterAccountCode: '186235',
            negotiationFee: 0.1,
            commissionFee: null,
            interBrokerFee: 0.1,
            lenderReversible: true,
            tenderOfferLenderReversible: true,
            lockUpDate: '2019-12-18T00:00:00Z',
            expireDate: '2020-12-29T00:00:00Z',
            issuerBrokerCode: '1026',
            issuerCustodianCode: '2028',
            issuerCustodyAccountCode: '8108',
            issuerPortfolio: '21016',
            referenceDate: '2019-12-17T00:00:00Z',
            targetBrokerCode: null,
            internalStatus: 'Fulfilled',
            deskId: null,
            memo: null,
          },
          {
            id: '661d3b6fb56148eebb41df2e367cd088',
            externalId: null,
            requestType: 'APosteriori',
            instrumentSymbol: 'BOVA11',
            quantity: 10,
            matchedQuantity: 10,
            approvedQuantity: 10,
            pendingQuantity: 0,
            side: 'Borrower',
            issuerAccountCode: '98018',
            issuerAliasOrName: 'FUNDO DE INVESTIMENTO MULTIMERCADO CREDITO PRIVADO LS INVESTIMENTO NO EXTERIOR',
            masterAccountCode: '98018',
            negotiationFee: 1.4,
            commissionFee: null,
            interBrokerFee: 1.4,
            lenderReversible: false,
            tenderOfferLenderReversible: true,
            lockUpDate: '2020-01-22T00:00:00Z',
            expireDate: '2020-09-17T00:00:00Z',
            issuerBrokerCode: '1026',
            issuerCustodianCode: '1026',
            issuerCustodyAccountCode: '98018',
            issuerPortfolio: '21016',
            referenceDate: '2020-01-21T00:00:00Z',
            targetBrokerCode: null,
            internalStatus: 'Fulfilled',
            deskId: null,
            memo: null,
          },
        ],
      }
      resolve(assets)
    }, 1000)
  })
}
