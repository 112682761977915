export interface StockLoanApprovalInstructionRequestType {
  Unknown: string
  OfferInclusion: string
  CarryingPosition: string
  SettlementRequest: string
  RenewalRequest: string
  APosteriori: string
  CustodianIndication: string
  AnyCustodianApproval: string
  [key: string]: string
}

export const stockLoanApprovalInstructionRequestType: StockLoanApprovalInstructionRequestType = {
  Unknown: 'Unknown',
  OfferInclusion: 'Offer Inclusion',
  CarryingPosition: 'Carrying Position',
  SettlementRequest: 'Settlement Request',
  RenewalRequest: 'Renewal Request',
  APosteriori: 'A Posteriori',
  CustodianIndication: 'Custodian Indication',
  AnyCustodianApproval: 'Any Custodian Approval',
}
