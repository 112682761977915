import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@material-ui/core'
import { ICustomDialog } from './interfaces'
import { strings } from '../../resources/strings/strings'

export const CustomDialogView: React.FC<ICustomDialog> = ({ title, text, isOpen, handleClose, handleAction }) => {
  const onClickAction = () => {
    if (handleAction) {
      handleAction()
    }
    handleClose()
  }

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{text}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {strings.general.cancel}
          </Button>
          <Button onClick={onClickAction} color="primary">
            {strings.general.ok}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
