import {
  ApprovalInstructionsFilterSchema,
  ApprovalInstructionsFilterNames,
} from '../filters/approvalInstructionsFilters'
import mountDefaultFilters from '../../utils/mountDefaultFilter'
import { currentDateToString, DateTime } from '../../utils/currentDateToString'

const approvalInstructionsDefaultConfig = [
  {
    name: ApprovalInstructionsFilterNames.MinCreationDate,
    value: currentDateToString(DateTime.FirstSecond),
  },
  {
    name: ApprovalInstructionsFilterNames.MaxCreationDate,
    value: currentDateToString(DateTime.LastSecond),
  },
]

export const approvalInstructionsDefaultFilters = mountDefaultFilters(
  ApprovalInstructionsFilterSchema,
  ...approvalInstructionsDefaultConfig
)
