import { getGlobal, setGlobal } from '../global/useGlobal'
import { checkToken } from '../services/auth/CheckToken/checkToken'
import { FeatureManager } from '../services/FeatureManager'
import { isTokenExpired } from './isTokenExpired'

export const validateUserToken = (token: string) => {
  const hasTokenExpired = isTokenExpired(token)

  if (hasTokenExpired) {
    !FeatureManager.shouldUseLoginMock ? checkToken(token) : setGlobal({ isLoggedIn: true })
    const isLogged = getGlobal().isLoggedIn
    return !!isLogged
  } else {
    return true
  }
}
