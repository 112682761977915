import React from 'react'
import { colors } from '../../../styles/themes/light'
import { SvgComponent } from './interfaces'
import { useLocation } from 'react-router'
import { Routes } from '../../../routes'

export const ContractsIcon: React.FC<SvgComponent> = (props) => {
  const { pathname } = useLocation()
  const isCurrentPage = pathname === Routes.contracts
  const fill = isCurrentPage ? colors.moduleIconHighlight : colors.moduleAssetSelectIcon

  return (
    <>
      <svg viewBox="0 0 24 24" {...props} fill={fill} xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.01 4C4.01 2.9 4.9 2 6 2H14L20 8V20C20 21.1 19.1 22 18 22H5.99C4.89 22 4 21.1 4 20L4.01 4ZM13 3.5V9H18.5L13 3.5Z"
          fill="fill"
          fill-opacity="1"
        />
      </svg>
    </>
  )
}

ContractsIcon.defaultProps = {
  height: 24,
  width: 24,
}
