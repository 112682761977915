import styled from 'styled-components'
import { colors } from '../../styles/themes/light'
import { baseTheme } from '../../styles/themes/baseTheme'
import { makeStyles } from '@material-ui/core/styles'

export const StyledFieldset = styled.fieldset`
  height: 48px;
  margin: 0;
  padding: 0 5px 0 12px;
  border-radius: 4px;
  border-color: ${colors.lightGray};
  border-width: 1px;
  display: flex;
`

export const DropdownContainer = styled.div`
  height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
`

export const StyledLegend = styled.legend`
  margin: 0px;
  padding: 0px;
  font-size: ${baseTheme.typography.subtitle2.fontSize};
  color: ${colors.mediumGray};
`

export const useStyles = makeStyles({
  root: {
    width: '100%',
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
  },
  menuList: {
    '& ul': {
      height: '105px',
      overflow: 'auto',
    },
  },
})
