import React from 'react'
import { FormControlLabel, IconButton, Radio } from '@material-ui/core'
import AddBoxIcon from '@material-ui/icons/AddBox'
import { ICustomCheckboxField } from './interfaces'
import { StyledFieldset, ContainerCheckbox, StyledLegend, StyledRadioGroup } from './style'

export const CustomCheckboxFieldView: React.FC<ICustomCheckboxField> = ({
  label,
  name,
  firstRadioLabel,
  secondRadioLabel,
  handleChange,
  onClick,
}) => {
  return (
    <StyledFieldset>
      <StyledLegend>{label}</StyledLegend>
      <ContainerCheckbox>
        <StyledRadioGroup row aria-label="position" name={name} onChange={handleChange}>
          <FormControlLabel value={firstRadioLabel} control={<Radio color="primary" />} label={firstRadioLabel} />
          <FormControlLabel value={secondRadioLabel} control={<Radio color="primary" />} label={secondRadioLabel} />
        </StyledRadioGroup>
        <IconButton aria-label={`add-${label}`} onClick={onClick} edge="end">
          <AddBoxIcon />
        </IconButton>
      </ContainerCheckbox>
    </StyledFieldset>
  )
}
