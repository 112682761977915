import { setGlobal } from '../../../global/useGlobal'
import { FeatureManager } from '../../FeatureManager'
import { mockCheckToken } from './mockEndpoint'
import { checkTokenRequest } from './request'
import { decode } from 'jsonwebtoken'
import { IUserInfo } from './interfaces'
import { saveAuthInfo, saveProfileInfo, saveUsernameInfo } from '../../../utils/localStorageManager'
import { openSnackBar } from '../../pageStateService'
import { strings } from '../../../resources/strings/strings'

export const checkToken = async (token: string) => {
  await setGlobal({ isPageLoading: true })

  try {
    const userInfo = decode(token) as IUserInfo
    let profileInfo

    if (!userInfo.userName) {
      return handleAuthError(strings.alerts.invalidToken)
    }

    const response = FeatureManager.shouldMockCheckToken
      ? await mockCheckToken(true)
      : await checkTokenRequest({
          token,
          userName: userInfo?.userName,
        })

    if (response.tokenCode) {
      FeatureManager.shouldMockRefreshedTokenResponse = false
      checkToken(response.tokenCode)
      return
    }

    if (response.messageCode !== 200) {
      return handleAuthError(strings.alerts.invalidToken)
    }

    saveUsernameInfo(userInfo.userName)

    profileInfo = userInfo?.profilesClaim?.split('|')
    saveProfileInfo(profileInfo || [])

    saveAuthInfo(true, token)

    return setGlobal({
      isPageLoading: false,
      isLoggedIn: true,
      shouldShowLoginError: false,
      username: userInfo.userName,
      accessToken: token,
    })
  } catch (e) {
    return handleAuthError(strings.alerts.invalidToken)
  }
}

const handleAuthError = (message: string, messageCode?: number) => {
  saveAuthInfo(false)

  setGlobal({
    isPageLoading: false,
    isLoggedIn: false,
  })

  return openSnackBar(message, true)
}
