import { LoginResponse } from './loginResponse'
import { FeatureManager } from '../../FeatureManager'

export const mockAuthenticate = async (email: string, password: string): Promise<LoginResponse> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (email === 'usuario@superemail.com' && password === 'senhasecreta') {
        if (FeatureManager.shouldLoginExpire) {
          resolve({
            created: '2020-05-18T22:43:30.2305397Z',
            expired: '2020-05-18T22:44:29.1970655Z',
            tokenCode:
              'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiMzcwOTQzODA4MDEiLCJleHAiOjE1ODk4NDE4NjksImlzcyI6IkJUR1NvbHV0aW9ucyIsImF1ZCI6Imh0dHA6Ly9sb2NhbGhvc3Q6NjM0OTkifQ.h7p2HfYxr555Tde2JEWW22sdZV4ExjOzsGuUdDiftN8',
            messageCode: 773,
            message: 'Login expired, and must be renewed.',
            profile: [
              'BTG',
              'Settings',
              'TimesAndSales',
              'MarketNews',
              'BookOrder',
              'Carrying',
              'OrdersEntry',
              'MarketWatch',
            ],
          })
        }

        resolve({
          created: '2020-05-18T22:43:30.2305397Z',
          expired: '2020-05-18T22:44:29.1970655Z',
          tokenCode:
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyTmFtZSI6InJhZmFlbC5jYXJtb0BidGdwYWN0dWFsLmNvbSIsInRva2VuQ3JlYXRlZCI6IjgvMjEvMjAyMCA0OjMxOjM4IFBNIiwibWFpbCI6InJhZmFlbC5jYXJtb0BidGdwYWN0dWFsLmNvbSIsInByb2ZpbGVzQ2xhaW0iOiJNYXJrZXRXYXRjaHxDYXJyeWluZ3xCVEd8T3JkZXJzRW50cnl8Qm9va09yZGVyfFRpbWVzQW5kU2FsZXN8TWFya2V0TmV3cyIsImV4cCI6MTU5ODAyNzU1OCwiaXNzIjoiQlRHU29sdXRpb25zIiwiYXVkIjoiZmU4MDo6YTkxZTo5NTZiOjY4YjQ6NGIzNyUxMyJ9.wm51rzAeCEpFYnpVW32KUBwzz-AgJ7SOYYKhCVnjJd8',
          messageCode: 200,
          btg_solutions_user_session: FeatureManager.shouldMockExtranetSync ? 'token' : undefined,
          extranet_user_id: FeatureManager.shouldMockExtranetSync ? '00000' : undefined,
          profile: [
            'BTG',
            'Settings',
            'TimesAndSales',
            'MarketNews',
            'BookOrder',
            'Carrying',
            'OrdersEntry',
            'MarketWatch',
          ],
        })
      } else {
        resolve({
          messageCode: 52,
          message: 'Invalid credentials.',
        })
      }
    }, 1000)
  })
}
