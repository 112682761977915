import React, { useEffect } from 'react'
import { WorkspaceAppbar } from '../../components/WorkspaceAppbar'
import { CustomModal } from '../../components/CustomModal'
import { ApproveInstructionsContainer } from './style'
import { WorkspaceWrapper } from '../../components/WorkspaceWrapper'
import { useGlobal } from '../../global/useGlobal'
import { useLegacyState } from '../../utils/hooks/useLegacyState'
import { CustomFiltersContent } from '../../components/CustomFiltersContent'
import { approvalInstructionsSchema } from '../../services/dataSchemas/approvalInstructions/schema'
import Table from '../../components/CustomTable'
import { useWindowSize } from '../../utils/hooks/useWindowSize'
import { getApprovalInstructions } from '../../services/approvalInstructions/get/getApprovalInstructions'
import {
  ApprovalInstructionsFilterInputs,
  ApprovalInstructionsFilterSchema,
} from '../../constants/filters/approvalInstructionsFilters'
import { usePromise } from '../../utils/hooks/usePromise'
import CreateApprovalInstructions from '../../components/CreateApprovalInstructions'
import { buildTableSchema } from '../../utils/schemaBuilder'
import UpdateApprovalInstructions from '../../components/UpdateApprovalInstructions'
import { getDefaultDates } from '../../services/defaultDates/getDefaultDates'

interface IApproveInstructions {}

const ApproveInstructionsView: React.FC<IApproveInstructions> = () => {
  const [isFilterModalOpen, setIsFilterModalOpen] = useGlobal('approvalInstructionsFilterModalOpen')
  const [isCreateApprovalInstructionsModalOpen, setIsCreateApprovalInstructionsModalOpen] = useGlobal(
    'isCreateApprovalInstructionsModalOpen'
  )
  const [isUpdateApprovalInstructionsModalOpen, setIsUpdateApprovalInstructionsModalOpen] = useGlobal(
    'isUpdateApprovalInstructionsModalOpen'
  )
  const { windowHeight } = useWindowSize()

  const [filters] = useGlobal('approvalInstructionsAppliedFilters')
  const [sorting, setSorting] = useGlobal('approvalInstructionsAppliedSorting')
  const [selectedFilters, setSelectedFilters] = useGlobal('approvalInstructionsSelectedFilters')
  const [currentApprovalInstructionsPage, setApprovalInstructionsPage] = useGlobal('currentApprovalInstructionsPage')
  const [approvalInstructionsPaginationArgs] = useGlobal('approvalInstructionsPaginationArgs')
  const [approvalInstructionsColumns] = useGlobal('approvalInstructionsColumns')
  const [isLoading] = useGlobal('isDataLoading')
  const [tableData] = useGlobal('approvalInstructionsData')

  const closeFilterModal = () => setIsFilterModalOpen(false)
  const closeCreateAIModal = () => setIsCreateApprovalInstructionsModalOpen(false)
  const closeUpdateAIModal = () => setIsUpdateApprovalInstructionsModalOpen(false)
  const [values, setValues] = useLegacyState<ApprovalInstructionsFilterInputs>({
    ID: '',
    RequestType: '',
    AccountCode: '',
    InstrumentSymbol: '',
    InstructionStatuses: '',
    OrderSide: '',
    MinReferenceDate: '',
    MaxReferenceDate: '',
    MinCreationDate: '',
    MaxCreationDate: '',
  })

  useEffect(() => {
    getApprovalInstructions()
  }, [filters, sorting])

  const getDates = async () => {
    await getDefaultDates()
  }

  useEffect(() => {
    getDates()
  }, [])

  const loadMore = async () => {
    const nextApprovalInstructionsPage = currentApprovalInstructionsPage + 1
    await getApprovalInstructions(nextApprovalInstructionsPage)
    await setApprovalInstructionsPage(nextApprovalInstructionsPage)
  }

  const { loading: isLoadingMore, request: doLoadMore } = usePromise(loadMore, [currentApprovalInstructionsPage], {})

  const loadAll = async () => {
    await getApprovalInstructions(undefined, true)
  }

  const { loading: isLoadingAll, request: doLoadAll } = usePromise(loadAll, [], {})

  const tableHeight = windowHeight - 160 // 160 is the table header height

  const totalCount = approvalInstructionsPaginationArgs?.totalCount
  const currentCount = tableData.length

  const hasPagination = totalCount > currentCount

  const paginationOptions = {
    doLoadMore,
    doLoadAll,
    isLoadingMore,
    isLoadingAll,
    totalCount,
  }

  return (
    <ApproveInstructionsContainer>
      <WorkspaceAppbar />
      <WorkspaceWrapper>
        <Table
          tableRefGlobalKey={'approvalInstructionsTableRef'}
          tableColumnsGlobalKey={'approvalInstructionsColumns'}
          columnWidthsGlobalKey={'approvalInstructionsColumnWidths'}
          tableDataSchema={approvalInstructionsSchema as any}
          dataGlobalKey={'approvalInstructionsData'}
          containerHeight={tableHeight}
          tableHeight={tableHeight}
          hasCheckboxColumn={true}
          hasContextMenu={true}
          hasPagination={hasPagination}
          paginationOptions={paginationOptions}
          sorting={sorting}
          setSorting={setSorting}
          hasFooter={true}
          isLoading={isLoading}
          persistColumnOrder
        />
        <CustomModal closeModal={closeFilterModal} isOpen={isFilterModalOpen} filters={true}>
          <CustomFiltersContent
            filtersSchema={ApprovalInstructionsFilterSchema}
            values={values}
            setValues={setValues}
            state={selectedFilters}
            setState={setSelectedFilters}
            handleClose={closeFilterModal}
          />
        </CustomModal>
        <CustomModal closeModal={closeCreateAIModal} isOpen={isCreateApprovalInstructionsModalOpen}>
          <CreateApprovalInstructions handleClose={closeCreateAIModal} />
        </CustomModal>
        <CustomModal closeModal={closeUpdateAIModal} isOpen={isUpdateApprovalInstructionsModalOpen}>
          <UpdateApprovalInstructions handleClose={closeUpdateAIModal} />
        </CustomModal>
      </WorkspaceWrapper>
    </ApproveInstructionsContainer>
  )
}

export default ApproveInstructionsView
