import React from 'react'
import { ISearchBarView } from './interfaces'
import { StyledSelect } from './style'
import { strings } from '../../resources/strings/strings'

export const SearchBarView: React.FC<ISearchBarView> = ({
  options,
  CustomOption,
  customFilter,
  getNoOptionsMessage,
  onChange,
  onKeyDown,
}) => {
  return (
    <StyledSelect
      options={options}
      placeholder={strings.homeScreen.searchBar.label}
      components={{ Option: CustomOption, DropdownIndicator: null, clearIndicator: null, IndicatorSeparator: null }}
      filterOption={customFilter}
      noOptionsMessage={getNoOptionsMessage}
      controlShouldRenderValue={false}
      onChange={onChange}
      onKeyDown={onKeyDown}
    />
  )
}
