import { ContractsFieldsDTO } from './interfaces/contractsFieldsDTO'

export const mockContractsRequest = async (): Promise<ContractsFieldsDTO> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const assets = {
        paginationSummary: {
          count: 300,
          totalCount: 1375,
          pageNum: 1,
          pageSize: 300,
          sorting: null,
        },
        contractLegs: [
          {
            code: '201902280022729181-1',
            instrumentSymbol: 'RADL3',
            quantity: 0,
            originalQuantity: 100,
            effectiveQuantity: 0,
            projectedQuantity: 0,
            settledQuantity: 0,
            coveredQuantity: 0,
            uncoveredQuantity: 0,
            renewalQuantity: 0,
            requestedRenewalQuantity: 0,
            requestedSettledQuantity: 0,
            effectiveVolume: null,
            totalVolume: null,
            basePrice: 66.51,
            brokerageFee: null,
            side: 'Borrower',
            paymentType: 'Netting',
            marketModality: 'OtcSecuritiesLending',
            securityLendingContractType: 'RegularLenderOffer',
            masterAccountCode: '81048',
            masterOrSelfAliasOrName: 'NOME DE CLIENTE',
            issuerAccountCode: '196826',
            issuerAliasOrName: 'NOME DE CLIENTE',
            issuerExecutionBrokerCode: '129',
            issuerCarryingBrokerCode: '1026',
            issuerCustodianCode: '1026',
            issuerCustodyAccountCode: '196826',
            issuerPortfolio: '21016',
            negotiationFee: 3.9,
            lenderReversible: false,
            tenderOfferLenderReversible: true,
            internalStatus: 'Finalized',
            tradeDate: '2019-02-28T00:00:00Z',
            startDate: '2019-02-28T00:00:00Z',
            lockUpDate: '2019-03-01T00:00:00Z',
            expireDate: '2020-10-23T00:00:00Z',
            creationDate: '2019-02-28T00:00:00Z',
            lastExchangeUpdate: '2019-12-04T21:45:59.05Z',
          },
          {
            code: '201902280022729182-1',
            instrumentSymbol: 'RADL3',
            quantity: 0,
            originalQuantity: 228,
            effectiveQuantity: 0,
            projectedQuantity: 0,
            settledQuantity: 0,
            coveredQuantity: 0,
            uncoveredQuantity: 0,
            renewalQuantity: 0,
            requestedRenewalQuantity: 0,
            requestedSettledQuantity: 0,
            effectiveVolume: null,
            totalVolume: null,
            basePrice: 66.51,
            brokerageFee: null,
            side: 'Borrower',
            paymentType: 'Netting',
            marketModality: 'OtcSecuritiesLending',
            securityLendingContractType: 'RegularLenderOffer',
            masterAccountCode: '81048',
            masterOrSelfAliasOrName: 'NOME DE CLIENTE',
            issuerAccountCode: '196826',
            issuerAliasOrName: 'NOME DE CLIENTE',
            issuerExecutionBrokerCode: '129',
            issuerCarryingBrokerCode: '1026',
            issuerCustodianCode: '1026',
            issuerCustodyAccountCode: '196826',
            issuerPortfolio: '21016',
            negotiationFee: 4.3,
            lenderReversible: false,
            tenderOfferLenderReversible: true,
            internalStatus: 'Finalized',
            tradeDate: '2019-02-28T00:00:00Z',
            startDate: '2019-02-28T00:00:00Z',
            lockUpDate: '2019-03-01T00:00:00Z',
            expireDate: '2020-10-23T00:00:00Z',
            creationDate: '2019-02-28T00:00:00Z',
            lastExchangeUpdate: '2019-12-04T21:45:59.05Z',
          },
          {
            code: '201903010022736535-1',
            instrumentSymbol: 'TIET4',
            quantity: 100,
            originalQuantity: 100,
            effectiveQuantity: 100,
            projectedQuantity: 100,
            settledQuantity: 0,
            coveredQuantity: 0,
            uncoveredQuantity: 100,
            renewalQuantity: 0,
            requestedRenewalQuantity: 0,
            requestedSettledQuantity: 0,
            effectiveVolume: null,
            totalVolume: null,
            basePrice: 2.3,
            brokerageFee: null,
            side: 'Borrower',
            paymentType: 'Netting',
            marketModality: 'OtcSecuritiesLending',
            securityLendingContractType: 'RegularLenderOffer',
            masterAccountCode: '122356',
            masterOrSelfAliasOrName: 'NOME DE CLIENTE',
            issuerAccountCode: '359658',
            issuerAliasOrName: 'NOME DE CLIENTE',
            issuerExecutionBrokerCode: '120',
            issuerCarryingBrokerCode: '1026',
            issuerCustodianCode: '1026',
            issuerCustodyAccountCode: '359658',
            issuerPortfolio: '21016',
            negotiationFee: 4.25,
            lenderReversible: true,
            tenderOfferLenderReversible: true,
            internalStatus: 'Finalized',
            tradeDate: '2019-03-01T00:00:00Z',
            startDate: '2019-03-01T00:00:00Z',
            lockUpDate: '2019-03-06T00:00:00Z',
            expireDate: '2020-11-06T00:00:00Z',
            creationDate: '2019-03-01T00:00:00Z',
            lastExchangeUpdate: '2019-12-04T21:45:59.05Z',
          },
          {
            code: '201909230022183089-2',
            instrumentSymbol: 'RENT3',
            quantity: 1228673,
            originalQuantity: 1750165,
            effectiveQuantity: 1228673,
            projectedQuantity: 1228673,
            settledQuantity: 580000,
            coveredQuantity: 0,
            uncoveredQuantity: 1228665,
            renewalQuantity: 0,
            requestedRenewalQuantity: 0,
            requestedSettledQuantity: 8,
            effectiveVolume: null,
            totalVolume: null,
            basePrice: 45.71,
            brokerageFee: null,
            side: 'Lender',
            paymentType: 'Netting',
            marketModality: 'OtcSecuritiesLending',
            securityLendingContractType: 'Renewal',
            masterAccountCode: '75342',
            masterOrSelfAliasOrName: 'NOME DE CLIENTE',
            issuerAccountCode: '75342',
            issuerAliasOrName: 'NOME DE CLIENTE',
            issuerExecutionBrokerCode: '85',
            issuerCarryingBrokerCode: '1026',
            issuerCustodianCode: '1026',
            issuerCustodyAccountCode: '75342',
            issuerPortfolio: '21016',
            negotiationFee: 0.2,
            lenderReversible: false,
            tenderOfferLenderReversible: true,
            internalStatus: 'Finalized',
            tradeDate: '2019-09-23T00:00:00Z',
            startDate: '2019-09-23T00:00:00Z',
            lockUpDate: '2019-09-24T00:00:00Z',
            expireDate: '2020-10-30T00:00:00Z',
            creationDate: '2019-09-23T00:00:00Z',
            lastExchangeUpdate: '2019-12-30T20:54:33Z',
          },
          {
            code: '201909230022183089-2',
            instrumentSymbol: 'RENT3',
            quantity: 1228673,
            originalQuantity: 1750165,
            effectiveQuantity: 1228673,
            projectedQuantity: 1228673,
            settledQuantity: 580000,
            coveredQuantity: 0,
            uncoveredQuantity: 1228665,
            renewalQuantity: 0,
            requestedRenewalQuantity: 0,
            requestedSettledQuantity: 8,
            effectiveVolume: null,
            totalVolume: null,
            basePrice: 45.71,
            brokerageFee: null,
            side: 'Lender',
            paymentType: 'Netting',
            marketModality: 'OtcSecuritiesLending',
            securityLendingContractType: 'Renewal',
            masterAccountCode: '75342',
            masterOrSelfAliasOrName: 'NOME DE CLIENTE',
            issuerAccountCode: '75342',
            issuerAliasOrName: 'NOME DE CLIENTE',
            issuerExecutionBrokerCode: '85',
            issuerCarryingBrokerCode: '1026',
            issuerCustodianCode: '1026',
            issuerCustodyAccountCode: '75342',
            issuerPortfolio: '21016',
            negotiationFee: 0.2,
            lenderReversible: false,
            tenderOfferLenderReversible: true,
            internalStatus: 'Finalized',
            tradeDate: '2019-09-23T00:00:00Z',
            startDate: '2019-09-23T00:00:00Z',
            lockUpDate: '2019-09-24T00:00:00Z',
            expireDate: '2020-10-30T00:00:00Z',
            creationDate: '2019-09-23T00:00:00Z',
            lastExchangeUpdate: '2019-12-30T20:54:33Z',
          },
        ],
      }
      resolve(assets)
    }, 1000)
  })
}
