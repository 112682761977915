import { DataSchema } from '../interfaces'

export const responseApprovalInstructionsSchema: { [key: string]: DataSchema } = {
  externalId: {
    title: 'External ID',
    type: 'text',
    tooltipName: 'External ID',
  },
  requestType: {
    title: 'Request Type',
    type: 'text',
    tooltipName: 'Request Type',
  },
  instrumentSymbol: {
    title: 'Symbol',
    type: 'text',
    tooltipName: 'Symbol',
  },
  quantity: {
    title: 'Qty',
    type: 'text',
    tooltipName: 'Qty',
  },
  side: {
    title: 'Side',
    type: 'text',
    tooltipName: 'Side',
  },
  issuerAccountCode: {
    title: 'Account',
    type: 'text',
    tooltipName: 'Account',
  },
  negotiationFee: {
    title: 'Negotiation Fee',
    type: 'text',
    tooltipName: 'Negotiation Fee',
  },
  commissionFee: {
    title: 'Commission Fee',
    type: 'text',
    tooltipName: 'Commission Fee',
  },
  interBrokerFee: {
    title: 'Inter Broker Fee',
    type: 'text',
    tooltipName: 'Inter Broker Fee',
  },
  lenderReversible: {
    title: 'Callable?',
    type: 'text',
    tooltipName: 'Lender Reversible?',
  },
  tenderOfferLenderReversible: {
    title: 'TOLR?',
    type: 'text',
    tooltipName: 'Tender Offer Lender Reversible?',
  },
  lockUpDate: {
    title: 'Lock Up Date',
    type: 'text',
    tooltipName: 'Lock Up Date',
  },
  expireDate: {
    title: 'Expire Date',
    type: 'text',
    tooltipName: 'Expire Date',
  },
  issuerCustodianCode: {
    title: 'Issuer Custodian Code',
    type: 'text',
    tooltipName: 'Issuer Custodian Code',
  },
  issuerCustodyAccountCode: {
    title: 'Issuer Custody Account Code',
    type: 'text',
    tooltipName: 'Issuer Custody Account Code',
  },
  issuerPortfolio: {
    title: 'Issuer Portfolio',
    type: 'text',
    tooltipName: 'Issuer Portfolio',
  },
  referenceDate: {
    title: 'Reference Date',
    type: 'text',
    tooltipName: 'Reference Date',
  },
  targetBrokerCode: {
    title: 'Target Broker Code',
    type: 'text',
    tooltipName: 'Target Broker Code',
  },
  internalStatus: {
    title: 'Status',
    type: 'text',
    tooltipName: 'Status',
  },
  deskId: {
    title: 'Desk ID',
    type: 'text',
    tooltipName: 'Desk ID',
  },
  memo: {
    title: 'Memo',
    type: 'text',
    tooltipName: 'Memo',
  },
  feedback: {
    title: 'Feedback',
    type: 'text',
    tooltipName: 'Feedback',
  },
}
