import {} from './interfaces/virtualSettlementsDTO'

export const mockVirtualSettleRequest = async () => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const assets = {
        countSuccess: 1,
        countFailed: 2,
        success: [
          {
            contractLegCode: '201911290023949673-1',
            quantity: 2,
            side: 'Borrower',
            internalStatus: 'InsufficientBalance',
            errorRuleIds: 'req-fb.unknown-consolidated-position',
            contractLeg: {
              code: '201911290023949673-1',
              instrumentSymbol: 'SANB11',
              quantity: 150,
              originalQuantity: 150,
              effectiveQuantity: 150,
              projectedQuantity: 147,
              settledQuantity: 0,
              coveredQuantity: 0,
              uncoveredQuantity: 150,
              renewalQuantity: 0,
              requestedRenewalQuantity: 0,
              requestedSettledQuantity: 150,
              totalVolume: null,
              basePrice: 44.23,
              brokerageFee: null,
              side: 'Borrower',
              paymentType: 'Netting',
              marketModality: 'OtcSecuritiesLending',
              securityLendingContractType: 'MandatoryLoan',
              masterAccountCode: '186235',
              masterOrSelfAliasOrName: 'RPS CAPITAL ADMINISTRADORA DE RECURSOS LTDA',
              issuerAccountCode: '2056639',
              issuerAliasOrName: 'RPS PREV ABSOLUTO FIM PREVIDENCIA MULTIMERCADO MASTER',
              issuerExecutionBrokerCode: '1026',
              issuerCarryingBrokerCode: '1026',
              issuerCustodianCode: '2028',
              issuerCustodyAccountCode: '8108',
              issuerPortfolio: '21016',
              negotiationFee: 2,
              lenderReversible: true,
              tenderOfferLenderReversible: true,
              internalStatus: 'AwaitingSettlement',
              tradeDate: '2019-11-29T00:00:00Z',
              startDate: '2019-11-29T00:00:00Z',
              lockUpDate: '2019-12-02T00:00:00Z',
              expireDate: '2021-01-04T00:00:00Z',
              creationDate: '2019-11-29T14:47:51.563Z',
              lastExchangeUpdate: '2020-01-22T20:28:56Z',
            },
          },
        ],
        failed: [
          {
            issuerAccountCode: 915102,
            side: 'Lender',
            instrumentSymbol: 'PETR4',
            quantity: 100,
            rejectMessage: 'no-contract-found',
          },
          {
            issuerAccountCode: 915102,
            side: 'Lender',
            instrumentSymbol: 'VALE3',
            quantity: 0,
            rejectMessage: 'no-contract-found',
          },
        ],
      }
      resolve(assets)
    }, 1000)
  })
}
