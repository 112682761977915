import React from 'react'
import { isContractCode, isOnlyNumber, isSymbol } from '../constants/regex/regex'
import { AppliedFiltersStateType, SelectedFiltersStateType } from '../global/components/filtersState'
import { getGlobal, setGlobal } from '../global/useGlobal'
import { Filter, FilterPatterns, FilterSchema, FilterType } from '../constants/filters/filterTypes'
import { ACCOUNT_CODE_MAX_LENGTH, ACCOUNT_CODE_MIN_LENGTH, CONTRACT_CODE_LENGTH } from '../constants/dimensions'

interface onPasteFiltersInput {
  appliedFiltersGlobalKey: keyof AppliedFiltersStateType
  selectedFiltersGlobalKey: keyof SelectedFiltersStateType
  schema: FilterSchema[]
  shouldUseSelected?: boolean
  specificFilterPattern?: FilterPatterns
}

export const onPasteFilters = (input: onPasteFiltersInput) => (e: React.ClipboardEvent<HTMLDivElement>) => {
  const { appliedFiltersGlobalKey, schema, selectedFiltersGlobalKey, shouldUseSelected, specificFilterPattern } = input
  const filtersData = getGlobal()[shouldUseSelected ? selectedFiltersGlobalKey : appliedFiltersGlobalKey]
  const { clipboardData } = e
  const content = clipboardData?.getData('text')

  const applyFilter = (input: string, pattern: FilterPatterns) => {
    const targetSchema = schema.find((value) => value.filterPattern === pattern)
    if (!targetSchema || targetSchema.filterType === FilterType.unique) return
    if (specificFilterPattern) {
      if (targetSchema.filterPattern !== specificFilterPattern) return
    }
    const { name, label, filterType } = targetSchema
    const filter: Filter = {
      name,
      value: input,
      isActive: true,
      label,
      type: filterType,
    }
    filtersData.push(filter)
    shouldUseSelected
      ? setGlobal({ [selectedFiltersGlobalKey]: [...filtersData] })
      : setGlobal({ [selectedFiltersGlobalKey]: [...filtersData], [appliedFiltersGlobalKey]: [...filtersData] })
    e.preventDefault()
  }

  if (content) {
    content.split(/\s+/g).forEach((input) => {
      if (input) {
        if (isSymbol.test(input)) {
          applyFilter(input, FilterPatterns.Symbol)
        } else if (isContractCode.test(input) && input.length === CONTRACT_CODE_LENGTH) {
          applyFilter(input, FilterPatterns.ContractCode)
        } else if (
          isOnlyNumber.test(input) &&
          input.length >= ACCOUNT_CODE_MIN_LENGTH &&
          input.length <= ACCOUNT_CODE_MAX_LENGTH
        ) {
          applyFilter(input, FilterPatterns.AccountCode)
        }
      }
    })
  }
}
