import { strings } from '../resources/strings/strings'
import { openSnackBar } from '../services/pageStateService'

interface ErrorType {
  type: string
  title: string
  status: number
  detail: string
  instance: string
}

export const displayError = (error: ErrorType) => {
  const isUnauthorized = error?.status === 401
  isUnauthorized ? openSnackBar(error.title, false, true) : openSnackBar(strings.alerts.genericError, true)
}
