import React, { RefObject } from 'react'
import { IContextMenuState } from '../components/CustomContextMenu/interfaces'

export const handleTableHeaderContextMenu = (
  setContextMenuState: (state: Partial<IContextMenuState>) => void,
  checkboxPositionRef?: RefObject<any>
) => (tableEl: any, columnIndexString: string | null, rowIndexString: string | null, mouseEvent: React.MouseEvent) => {
  const columnIndex = Number(columnIndexString)
  const rowIndex = Number(rowIndexString)
  if (checkboxPositionRef && columnIndex === checkboxPositionRef.current) {
    return false
  } else {
    return setContextMenuState({
      top: mouseEvent.pageY - 132,
      left: mouseEvent.pageX - 64,
      //TODO: get those values dynamically
      visible: true,
      column: columnIndex,
      row: rowIndex,
    })
  }
}
