import React from 'react'
import { InputAdornment, IconButton } from '@material-ui/core'
import AddBoxIcon from '@material-ui/icons/AddBox'
import { ICustomTextField, MaskState } from './interfaces'
import { StyledTextField } from './style'
import InputMask from 'react-input-mask'
import { formatMaskChars } from '../../constants/masks/masks'
import { TypeDate } from '../../constants/filters/filterTypes'

export const CustomTextFieldView: React.FC<ICustomTextField> = ({
  name,
  label,
  value,
  handleChange,
  onClick,
  mask = '',
  placeholder,
  ...props
}) => {
  const onKeyPress = (ev: React.KeyboardEvent<HTMLDivElement>) => {
    const isKeyPressedEnter = ev.key === 'Enter'
    if (isKeyPressedEnter) {
      onClick()
      ev.preventDefault()
    }
  }

  const beforeMaskedValueChange = (nextState: MaskState, oldState: MaskState, userInput: string) => {
    let { value, selection } = nextState
    const isDateInput = name in TypeDate

    if (isDateInput) {
      const hasLetterInFirstDigit = /^[A-Z]$/.test(value?.[0])
      value = hasLetterInFirstDigit ? value.slice(0, 4) : value
    }

    return {
      value,
      selection,
    }
  }

  const input = () => (
    <StyledTextField
      fullWidth
      name={name}
      type="text"
      size="small"
      value={value}
      onChange={handleChange}
      label={label}
      variant="outlined"
      placeholder={placeholder}
      onKeyPress={onKeyPress}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton name={name} aria-label={`add-${name}`} onClick={onClick} edge="end">
              <AddBoxIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  )

  return (
    <InputMask
      mask={mask}
      value={value}
      disabled={false}
      maskChar={''}
      beforeMaskedValueChange={beforeMaskedValueChange}
      formatChars={formatMaskChars}
      onChange={handleChange}
    >
      {input}
    </InputMask>
  )
}
