import { useEffect, useState } from 'react'
import { setGlobal } from '../global/useGlobal'
import qs from 'qs'

const authTokenCookieKey = '?btg_solutions_user_session'

export const useParams = (useLocation: any) => {
  const getTokenFromUseLocation = () => {
    const { search } = useLocation
    const parseSearch = qs.parse(search)
    return parseSearch && (parseSearch[authTokenCookieKey] as string)
  }

  const accessToken = getTokenFromUseLocation()

  useEffect(() => {
    if (accessToken) {
      setGlobal({ accessToken: accessToken })
    }
  }, [accessToken])

  return accessToken && accessToken
}
