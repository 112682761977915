import React from 'react'
import { StyledFiltersArea } from './style'
import { IFiltersArea } from './interfaces'

export const FiltersAreaView: React.FC<IFiltersArea> = ({ item, hasFilter, children }) => {
  return (
    <StyledFiltersArea item={item} hasFilter={hasFilter}>
      {children}
    </StyledFiltersArea>
  )
}
