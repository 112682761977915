import { CellValue } from '../../components/jexcel-interfaces'
import XLSX from 'xlsx'
import { openSnackBar } from '../../services/pageStateService'
import { strings } from '../../resources/strings/strings'

export const downloadWorksheet = (ref: any, fileName: string, isCsv?: boolean) => {
  const tableData = ref.current.jexcel.getData!()
  if (tableData.length) {
    const tableHeaders = ref.current.jexcel.getHeaders!().split(',')
    // getHeaders() returns a string with all table headers separated by commas

    tableData.unshift(tableHeaders)
    const checkboxColumnIndex = tableHeaders.indexOf('')

    if (checkboxColumnIndex >= 0) {
      tableData.forEach((row: CellValue[]) => {
        row.splice(checkboxColumnIndex, 1)
        // Removing checkbox column from all rows
      })
    }
    const worksheet = XLSX.utils.aoa_to_sheet(tableData)
    const new_workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(new_workbook, worksheet)

    if (isCsv) {
      const csvContent = XLSX.utils.sheet_to_csv(worksheet)
      const encodedUri = encodeURI(csvContent)
      const link = document.createElement('a')

      link.setAttribute('href', `data:text/plain;charset=utf-8, ${encodedUri}`)
      link.setAttribute('download', `${fileName}.csv`)

      document.body.appendChild(link) // Required for firefox
      link.click()
      document.body.removeChild(link)

      return
    }

    XLSX.writeFile(new_workbook, `${fileName}.xlsx`)
  } else {
    openSnackBar(strings.alerts.unableToDownloadTable, true)
  }
}
