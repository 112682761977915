import React, { useEffect } from 'react'
import { TextFieldProps } from '@material-ui/core'
import { ChangeEvent } from 'react'
import { useFormInput } from 'react-simple-hooks-forms'
import { ITextFieldFormInput } from './interfaces'
import { useGlobal } from '../../global/useGlobal'
import { useLegacyState } from '../../utils/hooks/useLegacyState'
import Adornment from './style'
import { TextFieldView } from './view'

export const CustomTextFieldFormInput: React.FC<TextFieldProps & ITextFieldFormInput> = ({
  startAdornment,
  endAdornment,
  helperText,
  name,
  error,
  ...props
}) => {
  const { value, setValue } = useFormInput({ name })
  const onChangeText = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValue(event.target.value)
  }

  const [theme] = useGlobal('theme')
  const [errorState, setErrorState] = useLegacyState({
    errorMessage: '' as React.ReactNode,
    adornmentColor: '',
  })
  const { errorMessage, adornmentColor } = errorState

  useEffect(() => {
    if (error) {
      setErrorState({
        errorMessage: helperText,
        adornmentColor: theme.palette.error.main,
      })
    } else {
      setErrorState({
        errorMessage: '',
        adornmentColor: '',
      })
    }
  }, [error, theme])

  const startAdornmentComponent = startAdornment ? (
    <Adornment position="start" color={adornmentColor}>
      {startAdornment}
    </Adornment>
  ) : undefined

  const endAdornmentComponent = endAdornment ? (
    <Adornment position="end" color={adornmentColor}>
      {endAdornment}
    </Adornment>
  ) : undefined

  return (
    <TextFieldView
      name={name}
      helperText={errorMessage}
      error={error}
      adornmentColor={adornmentColor}
      startAdornment={startAdornmentComponent}
      endAdornment={endAdornmentComponent}
      value={value}
      onChange={onChangeText}
      {...props}
    />
  )
}
