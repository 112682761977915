import React, { useEffect, useMemo, useRef, useState } from 'react'
import '../jexcel-styles.css'
import { CellValue, Options } from '../jexcel-interfaces'
import { ModalHeader } from '../ModalHeader'
import { ButtonsContainer } from './style'
import { ISettleModal } from './interfaces'
import { strings } from '../../resources/strings/strings'
import { Button } from '../Button'
import SaveIcon from '@material-ui/icons/Save'
import { settleSchema } from './schema'
import { useGlobal } from '../../global/useGlobal'
import { useWindowSize } from '../../utils/hooks/useWindowSize'
import {
  MODAL_RELATIVE_HEIGHT,
  MODAL_RELATIVE_WIDTH,
  TABLE_TO_MODAL_HEIGHT_PROPORTION,
  TABLE_TO_MODAL_WIDTH_PROPORTION,
} from '../../constants/dimensions'
import { createPostSettlementRequests } from '../../services/settlementRequests/post/createPostSettlementRequests'
import { CreateSettlementTableData } from '../../services/settlementRequests/post/interfaces/createSettlementRequestFields'
import { openSnackBar } from '../../services/pageStateService'
import { settleDefaultColumnOrder } from '../../services/dataSchemas/settle/settleDefaultColumnOrder'
import SettleColumnIndexes from '../../constants/settle/SettleColumnIndexes'
import { updateSettleTableWithResponse } from '../../utils/updateSettleTableWithResponse'
import { usePromise } from '../../utils/hooks/usePromise'
import { getContracts } from '../../services/contracts/getContracts'
import Table from '../CustomTable'

const SettleContent: React.FC<ISettleModal> = ({ handleClose }) => {
  const [tableRef] = useGlobal('settleTableRef')
  const [contractsData] = useGlobal('contractsTableData')
  const [contractsColumns] = useGlobal('contractsColumns')
  const { windowWidth, windowHeight } = useWindowSize()
  const [isResponse, setIsResponse] = useState(false)

  const settleTableRef = useRef<({ jexcel: Options } & HTMLDivElement) | null>(null)

  useEffect(() => {
    if (tableRef) {
      settleTableRef.current = tableRef.current
    }
  }, [tableRef])

  const { request: doSettle, loading: isLoadingSettle } = usePromise(createPostSettlementRequests, [], {
    onSuccess: (data) => {
      if (settleTableRef) {
        updateSettleTableWithResponse(data, settleTableRef)
        setIsResponse(true)
        getContracts()
      }
    },
    onError: (err) => openSnackBar(err.message, true),
  })

  const codeIndexOnContracts = contractsColumns.indexOf('code')

  const fetchContractDataToWriteSettle = (fetchContent: CellValue, writeIndex: number) => {
    contractsData.forEach((contract) => {
      if (fetchContent === contract[codeIndexOnContracts]) {
        // If this condition is met, it means that user's input is a valid contract
        const rowSettleData = settleDefaultColumnOrder.map((settleColumn) => {
          // Here we're building a settle row with only the information we need from contracts data
          return contract[contractsColumns.indexOf(settleColumn)]
        })
        rowSettleData.forEach((cellContent, index) => {
          settleTableRef?.current?.jexcel.setValueFromCoords!(index, writeIndex, cellContent, true)
          // It would be easier to use setRowData but we're updating readOnly columns so we need to use this method
        })
      }
    })
  }

  const onEdit = (a: any, b: any, column: number, row: number, content: CellValue) => {
    if (column === SettleColumnIndexes.ContractCode) {
      fetchContractDataToWriteSettle(content, row)
    }
  }

  const onBeforePaste = (a: any, content: string, columnIndexString: string, rowIndexString: string) => {
    const columnIndex = Number(columnIndexString)
    const rowIndex = Number(rowIndexString)
    // For some reason the onbeforepaste method returns indexes as strings (thanks a lot JS)

    const tableRowCount = settleTableRef?.current?.jexcel.getColumnData!().length
    const pasteRowCount = content.split('\n').length

    const rowsLeftToPaste = tableRowCount - rowIndex
    const minimumRowsToAdd = pasteRowCount - rowsLeftToPaste

    if (rowsLeftToPaste <= pasteRowCount) {
      settleTableRef?.current?.jexcel.insertRow!(minimumRowsToAdd + 1)
      // We're adding minimum rows + 1 because by doing this we ensure that the user will always have an empty
      // row to write (even though they're able to add a row just by pressing enter :])
    }
    if (columnIndex === SettleColumnIndexes.ContractCode) {
      content.split('\n').map((row: string, index: number) => {
        const pastedContractCodeValue = row.split('\t')[SettleColumnIndexes.ContractCode]
        const currentRow = rowIndex + index
        fetchContractDataToWriteSettle(pastedContractCodeValue, currentRow)
      })
    }
  }

  const tableDimensions: [number, number] = [0, 10]

  const onSaveSettle = () => {
    const settleData = settleTableRef?.current?.jexcel.getData!()
    const hasDataForRequest: boolean = settleData.some((row: string[]) => {
      return !!(row[SettleColumnIndexes.ContractCode] && row[SettleColumnIndexes.Qty])
    })
    if (hasDataForRequest) {
      let requestInput: CreateSettlementTableData[] = []
      settleData.forEach((contractRow: [string, any, any, string, 'Lender' | 'Borrower']) => {
        const hasContractInfo =
          contractRow[SettleColumnIndexes.ContractCode] &&
          contractRow[SettleColumnIndexes.Qty] &&
          contractRow[SettleColumnIndexes.Side]
        if (hasContractInfo) {
          const input: CreateSettlementTableData = {
            contractLegCode: contractRow[SettleColumnIndexes.ContractCode],
            quantity: contractRow[SettleColumnIndexes.Qty],
            side: contractRow[SettleColumnIndexes.Side],
          }
          requestInput.push(input)
        }
      })
      doSettle(requestInput)
    } else {
      openSnackBar(strings.alerts.invalidSettle, true)
    }
  }

  const height = useMemo(() => TABLE_TO_MODAL_HEIGHT_PROPORTION * MODAL_RELATIVE_HEIGHT * windowHeight, [windowHeight])

  const width = useMemo(() => TABLE_TO_MODAL_WIDTH_PROPORTION * MODAL_RELATIVE_WIDTH * windowWidth, [windowWidth])

  const tableStyle = {
    height,
    maxHeight: height,
    width,
    overflow: 'auto',
    position: 'relative',
    display: 'flex',
    margin: '32px auto auto',
  } as React.CSSProperties

  return (
    <React.Fragment>
      <ModalHeader title={strings.settleTable.title} handleClose={handleClose} />
      {/*styled-components is not working here because it changes the div class everytime the height changes :(*/}
      <Table
        tableRefGlobalKey={'settleTableRef'}
        tableColumnsGlobalKey={'settleColumns'}
        columnWidthsGlobalKey={'settleColumnWidths'}
        tableDataSchema={settleSchema}
        dataGlobalKey={'settleData'}
        tableHeight={height}
        containerHeight={height}
        hasFooter={false}
        tableStyle={tableStyle}
        hasTransparentBackground
        minDimensions={tableDimensions}
        hasInsertRow
        hasDeleteRow
        onEditionEnd={onEdit}
        onBeforePaste={onBeforePaste}
        isEditable
        shouldBlockColumnDrag
      />
      <ButtonsContainer>
        {!isResponse && <Button color="primary" type="outlined" text={strings.general.cancel} onClick={handleClose} />}
        <Button
          color="primary"
          type="contained"
          text={!isResponse ? strings.general.save : strings.general.close}
          onClick={!isResponse ? onSaveSettle : handleClose}
          startIcon={!isResponse && <SaveIcon />}
          loading={isLoadingSettle}
        />
      </ButtonsContainer>
    </React.Fragment>
  )
}

export default SettleContent
