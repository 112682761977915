import { Accounts } from '../../services/accounts/interfaces/accountsFieldsDTO'
import { TreatedDefaultDatesType } from '../../services/defaultDates/interfaces/TreatedDefaultDatesType'
import { Instrument } from '../../services/instruments/interfaces/instrumentSymbolsFieldsDTO'

interface FilterSuggestionsStateType {
  accounts?: Accounts[]
  instruments?: Instrument[]
  defaultDates?: TreatedDefaultDatesType
}

export const filterSuggestionsState: FilterSuggestionsStateType = {
  accounts: undefined,
  instruments: undefined,
  defaultDates: undefined,
}
