import { setGlobal } from 'reactn'
import { displayError } from '../../utils/displayError'
import { FeatureManager } from '../FeatureManager'
import { mockInstrumentSymbolsRequest } from './mockEndpoint'
import { instrumentSymbolsRequest } from './request'
import { InstrumentsFieldsDTO, InstrumentSymbolParams } from './interfaces/instrumentSymbolsFieldsDTO'

export const getInstruments = async (): Promise<any> => {
  try {
    const response: InstrumentsFieldsDTO = FeatureManager.shouldMockInstrumentSymbols
      ? await mockInstrumentSymbolsRequest()
      : await instrumentSymbolsRequest()

    await setGlobal({
      instruments: response.instruments,
    })
  } catch (error) {
    displayError(error.error)
  }
}
