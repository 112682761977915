import React, { useEffect, useState } from 'react'
import { ILayout } from './interfaces'
import { LayoutWrapper } from './style'
import { Theme, ThemeProvider } from '@material-ui/core'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import { setGlobal, useGlobal } from '../../global/useGlobal'
import light from '../../styles/themes/light'
import SnackBar from '../SnackBar'
import GlobalLoading from '../Loading'
import { checkLogin, getPersistedTheme } from '../../utils/localStorageManager'

const Layout: React.FC<ILayout> = ({ children }) => {
  const [theme] = useGlobal('theme')
  const [isPageLoading] = useGlobal('isPageLoading')
  const [isSyncing] = useGlobal('isSyncLoading')
  const [muiTheme, setMuiTheme] = useState<Theme>(getPersistedTheme())

  const loginCheck = checkLogin()

  useEffect(() => {
    if (loginCheck) {
      if (theme) {
        setGlobal({ theme: getPersistedTheme() })
        setMuiTheme(theme)
      }
    } else {
      setGlobal({ theme: light })
      setMuiTheme(theme)
    }
  }, [theme, loginCheck])

  return (
    <LayoutWrapper>
      <ThemeProvider theme={muiTheme}>
        <StyledThemeProvider theme={muiTheme}>
          {isPageLoading ? <GlobalLoading isSyncing={isSyncing} /> : children}
          <SnackBar />
        </StyledThemeProvider>
      </ThemeProvider>
    </LayoutWrapper>
  )
}

export default Layout
