import { DateTypes, DefaultDatesDTO } from './interfaces/defaultDatesDTO'

export const mockDefaultDatesRequest = async (): Promise<DefaultDatesDTO> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const assets = {
        count: 3,
        defaultDates: [
          {
            dateType: 'ExpireDate' as DateTypes,
            date: '2020-10-29T00:00:00-03:00',
          },
          {
            dateType: 'LockUpDate' as DateTypes,
            date: '2020-09-24T00:00:00',
          },
          {
            dateType: 'ReferenceDate' as DateTypes,
            date: '2020-09-23T00:00:00-03:00',
          },
        ],
      }
      resolve(assets)
    }, 1000)
  })
}
