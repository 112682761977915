import { Theme } from '@material-ui/core'
import { getPersistedTheme } from '../../utils/localStorageManager'

interface ThemeStateType {
  theme: Theme
}

export const themeState: ThemeStateType = {
  theme: getPersistedTheme(),
}
