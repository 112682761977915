import styled from 'styled-components'
import { Paper } from '@material-ui/core'
import { backgroundImage } from '../../resources/assets'

export const LoginWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(${backgroundImage});
  background-size: cover;
`

export const Container = styled(Paper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 54px;
  background-color: #f7f7f7 !important;
`

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  > * {
    margin-bottom: 16px !important;
  }
`

export const Logo = styled.img`
  margin-bottom: 30px;
`

export const MockCaptcha = styled.div`
  width: 352px;
  height: 113px;
  display: flex;
  justify-content: center;
  margin-top: 40px !important;
  margin-bottom: 24px !important;
  border: 1px solid lightblue;
  margin: 0 auto;
  opacity: 0;
`

export const ActionAreaWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 48px;
`
