import { ContractsColumnsSchema } from './interfaces'

export const contractsSchema: ContractsColumnsSchema = {
  checkbox: {
    title: '',
    tooltipName: '',
    index: 0,
    type: 'checkbox',
  },
  code: {
    title: 'Code',
    tooltipName: 'Contract Code',
    index: 1,
    type: 'text',
  },
  instrumentSymbol: {
    title: 'Symbol',
    tooltipName: 'Instrument Symbol',
    index: 2,
    type: 'text',
  },
  quantity: {
    title: 'Qty',
    tooltipName: 'Quantity',
    index: 4,
    type: 'numeric',
  },
  originalQuantity: {
    title: 'Orig. Qty',
    tooltipName: 'Original Quantity',
    index: 5,
    type: 'numeric',
  },
  effectiveQuantity: {
    title: 'Effec. Qty',
    tooltipName: 'Effective Quantity',
    index: 3,
    type: 'numeric',
  },
  projectedQuantity: {
    title: 'Proj. Qty',
    tooltipName: 'ProjectedQuantity',
    index: 6,
    type: 'numeric',
  },
  settledQuantity: {
    title: 'Settl. Qty',
    tooltipName: 'Settled Quantity',
    index: 7,
    type: 'numeric',
  },
  coveredQuantity: {
    title: 'Cov. Qty',
    tooltipName: 'Covered Quantity',
    index: 8,
    type: 'numeric',
  },
  uncoveredQuantity: {
    title: 'Uncov. Qty',
    tooltipName: 'Uncovered Quantity',
    index: 9,
    type: 'numeric',
  },
  renewalQuantity: {
    title: 'Ren. Qty',
    tooltipName: 'Renewal Quantity',
    index: 10,
    type: 'numeric',
  },
  requestedRenewalQuantity: {
    title: 'Req Ren. Qty',
    tooltipName: 'Requested Renewal Quantity',
    index: 11,
    type: 'numeric',
  },
  requestedSettledQuantity: {
    title: 'Req Settl. Qty',
    tooltipName: 'Requested Settled Quantity',
    index: 12,
    type: 'numeric',
  },
  effectiveVolume: {
    title: 'Effec. Vol.',
    tooltipName: 'EffectiveVolume',
    index: 13,
    type: 'text',
  },
  totalVolume: {
    title: 'Tot. Vol',
    tooltipName: 'Total Volume',
    index: 14,
    type: 'text',
  },
  basePrice: {
    title: 'Base Price',
    tooltipName: 'Base Price',
    index: 15,
    type: 'text',
  },
  brokerageFee: {
    title: 'Brok. Fee',
    tooltipName: 'Brokerage Fee',
    index: 16,
    type: 'text',
  },
  side: {
    title: 'Side',
    tooltipName: 'Contract Leg Side',
    index: 17,
    type: 'text',
  },
  paymentType: {
    title: 'Payment Type',
    tooltipName: 'Payment Type',
    index: 18,
    type: 'text',
  },
  marketModality: {
    title: 'Market Modality',
    tooltipName: 'Market Modality',
    index: 19,
    type: 'text',
  },
  securityLendingContractType: {
    title: 'Contract Type',
    tooltipName: 'Security Lending Contract Type',
    index: 20,
    type: 'text',
  },
  masterAccountCode: {
    title: 'Master Account',
    tooltipName: 'Master Account Code',
    index: 21,
    type: 'numeric',
  },
  masterOrSelfAliasOrName: {
    title: 'Master',
    tooltipName: 'Master Alias Or Name',
    index: 22,
    type: 'text',
  },
  issuerAccountCode: {
    title: 'Account',
    tooltipName: 'Issuer Account Code',
    index: 23,
    type: 'numeric',
  },
  issuerAliasOrName: {
    title: 'Name',
    tooltipName: 'Issuer Alias Or Name',
    index: 24,
    type: 'text',
  },
  issuerExecutionBrokerCode: {
    title: 'Execution Broker',
    tooltipName: 'Issuer Execution Broker Code',
    index: 25,
    type: 'numeric',
  },
  issuerCarryingBrokerCode: {
    title: 'Carrying Broker',
    tooltipName: 'Issuer Carrying Broker Code',
    index: 26,
    type: 'numeric',
  },
  issuerCustodianCode: {
    title: 'Custodian',
    tooltipName: 'Issuer Custodian Code',
    index: 27,
    type: 'numeric',
  },
  issuerCustodyAccountCode: {
    title: 'Issuer Custody Account',
    tooltipName: 'Issuer Custody Account Code',
    index: 28,
    type: 'numeric',
  },
  issuerPortfolio: {
    title: 'Issuer Portfolio',
    tooltipName: 'Issuer Portfolio',
    index: 29,
    type: 'numeric',
  },
  negotiationFee: {
    title: 'Negotiation Fee',
    tooltipName: 'Negotiation Fee',
    index: 30,
    type: 'text',
  },
  lenderReversible: {
    title: 'Callable?',
    tooltipName: 'Lender Reversible?',
    index: 31,
    type: 'text',
  },
  tenderOfferLenderReversible: {
    title: 'TOLR?',
    tooltipName: 'Tender Offer Lender Reversible?',
    index: 32,
    type: 'text',
  },
  internalStatus: {
    title: 'Status',
    tooltipName: 'Internal Status',
    index: 33,
    type: 'text',
  },
  tradeDate: {
    title: 'Trade Date',
    tooltipName: 'Trade Date',
    index: 34,
    type: 'text',
  },
  startDate: {
    title: 'Start Date',
    tooltipName: 'Start Date',
    index: 35,
    type: 'text',
  },
  lockUpDate: {
    title: 'Lock Up Date',
    tooltipName: 'Lock Up Date',
    index: 36,
    type: 'text',
  },
  expireDate: {
    title: 'Expire Date',
    tooltipName: 'Expire Date',
    index: 37,
    type: 'text',
  },
  creationDate: {
    title: 'Creation Date',
    tooltipName: 'Creation Date',
    index: 38,
    type: 'text',
  },
  lastExchangeUpdate: {
    title: 'Last Exchange Update',
    tooltipName: 'Last Exchange Update',
    index: 39,
    type: 'text',
  },
}
