import { strings } from '../../resources/strings/strings'
import { stockLoanContractStatus } from '../contracts/stockLoanContractStatus'
import { stockLoanSide } from '../contracts/stockLoanSide'
import { FilterPatterns, FilterSchema, FilterType, InputStyle, AutoCompleteOptions } from './filterTypes'
import { settlementRequestInternalStatus } from '../contracts/settlementRequestInternalStatus'
import { contractCodeMask } from '../masks/masks'
import { ColumnNames } from '../../constants/columnNames'

export interface SettlementRequestsFilterInputs {
  Account: string
  InstrumentSymbol: string
  RequestInternalStatuses: string
  OrderSide: string
  MinCreationDate: string
  MaxCreationDate: string
  [key: string]: string
}

export enum SettlementRequestsFilterNames {
  Account = 'Account',
  ContractCode = 'ContractCode',
  InstrumentSymbol = 'InstrumentSymbol',
  RequestInternalStatuses = 'RequestInternalStatuses',
  OrderSide = 'OrderSide',
  MinCreationDate = 'MinCreationDate',
  MaxCreationDate = 'MaxCreationDate',
}

const { filters } = strings.homeScreen

export const SettlementRequestsFilterSchema: FilterSchema[] = [
  {
    name: SettlementRequestsFilterNames.Account,
    label: 'Account',
    mask: filters.accountMask,
    value: '',
    size: 3,
    inputStyle: InputStyle.autoComplete,
    filterType: FilterType.multiple,
    filterPattern: FilterPatterns.AccountCode,
    autoCompleteOptions: AutoCompleteOptions.accounts,
    columnReference: ColumnNames.IssuerAccountCode,
  },
  {
    name: SettlementRequestsFilterNames.InstrumentSymbol,
    label: 'Symbol',
    mask: filters.symbolMask,
    value: '',
    size: 3,
    inputStyle: InputStyle.autoComplete,
    filterType: FilterType.multiple,
    filterPattern: FilterPatterns.Symbol,
    autoCompleteOptions: AutoCompleteOptions.instruments,
    columnReference: ColumnNames.InstrumentSymbol,
  },
  {
    name: SettlementRequestsFilterNames.ContractCode,
    label: 'Contract Code',
    regexMask: contractCodeMask,
    value: '',
    size: 6,
    inputStyle: InputStyle.textField,
    filterType: FilterType.multiple,
    filterPattern: FilterPatterns.ContractCode,
    columnReference: ColumnNames.ContractCode,
  },
  {
    name: SettlementRequestsFilterNames.RequestInternalStatuses,
    label: 'Status',
    value: '',
    size: 6,
    inputStyle: InputStyle.dropdown,
    options: settlementRequestInternalStatus,
    filterType: FilterType.unique,
    filterPattern: FilterPatterns.OnlyLetters,
    columnReference: ColumnNames.InternalStatus,
  },
  {
    name: SettlementRequestsFilterNames.OrderSide,
    label: 'Side',
    value: '',
    size: 6,
    inputStyle: InputStyle.radio,
    radios: stockLoanSide,
    filterType: FilterType.unique,
    filterPattern: FilterPatterns.Side,
    columnReference: ColumnNames.Side,
  },
  {
    name: SettlementRequestsFilterNames.MinCreationDate,
    label: 'Min Creation Date',
    mask: filters.dateTimeMask,
    value: '',
    size: 3,
    inputStyle: InputStyle.textField,
    filterType: FilterType.unique,
    filterPattern: FilterPatterns.Datetime,
    placeholder: filters.dateTimePlaceholder,
  },
  {
    name: SettlementRequestsFilterNames.MaxCreationDate,
    label: 'Max Creation Date',
    mask: filters.dateTimeMask,
    value: '',
    size: 3,
    inputStyle: InputStyle.textField,
    filterType: FilterType.unique,
    filterPattern: FilterPatterns.Datetime,
    placeholder: filters.dateTimePlaceholder,
  },
]
