import styled from 'styled-components'
import Modal from '@material-ui/core/Modal'
import { colors } from '../../styles/themes/light'

export const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const ModalContainer = styled.div<any>`
  width: 60%;
  min-width: 650px;
  max-height: 70%;
  min-height: 200px;
  outline: none;
  background-color: ${colors.white};
  box-shadow: ${colors.opacityBlack};
  overflow-y: auto;
`
