/**
 * Global State
 * this object represents the initial Global State
 * the global state's type is inferred from this object
 */
import { loginState } from './components/loginState'
import { themeState } from './components/themeState'
import { pageState } from './components/pageState'
import { settleState } from './components/settleState'
import { contractsState } from './components/contractsState'
import { settlementsState } from './components/settlementsState'
import { virtualSettleState } from './components/virtualSettle'
import { settlementRequestsState } from './components/settlementRequestsState'
import {
  approvalInstructionsState,
  createApprovalInstructionsState,
  requestTypeApprovalInstructionsState,
  responseApprovalInstructionsState,
  updateApprovalInstructionsState,
} from './components/approvalInstructions'
import { favoriteFiltersState } from './components/favoriteFiltersState'
import { filterSuggestionsState } from './components/filterSuggestionsState'
import { approvalDivergencesState } from './components/approvalDivergences'
import { filtersState } from './components/filtersState'

export const InitialGlobalState = {
  ...themeState,
  ...loginState,
  ...pageState,
  ...settleState,
  ...contractsState,
  ...settlementsState,
  ...virtualSettleState,
  ...settlementRequestsState,
  ...approvalInstructionsState,
  ...favoriteFiltersState,
  ...createApprovalInstructionsState,
  ...updateApprovalInstructionsState,
  ...responseApprovalInstructionsState,
  ...requestTypeApprovalInstructionsState,
  ...filterSuggestionsState,
  ...approvalDivergencesState,
  ...filtersState,
}

export const InitialGlobalEntityState = {}

export type GlobalState = typeof InitialGlobalState
export type GlobalEntityState = typeof InitialGlobalEntityState
export type GlobalEntityStateKeys = keyof GlobalEntityState
