import { CreateSettlementRequestResponse } from './interfaces/createSettlementRequestResponse'

export const mockSettleRequest = async (): Promise<CreateSettlementRequestResponse> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const assets: CreateSettlementRequestResponse = {
        count: 1,
        contractLegs: [
          {
            contractLegCode: '201911270023940831-1',
            side: 'Borrower',
            quantity: 0,
            internalStatus: 'InvalidRejected',
            errorRuleIds: 'lh-inactive-contract',
          },
        ],
      }
      resolve(assets)
    }, 1000)
  })
}
