import React, { useEffect } from 'react'
import { WorkspaceAppbar } from '../../components/WorkspaceAppbar'
import { CustomModal } from '../../components/CustomModal'
import { SettlementsContainer } from './style'
import { WorkspaceWrapper } from '../../components/WorkspaceWrapper'
import { useGlobal } from '../../global/useGlobal'
import { getSettlements } from '../../services/settlements/getSettlements'
import { SettlementsFilterInputs, SettlementsFilterSchema } from '../../constants/filters/settlementsFilters'
import { useLegacyState } from '../../utils/hooks/useLegacyState'
import { CustomFiltersContent } from '../../components/CustomFiltersContent'
import Table from '../../components/CustomTable'
import { settlementsSchema } from '../../services/dataSchemas/settlements/schema'
import { usePromise } from '../../utils/hooks/usePromise'

interface ISettlements {}

const SettlementsView: React.FC<ISettlements> = () => {
  const [filters] = useGlobal('settlementsAppliedFilters')
  const [selectedFilters, setSelectedFilters] = useGlobal('settlementsSelectedFilters')
  const [isFilterModalOpen, setIsFilterModalOpen] = useGlobal('settlementsFilterModalOpen')
  const [isLoading] = useGlobal('isDataLoading')
  const [tableData] = useGlobal('settlementsData')
  const [paginationArgs] = useGlobal('settlementsPaginationArgs')
  const [currentPage, setCurrentPage] = useGlobal('currentSettlementsPage')
  const [sorting, setSorting] = useGlobal('settlementsAppliedSorting')

  const closeFilterModal = () => setIsFilterModalOpen(false)
  const [values, setValues] = useLegacyState<SettlementsFilterInputs>({
    ContractCode: '',
    Account: '',
    InstrumentSymbol: '',
    MinSettlementDate: '',
    MaxSettlementDate: '',
    MinCreationDate: '',
    MaxCreationDate: '',
    MinExpireDate: '',
    MaxExpireDate: '',
    ContractInternalStatuses: '',
    InternalStatus: '',
  })

  const loadMore = async () => {
    const nextPage = currentPage + 1
    await getSettlements(nextPage)
    await setCurrentPage(nextPage)
  }

  const { loading: isLoadingMore, request: doLoadMore } = usePromise(loadMore, [currentPage], {})

  const loadAll = async () => {
    await getSettlements(undefined, true)
  }

  const { loading: isLoadingAll, request: doLoadAll } = usePromise(loadAll, [], {})

  useEffect(() => {
    getSettlements()
  }, [filters, sorting])

  const totalCount = paginationArgs?.totalCount
  const currentCount = tableData.length

  const hasPagination = totalCount > currentCount

  const paginationOptions = {
    doLoadMore,
    doLoadAll,
    isLoadingMore,
    isLoadingAll,
    totalCount,
  }

  return (
    <SettlementsContainer>
      <WorkspaceAppbar />
      <WorkspaceWrapper>
        <Table
          hasContextMenu
          tableDataSchema={settlementsSchema as any}
          tableRefGlobalKey={'settlementsTableRef'}
          tableColumnsGlobalKey={'settlementsColumns'}
          columnWidthsGlobalKey={'settlementRequestsColumnWidths'}
          dataGlobalKey={'settlementsData'}
          isLoading={isLoading}
          hasFooter
          hasPagination={hasPagination}
          paginationOptions={paginationOptions}
          sorting={sorting}
          setSorting={setSorting}
          persistColumnOrder
        />
        <CustomModal closeModal={closeFilterModal} isOpen={isFilterModalOpen} filters={true}>
          <CustomFiltersContent
            filtersSchema={SettlementsFilterSchema}
            values={values}
            setValues={setValues}
            state={selectedFilters}
            setState={setSelectedFilters}
            handleClose={closeFilterModal}
          />
        </CustomModal>
      </WorkspaceWrapper>
    </SettlementsContainer>
  )
}

export default SettlementsView
