interface SnackBarType {
  isOpen: boolean
  isError: boolean
  isWarning: boolean
  message: string
}

interface DialogType {
  isOpen: boolean
  title: string
  text: string
  handleAction?: () => void
}

interface PageStateType {
  snackBar: SnackBarType
  isPageLoading: boolean
  dialog: DialogType
  isDataLoading: boolean
  isSyncLoading: boolean
}

export const pageState: PageStateType = {
  snackBar: { isOpen: false, isError: false, isWarning: false, message: '' },
  isPageLoading: false,
  dialog: { isOpen: false, title: '', text: '', handleAction: undefined },
  isDataLoading: false,
  isSyncLoading: false,
}
