import { SettlementsColumnsSchema } from './interfaces'

export const settlementsSchema: SettlementsColumnsSchema = {
  exchangeTransactionId: {
    title: 'Exchange Transaction Id',
    tooltipName: 'Exchange Transaction Id',
    index: 0,
    type: 'text',
  },
  contractCode: {
    title: 'Contract Code',
    tooltipName: 'Contract Code',
    index: 1,
    type: 'text',
  },
  instrumentSymbol: {
    title: 'Symbol',
    tooltipName: 'Instrument Symbol',
    index: 2,
    type: 'text',
  },
  quantity: {
    title: 'Qty',
    tooltipName: 'Quantity',
    index: 3,
    type: 'text',
  },
  side: {
    title: 'Side',
    tooltipName: 'Side',
    index: 4,
    type: 'text',
  },
  issuerBrokerCode: {
    title: 'Broker',
    tooltipName: 'Issuer Broker Code',
    index: 5,
    type: 'text',
  },
  issuerAccountCode: {
    title: 'Account',
    tooltipName: 'Issuer Account Code',
    index: 6,
    type: 'text',
  },
  issuerAliasOrName: {
    title: 'Name',
    tooltipName: 'Issuer Alias or Name',
    index: 7,
    type: 'text',
  },
  settlementDate: {
    title: 'Settlement Date',
    tooltipName: 'Settlement Date',
    index: 8,
    type: 'text',
  },
  expireDate: {
    title: 'Expire Date',
    tooltipName: 'Expire Date',
    index: 9,
    type: 'text',
  },
  issuerGrossValue: {
    title: 'Gross Value',
    tooltipName: 'Gross Value',
    index: 10,
    type: 'text',
  },
  issuerNetValue: {
    title: 'Net Value',
    tooltipName: 'Net Value',
    index: 11,
    type: 'text',
  },
  shownIssuerComission: {
    title: 'Issuer Commission',
    tooltipName: 'Issuer Commission',
    index: 12,
    type: 'text',
  },
  issuerWithheldTax: {
    title: 'Issuer Withheld Tax',
    tooltipName: 'Issuer Withheld Tax',
    index: 13,
    type: 'text',
  },
  settlementValue: {
    title: 'Settlement Value',
    tooltipName: 'Settlement Value',
    index: 14,
    type: 'text',
  },
  issuerCustodianCode: {
    title: 'Custodian',
    tooltipName: 'Issuer Custodian Code',
    index: 15,
    type: 'text',
  },
  issuerCustodyAccountCode: {
    title: 'Cust. Account',
    tooltipName: 'Issuer Custody Account Code',
    index: 16,
    type: 'text',
  },
  issuerPortfolio: {
    title: 'Portfolio',
    tooltipName: 'Issuer Portfolio',
    index: 17,
    type: 'text',
  },
  internalStatus: {
    title: 'Settlement Status ',
    tooltipName: 'Settlement Status',
    index: 18,
    type: 'text',
  },
  contractInternalStatus: {
    title: 'Contract Status ',
    tooltipName: 'Contract Status',
    index: 19,
    type: 'text',
  },
}
