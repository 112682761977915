import { FeatureManager } from '../FeatureManager'
import { getGlobal, setGlobal } from '../../global/useGlobal'
import { SettlementsFieldsDTO } from './interfaces/settlementsFieldsDTO'
import { mockSettlementsRequest } from './mockEndpoint'
import { settlementsRequest } from './request'
import { mapDTOResponseToTableData } from '../../utils/mapDTOResponseToTableData'
import { isActiveFilter } from '../../utils/isActiveFilter'
import { INITIAL_PAGE_NUMBER, PAGE_SIZE } from '../../constants/pagination'
import { numberOfPagesCalculator } from '../../utils/numberOfPagesCalculator'
import { Filter } from '../../constants/filters/filterTypes'
import { formatFilter } from '../../utils/formatFilter'
import { formatDateFilter } from '../../utils/formatDateFilter'
import { SettlementsFilterNames } from '../../constants/filters/settlementsFilters'
import { displayError } from '../../utils/displayError'

export const getSettlements = async (page?: number, allData?: boolean): Promise<any> => {
  await setGlobal({
    isDataLoading: true,
  })

  const appliedFilters = getGlobal().settlementsAppliedFilters
  const appliedSorting = getGlobal().settlementsAppliedSorting

  try {
    const filters = mountFilters(appliedFilters, page, appliedSorting, allData)
    const oldData = getGlobal().settlementsData
    const response: SettlementsFieldsDTO = FeatureManager.shouldMockSettlements
      ? await mockSettlementsRequest()
      : await settlementsRequest(filters)
    const apiDataSettlementsResponse = mapDTOResponseToTableData(response)
    const settlementsPaginationArgs = mapDTOResponseToPaginationSummary(response)
    let settlementsData: any[]
    if (page) {
      settlementsData = oldData.concat(apiDataSettlementsResponse)
    } else {
      settlementsData = apiDataSettlementsResponse
    }
    await setGlobal({
      settlementsData,
      settlementsPaginationArgs,
      isDataLoading: false,
    })
  } catch (error) {
    await setGlobal({
      isDataLoading: false,
    })
    displayError(error.error)
  }
}

const mountFilters = (filters: Filter[], page?: number, sorting?: string[], allData?: boolean) => {
  const activeFilters = isActiveFilter(filters)
  const filtersObject = {
    ContractCode: formatFilter(activeFilters, SettlementsFilterNames.ContractCode),
    AccountCode: formatFilter(activeFilters, SettlementsFilterNames.Account),
    InstrumentSymbol: formatFilter(activeFilters, SettlementsFilterNames.InstrumentSymbol),
    MinExpireDate: formatDateFilter(activeFilters, SettlementsFilterNames.MinExpireDate),
    MaxExpireDate: formatDateFilter(activeFilters, SettlementsFilterNames.MaxExpireDate),
    MinCreationDate: formatDateFilter(activeFilters, SettlementsFilterNames.MinCreationDate),
    MaxCreationDate: formatDateFilter(activeFilters, SettlementsFilterNames.MaxCreationDate),
    MinSettlementDate: formatDateFilter(activeFilters, SettlementsFilterNames.MinSettlementDate),
    MaxSettlementDate: formatDateFilter(activeFilters, SettlementsFilterNames.MaxSettlementDate),
    ContractInternalStatuses: formatFilter(activeFilters, SettlementsFilterNames.ContractInternalStatuses),
    InternalStatus: formatFilter(activeFilters, SettlementsFilterNames.InternalStatus),
    OrderSide: formatFilter(activeFilters, SettlementsFilterNames.OrderSide),
    PageSize: allData ? -1 : PAGE_SIZE,
    PageNum: allData ? -1 : page || INITIAL_PAGE_NUMBER,
    RequireAllData: !!allData,
    Sorting: sorting?.join(',') || undefined,
  }
  return JSON.parse(JSON.stringify(filtersObject))
}

const mapDTOResponseToPaginationSummary = (dto: SettlementsFieldsDTO) => {
  return {
    pageNum: dto.paginationSummary.pageNum,
    pageSize: dto.paginationSummary.pageSize,
    sorting: dto.paginationSummary.sorting,
    count: dto.paginationSummary.count,
    totalCount: dto.paginationSummary.totalCount,
    numberOfPages: numberOfPagesCalculator(dto.paginationSummary.pageSize, dto.paginationSummary.totalCount),
  }
}
