import { SettlementsFieldsDTO } from './interfaces/settlementsFieldsDTO'

export const mockSettlementsRequest = async (): Promise<SettlementsFieldsDTO> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const assets = {
        paginationSummary: {
          count: 200,
          totalCount: 758,
          pageNum: 1,
          pageSize: 200,
          sorting: null,
        },
        settlementLegs: [
          {
            exchangeTransactionId: '2019-11-21-1-5269',
            contractCode: '201911210023909094-1',
            instrumentSymbol: 'ELET6',
            quantity: 1223,
            side: 'Borrower',
            issuerBrokerCode: '1026',
            issuerAccountCode: '152909',
            issuerAliasOrName: 'NOME DE CLIENTE',
            settlementDate: '2019-11-22T00:00:00Z',
            expireDate: '2021-01-21T00:00:00Z',
            issuerGrossValue: 0.43,
            issuerNetValue: null,
            shownIssuerComission: null,
            issuerWithheldTax: null,
            settlementValue: null,
            issuerCustodianCode: '1026',
            issuerCustodyAccountCode: '152909',
            issuerPortfolio: '21016',
            contractLeg: {
              code: '201911210023909094-1',
              instrumentSymbol: 'ELET6',
              quantity: 1223,
              originalQuantity: 1223,
              effectiveQuantity: 1223,
              projectedQuantity: 1223,
              settledQuantity: 0,
              coveredQuantity: null,
              uncoveredQuantity: null,
              renewalQuantity: 0,
              requestedRenewalQuantity: 0,
              requestedSettledQuantity: 1223,
              totalVolume: null,
              basePrice: 35.89,
              brokerageFee: null,
              side: 'Borrower',
              paymentType: 'Netting',
              marketModality: 'OtcSecuritiesLending',
              securityLendingContractType: 'MandatoryLoan',
              masterAccountCode: '243122',
              masterOrSelfAliasOrName: 'NOME DE CLIENTE',
              issuerAccountCode: '152909',
              issuerAliasOrName: 'NOME DE CLIENTE',
              issuerExecutionBrokerCode: '1026',
              issuerCarryingBrokerCode: '1026',
              issuerCustodianCode: '1026',
              issuerCustodyAccountCode: '152909',
              issuerPortfolio: '21016',
              negotiationFee: null,
              lenderReversible: true,
              tenderOfferLenderReversible: true,
              internalStatus: 'Finalized',
              tradeDate: '2019-11-21T00:00:00Z',
              startDate: '2019-11-21T00:00:00Z',
              lockUpDate: '2019-11-22T00:00:00Z',
              expireDate: '2021-01-21T00:00:00Z',
              creationDate: '2019-11-21T14:43:32.773Z',
              lastExchangeUpdate: '2019-11-21T15:31:01Z',
            },
            contractInternalStatus: 'Unknown',
            internalStatus: 'Unset',
          },
          {
            exchangeTransactionId: '2019-11-22-1-7208',
            contractCode: '201911220023915610-1',
            instrumentSymbol: 'RAPT4',
            quantity: 1100,
            side: 'Borrower',
            issuerBrokerCode: '1026',
            issuerAccountCode: '182839',
            issuerAliasOrName: 'NOME DE CLIENTE',
            settlementDate: '2019-11-25T00:00:00Z',
            expireDate: '2020-12-30T00:00:00Z',
            issuerGrossValue: 0.53,
            issuerNetValue: null,
            shownIssuerComission: null,
            issuerWithheldTax: null,
            settlementValue: null,
            issuerCustodianCode: '1026',
            issuerCustodyAccountCode: '182839',
            issuerPortfolio: '21016',
            contractLeg: {
              code: '201911220023915610-1',
              instrumentSymbol: 'RAPT4',
              quantity: 1100,
              originalQuantity: 1100,
              effectiveQuantity: 1100,
              projectedQuantity: 1100,
              settledQuantity: 0,
              coveredQuantity: null,
              uncoveredQuantity: null,
              renewalQuantity: 0,
              requestedRenewalQuantity: 0,
              requestedSettledQuantity: 1100,
              totalVolume: null,
              basePrice: 10.37,
              brokerageFee: null,
              side: 'Borrower',
              paymentType: 'Netting',
              marketModality: 'OtcSecuritiesLending',
              securityLendingContractType: 'MandatoryLoan',
              masterAccountCode: '115287',
              masterOrSelfAliasOrName: 'NOME DE CLIENTE',
              issuerAccountCode: '182839',
              issuerAliasOrName: 'NOME DE CLIENTE',
              issuerExecutionBrokerCode: '1026',
              issuerCarryingBrokerCode: '1026',
              issuerCustodianCode: '1026',
              issuerCustodyAccountCode: '182839',
              issuerPortfolio: '21016',
              negotiationFee: null,
              lenderReversible: true,
              tenderOfferLenderReversible: true,
              internalStatus: 'Finalized',
              tradeDate: '2019-11-22T00:00:00Z',
              startDate: '2019-11-22T00:00:00Z',
              lockUpDate: '2019-11-25T00:00:00Z',
              expireDate: '2020-12-30T00:00:00Z',
              creationDate: '2019-11-22T14:55:42.62Z',
              lastExchangeUpdate: '2019-11-22T18:06:05Z',
            },
            contractInternalStatus: 'Unknown',
            internalStatus: 'Unset',
          },
          {
            exchangeTransactionId: '2019-11-22-1-7198',
            contractCode: '201911220023915611-1',
            instrumentSymbol: 'RAPT4',
            quantity: 17145,
            side: 'Borrower',
            issuerBrokerCode: '1026',
            issuerAccountCode: '182839',
            issuerAliasOrName: 'NOME DE CLIENTE',
            settlementDate: '2019-11-25T00:00:00Z',
            expireDate: '2021-01-21T00:00:00Z',
            issuerGrossValue: 20.85,
            issuerNetValue: null,
            shownIssuerComission: null,
            issuerWithheldTax: null,
            settlementValue: null,
            issuerCustodianCode: '1026',
            issuerCustodyAccountCode: '182839',
            issuerPortfolio: '21016',
            contractLeg: {
              code: '201911220023915611-1',
              instrumentSymbol: 'RAPT4',
              quantity: 17145,
              originalQuantity: 17145,
              effectiveQuantity: 17145,
              projectedQuantity: 17145,
              settledQuantity: 0,
              coveredQuantity: null,
              uncoveredQuantity: null,
              renewalQuantity: 0,
              requestedRenewalQuantity: 0,
              requestedSettledQuantity: 17145,
              totalVolume: null,
              basePrice: 10.37,
              brokerageFee: null,
              side: 'Borrower',
              paymentType: 'Netting',
              marketModality: 'OtcSecuritiesLending',
              securityLendingContractType: 'MandatoryLoan',
              masterAccountCode: '115287',
              masterOrSelfAliasOrName: 'NOME DE CLIENTE',
              issuerAccountCode: '182839',
              issuerAliasOrName: 'NOME DE CLIENTE',
              issuerExecutionBrokerCode: '1026',
              issuerCarryingBrokerCode: '1026',
              issuerCustodianCode: '1026',
              issuerCustodyAccountCode: '182839',
              issuerPortfolio: '21016',
              negotiationFee: null,
              lenderReversible: true,
              tenderOfferLenderReversible: true,
              internalStatus: 'Finalized',
              tradeDate: '2019-11-22T00:00:00Z',
              startDate: '2019-11-22T00:00:00Z',
              lockUpDate: '2019-11-25T00:00:00Z',
              expireDate: '2021-01-21T00:00:00Z',
              creationDate: '2019-11-22T14:55:42.807Z',
              lastExchangeUpdate: '2019-11-22T18:06:04Z',
            },
            contractInternalStatus: 'Unknown',
            internalStatus: 'Unset',
          },
          {
            exchangeTransactionId: 'P:201910040023672199-1',
            contractCode: '201910040023672199-1',
            instrumentSymbol: 'BOVA11',
            quantity: 0,
            side: 'Borrower',
            issuerBrokerCode: '114',
            issuerAccountCode: '2056639',
            issuerAliasOrName: 'NOME DE CLIENTE',
            settlementDate: '2020-09-15T00:00:00Z',
            expireDate: '2020-09-15T00:00:00Z',
            issuerGrossValue: 13.01,
            issuerNetValue: null,
            shownIssuerComission: null,
            issuerWithheldTax: null,
            settlementValue: 971.2,
            issuerCustodianCode: '2028',
            issuerCustodyAccountCode: '8108',
            issuerPortfolio: '21016',
            contractLeg: {
              code: '201910040023672199-1',
              instrumentSymbol: 'BOVA11',
              quantity: 10,
              originalQuantity: 10,
              effectiveQuantity: 10,
              projectedQuantity: 10,
              settledQuantity: 0,
              coveredQuantity: 0,
              uncoveredQuantity: 10,
              renewalQuantity: 0,
              requestedRenewalQuantity: 0,
              requestedSettledQuantity: 10,
              totalVolume: null,
              basePrice: 97.12,
              brokerageFee: null,
              side: 'Borrower',
              paymentType: 'Netting',
              marketModality: 'OtcSecuritiesLending',
              securityLendingContractType: 'MandatoryLoan',
              masterAccountCode: '186235',
              masterOrSelfAliasOrName: 'NOME DE CLIENTE',
              issuerAccountCode: '2056639',
              issuerAliasOrName: 'NOME DE CLIENTE',
              issuerExecutionBrokerCode: '114',
              issuerCarryingBrokerCode: '1026',
              issuerCustodianCode: '2028',
              issuerCustodyAccountCode: '8108',
              issuerPortfolio: '21016',
              negotiationFee: 1.45,
              lenderReversible: false,
              tenderOfferLenderReversible: true,
              internalStatus: 'Finalized',
              tradeDate: '2019-10-04T00:00:00Z',
              startDate: '2019-10-04T00:00:00Z',
              lockUpDate: '2019-10-07T00:00:00Z',
              expireDate: '2020-09-15T00:00:00Z',
              creationDate: '2019-10-04T00:00:00Z',
              lastExchangeUpdate: '2020-01-22T22:39:30Z',
            },
            contractInternalStatus: 'Unknown',
            internalStatus: 'Unset',
          },
          {
            exchangeTransactionId: 'P:201910090023690723-1',
            contractCode: '201910090023690723-1',
            instrumentSymbol: 'MRVE3',
            quantity: 0,
            side: 'Borrower',
            issuerBrokerCode: '114',
            issuerAccountCode: '2056639',
            issuerAliasOrName: 'NOME DE CLIENTE',
            settlementDate: '2020-09-15T00:00:00Z',
            expireDate: '2020-09-15T00:00:00Z',
            issuerGrossValue: 7.42,
            issuerNetValue: null,
            shownIssuerComission: null,
            issuerWithheldTax: null,
            settlementValue: 3252.3,
            issuerCustodianCode: '2028',
            issuerCustodyAccountCode: '8108',
            issuerPortfolio: '21016',
            contractLeg: {
              code: '201910090023690723-1',
              instrumentSymbol: 'MRVE3',
              quantity: 185,
              originalQuantity: 185,
              effectiveQuantity: 185,
              projectedQuantity: 185,
              settledQuantity: 0,
              coveredQuantity: 0,
              uncoveredQuantity: 185,
              renewalQuantity: 0,
              requestedRenewalQuantity: 0,
              requestedSettledQuantity: 185,
              totalVolume: null,
              basePrice: 17.58,
              brokerageFee: null,
              side: 'Borrower',
              paymentType: 'Netting',
              marketModality: 'OtcSecuritiesLending',
              securityLendingContractType: 'MandatoryLoan',
              masterAccountCode: '186235',
              masterOrSelfAliasOrName: 'NOME DE CLIENTE',
              issuerAccountCode: '2056639',
              issuerAliasOrName: 'NOME DE CLIENTE',
              issuerExecutionBrokerCode: '114',
              issuerCarryingBrokerCode: '1026',
              issuerCustodianCode: '2028',
              issuerCustodyAccountCode: '8108',
              issuerPortfolio: '21016',
              negotiationFee: 0.25,
              lenderReversible: true,
              tenderOfferLenderReversible: true,
              internalStatus: 'Finalized',
              tradeDate: '2019-10-09T00:00:00Z',
              startDate: '2019-10-09T00:00:00Z',
              lockUpDate: '2019-10-10T00:00:00Z',
              expireDate: '2020-09-15T00:00:00Z',
              creationDate: '2019-10-09T00:00:00Z',
              lastExchangeUpdate: '2020-01-22T22:39:30Z',
            },
            contractInternalStatus: 'Unknown',
            internalStatus: 'Unset',
          },
        ],
      }
      resolve(assets)
    }, 1000)
  })
}
