import { DataSchema } from '../../services/dataSchemas/interfaces'
import { accountMask, dateMask, floatMask, intMask } from '../../constants/masks/masks'
import { getDropdownSourceFromObject } from '../../utils/getDropdownSourceFromObject'
import { stockLoanApprovalInstructionRequestType } from '../../constants/approvalInstructions/StockLoanApprovalInstructionsRequestType'
import { booleanDropdownSource, sideDropdownSource } from '../../constants/dropdownSources'

export const createApprovalInstructionsSchemaGetter: (isFixedRequestType?: boolean) => { [key: string]: DataSchema } = (
  isFixedRequestType
) => {
  return {
    externalId: {
      title: 'External ID',
      type: 'text',
      tooltipName: 'External ID',
    },
    requestType: {
      title: 'Request Type',
      type: 'dropdown',
      tooltipName: 'Request Type',
      readOnly: !!isFixedRequestType,
      source: getDropdownSourceFromObject(stockLoanApprovalInstructionRequestType),
    },
    instrumentSymbol: {
      title: 'Symbol',
      type: 'text',
      tooltipName: 'Instrument Symbol',
    },
    quantity: {
      title: 'Qty',
      type: 'text',
      tooltipName: 'Quantity',
      mask: intMask,
    },
    side: {
      title: 'Side',
      type: 'dropdown',
      tooltipName: 'Side',
      source: sideDropdownSource,
    },
    issuerAccountCode: {
      title: 'Account',
      type: 'text',
      tooltipName: 'Issuer Account Code',
      mask: accountMask,
    },
    negotiationFee: {
      title: 'Negotiation Fee',
      type: 'text',
      tooltipName: 'Negotiation Fee',
      mask: floatMask,
    },
    commissionFee: {
      title: 'Commission Fee',
      type: 'text',
      tooltipName: 'Commission Fee',
      mask: floatMask,
    },
    interBrokerFee: {
      title: 'Inter Broker Fee',
      type: 'text',
      tooltipName: 'Inter Broker Fee',
      mask: floatMask,
    },
    lenderReversible: {
      title: 'Callable?',
      type: 'dropdown',
      tooltipName: 'Lender Reversible?',
      source: booleanDropdownSource,
    },
    tenderOfferLenderReversible: {
      title: 'TOLR?',
      type: 'dropdown',
      tooltipName: 'Tender Offer Lender Reversible?',
      source: booleanDropdownSource,
    },
    lockUpDate: {
      title: 'Lock Up Date',
      type: 'text',
      mask: dateMask,
      tooltipName: 'Lock Up Date',
    },
    expireDate: {
      title: 'Expire Date',
      type: 'text',
      mask: dateMask,
      tooltipName: 'Expire Date',
    },
    issuerCustodianCode: {
      title: 'Issuer Custodian Code',
      type: 'text',
      tooltipName: 'Issuer Custodian Code',
      mask: accountMask,
    },
    issuerCustodyAccountCode: {
      title: 'Issuer Custody Account Code',
      type: 'text',
      tooltipName: 'Issuer Custody Account Code',
      mask: accountMask,
    },
    issuerPortfolio: {
      title: 'Issuer Portfolio',
      type: 'text',
      tooltipName: 'Issuer Portfolio',
      mask: accountMask,
    },
    referenceDate: {
      title: 'Reference Date',
      type: 'text',
      mask: dateMask,
      tooltipName: 'Reference Date',
    },
    targetBrokerCode: {
      title: 'Target Broker Code',
      type: 'text',
      tooltipName: 'Target Broker Code',
      mask: accountMask,
    },
    internalStatus: {
      title: 'Status',
      type: 'text',
      tooltipName: 'Internal Status',
      readOnly: true,
    },
    deskId: {
      title: 'Desk ID',
      type: 'text',
      tooltipName: 'Desk ID',
    },
    memo: {
      title: 'Memo',
      type: 'text',
      tooltipName: 'Memo',
    },
    feedback: {
      title: 'Feedback',
      type: 'text',
      tooltipName: 'Feedback',
      readOnly: true,
    },
    averageBorrowerCharge: {
      title: 'Avg Borrower Charge',
      type: 'text',
      tooltipName: 'Average Borrower Charge',
      readOnly: true,
    },
    averageLenderInterest: {
      title: 'Avg Lender Interest',
      type: 'text',
      tooltipName: 'Average Lender Interest',
      readOnly: true,
    },
  }
}
