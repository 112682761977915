import React from 'react'
import styled from 'styled-components'
import { colors } from '../../../styles/themes/light'
import { SvgComponent } from './interfaces'

export const ApprovalInstructionsSettleIcon: React.FC<SvgComponent> = (props) => {
  return (
    <SvgContainer>
      <svg {...props} preserveAspectRatio="xMaxYMax meet" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.66 5.99998L8 0.349976L2.34 5.99998C0.78 7.55998 0 9.63998 0 11.64C0 13.64 0.78 15.75 2.34 17.31C3.9 18.87 5.95 19.66 8 19.66C10.05 19.66 12.1 18.87 13.66 17.31C15.22 15.75 16 13.64 16 11.64C16 9.63998 15.22 7.55998 13.66 5.99998Z"
          fill="fill"
          fill-opacity="1"
        />
      </svg>
    </SvgContainer>
  )
}

ApprovalInstructionsSettleIcon.defaultProps = {
  fill: colors.moduleAssetSelectIcon,
  height: 24,
  width: 24,
}

const SvgContainer = styled.div`
  background-color: ${colors.white};
  border: 1px solid ${colors.lightGray};
  height: 40px;
  width: 40px;
  border-radius: 4px;
  padding: 9px 10px;
`
