import { displayError } from '../../../utils/displayError'
import { FeatureManager } from '../../FeatureManager'
import { ApprovalInstructionsFieldsDTO } from '../get/interfaces/approvalInstructionsFieldsDTO'
import { mockDeleteApprovalInstructionsRequest } from './mockEndpoint'
import { deleteApprovalInstructionsRequest } from './request'

export const deleteApprovalInstructions = async (
  input: string[]
): Promise<ApprovalInstructionsFieldsDTO[] | undefined> => {
  const ids = {
    ids: input,
  }
  try {
    const response: ApprovalInstructionsFieldsDTO[] = FeatureManager.shouldMockApprovalInstructions
      ? await mockDeleteApprovalInstructionsRequest()
      : await deleteApprovalInstructionsRequest(ids)
    return response
  } catch (error) {
    displayError(error.error)
  }
}
