export interface SettlementRequestStatusType {
  InvalidRejected: string
  Created: string
  TransmissionFail: string
  ExchangePending: string
  ExchangeAccepted: string
  ExchangeRejected: string
  Converted: string
  Confirmed: string
  Discarded: string
  Finalized: string
  Restricted: string
  Aborted: string
  AbortedRejected: string
  InsufficientBalance: string
  UnAccepted: string
  PendingNotAborted: string
  Pending: string
  AwaitingConfirmation: string
  SentAndOk: string
  Confirmable: string
  SentToConfirmed: string
  CreatedToConfirmed: string
  Sent: string
  Disposable: string
  Limited: string
  Blocked: string
  [key: string]: string
}

export const settlementRequestInternalStatus: SettlementRequestStatusType = {
  InvalidRejected: 'InvalidRejected',
  Created: 'Created',
  TransmissionFail: 'TransmissionFail',
  ExchangePending: 'ExchangePending',
  ExchangeAccepted: 'ExchangeAccepted',
  ExchangeRejected: 'ExchangeRejected',
  Converted: 'Converted',
  Confirmed: 'Confirmed',
  Discarded: 'Discarded',
  Finalized: 'Finalized',
  Restricted: 'Restricted',
  Aborted: 'Aborted',
  AbortedRejected: 'AbortedRejected',
  InsufficientBalance: 'InsufficientBalance',
  UnAccepted: 'UnAccepted',
  PendingNotAborted: 'PendingNotAborted',
  Pending: 'Pending',
  AwaitingConfirmation: 'AwaitingConfirmation',
  SentAndOk: 'SentAndOk',
  Confirmable: 'Confirmable',
  SentToConfirmed: 'SentToConfirmed',
  CreatedToConfirmed: 'CreatedToConfirmed',
  Sent: 'Sent',
  Disposable: 'Disposable',
  Limited: 'Limited',
  Blocked: 'Blocked',
}
