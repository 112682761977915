import { useForm } from 'react-simple-hooks-forms'
import { useMemo, useState } from 'react'
import { forgotPasswordRequest } from '../../services/auth/ForgotPasswordService'
import { forgotPasswordConfig } from '../../constants/formsConfig/forgotPassword'
import { strings } from '../../resources/strings/strings'

export class ForgotPasswordVM {
  static initVM() {
    return useMemo(() => new ForgotPasswordVM(), [])
  }

  useDataHook() {
    const { forgotPasswordScreen: screenStrings } = strings
    const { Form, submit } = useForm({ ...forgotPasswordConfig })
    const [isEmailSent, setIsEmailSent] = useState(false)
    const [errorMessage, setErrorMessage] = useState({
      email: '',
    })

    const onSubmit = async () => {
      return await submit(
        (values) => {
          const { email } = values
          forgotPasswordRequest({ email })
          setIsEmailSent(true)
        },
        (error) => {
          setErrorMessage({
            email: error.email,
          })
        }
      )
    }

    return {
      Form,
      onSubmit,
      isEmailSent,
      errorMessage,
      screenStrings,
    }
  }
}
