import { makeStyles, Theme, createStyles, Typography, Toolbar } from '@material-ui/core'
import styled from 'styled-components'
import { device } from '../../styles/media-queries'
import { baseColors } from '../../styles/themes/baseTheme'

export const workspaceAppbarUseStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: theme.palette.background.paper,
    },
    toolbar: {
      justifyContent: 'space-between',
      padding: 0,
    },
    divider: {
      height: `calc(${theme.mixins.toolbar.minHeight}px - 16px)`,
      backgroundColor: `${
        theme.palette.type === 'dark' ? baseColors.appbarDividerDarkBackground : baseColors.appbarDividerLightBackground
      }`,
      marginRight: 16,
    },
  })
)

export const StyledLeftContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 90%;
  padding-right: 8px;
  ${device.m} {
    max-width: 80%;
  }
`
export const StyledRightContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
`
export const StyledUsername = styled(Typography)`
  margin-right: 16px;
`
export const StyledLogoWrapper = styled.div`
  padding: 8px 40px;
`
export const StyledToolbar = styled(Toolbar)`
  justify-content: space-between;
  padding: 0 !important;
`
