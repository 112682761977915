import { FilterSet } from '../../global/components/favoriteFiltersState'
import { GlobalState } from '../../global/InitialGlobalState'
import { Filter } from '../../constants/filters/filterTypes'
import { setGlobal } from '../../global/useGlobal'

export const applyFilterSet = (
  filterSet: FilterSet,
  appliedFiltersGlobalKey: keyof GlobalState,
  selectedFiltersGlobalKey: keyof GlobalState
) => {
  let schema: Filter[] = [...filterSet.filters]
  setGlobal({
    [appliedFiltersGlobalKey]: schema,
    [selectedFiltersGlobalKey]: schema,
  })
}
