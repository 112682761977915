export const mockCreateUpdateApprovalInstructions = async () => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const assets = {
        count: 2,
        approvalInstructions: [
          {
            feedback: null,
            externalId: null,
            requestType: 'CarryingPosition',
            instrumentSymbol: 'PETR4',
            quantity: 100,
            side: 'Lender',
            issuerAccountCode: '98018',
            negotiationFee: null,
            commissionFee: null,
            interBrokerFee: null,
            lenderReversible: true,
            tenderOfferLenderReversible: true,
            lockUpDate: '2020-08-12T00:00:00Z',
            expireDate: '2020-09-21T00:00:00Z',
            issuerCustodianCode: '1026',
            issuerCustodyAccountCode: null,
            issuerPortfolio: '21016',
            referenceDate: '2020-08-11T00:00:00Z',
            targetBrokerCode: null,
            internalStatus: 'Pending',
            deskId: null,
            memo: null,
          },
          {
            feedback: null,
            externalId: null,
            requestType: 'CarryingPosition',
            instrumentSymbol: 'VALE3',
            quantity: 100,
            side: 'Lender',
            issuerAccountCode: '98018',
            negotiationFee: null,
            commissionFee: null,
            interBrokerFee: null,
            lenderReversible: true,
            tenderOfferLenderReversible: true,
            lockUpDate: '2020-08-12T00:00:00Z',
            expireDate: '2020-09-21T00:00:00Z',
            issuerCustodianCode: '1026',
            issuerCustodyAccountCode: null,
            issuerPortfolio: '21016',
            referenceDate: '2020-08-11T00:00:00Z',
            targetBrokerCode: null,
            internalStatus: 'Pending',
            deskId: null,
            memo: null,
          },
        ],
      }
      resolve(assets)
    }, 1000)
  })
}
