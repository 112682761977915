import { FeatureManager } from '../../FeatureManager'
import { setGlobal } from '../../../global/useGlobal'
import { getGlobal } from 'reactn'
import { numberOfPagesCalculator } from '../../../utils/numberOfPagesCalculator'
import { mapDTOResponseToTableData } from '../../../utils/mapDTOResponseToTableData'
import { isActiveFilter } from '../../../utils/isActiveFilter'
import { INITIAL_PAGE_NUMBER, PAGE_SIZE } from '../../../constants/pagination'
import { Filter } from '../../../constants/filters/filterTypes'
import { formatFilter } from '../../../utils/formatFilter'
import { formatDateFilter } from '../../../utils/formatDateFilter'
import { approvalInstructionsRequest } from './request'
import { mockApprovalInstructionsRequest } from './mockEndpoint'
import { ApprovalInstructionsFieldsDTO } from './interfaces/approvalInstructionsFieldsDTO'
import { ApprovalInstructionsFilterNames } from '../../../constants/filters/approvalInstructionsFilters'
import { displayError } from '../../../utils/displayError'

export const getApprovalInstructions = async (page?: number, allData?: boolean): Promise<any> => {
  await setGlobal({
    isDataLoading: true,
  })

  try {
    const appliedFilters = getGlobal().approvalInstructionsAppliedFilters
    const appliedSorting = getGlobal().approvalInstructionsAppliedSorting
    const oldData = getGlobal().approvalInstructionsData
    const filters = mountFilters(appliedFilters, page, appliedSorting, allData)
    const response = FeatureManager.shouldMockApprovalInstructions
      ? await mockApprovalInstructionsRequest()
      : await approvalInstructionsRequest(filters)
    const apiDataApprovalInstructionsResponse = mapDTOResponseToTableData(response)
    const approvalInstructionsPaginationArgs = mapDTOResponseToPaginationSummary(response)
    let approvalInstructionsData: any[]
    if (page) {
      approvalInstructionsData = oldData.concat(apiDataApprovalInstructionsResponse)
    } else {
      approvalInstructionsData = apiDataApprovalInstructionsResponse
    }
    await setGlobal({
      approvalInstructionsData,
      approvalInstructionsPaginationArgs,
      isDataLoading: false,
    })
  } catch (error) {
    await setGlobal({
      isDataLoading: false,
    })
    displayError(error.error)
  }
}

const mountFilters = (filters: Filter[], page?: number, sorting?: string[], allData?: boolean) => {
  const activeFilters = isActiveFilter(filters)
  const filtersObject = {
    Id: formatFilter(activeFilters, ApprovalInstructionsFilterNames.ID),
    RequestType: formatFilter(activeFilters, ApprovalInstructionsFilterNames.RequestType),
    AccountCode: formatFilter(activeFilters, ApprovalInstructionsFilterNames.AccountCode),
    InstrumentSymbol: formatFilter(activeFilters, ApprovalInstructionsFilterNames.InstrumentSymbol),
    InstructionStatuses: formatFilter(activeFilters, ApprovalInstructionsFilterNames.InstructionStatuses),
    OrderSide: formatFilter(activeFilters, ApprovalInstructionsFilterNames.OrderSide),
    MinReferenceDate: formatDateFilter(activeFilters, ApprovalInstructionsFilterNames.MinReferenceDate),
    MaxReferenceDate: formatDateFilter(activeFilters, ApprovalInstructionsFilterNames.MaxReferenceDate),
    MinCreationDate: formatDateFilter(activeFilters, ApprovalInstructionsFilterNames.MinCreationDate),
    MaxCreationDate: formatDateFilter(activeFilters, ApprovalInstructionsFilterNames.MaxCreationDate),
    PageSize: allData ? -1 : PAGE_SIZE,
    PageNum: allData ? -1 : page || INITIAL_PAGE_NUMBER,
    RequireAllData: !!allData,
    Sorting: sorting?.join(',') || undefined,
  }
  return JSON.parse(JSON.stringify(filtersObject))
}

const mapDTOResponseToPaginationSummary = (dto: ApprovalInstructionsFieldsDTO) => {
  return {
    pageNum: dto.paginationSummary.pageNum,
    pageSize: dto.paginationSummary.pageSize,
    sorting: dto.paginationSummary.sorting,
    count: dto.paginationSummary.count,
    totalCount: dto.paginationSummary.totalCount,
    numberOfPages: numberOfPagesCalculator(dto.paginationSummary.pageSize, dto.paginationSummary.totalCount),
  }
}
