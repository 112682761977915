import { ResetPasswordEndpoint } from './endpoint'
import { HttpMethods } from '../../apiService/baseEndpoint'

interface IResetPasswordParams {
  username: string
  token: string
  password: string
}

export const resetPasswordRequest = async (payload: IResetPasswordParams) => {
  const endpoint = new ResetPasswordEndpoint<undefined, IResetPasswordParams>(
    'register-new-password',
    HttpMethods.POST,
    undefined,
    payload
  )

  return await endpoint.execute()
}
