import { SettlementRequestsSchema } from './interfaces'

export const settlementRequestsSchema: SettlementRequestsSchema = {
  checkbox: {
    title: '',
    type: 'checkbox',
    tooltipName: '',
    index: 0,
  },
  id: {
    title: 'ID',
    type: 'text',
    tooltipName: 'ID',
    index: 1,
  },
  exchangeTransactionId: {
    title: 'Exchange Transaction ID',
    type: 'text',
    tooltipName: 'Exchange Transaction ID',
    index: 2,
  },
  originTransactionId: {
    title: 'Origin Transaction ID',
    type: 'text',
    tooltipName: 'Origin Transaction ID',
    index: 3,
  },
  contractCode: {
    title: 'Contract Code',
    type: 'text',
    tooltipName: 'Contract Code',
    index: 4,
  },
  instrumentSymbol: {
    title: 'Symbol',
    type: 'text',
    tooltipName: 'Instrument Symbol',
    index: 5,
  },
  effectiveQuantity: {
    title: 'Effec. Qty',
    type: 'text',
    tooltipName: 'Effective Quantity',
    index: 6,
  },
  quantity: {
    title: 'Qty',
    type: 'text',
    tooltipName: 'Quantity',
    index: 7,
  },
  side: {
    title: 'Side',
    type: 'text',
    tooltipName: 'Side',
    index: 8,
  },
  issuerExecutionBrokerCode: {
    title: 'Exec. Broker Code',
    type: 'text',
    tooltipName: 'Execution Broker Code',
    index: 9,
  },
  issuerAccountCode: {
    title: 'Account',
    type: 'text',
    tooltipName: 'Issuer Account Code',
    index: 10,
  },
  issuerAliasOrName: {
    title: 'Name',
    type: 'text',
    tooltipName: 'Issuer Alias or Name',
    index: 11,
  },
  originType: {
    title: 'Origin Type',
    type: 'text',
    tooltipName: 'Origin Type',
    index: 12,
  },
  creationDate: {
    title: 'Creation Date',
    type: 'text',
    tooltipName: 'Creation Date',
    index: 13,
  },
  lastUpdatetime: {
    title: 'Last Update Time',
    type: 'text',
    tooltipName: 'Last Update Time',
    index: 14,
  },
  externalStatus: {
    title: 'External Status',
    type: 'text',
    tooltipName: 'External Status',
    index: 15,
  },
  internalStatus: {
    title: 'Status',
    type: 'text',
    tooltipName: 'Status',
    index: 16,
  },
  nextPendingApproval: {
    title: 'Next Pend. Appr.',
    type: 'text',
    tooltipName: 'Next Pending Approval',
    index: 17,
  },
  nextPendingApprovingParticipant: {
    title: 'Next Pend. Appr. Part.',
    type: 'text',
    tooltipName: 'Next Pending Approving Participant',
    index: 18,
  },
  errorRuleIds: {
    title: 'Error Rule Ids',
    type: 'text',
    tooltipName: 'Error Rule Ids',
    index: 19,
  },
}
