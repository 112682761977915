import styled from 'styled-components'
import { colors } from '../../styles/themes/light'
import Typography from '@material-ui/core/Typography'

export const StyledSelectOption = styled.div<any>`
  padding: 8px;
  background-color: ${({ isFocused }) => (isFocused ? colors.gray : colors.surface)};
  :hover {
    background-color: ${colors.grayBackground};
  }
  display: flex;
  color: ${colors.black};
`

export const StyledLabel = styled(Typography)`
  && {
    font-weight: bold;
  }
`

export const StyledContent = styled(Typography)`
  && {
    margin-left: 16px;
  }
`
