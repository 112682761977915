import { FeatureManager } from '../../FeatureManager'
import { CheckTokenResponse } from './checkTokenResponse'

export const mockCheckToken = async (success: boolean): Promise<CheckTokenResponse> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (FeatureManager.shouldMockRefreshedTokenResponse) {
        resolve({
          created: '2020-08-21T16:31:38.1451395Z',
          expired: '2020-08-21T16:32:38.1446165Z',
          tokenCode:
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyTmFtZSI6InJhZmFlbC5jYXJtb0BidGdwYWN0dWFsLmNvbSIsInRva2VuQ3JlYXRlZCI6IjgvMjEvMjAyMCA0OjMxOjM4IFBNIiwibWFpbCI6InJhZmFlbC5jYXJtb0BidGdwYWN0dWFsLmNvbSIsInByb2ZpbGVzQ2xhaW0iOiJNYXJrZXRXYXRjaHxDYXJyeWluZ3xCVEd8T3JkZXJzRW50cnl8Qm9va09yZGVyfFRpbWVzQW5kU2FsZXN8TWFya2V0TmV3cyIsImV4cCI6MTYxODAyNzU1OCwiaXNzIjoiQlRHU29sdXRpb25zIiwiYXVkIjoiZmU4MDo6YTkxZTo5NTZiOjY4YjQ6NGIzNyUxMyJ9.FR5nxs6KOFAC9nNILufArZdko3q4cknv9xYlOmdh39M',
          logonExpired: 99,
          profile: ['MarketWatch', 'Carrying', 'BTG', 'OrdersEntry', 'BookOrder', 'TimesAndSales', 'MarketNews'],
        })
      } else {
        success
          ? resolve({
              messageCode: 200,
              message: 'OK.',
            })
          : resolve({
              messageCode: 16,
              message: 'Invalid Token.',
            })
      }
    }, 1000)
  })
}
