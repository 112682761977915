import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { colors } from '../../styles/themes/light'

export const WorkspaceWrapperUseStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      paddingTop: 76,
      '& .jexcel > tbody > tr': {
        '& > td': {
          backgroundColor: colors.white,
        },
        '&:nth-child(2n) > td': {
          backgroundColor: colors.gray,
        },
      },
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
    contentToolbar: {
      minHeight: 80,
    },
    content: {
      margin: '55px 0 0 56px',
      width: 'calc(100% - 56px)',
      height: 'calc(100% - 56px)',
      position: 'fixed',
      overflow: 'hidden',
      backgroundColor: colors.grayBackground,
    },
  })
)
