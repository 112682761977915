import { Filter } from '../constants/filters/filterTypes'

export const formatFilter = (filters: Filter[] | undefined, filterName: string) => {
  if (!filters) {
    return
  }
  const filtersByName = filters.filter(({ name }) => name === filterName)
  if (!filtersByName.length) {
    return
  }
  const filterValues = filtersByName.map((filter) => filter.value)
  return filterValues.join(',').replace(' ', '')
}
