import { getGlobal } from 'reactn'
import { request } from './fetcher'

export interface Target {
  method: HttpMethods
  url: string
  path: string
  auth: boolean
}

export enum HttpMethods {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export class BaseEndpoint<Body = undefined, Params = undefined> {
  protected path: string
  protected url: string
  protected auth: boolean
  protected params?: Params
  protected body?: Body
  private data: RequestInit

  constructor(config: Target) {
    this.path = config.path
    this.url = config.url
    this.auth = config.auth
    this.data = {
      method: config.method,
    }
  }

  public async execute(): Promise<any> {
    if (this.body) {
      this.setBody()
    }
    await this.setHeaders()
    return request(this.mountUrl(), this.data)
  }

  private async setHeaders(): Promise<void> {
    const headers = new Headers()
    headers.append('Content-Type', 'application/json')
    if (this.auth) {
      const token = getGlobal().accessToken
      headers.append('Authorization', `Bearer ${token}`)
    }
    this.data.headers = headers
    return
  }

  private async setBody(): Promise<void> {
    this.data.body = JSON.stringify(this.body)
    return
  }

  protected mountUrl(): string {
    let partialUrl = `${this.url}/${this.path}`
    if (!this.params) {
      return partialUrl
    } else {
      partialUrl += '?'
      for (const key in this.params) {
        // @ts-ignore
        if (this.params.hasOwnProperty(key)) {
          // @ts-ignore
          partialUrl += `${key}=${encodeURIComponent(this.params[key])}&`
        }
      }
      return partialUrl
    }
  }
}
