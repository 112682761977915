import React, { useEffect, useMemo, useRef, useState } from 'react'
import jexcel from 'jexcel'
import '../jexcel-styles.css'
import { Options } from '../jexcel-interfaces'
import { TableContainer } from './style'
import { getGlobal, setGlobal, useGlobal } from '../../global/useGlobal'
import { handleColumnReorder } from '../../utils/handleColumnReorder'
import { ITable } from './interfaces'
import TableFooter from '../TableFooter'
import { strings } from '../../resources/strings/strings'
import ContextMenu from '../CustomContextMenu'
import { useLegacyState } from '../../utils/hooks/useLegacyState'
import { IContextMenuState } from '../CustomContextMenu/interfaces'
import { onSortData } from '../../utils/onSortData'
import { handleTableHeaderContextMenu } from '../../utils/handleTableContextMenu'
import { useWindowSize } from '../../utils/hooks/useWindowSize'
import LinearLoading from '../LinearProgress'
import { AIRequestTypes } from '../../constants/approvalInstructions/requestType'
import { setOrderSideColumnStyle } from '../../utils/jexcelMethods/setOrderSideColumnStyle'
import { saveToLocalStorage } from '../../utils/localStorageManager'
import { buildTableSchema } from '../../utils/schemaBuilder'
import { getColumnWidths } from '../../utils/getColumnWidths'
import { useKeyPress } from '../../utils/hooks/useKeyPress'
import { useLocation } from 'react-router'
import { getGlobalKeys } from '../../utils/getGlobalKeys'
import { Hotkeys } from '../../constants/hotkeys'
import { sideColumnAutoComplete } from '../../utils/sideColumnAutoComplete'
import { treatValueInBooleanColumn } from '../../utils/treatValueInBooleanColumn'
import { treatValueInDateColumn } from '../../utils/treatValueInDateColumn'
import { openSnackBar } from '../../services/pageStateService'
import { addFilterHandler } from '../../utils/filterHandlers/addFilterHandler'
import { ColumnNames } from '../../constants/columnNames'
import { onSymbolInput } from '../../utils/onSymbolInput'
import { setValueFromCoords } from '../../utils/jexcelMethods/setValueFromCoords'
import { enumColumnAutoComplete } from '../../utils/enumColumnAutoComplete'
import { stockLoanApprovalInstructionRequestType } from '../../constants/approvalInstructions/StockLoanApprovalInstructionsRequestType'
import { getColumnContentFromPaste } from '../../utils/getColumnContentFromPaste'
import { onAccountInput } from '../../utils/onAccountInput'

const Table: React.FC<ITable> = ({
  hasContextMenu,
  tableColumnsGlobalKey,
  tableDataSchema,
  sorting,
  setSorting,
  tableStyle,
  dataGlobalKey,
  paginationOptions,
  containerHeight,
  tableHeight,
  hasPagination,
  downloadFileName,
  hasCheckboxColumn,
  isLoading,
  hasFooter,
  hasTransparentBackground,
  minDimensions,
  hasInsertRow,
  hasDeleteRow,
  persistColumnOrder,
  isEditable,
  onBeforePaste,
  onEditionEnd,
  columnWidthsGlobalKey,
  tableRefGlobalKey,
  shouldBlockColumnDrag,
  customOnLoad,
  autoCompleteColumns,
  autofillColumns,
  autocompleteCellsEditedByUser,
  onEditionStart,
}) => {
  const { pathname } = useLocation()

  const { filterSchema, selectedFiltersGlobalKey, appliedFiltersGlobalKey } = getGlobalKeys(pathname)

  const filterColumnReference = filterSchema.filter((filter) => !!filter.columnReference)

  const tableRef = useRef<{ jexcel: Options } & HTMLDivElement>(null)
  const [tableColumns] = useGlobal(tableColumnsGlobalKey)
  const [globalTableRef, setGlobalTableRef] = useGlobal(tableRefGlobalKey)
  const [columnWidths, setColumnWidths] = useGlobal(columnWidthsGlobalKey)
  const [selectedFilters, setSelectedFilters] = useGlobal(selectedFiltersGlobalKey)
  const [appliedFilters, setAppliedFilters] = useGlobal(appliedFiltersGlobalKey)
  const tableColumnsRef = useRef(tableColumns)
  const [data] = useGlobal(dataGlobalKey)
  const checkboxChecked = useRef<boolean>(false)
  const checkboxPositionRef = useRef<number>(tableColumns.indexOf('checkbox'))
  const hasRenderedTableRef = useRef<boolean>(false)
  const [checkboxPosition, setCheckboxPosition] = useState(checkboxPositionRef.current)
  const { windowHeight } = useWindowSize()
  const [contextMenuState, setContextMenuState] = useLegacyState<IContextMenuState>({
    top: 0,
    left: 0,
    visible: false,
    column: null,
    row: null,
  })

  let rowsNumber: number = 0
  const FIRST_CELL_POSITION = 0

  const onSelection = (tableEl: any, initialX: number, initialY: number, endX: number, endY: number) => {
    // this line get the correct index o axis Y when click in headers
    const axisY = initialY === FIRST_CELL_POSITION && initialX > FIRST_CELL_POSITION ? initialY : endY
    const axisX = initialX + 1
    const lastSelectedCell = tableEl?.children[1]?.children[0]?.children[2]?.children[axisY]?.children[axisX]
    const verticalPosition = Number(lastSelectedCell?.getAttribute('data-y'))
    const block = getScrollBehavior(verticalPosition)
    const inline = getScrollBehavior(endX)

    lastSelectedCell?.scrollIntoView({ block, inline, behavior: 'auto' })
  }

  const getScrollBehavior = (position: number) => (position === FIRST_CELL_POSITION ? 'end' : 'nearest')

  const dismissContextMenu = () => setContextMenuState({ visible: false })

  const onSortAsc = () => {
    if (sorting && setSorting) {
      if (contextMenuState.column !== null) {
        const newSorting = onSortData(true, tableColumns[contextMenuState.column], sorting)
        setSorting(newSorting)
      }
    }
  }

  const onSortDesc = () => {
    if (sorting && setSorting) {
      if (contextMenuState.column !== null) {
        const newSorting = onSortData(false, tableColumns[contextMenuState.column], sorting)
        setSorting(newSorting)
      }
    }
  }

  const headersElement = tableRef?.current?.children[1]?.children[0]?.children[1]?.children[0]?.children

  const undoSorting = () => {
    if (sorting && setSorting && contextMenuState.column !== null) {
      const newSorting = onSortData(false, tableColumns[contextMenuState.column], sorting, true)
      setSorting([...newSorting])
      if (headersElement && headersElement[contextMenuState.column]) {
        headersElement[contextMenuState.column + 1].classList.remove('arrow-up', 'arrow-down')
      }
    }
  }

  const contextActions = [
    {
      label: strings.general.sortAsc,
      onClick: onSortAsc,
    },
    {
      label: strings.general.sortDesc,
      onClick: onSortDesc,
    },
    {
      label: strings.general.undoSorting,
      onClick: undoSorting,
    },
  ]

  const handleContextMenu = handleTableHeaderContextMenu(setContextMenuState, checkboxPositionRef)

  useEffect(() => {
    jexcel(tableRef.current, tableOptions)
    setGlobalTableRef(tableRef)
  }, [])

  const tableSchema = useMemo(() => {
    return buildTableSchema(tableColumns, tableDataSchema, isEditable)
  }, [tableColumns, tableDataSchema, isEditable])

  const indexOfSideColumn = useMemo(() => {
    return tableSchema.findIndex((column) => String(column.title)?.toLowerCase().includes('side'))
  }, [tableSchema])

  useEffect(() => {
    tableRef?.current?.jexcel.setData!(data)
    if (indexOfSideColumn >= 0) {
      setOrderSideColumnStyle(tableRef, indexOfSideColumn)
    }
  }, [data])

  const jexcelEl = tableRef?.current?.children[1]?.children[0]
  const hasRows = jexcelEl?.children[2]?.children && !!Array.from(jexcelEl?.children[2]?.children).length
  const tableHeaderEl = jexcelEl?.children[1]?.children[0]
  // tableHeaderEl.children is the HTMLCollection that has every column header element
  const tableRowsLength = jexcelEl?.children[2]?.children.length
  const tableRowsEl = useMemo(() => {
    return jexcelEl?.querySelectorAll('tbody tr')
  }, [tableRowsLength])

  const handleSpace = () => {
    const selectedRows = tableRef?.current?.jexcel?.getSelectedRows!(true)
    const selectedColumns = tableRef?.current?.jexcel?.getSelectedColumns!()
    const isSelectingCheckbox = selectedColumns.includes(checkboxPositionRef.current)
    if (hasCheckboxColumn && !isSelectingCheckbox) {
      selectedRows.forEach((row: number) => {
        const checkboxValue = tableRef?.current?.jexcel?.getValueFromCoords!(checkboxPositionRef.current, row)
        tableRef?.current?.jexcel?.setValueFromCoords!(checkboxPositionRef.current, row, !checkboxValue)
      })
    }
  }

  useKeyPress(Hotkeys.ToggleCheckboxRow, handleSpace)

  useEffect(() => {
    tableRowsEl?.forEach((row) => {
      // Table elements doesn't have key events because they're not focusable. In order to make it work, we need to give it a tabindex attribute so it becames focusable and can interpret keyboard events properly.
      row.setAttribute('tabindex', '0')
    })
  }, [tableRowsEl])

  useEffect(() => {
    if (tableSchema) {
      let headers = tableSchema
      headers.forEach((item, index) => {
        if (tableHeaderEl && item.name && item.type !== 'checkbox') {
          // index + 1 is due to 'select all' column
          tableHeaderEl.children[index + 1].innerHTML = item.name as string
        }
      })
    }
  }, [tableHeaderEl])

  useEffect(() => {
    if (isEditable) {
      // Editable tables usually don't have content so we don't need to auto-fit
      jexcelEl?.classList.add('fixedLayout')
    }
    if (hasRows) {
      if (columnWidths) {
        // if the table has custom column widths, use these values to set layout
        jexcelEl?.classList.add('fixedLayout')
        columnWidths.forEach((width: number, index: number) => {
          tableRef?.current?.jexcel?.setWidth!(index, width)
        })
      } else {
        // if the table does not have custom column widths, get width values from auto-fit
        const newWidths = getColumnWidths(tableHeaderEl, true)
        jexcelEl?.classList.add('fixedLayout')
        newWidths?.forEach((width, index) => {
          tableRef?.current?.jexcel?.setWidth!(index, width)
        })
      }
      hasRenderedTableRef.current = true
    }
  }, [hasRows])

  useEffect(() => {
    rowsNumber = tableRef?.current?.jexcel?.rows?.length
  }, [rowsNumber])

  useEffect(() => {
    if (hasCheckboxColumn) {
      ;(window as any).checkAll = () => {
        checkboxChecked.current = !checkboxChecked.current

        tableRef?.current?.jexcel.rows?.forEach((row: HTMLDivElement, index: number) => {
          tableRef?.current?.jexcel.setValueFromCoords!(checkboxPosition, index, checkboxChecked.current)
        })
      }
    }
  }, [checkboxChecked.current, checkboxPosition])

  const fillDefaultColumns = () => {
    if (autofillColumns) {
      autofillColumns.forEach((col) => {
        if (col.content) {
          tableRef?.current?.jexcel.rows?.forEach((row: HTMLDivElement, index: number) => {
            tableRef?.current?.jexcel.setValueFromCoords!(col.index, index, col.content, true)
          })
        }
      })
    }
  }

  const onLoad = (el: any, table: any) => {
    if (customOnLoad) {
      customOnLoad()
    }
    if (hasCheckboxColumn) {
      table.headers[checkboxPosition].innerHTML = `<input 
        type="checkbox" 
        onchange="checkAll()" 
        ${checkboxChecked.current && 'checked="true"'} 
      />`
    }
    fillDefaultColumns()
  }

  const handleColumnDrag = (tableElement: any, oldIndex: number, newIndex: number) => {
    const reorderedColumns = handleColumnReorder(oldIndex, newIndex, tableColumnsRef.current)
    setGlobal({
      [tableColumnsGlobalKey]: reorderedColumns,
    })
    if (persistColumnOrder) {
      saveToLocalStorage(reorderedColumns, tableColumnsGlobalKey as string)
    }
    tableColumnsRef.current = reorderedColumns

    if (hasCheckboxColumn) {
      const checkboxInput = checkboxChecked.current
        ? '<input type="checkbox" onchange="checkAll()" checked="true" />'
        : '<input type="checkbox" onchange="checkAll()" />'
      if (oldIndex === checkboxPositionRef.current) {
        setCheckboxPosition(newIndex)
        checkboxPositionRef.current = newIndex
        const headerElement = tableElement
          ?.getElementsByClassName('jexcel_content')[0]
          ?.children[0]?.children[1]?.children[0]?.getElementsByTagName('*')[reorderedColumns.indexOf('checkbox') + 1]
        if (headerElement) {
          headerElement.innerHTML = checkboxInput
        }
      } else if (newIndex <= checkboxPositionRef.current && oldIndex > checkboxPositionRef.current) {
        // This is a little confusing at first but what's happening is that the user is dragging one column
        // to somewhere before current checkbox column, pushing it one column to the right
        const checkboxIndex = checkboxPositionRef.current + 1
        setCheckboxPosition(checkboxIndex)
        checkboxPositionRef.current = checkboxIndex
        const headerElement = tableElement
          ?.getElementsByClassName('jexcel_content')[0]
          ?.children[0]?.children[1]?.children[0]?.getElementsByTagName('*')[checkboxIndex + 1]
        if (headerElement) {
          headerElement.innerHTML = checkboxInput
        }
      }
    }
  }

  const onResizeColumn = () => {
    if (hasRenderedTableRef.current) {
      const newColumnWidths = getColumnWidths(tableRef?.current?.children[1]?.children[0]?.children[1]?.children[0])
      if (newColumnWidths) {
        setColumnWidths(newColumnWidths)
        saveToLocalStorage(newColumnWidths, columnWidthsGlobalKey as string)
      }
    }
  }

  const trackedColumns = [
    { side: ColumnNames.Side },
    { lender: ColumnNames.LenderReversible },
    { tender: ColumnNames.TenderOfferLenderReversible },
    { symbol: ColumnNames.InstrumentSymbol },
    { lockUp: ColumnNames.LockUpDate },
    { expire: ColumnNames.ExpireDate },
    { reference: ColumnNames.ReferenceDate },
    { requestType: ColumnNames.RequestType },
    { account: ColumnNames.IssuerAccountCode },
  ]

  const trackedColumnsObj = Object.assign({}, ...trackedColumns)
  const trackedColumnsIndexes: { [key: string]: number } = Object.keys(trackedColumnsObj).reduce((acc, column) => {
    ;(acc as any)[column] = tableColumns.indexOf(trackedColumnsObj[column])
    return acc
  }, {})

  const {
    reference: referenceIndex,
    side: sideIndex,
    lender: lenderIndex,
    lockUp: lockUpIndex,
    tender: tenderIndex,
    symbol: symbolIndex,
    expire: expireIndex,
    requestType: requestTypeIndex,
    account: accountIndex,
  } = trackedColumnsIndexes

  const defaultDates = getGlobal().defaultDates

  const onEdit = (tableElement: any, cellElement: any, columnIndex: number, rowIndex: number, cellContent: string) => {
    if (!cellContent) return

    if (columnIndex === requestTypeIndex) {
      enumColumnAutoComplete({
        tableRef,
        content: cellContent,
        rowIndex,
        columnIndex,
        options: stockLoanApprovalInstructionRequestType,
      })
    }
    if (columnIndex === sideIndex) {
      sideColumnAutoComplete({
        tableRef,
        content: cellContent,
        rowIndex,
        columnIndex,
      })
    }
    if ([lenderIndex, tenderIndex].includes(columnIndex)) {
      treatValueInBooleanColumn({
        tableRef,
        content: cellContent,
        rowIndex,
        columnIndex,
      })
    }
    if ([lockUpIndex, expireIndex, referenceIndex].includes(columnIndex)) {
      treatValueInDateColumn({
        tableRef,
        content: cellContent,
        rowIndex,
        columnIndex,
      })
    }
    if (columnIndex === symbolIndex) {
      const instruments = getGlobal().instruments
      setValueFromCoords(tableRef, columnIndex, rowIndex, cellContent.toUpperCase())
      onSymbolInput({
        tableRef,
        content: cellContent.toUpperCase(),
        rowIndex,
        columnIndex,
        instruments,
        defaultDates,
        autoCompleteColumns,
        cellsEditedByUser: autocompleteCellsEditedByUser,
      })
    }
    if (columnIndex === accountIndex) {
      const accounts = getGlobal().accounts
      onAccountInput({
        tableRef,
        content: cellContent.toUpperCase(),
        rowIndex,
        columnIndex,
        accounts,
        autoCompleteColumns,
      })
    }

    onEditionEnd && onEditionEnd(tableElement, cellElement, columnIndex, rowIndex, cellContent)
  }

  const onPaste = (tableEl: Element, pastedContent: string[][]) => {
    const selectedColumns: number[] = tableRef?.current?.jexcel?.getSelectedColumns!()
    const firstSelectedRow: number = tableRef?.current?.jexcel?.getSelectedRows!(true)[0]
    const isSomeTrackedColumnSelected = selectedColumns.some((index: number) =>
      Object.values(trackedColumnsIndexes).includes(index)
    )
    if (!isSomeTrackedColumnSelected) return

    if (selectedColumns.includes(sideIndex)) {
      const sideColumnData = getColumnContentFromPaste(selectedColumns, sideIndex, pastedContent)
      sideColumnData.forEach((content: string, index: number) => {
        if (!content) return
        const rowIndex = index + firstSelectedRow
        sideColumnAutoComplete({ tableRef, content, rowIndex, columnIndex: sideIndex })
      })
    }

    if (selectedColumns.includes(requestTypeIndex)) {
      const requestTypeColumnData = getColumnContentFromPaste(selectedColumns, requestTypeIndex, pastedContent)
      requestTypeColumnData.forEach((content: string, index: number) => {
        if (!content) return
        const rowIndex = index + firstSelectedRow
        enumColumnAutoComplete({
          tableRef,
          content,
          rowIndex,
          columnIndex: requestTypeIndex,
          options: stockLoanApprovalInstructionRequestType,
        })
      })
    }

    ;[lenderIndex, tenderIndex].forEach((columnIndex) => {
      if (selectedColumns.includes(columnIndex)) {
        const columnData = tableRef?.current?.jexcel.getColumnData!(columnIndex)
        columnData.forEach((content: string, rowIndex: number) => {
          if (!content) return
          treatValueInBooleanColumn({
            tableRef,
            content,
            rowIndex,
            columnIndex,
          })
        })
      }
    })
    ;[lockUpIndex, expireIndex, referenceIndex].forEach((columnIndex) => {
      if (selectedColumns.includes(columnIndex)) {
        const columnData = tableRef?.current?.jexcel.getColumnData!(columnIndex)
        columnData.forEach((content: string, rowIndex: number) => {
          if (!content) return
          treatValueInDateColumn({
            tableRef,
            content,
            rowIndex,
            columnIndex,
          })
        })
      }
    })

    if (selectedColumns.includes(symbolIndex)) {
      const instruments = getGlobal().instruments
      const columnData = tableRef?.current?.jexcel.getColumnData!(symbolIndex)
      columnData.forEach((content: string, rowIndex: number) => {
        if (!content) return
        setValueFromCoords(tableRef, symbolIndex, rowIndex, content.toUpperCase())
        onSymbolInput({
          tableRef,
          content,
          rowIndex,
          columnIndex: symbolIndex,
          instruments,
          defaultDates,
          autoCompleteColumns,
          cellsEditedByUser: autocompleteCellsEditedByUser,
        })
      })
    }

    if (selectedColumns.includes(accountIndex)) {
      const accounts = getGlobal().accounts
      const columnData = tableRef?.current?.jexcel.getColumnData!(accountIndex)
      columnData.forEach((content: string, rowIndex: number) => {
        if (!content) return
        onAccountInput({
          tableRef,
          content,
          rowIndex,
          columnIndex: accountIndex,
          accounts,
          autoCompleteColumns,
        })
      })
    }
  }

  const tableOptions: Options = {
    defaultColWidth: 100,
    columnDrag: !shouldBlockColumnDrag,
    rowDrag: false,
    columns: tableSchema,
    minDimensions: minDimensions || [0, 0],
    onmovecolumn: handleColumnDrag,
    allowInsertColumn: false,
    allowDeleteColumn: false,
    allowRenameColumn: false,
    allowInsertRow: hasInsertRow || false,
    allowDeleteRow: hasDeleteRow || false,
    csvFileName: downloadFileName || 'table',
    data: data,
    contextMenu: hasInsertRow || hasDeleteRow ? undefined : handleContextMenu,
    onload: onLoad,
    oninsertrow: fillDefaultColumns,
    oneditionend: onEdit,
    onbeforepaste: onBeforePaste,
    onresizecolumn: onResizeColumn,
    onselection: onSelection,
    onpaste: onPaste,
    oneditionstart: onEditionStart,
  }

  const result = `${data?.length || 0}/${paginationOptions?.totalCount || 0}`

  const defaultContainerHeight = windowHeight
  const defaultTableHeight = windowHeight - 160 //TODO: 160 is the header size, we need to get this value dynamically

  const applyFiltersFromFocusedContent = () => {
    const focusedContent = tableRef?.current?.jexcel?.getData!(true)
    const focusedColumns = tableRef?.current?.jexcel?.getSelectedColumns!()
    if (focusedContent?.length === 1 && focusedColumns?.length === 1) {
      const columnIndex = focusedColumns[0]
      const content = focusedContent[0][0]
      const schema = filterColumnReference.find(
        ({ columnReference }) => columnReference?.toLowerCase() === tableColumns[columnIndex].toLowerCase()
      )
      if (!schema) return
      const { oldFilterIndex, newFilter, isAlreadyApplied, isFilterUnique, hasOldFilter, newState } = addFilterHandler({
        schema,
        newValue: content,
        filterStateKey: appliedFiltersGlobalKey,
      })

      if (isAlreadyApplied) {
        openSnackBar(strings.alerts.appliedFilterDuplicate, true)
        return
      }

      if (isFilterUnique && hasOldFilter) {
        newState.splice(oldFilterIndex, 1, newFilter)
        setSelectedFilters([...newState])
        setAppliedFilters([...newState])
        return
      }
      newState.push(newFilter)
      setSelectedFilters([...newState])
      setAppliedFilters([...newState])
    }
  }

  useKeyPress(Hotkeys.ApplySelectedFilter, applyFiltersFromFocusedContent)

  return (
    <>
      {hasContextMenu && contextMenuState.visible && (
        <ContextMenu
          dismiss={dismissContextMenu}
          actions={contextActions}
          top={contextMenuState.top}
          left={contextMenuState.left}
        />
      )}
      <TableContainer
        hasTransparentBackground={hasTransparentBackground}
        height={containerHeight || defaultContainerHeight}
      >
        {isLoading && <LinearLoading display={'flex'} />}
        {/*styled-components is not working here because it changes the div class everytime the height changes :(*/}
        <div
          style={
            tableStyle || {
              overflow: 'scroll',
              position: 'relative',
              width: '99%',
              height: tableHeight || defaultTableHeight,
              display: 'flex',
              margin: 'auto',
              borderRadius: 4,
            }
          }
          ref={tableRef as any}
        />
        {hasFooter && (
          <TableFooter
            label={strings.general.results}
            resultCount={result || '0'}
            width={tableRef?.current?.offsetWidth}
            firstText={strings.general.loadMore}
            secondText={strings.general.loadAll}
            loadMore={paginationOptions?.doLoadMore}
            loadAll={paginationOptions?.doLoadAll}
            isLoadingMore={paginationOptions?.isLoadingMore}
            isLoadingAll={paginationOptions?.isLoadingAll}
            hasPagination={hasPagination}
          />
        )}
      </TableContainer>
    </>
  )
}

export default Table
