import { setGlobal } from '../../global/useGlobal'
import { Filter } from '../../constants/filters/filterTypes'
import { GlobalState } from '../../global/InitialGlobalState'

export const applyFilters = (
  selectedFilters: Filter[],
  closeFilterModal: () => void,
  appliedFiltersGlobalKey: keyof GlobalState,
  selectedFiltersGlobalKey: keyof GlobalState
) => {
  const filters = JSON.parse(JSON.stringify(selectedFilters))
  setGlobal({ [appliedFiltersGlobalKey]: filters, [selectedFiltersGlobalKey]: filters })
  closeFilterModal()
}
