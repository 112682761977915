import React from 'react'
import { GlobalLoadingWrapper, LocalLoadingWrapper, StyledLoadMessage } from './style'
import { IGlobalLoadingView, ILocalLoadingView } from './interfaces'
import CircularProgress from '@material-ui/core/CircularProgress'
import { strings } from '../../resources/strings/strings'

export const GlobalLoadingView: React.FC<IGlobalLoadingView> = ({ props, isSyncing }) => {
  return (
    <GlobalLoadingWrapper>
      <CircularProgress variant="indeterminate" disableShrink={true} size={148} thickness={0.5} {...props} />
      <StyledLoadMessage align={'center'} color={'primary'}>
        {isSyncing && strings.general.syncingExtranet}
      </StyledLoadMessage>
    </GlobalLoadingWrapper>
  )
}

export const LocalLoadingView: React.FC<ILocalLoadingView> = ({ props, value, size, thickness }) => {
  return (
    <LocalLoadingWrapper>
      <CircularProgress
        variant="indeterminate"
        disableShrink={true}
        thickness={thickness}
        color={'inherit'}
        value={value}
        size={size}
        {...props}
      />
    </LocalLoadingWrapper>
  )
}
