import styled from 'styled-components'
import { MIN_MODAL_WIDTH } from '../../constants/dimensions'

export const ButtonsContainer = styled.div`
  width: ${MIN_MODAL_WIDTH / 2}px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 72px 0 16px auto;
  padding: 0 16px;
`
