import React from 'react'
import { Tooltip } from '@material-ui/core'
import SaveIcon from '@material-ui/icons/Save'
import StarIcon from '@material-ui/icons/Star'
import DeleteIcon from '@material-ui/icons/Delete'
import { ICustomButtonGroupView } from './interfaces'
import { StyledButton, StyledButtonGroup } from './style'

export const CustomButtonGroupView: React.FC<ICustomButtonGroupView> = ({
  leftButtonLabel,
  rightButtonLabel,
  onClickLeftButton,
  onClickRightButton,
}) => {
  return (
    <StyledButtonGroup color="default">
      <Tooltip disableFocusListener title={leftButtonLabel}>
        <StyledButton onClick={onClickLeftButton}>
          <StarIcon color="action" />
        </StyledButton>
      </Tooltip>
      <Tooltip disableFocusListener title={rightButtonLabel}>
        <StyledButton onClick={onClickRightButton}>
          <SaveIcon color="action" />
        </StyledButton>
      </Tooltip>
    </StyledButtonGroup>
  )
}
