import { displayError } from '../../utils/displayError'
import { DefaultDatesDTO } from './interfaces/defaultDatesDTO'
import { FeatureManager } from '../FeatureManager'
import { mockDefaultDatesRequest } from './mockEndpoint'
import { defaultDatesRequest } from './request'
import { setGlobal } from 'reactn'

export const getDefaultDates = async () => {
  try {
    const response: DefaultDatesDTO = FeatureManager.shouldMockDefaultDates
      ? await mockDefaultDatesRequest()
      : await defaultDatesRequest()

    const referenceDate = response.defaultDates.find((date) => {
      return date.dateType === 'ReferenceDate'
    })?.date

    const expireDate = response.defaultDates.find((date) => {
      return date.dateType === 'ExpireDate'
    })?.date

    const lockUpDate = response.defaultDates.find((date) => {
      return date.dateType === 'LockUpDate'
    })?.date

    const defaultDates = {
      referenceDate,
      expireDate,
      lockUpDate,
    }

    setGlobal({ defaultDates })
  } catch (error) {
    displayError(error.error)
  }
}
