import { useEffect } from 'react'

export const useKeyPress = (targetKey: string, onPress: () => void) => {
  const downHandler = (event: any) => {
    if (event.key === targetKey) {
      onPress()
      event.preventDefault()
    }
  }

  const upHandler = (event: any) => {
    if (event.key === targetKey) {
    }
  }

  // Add event listeners
  useEffect(() => {
    window.addEventListener('keydown', downHandler)
    window.addEventListener('keyup', upHandler)
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler)
      window.removeEventListener('keyup', upHandler)
    }
  }, [])
}
