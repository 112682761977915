import styled from 'styled-components'
import Tabs from '@material-ui/core/Tabs'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from '../../styles/themes/light'

export const useStyles = makeStyles({
  root: {
    background: colors.white,
    boxShadow: 'none',
    padding: '0 8px',
  },
})

export const FilterContainer = styled(Tabs)`
  display: flex;
  align-items: center;
  justify-content: initial;
  .MuiTabs-flexContainer {
    margin-top: 8px;
  }
  .MuiTabScrollButton-root.Mui-disabled {
    opacity: 0;
  }
`
