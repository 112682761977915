import { CreateSettlementRequestResponse } from './interfaces/createSettlementRequestResponse'
import { FeatureManager } from '../../FeatureManager'
import { mockSettleRequest } from './mockEndpoint'
import { postSettlementRequests } from './request'
import { CreateSettlementTableData, CreateSettlementRequestFields } from './interfaces/createSettlementRequestFields'
import { displayError } from '../../../utils/displayError'

export const createPostSettlementRequests = async (input: CreateSettlementTableData[]): Promise<any> => {
  try {
    const requestData = mapTableDataToRequest(input)
    const response: CreateSettlementRequestResponse = FeatureManager.shouldMockSettle
      ? await mockSettleRequest()
      : await postSettlementRequests({ legs: requestData })
    return response
  } catch (error) {
    displayError(error.error)
  }
}

const mapTableDataToRequest = (input: CreateSettlementTableData[]) => {
  const legs: CreateSettlementRequestFields[] = []
  input.forEach((row) => {
    let quantity = parseInt(row.quantity.replace(/,+/g, ''))

    const data = {
      contractLegCode: row.contractLegCode,
      quantity,
      side: row.side,
    }
    legs.push(data)
  })

  return legs
}
