const size = {
  xs: '320px',
  sm: '425px',
  m: '768px',
  l: '1024px',
  xl: '1440px',
  xxl: '2560px',
}

export const device = {
  xs: `@media (max-width: ${size.xs})`,
  s: `@media (max-width: ${size.sm})`,
  m: `@media (max-width: ${size.m})`,
  l: `@media (max-width: ${size.l})`,
  xl: `@media (max-width: ${size.xl})`,
  xxl: `@media (max-width: ${size.xxl})`,
}
