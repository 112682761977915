import { FilterPatterns } from '../../constants/filters/filterTypes'
import { SYMBOL_LENGTH } from '../../constants/dimensions'

export const getMinLengthFromPattern = (filterPattern: FilterPatterns) => {
  switch (filterPattern) {
    case FilterPatterns.Symbol:
      return SYMBOL_LENGTH
    default:
      return
  }
}
