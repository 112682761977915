import { RefObject } from 'react'
import { Options } from '../components/jexcel-interfaces'
import { setValueFromCoords } from './jexcelMethods/setValueFromCoords'
import { openSnackBar } from '../services/pageStateService'
import { strings } from '../resources/strings/strings'

const TRUE_INPUTS = ['yes', 'y', 'ye', 'true']
const FALSE_INPUTS = ['no', 'n', 'false']
const TRUE_OUTPUT = 'Yes'
const FALSE_OUTPUT = 'No'

export interface TreatValueInBooleanColumnInput {
  tableRef: RefObject<{ jexcel: Options } & HTMLDivElement> | undefined
  content: string
  rowIndex: number
  columnIndex: number
}

export const treatValueInBooleanColumn = (input: TreatValueInBooleanColumnInput) => {
  const { tableRef, content, rowIndex, columnIndex } = input
  const lowerCaseContent = content.toLowerCase()
  if (TRUE_INPUTS.includes(lowerCaseContent)) {
    setValueFromCoords(tableRef, columnIndex, rowIndex, TRUE_OUTPUT)
  } else if (FALSE_INPUTS.includes(lowerCaseContent)) {
    setValueFromCoords(tableRef, columnIndex, rowIndex, FALSE_OUTPUT)
  } else {
    setValueFromCoords(tableRef, columnIndex, rowIndex, '')
    openSnackBar(strings.alerts.invalidInputLength, true)
    return
  }
}
