import { CellValue } from '../components/jexcel-interfaces'
import { ColumnNames } from '../constants/columnNames'
import { BooleanColumns, DateColumns, NumericColumns } from '../constants/columnTypes'
import { commaStringToNumber } from './commaStringToNumber'
import { convertToBoolean, trueOptionsApprovalInstructions } from './convertToBoolean'
import { mapStringToISODate } from './mapStringToISODate'

export const mountRequestData = (
  tableData: ([] | undefined)[],
  columnOrder: (ColumnNames | string)[],
  fieldsToIgnore?: any
) => {
  const allRequestData: any[] = []

  tableData?.forEach((row) => {
    const data = Object.create(Object.prototype)

    row?.forEach((cellValue: CellValue, i) => {
      const stringValue = cellValue.toString()

      if (fieldsToIgnore && columnOrder[i] in fieldsToIgnore) return

      if (columnOrder[i] in NumericColumns) {
        return (data[columnOrder[i]] = commaStringToNumber(stringValue))
      }

      if (columnOrder[i] in BooleanColumns) {
        return (data[columnOrder[i]] = convertToBoolean(stringValue, trueOptionsApprovalInstructions))
      }

      if (columnOrder[i] in DateColumns) {
        return (data[columnOrder[i]] = mapStringToISODate(stringValue))
      }

      data[columnOrder[i]] = stringValue
    })

    allRequestData.push(data)
  })

  return allRequestData
}
