import { ApprovalDivergencesFieldsDTO } from './interfaces/approvalDivergencesFieldsDTO'

export const mockApprovalDivergencesRequest = async (): Promise<ApprovalDivergencesFieldsDTO> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const assets = {
        paginationSummary: {
          count: 8,
          totalCount: 8,
          pageNum: 1,
          pageSize: 100,
          sorting: null,
        },
        approvalDivergences: [
          {
            requestType: 'CarryingPosition',
            divergentFields: 'NoInstruction',
            exchangeTransactionId: '20091706',
            objectExchangeId: '20091706',
            instrumentSymbol: 'BBSE3',
            quantity: 10,
            matchedQuantity: 0,
            approvedQuantity: 0,
            pendingQuantity: 0,
            side: 'Borrower',
            issuerAccountCode: '204321',
            issuerAliasOrName: 'NOME DE CLIENTE',
            masterAccountCode: '204321',
            negotiationFee: 1.6,
            commissionFee: null,
            interBrokerFee: 1.6,
            lenderReversible: true,
            tenderOfferLenderReversible: true,
            lockUpDate: '2020-10-06T00:00:00Z',
            expireDate: '2020-10-16T00:00:00Z',
            issuerBrokerCode: '1026',
            issuerCustodianCode: '1026',
            issuerCustodyAccountCode: '204321',
            issuerPortfolio: '21016',
          },
          {
            requestType: 'CarryingPosition',
            divergentFields: 'NoInstruction',
            exchangeTransactionId: '20091709',
            objectExchangeId: '20091709',
            instrumentSymbol: 'BBSE3',
            quantity: 10,
            matchedQuantity: 0,
            approvedQuantity: 0,
            pendingQuantity: 0,
            side: 'Borrower',
            issuerAccountCode: '204321',
            issuerAliasOrName: 'NOME DE CLIENTE',
            masterAccountCode: '204321',
            negotiationFee: 1.6,
            commissionFee: null,
            interBrokerFee: 1.6,
            lenderReversible: true,
            tenderOfferLenderReversible: true,
            lockUpDate: '2020-10-06T00:00:00Z',
            expireDate: '2020-10-16T00:00:00Z',
            issuerBrokerCode: '1026',
            issuerCustodianCode: '1026',
            issuerCustodyAccountCode: '204321',
            issuerPortfolio: '21016',
          },
          {
            requestType: 'CarryingPosition',
            divergentFields: 'NoInstruction',
            exchangeTransactionId: '20091707',
            objectExchangeId: '20091707',
            instrumentSymbol: 'BBSE3',
            quantity: 10,
            matchedQuantity: 0,
            approvedQuantity: 0,
            pendingQuantity: 0,
            side: 'Borrower',
            issuerAccountCode: '204321',
            issuerAliasOrName: 'NOME DE CLIENTE',
            masterAccountCode: '204321',
            negotiationFee: 1.6,
            commissionFee: null,
            interBrokerFee: 1.6,
            lenderReversible: true,
            tenderOfferLenderReversible: true,
            lockUpDate: '2020-10-06T00:00:00Z',
            expireDate: '2020-10-16T00:00:00Z',
            issuerBrokerCode: '1026',
            issuerCustodianCode: '1026',
            issuerCustodyAccountCode: '204321',
            issuerPortfolio: '21016',
          },
          {
            requestType: 'CarryingPosition',
            divergentFields: 'NoInstruction',
            exchangeTransactionId: '20091708',
            objectExchangeId: '20091708',
            instrumentSymbol: 'BBSE3',
            quantity: 10,
            matchedQuantity: 0,
            approvedQuantity: 0,
            pendingQuantity: 0,
            side: 'Borrower',
            issuerAccountCode: '204321',
            issuerAliasOrName: 'NOME DE CLIENTE',
            masterAccountCode: '204321',
            negotiationFee: 1.6,
            commissionFee: null,
            interBrokerFee: 1.6,
            lenderReversible: true,
            tenderOfferLenderReversible: true,
            lockUpDate: '2020-10-06T00:00:00Z',
            expireDate: '2020-10-16T00:00:00Z',
            issuerBrokerCode: '1026',
            issuerCustodianCode: '1026',
            issuerCustodyAccountCode: '204321',
            issuerPortfolio: '21016',
          },
          {
            requestType: 'CarryingPosition',
            divergentFields: 'NoInstruction',
            exchangeTransactionId: '20091758',
            objectExchangeId: '20091758',
            instrumentSymbol: 'BBSE3',
            quantity: 50,
            matchedQuantity: 0,
            approvedQuantity: 0,
            pendingQuantity: 0,
            side: 'Lender',
            issuerAccountCode: '98018',
            issuerAliasOrName: 'NOME DE CLIENTE',
            masterAccountCode: '98018',
            negotiationFee: 1.6,
            commissionFee: null,
            interBrokerFee: 1.6,
            lenderReversible: true,
            tenderOfferLenderReversible: true,
            lockUpDate: '2020-10-06T00:00:00Z',
            expireDate: '2020-10-16T00:00:00Z',
            issuerBrokerCode: '1026',
            issuerCustodianCode: null,
            issuerCustodyAccountCode: null,
            issuerPortfolio: null,
          },
        ],
      }
      resolve(assets)
    }, 1000)
  })
}
