import { Filter } from '../../constants/filters/filterTypes'
import { INITIAL_PAGE_NUMBER, PAGE_SIZE } from '../../constants/pagination'
import { getGlobal, setGlobal } from '../../global/useGlobal'
import { displayError } from '../../utils/displayError'
import { formatDateFilter } from '../../utils/formatDateFilter'
import { formatFilter } from '../../utils/formatFilter'
import { isActiveFilter } from '../../utils/isActiveFilter'
import { mapDTOResponseToTableData } from '../../utils/mapDTOResponseToTableData'
import { numberOfPagesCalculator } from '../../utils/numberOfPagesCalculator'
import { FeatureManager } from '../FeatureManager'
import { approvalDivergencesRequest } from './request'
import { ApprovalDivergencesFilterNames } from '../../constants/filters/approvalDivergencesFilters'
import { mockApprovalDivergencesRequest } from './mockEndpoint'
import { ApprovalDivergencesFieldsDTO } from './interfaces/approvalDivergencesFieldsDTO'

export const getApprovalDivergences = async (page?: number, allData?: boolean): Promise<any> => {
  await setGlobal({
    isDataLoading: true,
  })

  try {
    const appliedFilters = getGlobal().approvalDivergencesAppliedFilters
    const appliedSorting = getGlobal().approvalDivergencesAppliedSorting
    const oldData = getGlobal().approvalDivergencesData
    const filters = mountFilters(appliedFilters, page, appliedSorting, allData)
    const response = FeatureManager.shouldMockApprovalDivergences
      ? await mockApprovalDivergencesRequest()
      : await approvalDivergencesRequest(filters)
    const apiDataApprovalDivergencesResponse = mapDTOResponseToTableData(response)
    const approvalDivergencesPaginationArgs = mapDTOResponseToPaginationSummary(response)
    let approvalDivergencesData: any[]
    if (page) {
      approvalDivergencesData = oldData.concat(apiDataApprovalDivergencesResponse)
    } else {
      approvalDivergencesData = apiDataApprovalDivergencesResponse
    }
    await setGlobal({
      approvalDivergencesData,
      approvalDivergencesPaginationArgs,
      isDataLoading: false,
    })
  } catch (error) {
    await setGlobal({
      isDataLoading: false,
    })
    displayError(error.error)
  }
}

const mountFilters = (filters: Filter[], page?: number, sorting?: string[], allData?: boolean) => {
  const activeFilters = isActiveFilter(filters)
  const filtersObject = {
    AccountCode: formatFilter(activeFilters, ApprovalDivergencesFilterNames.AccountCode),
    InstrumentSymbol: formatFilter(activeFilters, ApprovalDivergencesFilterNames.InstrumentSymbol),
    ExpireDate: formatDateFilter(activeFilters, ApprovalDivergencesFilterNames.ExpireDate),
    RequestInternalStatuses: formatFilter(activeFilters, ApprovalDivergencesFilterNames.RequestInternalStatuses),
    RequestType: formatFilter(activeFilters, ApprovalDivergencesFilterNames.RequestType),
    OrderSide: formatFilter(activeFilters, ApprovalDivergencesFilterNames.OrderSide),
    PageSize: allData ? -1 : PAGE_SIZE,
    PageNum: allData ? -1 : page || INITIAL_PAGE_NUMBER,
    RequireAllData: !!allData,
    Sorting: sorting?.join(',') || undefined,
  }
  return JSON.parse(JSON.stringify(filtersObject))
}

const mapDTOResponseToPaginationSummary = (dto: ApprovalDivergencesFieldsDTO) => {
  return {
    pageNum: dto.paginationSummary.pageNum,
    pageSize: dto.paginationSummary.pageSize,
    sorting: dto.paginationSummary.sorting,
    count: dto.paginationSummary.count,
    totalCount: dto.paginationSummary.totalCount,
    numberOfPages: numberOfPagesCalculator(
      dto.paginationSummary.pageSize,
      dto.paginationSummary.totalCount || dto.paginationSummary.count
    ),
  }
}
