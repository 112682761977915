import { setGlobal } from 'reactn'
import { saveAuthInfo, saveUsernameInfo, removeUsernameInfo, saveProfileInfo } from '../../utils/localStorageManager'
import { loginRequest } from './LoginService'
import { FeatureManager } from '../FeatureManager'
import { mockAuthenticate } from './LoginService/mockEndpoint'
import { strings } from '../../resources/strings/strings'
import { history } from '../../App'
import { extranetSync } from './ExtranetSync/extranetSync'

export const login = async (
  email: string,
  password: string,
  rememberCredentials?: boolean
): Promise<{
  isLogged: boolean
  successMessage: string
  errorMessage: string
  shouldNotifyAboutToExpireLogin?: boolean | undefined
  logonExpired?: number | undefined
}> => {
  await setGlobal({
    isPageLoading: true,
  })

  try {
    const response = FeatureManager.shouldUseLoginMock
      ? await mockAuthenticate(email, password)
      : await loginRequest({
          username: email,
          password: [password],
        })
    const treatedLogonExpired = response.logonExpired || 0

    if (response.created && response.expired && response.tokenCode && treatedLogonExpired >= 0) {
      const mustResetPassword = response.messageCode === 773
      const accountExpired = response.messageCode === 701
      const LIMIT_DATE_TO_NOTIFY_EXPIRING_LOGIN = 5
      const shouldNotifyAboutToExpireLogin = treatedLogonExpired <= LIMIT_DATE_TO_NOTIFY_EXPIRING_LOGIN

      if (rememberCredentials) {
        saveUsernameInfo(email)
      } else {
        removeUsernameInfo()
      }

      if (response.btg_solutions_user_session && response.extranet_user_id) {
        await extranetSync({ extranetUserId: response.extranet_user_id })
      }

      if (response.profile) {
        saveProfileInfo(response.profile || [])
      }

      if (mustResetPassword) {
        history.push('/reset-password')
        return handleExpiredLogin(false)
      } else if (accountExpired) {
        return handleExpiredLogin(true)
      } else {
        return handleLoginSuccess(
          response.created,
          response.expired,
          response.tokenCode,
          email,
          shouldNotifyAboutToExpireLogin,
          treatedLogonExpired,
          response.profile
        )
      }
    } else {
      return handleLoginError(response.messageCode, response.message)
    }
  } catch (e) {
    return handleLoginError()
  }
}

const handleLoginSuccess = (
  created: string,
  expired: string,
  tokenCode: string,
  username: string,
  shouldNotifyAboutToExpireLogin: boolean,
  logonExpired: number,
  profileInfo?: string[]
) => {
  saveAuthInfo(true, tokenCode)
  saveUsernameInfo(username)
  setGlobal({
    isPageLoading: false,
    isLoggedIn: true,
    shouldShowLoginError: false,
    username,
    accessToken: tokenCode,
    profileInfo: profileInfo || [],
  })
  return {
    isLogged: true,
    successMessage: strings.loginScreen.messages.loginSuccess,
    errorMessage: '',
    shouldNotifyAboutToExpireLogin,
    logonExpired,
  }
}

const handleLoginError = (messageCode?: number, message?: string) => {
  saveAuthInfo(false)
  setGlobal({
    isPageLoading: false,
    isLoggedIn: false,
    shouldShowLoginError: false,
  })
  return {
    isLogged: false,
    successMessage: '',
    errorMessage: message || strings.loginScreen.errors.loginError,
  }
}

const handleExpiredLogin = (shouldShowRenewPasswordDialog: boolean) => {
  saveAuthInfo(false)
  setGlobal({
    isPageLoading: false,
    isLoggedIn: false,
    shouldShowLoginError: false,
  })
  return {
    isLogged: false,
    successMessage: '',
    errorMessage: shouldShowRenewPasswordDialog ? '' : strings.loginScreen.messages.expiredLogin,
  }
}
