export const onSortData = (
  sortByAsc: boolean,
  fieldLabel: string,
  currentSorting: string[],
  isUndoingSorting?: boolean
): string[] => {
  const ascModifier = '+'
  const descModifier = '-'
  const sortModifier: string = sortByAsc ? ascModifier : descModifier
  const isFieldInCurrentSort: boolean =
    currentSorting.includes(`${ascModifier}${fieldLabel}`) || currentSorting.includes(`${descModifier}${fieldLabel}`)
  if (isFieldInCurrentSort) {
    const filterIndex: number = Math.max(
      currentSorting.indexOf(`${ascModifier}${fieldLabel}`),
      currentSorting.indexOf(`${descModifier}${fieldLabel}`)
    )
    // This is working because we're making sure that either `+${fieldLabel}` or `-${fieldLabel}` are in the array,
    // so one of those will be -1 and the other will be 0 or a positive number.
    // Math.max makes sure that we're getting the index of the string that is in the array.
    currentSorting.splice(filterIndex, 1)
    if (isUndoingSorting) {
      return currentSorting
    }
  }
  return currentSorting.concat(`${sortModifier}${fieldLabel}`)
}
