import { Filter } from '../../constants/filters/filterTypes'
import { setGlobal } from '../../global/useGlobal'
import { GlobalState } from '../../global/InitialGlobalState'

export const removeAppliedFilter = (
  filters: Filter[],
  appliedFiltersGlobalKey: keyof GlobalState,
  selectedFiltersGlobalKey: keyof GlobalState
) => (filter: Filter) => {
  const newfilters = filters
  const filterIndex = newfilters.indexOf(filter)
  newfilters.splice(filterIndex, 1)

  setGlobal({
    [appliedFiltersGlobalKey]: JSON.parse(JSON.stringify(newfilters)),
    [selectedFiltersGlobalKey]: JSON.parse(JSON.stringify(newfilters)),
  })
}
