import styled from 'styled-components'
import Autocomplete from '@material-ui/lab/Autocomplete'

export const StyledAutocomplete = styled<any>(Autocomplete)`
  .MuiInputBase-root {
    height: 30px;
  }

  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input:first-child {
    padding: 0;
  }

  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input {
    padding: 0;
  }

  .MuiInputLabel-outlined {
    transform: translate(14px, 12px) scale(1);
  }
`
