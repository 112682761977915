import React, { useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { FilterSchema, InputStyle } from '../../constants/filters/filterTypes'
import { strings } from '../../resources/strings/strings'
import { Button } from '../Button'
import { CustomBox } from '../CustomBox'
import { CustomCheckboxField } from '../CustomCheckboxField'
import { CustomTextField } from '../CustomTextField'
import { DropdownStatus } from '../DropdownStatus'
import { FilterDisplay } from '../FilterDisplay'
import { ModalHeader } from '../ModalHeader'
import { ICustomFiltersContentView } from './interfaces'
import { ContainerButton, InputContainer } from './style'
import { getAccounts } from '../../services/accounts/getAccounts'
import { getInstruments } from '../../services/instruments/getInstruments'
import { getGlobal } from '../../global/useGlobal'
import { CustomAutoComplete } from '../CustomAutoComplete'
import { onPasteFilters } from '../../utils/onPasteFilters'
import { getGlobalKeys } from '../../utils/getGlobalKeys'
import { useLocation } from 'react-router'

export const CustomFiltersContentView: React.FC<ICustomFiltersContentView> = ({
  filtersSchema,
  values,
  handleChange,
  addFilter,
  removeFilter,
  handleClose,
  clearFilters,
  applyFilters,
  onSelect,
}) => {
  const { labels } = strings.homeScreen
  const accounts = getGlobal().accounts
  const instruments = getGlobal().instruments
  const generateGridKey = (filter: FilterSchema) => `${filter.name}-${filter.value}`
  const { pathname } = useLocation()
  const { selectedFiltersGlobalKey, appliedFiltersGlobalKey, schema } = getGlobalKeys(pathname)

  useEffect(() => {
    if (!accounts) {
      getAccounts()
    }
  }, [])

  useEffect(() => {
    if (!instruments) {
      getInstruments()
    }
  }, [])

  return (
    <>
      <ModalHeader handleClose={handleClose} title={labels.modalFiltersTitle} />
      <InputContainer>
        <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
          {filtersSchema?.map((filter: FilterSchema) => {
            if (filter.inputStyle === InputStyle.autoComplete && filter.autoCompleteOptions) {
              return (
                <Grid
                  item
                  xs={filter.size}
                  key={generateGridKey(filter)}
                  onPaste={onPasteFilters({
                    appliedFiltersGlobalKey,
                    selectedFiltersGlobalKey,
                    schema,
                    shouldUseSelected: true,
                    specificFilterPattern: filter.filterPattern,
                  })}
                >
                  <CustomAutoComplete
                    optionsName={filter.autoCompleteOptions}
                    name={filter.name}
                    label={filter.label}
                    placeholder={filter.placeholder}
                    mask={filter.mask}
                    value={values[filter.name]?.toUpperCase()}
                    onSelect={onSelect}
                    handleChange={handleChange()}
                    onClick={() => addFilter(filter)}
                  />
                </Grid>
              )
            }
            if (filter.inputStyle === InputStyle.textField) {
              return (
                <Grid
                  item
                  xs={filter.size}
                  key={generateGridKey(filter)}
                  onPaste={onPasteFilters({
                    appliedFiltersGlobalKey,
                    selectedFiltersGlobalKey,
                    schema,
                    shouldUseSelected: true,
                    specificFilterPattern: filter.filterPattern,
                  })}
                >
                  <CustomTextField
                    name={filter.name}
                    label={filter.label}
                    placeholder={filter.placeholder}
                    mask={filter.mask}
                    value={values[filter.name]?.toUpperCase()}
                    handleChange={handleChange(filter.regexMask)}
                    onClick={() => addFilter(filter)}
                  />
                </Grid>
              )
            }
            if (filter.inputStyle === InputStyle.dropdown) {
              return (
                <Grid item xs={filter.size} key={generateGridKey(filter)}>
                  <DropdownStatus
                    name={filter.name}
                    label={filter.label}
                    options={filter.options}
                    status={values[filter.name]}
                    handleChange={handleChange()}
                    onClick={() => addFilter(filter)}
                  />
                </Grid>
              )
            }
            if (filter.inputStyle === InputStyle.radio) {
              return (
                <Grid item xs={filter.size} key={generateGridKey(filter)}>
                  <CustomCheckboxField
                    name={filter.name}
                    label={filter.label}
                    firstRadioLabel={filter?.radios?.borrower}
                    secondRadioLabel={filter?.radios?.lender}
                    handleChange={handleChange()}
                    onClick={() => addFilter(filter)}
                  />
                </Grid>
              )
            }
          })}
        </Grid>
      </InputContainer>
      <CustomBox label={labels.customBoxLabel}>
        <FilterDisplay removeFilter={removeFilter} />
      </CustomBox>
      <ContainerButton>
        <Button color="primary" type="outlined" text={labels.favoriteButton} />
        <Button color="primary" type="outlined" text={labels.clearFiltersButton} onClick={clearFilters} />
        <Button color="primary" type="contained" text={labels.applyFiltersButton} onClick={applyFilters} />
      </ContainerButton>
    </>
  )
}
