import { CellValue, Options } from '../../components/jexcel-interfaces'
import { RefObject } from 'react'
import { SettlementRequestsSchema } from '../../services/dataSchemas/settlementRequests/interfaces'
import { settlementRequestsDefaultColumnOrder } from '../../services/dataSchemas/settlementRequests/settlementRequestsDefaultColumnOrder'
import { getPersistedData } from '../../utils/localStorageManager'

interface SettlementRequestsStateType {
  settlementRequestsData: CellValue[][]
  settlementRequestsTableRef?: RefObject<{ jexcel: Options } & HTMLDivElement>
  settlementRequestsColumnWidths?: number[]
  currentSettlementRequestsPage: number
  settlementRequestsPaginationArgs: any
  settlementRequestsAppliedSorting: string[]
  settlementRequestsColumns: (keyof SettlementRequestsSchema)[]
}

export const settlementRequestsState: SettlementRequestsStateType = {
  settlementRequestsData: [],
  settlementRequestsTableRef: undefined,
  currentSettlementRequestsPage: 1,
  settlementRequestsColumnWidths: getPersistedData('settlementRequestsColumnWidths'),
  settlementRequestsColumns: getPersistedData('settlementRequestsColumns') || settlementRequestsDefaultColumnOrder,
  settlementRequestsPaginationArgs: {},
  settlementRequestsAppliedSorting: [],
}
