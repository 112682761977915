import { StockLoanApprovalInstructionRequestType } from '../approvalInstructions/StockLoanApprovalInstructionsRequestType'
import { StockLoanApprovalInstructionStatusType } from '../approvalInstructions/StockLoanApprovalInstructionStatus'
import { StockLoanContractStatusType } from '../contracts/stockLoanContractStatus'
import { SettlementRequestStatusType } from '../contracts/settlementRequestInternalStatus'
import { StockLoanSideType } from '../contracts/stockLoanSide'
import { ApprovalInstructionsFilterNames } from './approvalInstructionsFilters'
import { ContractsFilterNames } from './contractsFilters'
import { SettlementsFilterNames } from './settlementsFilters'
import { SettlementRequestsFilterNames } from './settlementRequestsFilters'
import { ApprovalDivergencesFilterNames } from './approvalDivergencesFilters'
import { ColumnNames } from '../../constants/columnNames'
import { StockLoanSettlementInternalStatusType } from '../settlements/stockLoanSettlementInternalStatus'

export const DATE_SHORTCUT_PATTERN = /^[Dd][+\-][0-9]{1,2}$/ //  ex.: D+0 || d-5 || d+10
export const DATE_PATTERN = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])\s?(?:([01][0-9]|2[0-3])[:]([0-5][0-9])[:]?(?:[0-5][0-9])?)?$/ //  ex.: 2020-10-10 || 2019-08-04 14:30: || 2018-03-02 15:32:08

export enum FilterPatterns {
  OnlyLetters,
  OnlyNumbers,
  Date,
  Quantity,
  Symbol,
  Side,
  AccountCode,
  ContractCode,
  Datetime,
}

export interface Filter {
  name: string
  label: string
  value: any
  type: FilterType
  isActive: boolean
}

export enum FilterType {
  unique = 'unique',
  multiple = 'multiple',
}

export interface FilterSchema {
  name:
    | ContractsFilterNames
    | SettlementsFilterNames
    | ApprovalInstructionsFilterNames
    | SettlementRequestsFilterNames
    | ApprovalDivergencesFilterNames
  label: string
  placeholder?: string
  mask?: string
  regexMask?: RegExp
  value: string
  size?: boolean | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  filterType: FilterType
  inputStyle: InputStyle
  filterPattern: FilterPatterns
  options?:
    | StockLoanContractStatusType
    | StockLoanApprovalInstructionStatusType
    | StockLoanApprovalInstructionRequestType
    | SettlementRequestStatusType
    | StockLoanSettlementInternalStatusType
  radios?: StockLoanSideType
  autoCompleteOptions?: AutoCompleteOptions
  columnReference?: ColumnNames
}

export enum InputStyle {
  textField = 'CustomTextField',
  dropdown = 'DropdownStatus',
  radio = 'CustomRadioField',
  autoComplete = 'CustomAutoComplete',
}

export enum AutoCompleteOptions {
  accounts = 'accounts',
  instruments = 'instruments',
}

export enum TypeDate {
  MinReferenceDate = 'MinReferenceDate',
  MaxReferenceDate = 'MaxReferenceDate',
  MinSettlementDate = 'MinSettlementDate',
  MaxSettlementDate = 'MaxSettlementDate',
  MinExpireDate = 'MinExpireDate',
  MaxExpireDate = 'MaxExpireDate',
  MinCreationDate = 'MinCreationDate',
  MaxCreationDate = 'MaxCreationDate',
  ExpireDate = 'ExpireDate',
}

export enum DateTimeInputs {
  MinCreationDate = 'MinCreationDate',
  MaxCreationDate = 'MaxCreationDate',
}
