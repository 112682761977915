import React from 'react'
import { colors } from '../../../styles/themes/light'
import { SvgComponent } from './interfaces'
import { useLocation } from 'react-router'
import { Routes } from '../../../routes'

export const SettleRequestsIcon: React.FC<SvgComponent> = (props) => {
  const { pathname } = useLocation()
  const isCurrentPage = pathname === Routes.settlementRequests
  const fill = isCurrentPage ? colors.moduleIconHighlight : colors.moduleAssetSelectIcon

  return (
    <>
      <svg {...props} fill={fill} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19.1 2H2.9C2.4 2 2 2.4 2 2.9V19.1C2 19.5 2.4 20 2.9 20H10.8211C10.4852 19.3627 10.2524 18.6848 10.1226 18H4V4H18V7.82575L20 9.82222V2.9C20 2.4 19.5 2 19.1 2Z"
          fill="fill"
          filll-opacity="1"
        />
        <path
          d="M12.0475 11.7713L13.8219 10H10V12H11.829C11.8997 11.9224 11.9726 11.8462 12.0475 11.7713Z"
          fill="fill"
          filll-opacity="1"
        />
        <path d="M6 6H8V8H6V6Z" fill="fill" filll-opacity="1" />
        <path d="M16 6H10V8H16V6Z" fill="fill" filll-opacity="1" />
        <path
          d="M17 10L20.5375 13.5312C21.5125 14.5063 22 15.8063 22 17.0563C22 18.3063 21.5125 19.625 20.5375 20.6C19.5625 21.575 18.2812 22.0688 17 22.0688C15.7188 22.0688 14.4375 21.575 13.4625 20.6C12.4875 19.625 12 18.3063 12 17.0563C12 15.8063 12.4875 14.5063 13.4625 13.5312L17 10Z"
          fill="fill"
          filll-opacity="1"
        />
        <path d="M8 10H6V12H8V10Z" fill="fill" filll-opacity="1" />
        <path d="M6 14H8V16H6V14Z" fill="fill" filll-opacity="1" />
      </svg>
    </>
  )
}

SettleRequestsIcon.defaultProps = {
  height: 24,
  width: 24,
}
