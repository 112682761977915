export const getDropdownSourceFromObject = (input: object) => {
  const objectEntries = Object.entries(input)
  // Creates array of object entries where each entry is an array in the format [key, value]

  return objectEntries.map((value, entryIndex) => {
    return {
      id: value[0],
      name: value[1],
    }
  })
}
