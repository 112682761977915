import styled from 'styled-components'

export const StyledContextMenu = styled.div<any>`
  position: absolute;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  border: solid 1px #ccc;
  display: inline-block;
  margin: 5px;
  background: #fff;
  color: #000;
  cursor: pointer;
  z-index: 9999;
`
