import { HttpMethods } from '../../apiService/baseEndpoint'
import { ConsolidateVirtualSettlementPositionEndpoint } from './endpoint'
import { RequestData } from '../interfaces/consolidateVirtualSettlementPositionDTO'

export const consolidateVirtualSettlementPositionRequest = async (input: RequestData): Promise<any> => {
  const endpoint = new ConsolidateVirtualSettlementPositionEndpoint<undefined, RequestData>(
    '',
    HttpMethods.POST,
    undefined,
    input
  )
  return endpoint.execute()
}
