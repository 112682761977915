import { Filter } from '../../constants/filters/filterTypes'
import { contractsDefaultFilters } from '../../constants/defaultFilters/contractsDefaultFilters'
import { settlementsDefaultFilters } from '../../constants/defaultFilters/settlementsDefaultFilters'
import { approvalInstructionsDefaultFilters } from '../../constants/defaultFilters/approvalInstructionsDefaultFilters'
import { settlementRequestsDefaultFilters } from '../../constants/defaultFilters/settlementRequestsDefaultFilters'

export interface AppliedFiltersStateType {
  contractsAppliedFilters: Filter[]
  settlementsAppliedFilters: Filter[]
  settlementRequestsAppliedFilters: Filter[]
  approvalInstructionsAppliedFilters: Filter[]
  approvalDivergencesAppliedFilters: Filter[]
}

export interface SelectedFiltersStateType {
  contractsSelectedFilters: Filter[]
  settlementsSelectedFilters: Filter[]
  settlementRequestsSelectedFilters: Filter[]
  approvalInstructionsSelectedFilters: Filter[]
  approvalDivergencesSelectedFilters: Filter[]
}

export interface FilterModalControllersStateType {
  contractsFilterModalOpen: boolean
  settlementsFilterModalOpen: boolean
  settlementRequestsFilterModalOpen: boolean
  approvalInstructionsFilterModalOpen: boolean
  approvalDivergencesFilterModalOpen: boolean
}

export const filtersState: AppliedFiltersStateType & SelectedFiltersStateType & FilterModalControllersStateType = {
  contractsAppliedFilters: [...contractsDefaultFilters],
  settlementsAppliedFilters: [...settlementsDefaultFilters],
  settlementRequestsAppliedFilters: [...settlementRequestsDefaultFilters],
  approvalInstructionsAppliedFilters: [...approvalInstructionsDefaultFilters],
  approvalDivergencesAppliedFilters: [],
  contractsSelectedFilters: [...contractsDefaultFilters],
  settlementsSelectedFilters: [...settlementsDefaultFilters],
  settlementRequestsSelectedFilters: [...settlementRequestsDefaultFilters],
  approvalInstructionsSelectedFilters: [...approvalInstructionsDefaultFilters],
  approvalDivergencesSelectedFilters: [],
  contractsFilterModalOpen: false,
  settlementsFilterModalOpen: false,
  settlementRequestsFilterModalOpen: false,
  approvalInstructionsFilterModalOpen: false,
  approvalDivergencesFilterModalOpen: false,
}
