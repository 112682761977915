import React from 'react'
import { Container, MockCaptcha, ActionAreaWrapper, LoginWrapper, FormWrapper, Logo } from './style'
import { CustomTextFieldFormInput } from '../../components/CustomTextFieldFormInput'
import { FormControlLabel, Button } from '@material-ui/core'
import { LoginVM } from './LoginVM'
import CheckboxInput from '../../components/Checkbox'
import btgLogo from '../../resources/assets/logo/btg-logo-login.png'
import PasswordField from '../../components/PasswordField'

interface ILoginScreen {}

const LoginScreen: React.FC<ILoginScreen> = () => {
  // observers and global management
  const viewModel = LoginVM.initVM()
  const { Form, onSubmit, errorMessage, screenStrings } = viewModel.useDataHooks()

  const onKeyPress = (ev: React.KeyboardEvent<HTMLDivElement>) => {
    const isKeyPressedEnter = ev.key === 'Enter'
    if (isKeyPressedEnter) {
      onSubmit()
      ev.preventDefault()
    }
  }

  return (
    <LoginWrapper>
      <Container>
        <Logo src={btgLogo} />
        <Form>
          <FormWrapper>
            <CustomTextFieldFormInput
              name={'email'}
              variant="filled"
              label={screenStrings.labels.usernameInputLabel}
              helperText={errorMessage.email}
              error={errorMessage.email !== ''}
              fullWidth
            />
            <FormControlLabel
              control={<CheckboxInput name={'rememberCredentials'} color="primary" />}
              label={screenStrings.labels.checkboxInputLabel}
            />
            <PasswordField
              name={'password'}
              label={screenStrings.labels.passwordInputLabel}
              errorMessage={errorMessage.password}
              onKeyPress={onKeyPress}
            />
            <MockCaptcha>
              <h2>Captcha</h2>
            </MockCaptcha>
            <ActionAreaWrapper>
              <Button color="primary" href={'/forgot-password'}>
                {screenStrings.labels.forgetPasswordButtonLabel}
              </Button>
              <Button color="primary" variant="contained" onClick={onSubmit}>
                {screenStrings.labels.loginButtonLabel}
              </Button>
            </ActionAreaWrapper>
          </FormWrapper>
        </Form>
      </Container>
    </LoginWrapper>
  )
}

export default LoginScreen
