export enum NumericColumns {
  quantity = 'quantity',
  negotiationFee = 'negotiationFee',
  commissionFee = 'commissionFee',
  interBrokerFee = 'interBrokerFee',
}

export enum DateColumns {
  lockUpDate = 'lockUpDate',
  expireDate = 'expireDate',
  referenceDate = 'referenceDate',
}

export enum BooleanColumns {
  lenderReversible = 'lenderReversible',
  tenderOfferLenderReversible = 'tenderOfferLenderReversible',
}

export enum ApprovalInstructionsReferenceColumns {
  feedback = 'feedback',
  averageLenderInterest = 'averageLenderInterest',
  averageBorrowerCharge = 'averageBorrowerCharge',
}
