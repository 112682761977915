export const baseTheme = {
  button: {
    ripple: {
      color: 'rgba(255, 255, 255, 0.20)',
    },
  },
  typography: {
    h1: {
      fontSize: '69px',
      fontWeight: 200,
    },
    h2: {
      fontSize: '60px',
    },
    h3: {
      fontSize: '48px',
    },
    h4: {
      fontSize: '24px',
    },
    h5: {
      fontSize: '20px',
    },
    h6: {
      fontSize: '14px',
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: '12px',
    },
    subtitle2: {
      fontSize: '10px',
      fontWeight: 500,
    },
    body1: {
      fontSize: '12px',
    },
    body2: {
      fontSize: '10px',
    },
    button: {
      fontSize: '12px',
      fontWeight: 500,
    },
    caption: {
      fontSize: '10px',
    },
    overline: {
      fontSize: '10px',
    },
  },
}

export const baseColors = {
  customDividerBackground: 'rgba(0, 0, 0, 0.2)',
  appbarDividerDarkBackground: 'rgba(255, 255, 255, 0.6)',
  appbarDividerLightBackground: 'rgba(0, 0, 0, 0.6)',
  cancelOrderModalBoxShadow: 'rgba(0, 0, 0, 0.2)',
}
