import { SettlementRequestLegs } from '../get/interfaces/settlementRequestsFieldsDTO'

export const mockDeleteSettlementRequests = async (input: string[]): Promise<SettlementRequestLegs[]> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const assets = input.map(() => {
        return {
          id: 'd9d6f2ff0c3441e08c0f544a6fd04057',
          exchangeTransactionId: '2019-12-05-1-3700',
          originTransactionId: '2019-12-05-1-989',
          contractCode: '201911270023940772-1',
          quantity: 100,
          side: 'Borrower',
          externalStatus: 'Completed',
          internalStatus: 'Confirmed',
          originType: 'Manual',
          nextPendingApproval: null,
          nextPendingApprovingParticipant: null,
          creationDate: '2019-12-05T14:58:28.927Z',
          lastUpdatetime: '2019-12-06T14:49:51.827Z',
          contractLeg: {
            code: '201911270023940772-1',
            instrumentSymbol: 'BEEF3',
            quantity: 100,
            originalQuantity: 100,
            effectiveQuantity: 100,
            projectedQuantity: 100,
            settledQuantity: 0,
            coveredQuantity: 0,
            uncoveredQuantity: 100,
            renewalQuantity: 0,
            requestedRenewalQuantity: 0,
            requestedSettledQuantity: 100,
            totalVolume: null,
            basePrice: 13.8,
            brokerageFee: null,
            side: 'Borrower',
            paymentType: 'Netting',
            marketModality: 'OtcSecuritiesLending',
            securityLendingContractType: 'RegularDirect',
            masterAccountCode: '98018',
            masterOrSelfAliasOrName: 'FUNDO DE INVESTIMENTO MULTIMERCADO CREDITO PRIVADO LS INVESTIMENTO NO EXTERIOR',
            issuerAccountCode: '98018',
            issuerAliasOrName: 'FUNDO DE INVESTIMENTO MULTIMERCADO CREDITO PRIVADO LS INVESTIMENTO NO EXTERIOR',
            issuerExecutionBrokerCode: '85',
            issuerCarryingBrokerCode: '1026',
            issuerCustodianCode: '1026',
            issuerCustodyAccountCode: '98018',
            issuerPortfolio: '21016',
            negotiationFee: 19.5,
            lenderReversible: true,
            tenderOfferLenderReversible: true,
            internalStatus: 'Finalized',
            tradeDate: '2019-11-27T00:00:00Z',
            startDate: '2019-11-27T00:00:00Z',
            lockUpDate: '2019-11-28T00:00:00Z',
            expireDate: '2020-01-09T00:00:00Z',
            creationDate: '2019-11-27T19:43:50.937Z',
            lastExchangeUpdate: '2019-12-05T14:58:46Z',
          },
          errorRuleIds: '',
        }
      })

      resolve(assets)
    }, 1000)
  })
}
