import { ApprovalInstructionsColumnsSchema } from './interfaces'
import { datetimeMask, floatMask, intMask } from '../../../constants/masks/masks'

export const approvalInstructionsSchema: ApprovalInstructionsColumnsSchema = {
  checkbox: {
    title: '',
    tooltipName: '',
    type: 'checkbox',
    index: 0,
  },
  id: {
    title: 'ID',
    tooltipName: 'ID',
    type: 'text',
    index: 1,
  },
  externalId: {
    title: 'External ID',
    tooltipName: 'External ID',
    type: 'text',
    index: 2,
  },
  requestType: {
    title: 'Request Type',
    tooltipName: 'Request Type',
    type: 'text',
    index: 3,
  },
  instrumentSymbol: {
    title: 'Symbol',
    tooltipName: 'Instrument Symbol',
    type: 'text',
    index: 4,
  },
  quantity: {
    title: 'Qty',
    tooltipName: 'Quantity',
    type: 'numeric',
    index: 5,
    mask: intMask,
  },
  matchedQuantity: {
    title: 'Matched Qty',
    tooltipName: 'Matched Quantity',
    type: 'numeric',
    index: 6,
    mask: intMask,
  },
  approvedQuantity: {
    title: 'Approved Qty',
    tooltipName: 'Approved Quantity',
    type: 'numeric',
    index: 7,
    mask: intMask,
  },
  pendingQuantity: {
    title: 'Pending Qty',
    tooltipName: 'Pending Quantity',
    type: 'numeric',
    index: 8,
    mask: intMask,
  },
  side: {
    title: 'Side',
    tooltipName: 'Side',
    type: 'text',
    index: 9,
  },
  issuerAccountCode: {
    title: 'Account',
    tooltipName: 'Account',
    type: 'text',
    index: 10,
  },
  issuerAliasOrName: {
    title: 'Name',
    tooltipName: 'Issuer Alias or Name',
    type: 'text',
    index: 11,
  },
  masterAccountCode: {
    title: 'Master Account',
    tooltipName: 'Master Account',
    type: 'text',
    index: 12,
  },
  negotiationFee: {
    title: 'Negotiation Fee',
    tooltipName: 'Negotiation Fee',
    type: 'numeric',
    index: 13,
    mask: floatMask,
  },
  commissionFee: {
    title: 'Commission Fee',
    tooltipName: 'Commission Fee',
    type: 'numeric',
    index: 14,
    mask: floatMask,
  },
  interBrokerFee: {
    title: 'Inter Broker Fee',
    tooltipName: 'Inter Broker Fee',
    type: 'numeric',
    index: 15,
    mask: floatMask,
  },
  lenderReversible: {
    title: 'Callable?',
    tooltipName: 'Lender Reversible?',
    type: 'text',
    index: 16,
  },
  tenderOfferLenderReversible: {
    title: 'TOLR?',
    tooltipName: 'Tender Offer Lender Reversible?',
    type: 'text',
    index: 17,
  },
  lockUpDate: {
    title: 'Lock Up Date',
    tooltipName: 'Lock Up Date',
    type: 'text',
    index: 18,
    mask: datetimeMask,
  },
  expireDate: {
    title: 'Expire Date',
    tooltipName: 'Expire Date',
    type: 'text',
    index: 19,
    mask: datetimeMask,
  },
  issuerBrokerCode: {
    title: 'Issuer Broker Code',
    tooltipName: 'Issuer Broker Code',
    type: 'text',
    index: 20,
  },
  issuerCustodianCode: {
    title: 'Issuer Custodian Code',
    tooltipName: 'Issuer Custodian Code',
    type: 'text',
    index: 21,
  },
  issuerCustodyAccountCode: {
    title: 'Issuer Custody Account Code',
    tooltipName: 'Issuer Custody Account Code',
    type: 'text',
    index: 22,
  },
  issuerPortfolio: {
    title: 'Issuer Portfolio',
    tooltipName: 'Issuer Portfolio',
    type: 'text',
    index: 23,
  },
  referenceDate: {
    title: 'Reference Date',
    tooltipName: 'Reference Date',
    type: 'text',
    index: 24,
    mask: datetimeMask,
  },
  targetBrokerCode: {
    title: 'Target Broker Code',
    tooltipName: 'Target Broker Code',
    type: 'text',
    index: 25,
  },
  internalStatus: {
    title: 'Status',
    tooltipName: 'Status',
    type: 'text',
    index: 26,
  },
  deskId: {
    title: 'Desk ID',
    tooltipName: 'Desk ID',
    type: 'text',
    index: 27,
  },
  memo: {
    title: 'Memo',
    tooltipName: 'Memo',
    type: 'text',
    index: 28,
  },
  creationDate: {
    title: 'Creation Date',
    type: 'text',
    mask: datetimeMask,
    tooltipName: 'Creation Date',
  },
}
