import React from 'react'
import { DropdownStatusView } from './DropdownView'
import { IDropdownStatus } from './interfaces'

export const DropdownStatus: React.FC<IDropdownStatus> = ({ name, label, options, handleChange, status, onClick }) => {
  return (
    <DropdownStatusView
      name={name}
      label={label}
      options={options}
      handleChange={handleChange}
      status={status}
      onClick={onClick}
    />
  )
}
