import React from 'react'
import { IShowFiltersModal } from './interfaces'
import { ModalContainer, StyledModal } from './style'

export const ShowFiltersModalView: React.FC<IShowFiltersModal> = ({ isOpen, children, closeModal }) => {
  return (
    <StyledModal onBackdropClick={closeModal} open={isOpen}>
      <ModalContainer>{children}</ModalContainer>
    </StyledModal>
  )
}
