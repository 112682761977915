export interface StockLoanApprovalInstructionStatusType {
  invalidRejected: string
  pending: string
  ordered: string
  matched: string
  fulfilled: string
  discarded: string
  overflow: string
  unresolved: string
  finalized: string
  [key: string]: string
}

export const stockLoanApprovalInstructionStatus: StockLoanApprovalInstructionStatusType = {
  invalidRejected: 'Invalid Rejected',
  pending: 'Pending',
  ordered: 'Ordered',
  matched: 'Matched',
  fulfilled: 'Fulfilled',
  discarded: 'Discarded',
  overflow: 'Overflow',
  unresolved: 'Unresolved',
  finalized: 'Finalized',
}
