import { BaseEndpoint, HttpMethods } from '../apiService/baseEndpoint'
import { config } from '../../constants/config'

const { BTG_CONTRACTS_URL } = config

export class InstrumentSymbolsEndpoint<Params = undefined, Body = undefined> extends BaseEndpoint<Body, Params> {
  constructor(path: string, method: HttpMethods, params?: Params, body?: Body) {
    super({
      method,
      url: BTG_CONTRACTS_URL,
      path: `${path}extranet/stock-loan/instruments`,
      auth: true,
    })

    this.body = body
    this.params = params
  }
}
