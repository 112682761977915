import { useEffect, useState } from 'react'

export const useDebounce = (value: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value)
  const [isDebouncing, setIsDebouncing] = useState(false)

  useEffect(() => {
    const handler = setTimeout(() => {
      setIsDebouncing(false)
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
      setIsDebouncing(true)
    }
  }, [value])
  return { debouncedValue, isDebouncing }
}
