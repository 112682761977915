export const MIN_COLUMN_WIDTH = 50
export const MIN_MODAL_WIDTH = 650
export const MIN_MODAL_HEIGHT = 550
export const MODAL_RELATIVE_WIDTH = 0.6
export const MODAL_RELATIVE_HEIGHT = 0.7
export const TABLE_TO_MODAL_WIDTH_PROPORTION = 0.96
export const TABLE_TO_MODAL_HEIGHT_PROPORTION = 0.7
export const CONTRACTS_PAGE_SIZE = 100
export const SETTLEMENTS_PAGE_SIZE = 100
export const APPROVAL_INSTRUCTIONS_PAGE_SIZE = 100
export const DEFAULT_PAGE_SIZE = 100
export const AUTO_HIDE_DURATION = 4000
export const SEARCH_DEBOUNCE_DELAY = 500
export const DATE_LENGTH = 10
export const YEAR_WITH_SEPARATOR_LENGTH = 5
export const QUANTITY_MAX_LENGTH = 7
export const FILTER_SET_NAME_MAX_LENGTH = 20
export const CONTRACT_CODE_FIRST_PART_LENGTH = 18
export const CONTRACT_CODE_LENGTH = 20
export const ACCOUNT_CODE_MIN_LENGTH = 0
export const ACCOUNT_CODE_MAX_LENGTH = 9
export const SYMBOL_LENGTH = 5
export const SEARCH_AREA_BUTTONS_WIDTH = 270
export const FF_MODAL_WIDTH = 300
export const AUTOFIT_COLUMN_MAX_WIDTH = 200
export const MODAL_TABLE_DIMENSIONS: [number, number] = [0, 10]
export const VIRTUAL_SETTLE_COLUMNS = 4
