import styled from 'styled-components'

export const InputContainer = styled.div`
  height: fit-content;
  padding: 16px;
`

export const ContainerButton = styled.div`
  width: 500px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0 16px auto;
  padding: 0 16px;
`
