import styled from 'styled-components'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import { Button } from '@material-ui/core'

export const StyledButtonGroup = styled(ButtonGroup)`
  margin: 0 4px;
`

export const StyledButton = styled(Button)`
  width: 40px;
  height: 36px;
`
