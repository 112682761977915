import { accountMask } from './../../constants/masks/masks'
import { DataSchema } from '../../services/dataSchemas/interfaces'
import { dateMask, floatMask, intMask } from '../../constants/masks/masks'
import { booleanDropdownSource, sideDropdownSource } from '../../constants/dropdownSources'

export const updateApprovalInstructionsSchema: { [key: string]: DataSchema } = {
  id: {
    title: 'ID',
    type: 'text',
    tooltipName: 'ID',
    readOnly: true,
  },
  externalId: {
    title: 'External ID',
    type: 'text',
    tooltipName: 'External ID',
  },
  requestType: {
    title: 'Request Type',
    type: 'text',
    tooltipName: 'Request Type',
    readOnly: true,
  },
  instrumentSymbol: {
    title: 'Symbol',
    type: 'text',
    tooltipName: 'Symbol',
  },
  quantity: {
    title: 'Qty',
    type: 'text',
    tooltipName: 'Qty',
    mask: intMask,
  },
  side: {
    title: 'Side',
    type: 'dropdown',
    source: sideDropdownSource,
    tooltipName: 'Side',
  },
  issuerAccountCode: {
    title: 'Account',
    type: 'text',
    tooltipName: 'Account',
    readOnly: true,
  },
  negotiationFee: {
    title: 'Negotiation Fee',
    type: 'text',
    tooltipName: 'Negotiation Fee',
    mask: floatMask,
  },
  commissionFee: {
    title: 'Commission Fee',
    type: 'text',
    tooltipName: 'Commission Fee',
    mask: floatMask,
  },
  interBrokerFee: {
    title: 'Inter Broker Fee',
    type: 'text',
    tooltipName: 'Inter Broker Fee',
    mask: floatMask,
  },
  lenderReversible: {
    title: 'Callable?',
    type: 'dropdown',
    tooltipName: 'Lender Reversible?',
    source: booleanDropdownSource,
  },
  tenderOfferLenderReversible: {
    title: 'TOLR?',
    type: 'dropdown',
    tooltipName: 'Tender Offer Lender Reversible?',
    source: booleanDropdownSource,
  },
  lockUpDate: {
    title: 'Lock Up Date',
    type: 'text',
    tooltipName: 'Lock Up Date',
    mask: dateMask,
  },
  expireDate: {
    title: 'Expire Date',
    type: 'text',
    tooltipName: 'Expire Date',
    mask: dateMask,
  },
  issuerCustodianCode: {
    title: 'Issuer Custodian Code',
    type: 'text',
    tooltipName: 'Issuer Custodian Code',
    mask: accountMask,
  },
  issuerCustodyAccountCode: {
    title: 'Issuer Custody Account Code',
    type: 'text',
    tooltipName: 'Issuer Custody Account Code',
    mask: accountMask,
  },
  issuerPortfolio: {
    title: 'Issuer Portfolio',
    type: 'text',
    tooltipName: 'Issuer Portfolio',
    mask: accountMask,
  },
  referenceDate: {
    title: 'Reference Date',
    type: 'text',
    tooltipName: 'Reference Date',
    mask: dateMask,
  },
  targetBrokerCode: {
    title: 'Target Broker Code',
    type: 'text',
    tooltipName: 'Target Broker Code',
    mask: accountMask,
  },
  internalStatus: {
    title: 'Status',
    type: 'text',
    tooltipName: 'Status',
    readOnly: true,
  },
  deskId: {
    title: 'Desk ID',
    type: 'text',
    tooltipName: 'Desk ID',
  },
  memo: {
    title: 'Memo',
    type: 'text',
    tooltipName: 'Memo',
  },
  feedback: {
    title: 'Feedback',
    type: 'text',
    tooltipName: 'Feedback',
    readOnly: true,
  },
}
