import styled from 'styled-components'
import { colors } from '../../styles/themes/light'
import { baseTheme } from '../../styles/themes/baseTheme'
import CloseIcon from '@material-ui/icons/Close'

export const HeaderContainer = styled.div<any>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  margin: 0;
  padding: 8px 16px;
  background-color: ${colors.primary};
  color: white;
  width: ${({ customWidth }) => `${customWidth}px` || 'auto'};
`

export const StyledTitle = styled.p`
  margin: 0;
  font-size: ${baseTheme.typography.subtitle1.fontSize};
`

export const StyledIconButton = styled(CloseIcon)`
  cursor: pointer;
`
