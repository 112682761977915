import React from 'react'
import styled from 'styled-components'
import { colors } from '../../../styles/themes/light'
import { SvgComponent } from './interfaces'

export const VirtualSettleIcon: React.FC<SvgComponent> = (props) => {
  return (
    <SvgContainer>
      <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M20 18C21.1 18 21.99 17.1 21.99 16L22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V16C2 17.1 2.9 18 4 18H0V20H24V18H20ZM4 6H20V16H4V6ZM12 7L14.1225 9.11875C14.7075 9.70375 15 10.4837 15 11.2337C15 11.9837 14.7075 12.775 14.1225 13.36C13.5375 13.945 12.7688 14.2413 12 14.2413C11.2312 14.2413 10.4625 13.945 9.8775 13.36C9.2925 12.775 9 11.9837 9 11.2337C9 10.4837 9.2925 9.70375 9.8775 9.11875L12 7Z"
          fill="white"
        />
      </svg>
    </SvgContainer>
  )
}

VirtualSettleIcon.defaultProps = {
  fill: colors.background,
  height: 24,
  width: 24,
}

const SvgContainer = styled.div`
  background-color: ${colors.secondary};
  height: 40px;
  width: 40px;
  padding: 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`
