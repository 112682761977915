import { RefObject } from 'react'
import { Options } from '../components/jexcel-interfaces'
import { Instrument } from '../services/instruments/interfaces/instrumentSymbolsFieldsDTO'
import { createApprovalInstructionsOrder } from '../components/CreateApprovalInstructions/createApprovalInstructionsOrder'
import { openSnackBar } from '../services/pageStateService'
import { strings } from '../resources/strings/strings'
import { setValueFromCoords } from './jexcelMethods/setValueFromCoords'
import { getAutocompleteColumnsInfo } from '../constants/approvalInstructions/getAutocompleteColumnsInfo'
import { TreatedDefaultDatesType } from '../services/defaultDates/interfaces/TreatedDefaultDatesType'
import { getValueFromCoords } from './jexcelMethods/getValueFromCoords'
import { IAutocompleteCellEditedByUser } from '../components/CreateApprovalInstructions/interfaces'

interface OnSymbolInputType {
  tableRef: RefObject<{ jexcel: Options } & HTMLDivElement> | undefined
  content: string
  rowIndex: number
  columnIndex: number
  instruments: Instrument[] | undefined
  defaultDates?: TreatedDefaultDatesType
  autoCompleteColumns?: boolean
  cellsEditedByUser?: IAutocompleteCellEditedByUser[]
}

export const onSymbolInput = (input: OnSymbolInputType) => {
  const {
    columnIndex,
    content,
    defaultDates,
    instruments,
    rowIndex,
    tableRef,
    autoCompleteColumns,
    cellsEditedByUser,
  } = input
  const symbol = content?.toUpperCase()
  if (!tableRef) return
  if (instruments?.some((value) => value.symbol === symbol)) {
    if (!autoCompleteColumns) return
    const instrumentInfo = instruments?.find((item) => {
      return item.symbol === symbol
    })

    const columnsInfo = getAutocompleteColumnsInfo(instrumentInfo, defaultDates)

    columnsInfo.forEach((info) => {
      const index = createApprovalInstructionsOrder.indexOf(info.name)
      const cellValue = getValueFromCoords(tableRef, rowIndex, index)
      const isCellEditedByUser = cellsEditedByUser?.find(
        (value) => value.x === rowIndex.toString() && value.y === index.toString()
      )

      if (cellValue && isCellEditedByUser) return
      setValueFromCoords(tableRef, index, rowIndex, info.content.toString())
    })
  } else {
    tableRef?.current?.jexcel.setValueFromCoords!(columnIndex, rowIndex, '')
    // TODO: check if we should erase cell content when symbol does not exist
    openSnackBar(strings.alerts.noSymbols, undefined, true)
  }
}

export enum AutocompleteFieldsIndex {
  interBrokerFee = 8,
  avgLenderInterest = 9,
  avgBorrowerCharge = 10,
  lenderReversible = 11,
  tenderOfferLenderReversible = 12,
  lockUpDate = 13,
  expireDate = 14,
  referenceDate = 18,
}
