import React from 'react'
import { ActionAreaWrapper, ButtonWrapper, ConfirmButton, Container, FormWrapper, Logo } from '../style'
import { btgLogo } from '../../../resources/assets'
import PasswordField from '../../../components/PasswordField'
import { CustomTextFieldFormInput } from '../../../components/CustomTextFieldFormInput'

interface IResetPasswordScreen {
  screenStrings: any
  errorMessage: any
  Form: any
  onSubmit: () => void
}

export const ResetPasswordScreen: React.FC<IResetPasswordScreen> = ({
  screenStrings,
  errorMessage,
  Form,
  onSubmit,
}) => {
  return (
    <Container>
      <Logo src={btgLogo} alt={'btg-logo'} />
      <Form>
        <FormWrapper>
          <CustomTextFieldFormInput
            name={'email'}
            variant="filled"
            label={screenStrings.labels.usernameInputLabel}
            helperText={errorMessage.email}
            error={errorMessage.email !== ''}
            fullWidth
          />
          <PasswordField
            name={'oldPassword'}
            label={screenStrings.labels.oldPasswordInputLabel}
            errorMessage={errorMessage.oldPassword}
          />
          <PasswordField
            name={'password'}
            label={screenStrings.labels.passwordInputLabel}
            errorMessage={errorMessage.password}
          />
          <PasswordField
            name={'confirmPassword'}
            label={screenStrings.labels.confirmPasswordInputLabel}
            errorMessage={errorMessage.confirmPassword}
          />
        </FormWrapper>
        <ActionAreaWrapper>
          <ButtonWrapper>
            <ConfirmButton color="primary" variant="contained" onClick={onSubmit}>
              {screenStrings.labels.submitButtonLabel}
            </ConfirmButton>
          </ButtonWrapper>
        </ActionAreaWrapper>
      </Form>
    </Container>
  )
}
