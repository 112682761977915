import { RefObject } from 'react'
import { Options } from '../../components/jexcel-interfaces'
import { getPersistedData } from '../../utils/localStorageManager'
import {
  failedResponseColumnOrder,
  succeededResponseColumnOrder,
  virtualSettleColumnOrder,
} from '../../components/VirtualSettle/virtualSettleColumnOrder'
import { ColumnNames } from '../../constants/columnNames'

interface VirtualSettleStateType {
  virtualSettleTableRef?: RefObject<{ jexcel: Options } & HTMLDivElement>
  succeededTableRef?: RefObject<{ jexcel: Options } & HTMLDivElement>
  failedTableRef?: RefObject<{ jexcel: Options } & HTMLDivElement>
  virtualSettleColumnWidths?: number[]
  succeededColumnWidths?: number[]
  failedColumnWidths?: number[]
  virtualSettleColumns: ColumnNames[]
  succeededColumns: ColumnNames[]
  failedColumns: ColumnNames[]
  virtualSettleData?: any[]
  virtualSettleSucceeded?: any[]
  virtualSettleFailed?: any[]
  isVirtualSettleModalOpen: boolean
  isVirtualSettleResponse: boolean
  isLoadingConsolidatedPosition: boolean
}

export const virtualSettleState: VirtualSettleStateType = {
  virtualSettleData: undefined,
  virtualSettleSucceeded: undefined,
  virtualSettleFailed: undefined,
  isVirtualSettleModalOpen: false,
  isVirtualSettleResponse: false,
  isLoadingConsolidatedPosition: false,
  virtualSettleColumnWidths: getPersistedData('virtualSettleColumnWidths'),
  succeededColumnWidths: getPersistedData('succeededColumnWidths'),
  failedColumnWidths: getPersistedData('failedColumnWidths'),
  virtualSettleColumns: virtualSettleColumnOrder,
  succeededColumns: succeededResponseColumnOrder,
  failedColumns: failedResponseColumnOrder,
}
