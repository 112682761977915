import { ContractsColumnsSchema } from './interfaces'
import { ColumnNames } from '../../../constants/columnNames'

export const contractsDefaultColumnOrder: (keyof ContractsColumnsSchema)[] = [
  ColumnNames.Checkbox,
  ColumnNames.Code,
  ColumnNames.InstrumentSymbol,
  ColumnNames.Quantity,
  ColumnNames.Side,
  ColumnNames.IssuerExecutionBrokerCode,
  ColumnNames.IssuerAccountCode,
  ColumnNames.IssuerAliasOrName,
  ColumnNames.NegotiationFee,
  ColumnNames.BrokerageFee,
  ColumnNames.LockUpDate,
  ColumnNames.ExpireDate,
  ColumnNames.LenderReversible,
  ColumnNames.TenderOfferLenderReversible,
  ColumnNames.MasterAccountCode,
  ColumnNames.MasterOrSelfAliasOrName,
  ColumnNames.IssuerCustodyAccountCode,
  ColumnNames.IssuerCarryingBrokerCode,
  ColumnNames.IssuerCustodianCode,
  ColumnNames.IssuerPortfolio,
  ColumnNames.InternalStatus,
  ColumnNames.TradeDate,
  ColumnNames.StartDate,
  ColumnNames.CreationDate,
  ColumnNames.LastExchangeUpdate,
  ColumnNames.OriginalQuantity,
  ColumnNames.EffectiveQuantity,
  ColumnNames.ProjectedQuantity,
  ColumnNames.SettledQuantity,
  ColumnNames.CoveredQuantity,
  ColumnNames.UncoveredQuantity,
  ColumnNames.RenewalQuantity,
  ColumnNames.RequestedRenewalQuantity,
  ColumnNames.RequestedSettledQuantity,
  ColumnNames.EffectiveVolume,
  ColumnNames.TotalVolume,
  ColumnNames.BasePrice,
  ColumnNames.PaymentType,
  ColumnNames.MarketModality,
  ColumnNames.SecurityLendingContractType,
]
