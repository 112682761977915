import { Column } from '../components/jexcel-interfaces'
import { DataSchema } from '../services/dataSchemas/interfaces'

const isNumericField = (fieldName?: string | number | boolean) => {
  if (typeof fieldName === 'string') {
    const fieldNameLowerCase = fieldName.toLowerCase()
    return (
      fieldNameLowerCase.includes('qty') ||
      fieldNameLowerCase.includes('price') ||
      fieldNameLowerCase.includes('value') ||
      fieldNameLowerCase.includes('fee') ||
      fieldNameLowerCase.includes('commission') ||
      fieldNameLowerCase.includes('tax')
    )
  } else {
    return false
  }
}

export const buildTableSchema = (
  indexes: string[],
  tableSchema: { [key: string]: DataSchema },
  isEditable?: boolean
) => {
  let schema: Column[] = []
  indexes.forEach((index: string) => {
    if (tableSchema[index]) {
      schema.push({
        type: tableSchema[index].type,
        autocomplete: tableSchema[index].type === 'dropdown',
        source: tableSchema[index].source,
        title: tableSchema[index].tooltipName,
        readOnly: isEditable ? tableSchema[index].readOnly : index !== 'checkbox',
        align: isNumericField(tableSchema[index].title) ? 'right' : 'center',
        width: tableSchema[index].width,
        mask: tableSchema[index].mask,
        name: tableSchema[index].title,
      })
    }
  })
  return schema
}
