import styled from 'styled-components'
import { colors } from '../../styles/themes/light'

export const StyledFooter = styled.div<any>`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid ${colors.tableBorder};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: ${colors.surface};
  width: ${(props) => props.width}px;
  height: 24px;
  align-items: center;
`
export const StyledResult = styled.p`
  margin: 0 16px 0 8px;
  font-weight: bold;
  font-size: 12px;
`
