import { displayError } from '../../../utils/displayError'
import { FeatureManager } from '../../FeatureManager'
import { SettlementRequestLegs } from '../get/interfaces/settlementRequestsFieldsDTO'
import { mockDeleteSettlementRequests } from './mockEndpoint'
import { deleteSettlementRequests } from './request'

export const doDeleteSettlementRequests = async (input: string[]): Promise<SettlementRequestLegs[] | undefined> => {
  const ids = {
    ids: input,
  }
  try {
    const response: SettlementRequestLegs[] = FeatureManager.shouldMockSettlementRequests
      ? await mockDeleteSettlementRequests(input)
      : await deleteSettlementRequests(ids)
    return response
  } catch (error) {
    displayError(error.error)
  }
}
