import { SettlementsColumnsSchema } from './interfaces'
import { ColumnNames } from '../../../constants/columnNames'

export const settlementsDefaultColumnOrder: (keyof SettlementsColumnsSchema)[] = [
  ColumnNames.SettlementDate,
  ColumnNames.ContractCode,
  ColumnNames.InstrumentSymbol,
  ColumnNames.Quantity,
  ColumnNames.Side,
  ColumnNames.IssuerAccountCode,
  ColumnNames.IssuerAliasOrName,
  ColumnNames.IssuerBrokerCode,
  ColumnNames.IssuerPortfolio,
  ColumnNames.ExpireDate,
  ColumnNames.IssuerGrossValue,
  ColumnNames.IssuerNetValue,
  ColumnNames.ShownIssuerComission,
  ColumnNames.IssuerWithheldTax,
  ColumnNames.SettlementValue,
  ColumnNames.IssuerCustodianCode,
  ColumnNames.IssuerCustodyAccountCode,
  ColumnNames.ExchangeTransactionId,
  ColumnNames.InternalStatus,
  ColumnNames.ContractInternalStatus,
]
