import { Filter } from '../../constants/filters/filterTypes'
import { GlobalState } from '../../global/InitialGlobalState'
import { getGlobal, setGlobal } from '../../global/useGlobal'
import { FilterSet } from '../../global/components/favoriteFiltersState'
import { openSnackBar } from '../../services/pageStateService'
import { strings } from '../../resources/strings/strings'
import { saveToLocalStorage } from '../localStorageManager'

export const saveFilterSet = async (setName: string, setContent: Filter[], favoritesGlobalKey: keyof GlobalState) => {
  const currentSets: FilterSet[] = getGlobal()[favoritesGlobalKey]
  const newSet: FilterSet = {
    identifier: setName,
    filters: setContent,
  }
  if (currentSets.filter((filter) => filter.identifier === setName).length > 0) {
    return openSnackBar(strings.alerts.duplicateSetName, true)
  }
  if (newSet.filters.length === 0) {
    return openSnackBar(strings.alerts.emptyFilterSet, true)
  }
  await setGlobal({
    [favoritesGlobalKey]: [...currentSets, newSet],
  })
  saveToLocalStorage([...currentSets, newSet], favoritesGlobalKey)
}
