import React from 'react'
import CancelIcon from '@material-ui/icons/Cancel'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import { ICustomChipView } from './interfaces'
import { StyledDiv, StyledType, StyledContent } from './style'

export const CustomChipView: React.FC<ICustomChipView> = ({ type, content, active, onRemove, changeStatus }) => {
  return (
    <StyledDiv active={active}>
      <StyledType>{`${type}:`}</StyledType>
      <StyledContent>{content}</StyledContent>
      {active ? (
        <VisibilityIcon onClick={changeStatus} fontSize="small" />
      ) : (
        <VisibilityOffIcon onClick={changeStatus} fontSize="small" />
      )}
      <CancelIcon onClick={onRemove} fontSize="small" />
    </StyledDiv>
  )
}
