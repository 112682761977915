import { Filter, FilterSchema, FilterType } from '../../constants/filters/filterTypes'
import { AppliedFiltersStateType } from '../../global/components/filtersState'
import { getGlobal } from '../../global/useGlobal'

interface addFilterHandlerType {
  schema: FilterSchema
  newValue: string
  filterState?: Filter[]
  filterStateKey?: keyof AppliedFiltersStateType
}

export const addFilterHandler = (input: addFilterHandlerType) => {
  let newState: Filter[] = []
  const { schema, newValue, filterState, filterStateKey } = input
  if (filterState) {
    newState = filterState
  } else if (filterStateKey) {
    newState = getGlobal()[filterStateKey]
  }
  const newFilter: Filter = {
    name: schema.name,
    label: schema.label,
    value: newValue,
    type: schema.filterType,
    isActive: true,
  }
  const oldFilterIndex = newState.findIndex(({ name }) => name === schema.name)
  const isFilterUnique = schema.filterType === FilterType.unique
  const hasOldFilter = oldFilterIndex !== -1
  const isAlreadyApplied =
    hasOldFilter && newState.findIndex(({ value, name }) => value === newValue && name === schema.name) > -1
  return { isFilterUnique, isAlreadyApplied, newState, hasOldFilter, newFilter, oldFilterIndex }
}
