import React, { useMemo } from 'react'
import { Button } from '../Button'
import { ModalHeader } from '../ModalHeader'
import { IUpdateApprovalInstructionsModal } from './interfaces'
import SaveIcon from '@material-ui/icons/Save'
import { ButtonsContainer } from './style'
import { strings } from '../../resources/strings/strings'
import Table from '../CustomTable'
import { updateApprovalInstructionsSchema } from './schema'
import { useWindowSize } from '../../utils/hooks/useWindowSize'
import { usePromise } from '../../utils/hooks/usePromise'
import { setGlobal, useGlobal } from '../../global/useGlobal'
import { updateApprovalInstructions } from '../../services/approvalInstructions/put/updateApprovalInstructions'
import {
  MODAL_RELATIVE_HEIGHT,
  MODAL_RELATIVE_WIDTH,
  TABLE_TO_MODAL_HEIGHT_PROPORTION,
  TABLE_TO_MODAL_WIDTH_PROPORTION,
} from '../../constants/dimensions'
import { responseApprovalInstructionsSchema } from '../../services/dataSchemas/approvalInstructions/responseSchema'

const UpdateApprovalInstructions: React.FC<IUpdateApprovalInstructionsModal> = ({ handleClose }) => {
  const [updateApprovalInstructionsTableRef] = useGlobal('updateApprovalInstructionsTableRef')
  const [isResponse] = useGlobal('isResponseApprovalInstructionsModalOpen')

  const { general, approvalInstructions, alerts } = strings
  const { windowWidth, windowHeight } = useWindowSize()

  const height = useMemo(() => TABLE_TO_MODAL_HEIGHT_PROPORTION * MODAL_RELATIVE_HEIGHT * windowHeight, [windowHeight])

  const width = useMemo(() => TABLE_TO_MODAL_WIDTH_PROPORTION * MODAL_RELATIVE_WIDTH * windowWidth, [windowWidth])

  const tableStyle = {
    height,
    maxHeight: height,
    width,
    overflow: 'auto',
    position: 'relative',
    display: 'flex',
    margin: '32px auto auto',
  } as React.CSSProperties

  const tableDimensions: [number, number] = [0, 10]

  const onSaveUpdateApprovalInstructions = async () => {
    const data = updateApprovalInstructionsTableRef?.current?.jexcel.getData!()
    if (!data[0][0]) {
      setGlobal({
        dialog: {
          isOpen: true,
          title: alerts.tableEmpty,
          text: alerts.insertDataInTable,
          handleAction: undefined,
        },
      })
      return
    }
    setGlobal({ updateApprovalInstructionsData: data })
    await updateApprovalInstructions()
  }

  const { loading: isSaving, request: doSaveUpdateAI } = usePromise(
    onSaveUpdateApprovalInstructions,
    [updateApprovalInstructionsTableRef?.current],
    {}
  )

  return (
    <React.Fragment>
      <ModalHeader
        title={
          isResponse
            ? approvalInstructions.resultApprovalInstructions.title
            : approvalInstructions.updateApprovalInstructions.title
        }
        handleClose={handleClose}
      />
      {isResponse ? (
        <>
          <Table
            tableRefGlobalKey={'responseApprovalInstructionsTableRef'}
            tableColumnsGlobalKey={'responseApprovalInstructionsColumns'}
            columnWidthsGlobalKey={'responseApprovalInstructionsColumnWidths'}
            tableDataSchema={responseApprovalInstructionsSchema}
            dataGlobalKey={'responseApprovalInstructionsData'}
            tableHeight={height}
            containerHeight={height}
            hasFooter={false}
            tableStyle={tableStyle}
            hasTransparentBackground
            hasInsertRow
            hasDeleteRow
            shouldBlockColumnDrag
          />
        </>
      ) : (
        <Table
          tableRefGlobalKey={'updateApprovalInstructionsTableRef'}
          tableColumnsGlobalKey={'updateApprovalInstructionsColumns'}
          columnWidthsGlobalKey={'updateApprovalInstructionsColumnWidths'}
          tableDataSchema={updateApprovalInstructionsSchema}
          dataGlobalKey={'updateApprovalInstructionsData'}
          tableHeight={height}
          containerHeight={height}
          hasFooter={false}
          tableStyle={tableStyle}
          hasTransparentBackground={true}
          minDimensions={tableDimensions}
          hasInsertRow
          hasDeleteRow
          isEditable
          shouldBlockColumnDrag
        />
      )}
      <ButtonsContainer>
        {!isResponse && <Button color="primary" type="outlined" text={general.cancel} onClick={handleClose} />}
        <Button
          color="primary"
          type="contained"
          text={isResponse ? general.close : general.save}
          startIcon={!isResponse && <SaveIcon />}
          onClick={isResponse ? handleClose : doSaveUpdateAI}
          loading={isSaving}
        />
      </ButtonsContainer>
    </React.Fragment>
  )
}

export default UpdateApprovalInstructions
