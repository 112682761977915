import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'
import { baseTheme } from '../../styles/themes/baseTheme'

export const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-marginDense {
    height: 30px;
  }
  .MuiOutlinedInput-adornedEnd {
    padding-right: 5px;
  }
  .MuiInputLabel-formControl {
    font-size: ${baseTheme.typography.subtitle1.fontSize};
    line-height: 9px;
  }
  .MuiInputLabel-shrink {
    font-size: ${baseTheme.typography.h6.fontSize};
  }
`
