import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import { IFiltersBarView } from './interfaces'
import { FilterContainer, useStyles } from './style'
import { FilterDisplay } from '../FilterDisplay'

export const FiltersBarView: React.FC<IFiltersBarView> = ({ removeFilter, isAppliedFilters }) => {
  const BarContainer = useStyles().root
  return (
    <AppBar position="relative" color="default" className={BarContainer}>
      <FilterContainer value={false} variant="scrollable" scrollButtons="on">
        <FilterDisplay isAppliedFilters={isAppliedFilters} removeFilter={removeFilter} />
      </FilterContainer>
    </AppBar>
  )
}
