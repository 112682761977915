import { createMuiTheme } from '@material-ui/core/styles'
import { baseTheme } from './baseTheme'

export const colors = {
  background: '#323E48',
  black: '#000000',
  error: '#CF6679',
  primary: '#1B70AC',
  primaryVariant: '#00447C',
  secondary: '#4C9DD0',
  surface: '#43525A',
  tableBorder: 'rgba(255, 255, 255, 0.12)',
  tableHeaderBid: '#00A7E1',
  tableHeaderAsk: '#D26254',
  tableColumnVariationDown: '#ED6666',
  tableColumnVariationUp: '#9FE9B4',
  moduleAssetSelectIcon: 'rgba(255, 255, 255, 0.6)',
  white: '#ffffff',
  mediumGray: 'rgba(0, 0, 0, 0.38)',
  lightGray: 'rgba(0, 0, 0, 0.18)',
  opacityBlack: 'rgba(0, 0, 0, 0.7)',
}

export const dark = createMuiTheme({
  overrides: {
    MuiFab: {
      primary: {
        backgroundColor: colors.primary,
        '&:hover': {
          backgroundColor: colors.primary,
          boxShadow: 'null',
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: colors.primary,
          },
        },
        '&:active': {
          boxShadow: 'null',
        },
      },
    },
    MuiButton: {
      outlinedPrimary: {
        border: `null`,
        '&:hover': {
          border: 'null',
        },
      },
      containedPrimary: {
        backgroundColor: colors.primary,
        '&:hover': {
          backgroundColor: colors.primary,
          boxShadow: 'null',
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: colors.primary,
          },
        },
        '&:active': {
          boxShadow: 'null',
        },
      },
    },
  },
  palette: {
    type: 'dark',
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    background: {
      paper: colors.surface,
      default: colors.background,
    },
    error: {
      main: colors.error,
    },
  },
  typography: baseTheme.typography,
})

export default dark
