import React, { useState } from 'react'
import { Grid, Tooltip } from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import { FilterControls, SearchAreaContainer, SearchBarContainer } from './style'
import { SearchBar } from '../SearchBar'
import { Button } from '../Button'
import { CustomButtonGroup } from '../CustomButtonGroup'
import { strings } from '../../resources/strings/strings'
import { ISearchAreaView } from './interfaces'
import { FiltersBar } from '../FiltersBar'
import { FiltersArea } from '../FiltersArea/'
import { filterControlsIcon, restoreLayoutButton, saveCsvIcon, saveXlsxIcon } from '../../resources/assets/index'
import LinearLoading from '../LinearProgress'
import { useLocation } from 'react-router'
import { useGlobal } from '../../global/useGlobal'
import { removeAppliedFilter } from '../../utils/filterHandlers/removeAppliedFilters'
import { getGlobalKeys } from '../../utils/getGlobalKeys'
import FormDialog from '../FormDialog'
import TextField from '@material-ui/core/TextField'
import { saveFilterSet } from '../../utils/favoriteFilterHandling/saveFilterSet'
import { openSnackBar } from '../../services/pageStateService'
import { FavoriteItemsType } from '../FavoriteItemsTypes'
import { deleteFilterSet } from '../../utils/favoriteFilterHandling/deleteFilterSet'
import { applyFilterSet } from '../../utils/favoriteFilterHandling/applyFilterSet'
import { FILTER_SET_NAME_MAX_LENGTH, SEARCH_AREA_BUTTONS_WIDTH } from '../../constants/dimensions'
import FavoriteFiltersModal from '../FavoriteFiltersModal'
import ShowFiltersModal from '../ShowFiltersModal'
import { useWindowSize } from '../../utils/hooks/useWindowSize'
import { FilterDisplay } from '../FilterDisplay'
import { CustomBox } from '../CustomBox'
import { ModalHeader } from '../ModalHeader'
import { clearPageLayout } from '../../utils/localStorageManager'
import { refreshData } from '../../utils/refreshData'
import { onPasteFilters } from '../../utils/onPasteFilters'

const OVERFLOW_WIDTH = 1150

export const SearchAreaView: React.FC<ISearchAreaView> = ({ saveCsv, saveXlsx, isLoading }) => {
  const { labels } = strings.homeScreen
  const { pathname } = useLocation()

  const {
    appliedFiltersGlobalKey,
    selectedFiltersGlobalKey,
    isFilterModalOpenGlobalKey,
    favoriteFiltersGlobalKey,
    columnsGlobalKey,
    columnWidthsGlobalKey,
    schema,
  } = getGlobalKeys(pathname)

  const [filters] = useGlobal(appliedFiltersGlobalKey)
  const [favoriteFilters] = useGlobal(favoriteFiltersGlobalKey)
  const [isModalOpen, setIsModalOpen] = useGlobal(isFilterModalOpenGlobalKey)

  const [filterSetName, setFilterSetName] = useState<string>('')
  const [isFormDialogOpen, setIsFormDialogOpen] = useState(false)
  const [isFavoritesModalOpen, setIsFavoritesModalOpen] = useState(false)
  const [isShowFiltersModalOpen, setIsShowFiltersModalOpen] = useState(false)

  const closeFormDialog = () => setIsFormDialogOpen(false)

  const handleOpenModal = () => setIsModalOpen(true)
  const handleSave = () => setIsFormDialogOpen(true)
  const handleChange = (event: any) => setFilterSetName(event?.target?.value)
  const openFavoritesModal = () => {
    if (favoriteFilters.length) {
      setIsFavoritesModalOpen(true)
    } else {
      openSnackBar(strings.alerts.emptyFavorite, true)
    }
  }
  const openShowFiltersModal = () => setIsShowFiltersModalOpen(true)
  const closeFavoritesModal = () => setIsFavoritesModalOpen(false)
  const closeShowFiltersModal = () => setIsShowFiltersModalOpen(false)

  const saveFilters = () => saveFilterSet(filterSetName, filters, favoriteFiltersGlobalKey)

  const restoreLayout = () => {
    const columnData = window.localStorage.getItem(columnsGlobalKey)
    const columnWidthsData = window.localStorage.getItem(columnWidthsGlobalKey)
    const hasLayoutApplied = !!columnWidthsData || !!columnData

    if (hasLayoutApplied) {
      clearPageLayout([columnsGlobalKey, columnWidthsGlobalKey])
    } else {
      openSnackBar(strings.alerts.noLayoutApplied, false, true)
    }
  }

  const refresh = () => {
    refreshData(pathname)
  }

  const { windowWidth } = useWindowSize()
  const shouldHideFilters = windowWidth < OVERFLOW_WIDTH
  const hasFilter = filters.length

  return (
    <SearchAreaContainer container spacing={1} direction="row" alignItems="center">
      <Grid item>
        <SearchBarContainer onPaste={onPasteFilters({ appliedFiltersGlobalKey, selectedFiltersGlobalKey, schema })}>
          {/*This container allow us to trigger onPaste event on SearchBar*/}
          <SearchBar />
        </SearchBarContainer>
      </Grid>
      <FiltersArea item hasFilter={hasFilter}>
        {!shouldHideFilters ? (
          <FiltersBar
            isAppliedFilters
            removeFilter={removeAppliedFilter(filters, appliedFiltersGlobalKey, selectedFiltersGlobalKey)}
          />
        ) : (
          hasFilter && (
            <Button
              color="primary"
              type="contained"
              text={strings.homeScreen.labels.showFiltersButton}
              onClick={openShowFiltersModal}
            />
          )
        )}
      </FiltersArea>
      <Grid item>
        <FilterControls>
          {isLoading ? (
            <LinearLoading width={SEARCH_AREA_BUTTONS_WIDTH} />
          ) : (
            <>
              <Tooltip title={labels.filterControlsButton}>
                <div>
                  <Button svgIcon={filterControlsIcon} color="primary" type="contained" onClick={handleOpenModal} />
                </div>
              </Tooltip>
              <CustomButtonGroup
                leftButtonLabel={labels.favoriteButtonLabel}
                rightButtonLabel={labels.saveButtonLabel}
                onClickLeftButton={openFavoritesModal}
                onClickRightButton={handleSave}
              />
              <Tooltip title={labels.saveCsvButton}>
                <div>
                  <Button onClick={saveCsv} svgIcon={saveCsvIcon} color="default" type="outlined" />
                </div>
              </Tooltip>
              <Tooltip title={labels.saveXlsxButton}>
                <div>
                  <Button onClick={saveXlsx} svgIcon={saveXlsxIcon} color="default" type="outlined" />
                </div>
              </Tooltip>
              <Tooltip title={labels.restoreLayoutButton}>
                <div>
                  <Button onClick={restoreLayout} svgIcon={restoreLayoutButton} color="default" type="outlined" />
                </div>
              </Tooltip>
              <Tooltip title={labels.refreshLabel}>
                <div>
                  <Button onClick={refresh} materialIcon={<RefreshIcon />} color="default" type="outlined" />
                </div>
              </Tooltip>
            </>
          )}
        </FilterControls>
      </Grid>
      <FormDialog
        handleAction={saveFilters}
        title={strings.favoriteFilters.dialogTitle}
        text={strings.favoriteFilters.dialogText}
        isOpen={isFormDialogOpen}
        handleClose={closeFormDialog}
      >
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label={strings.favoriteFilters.inputLabel}
          fullWidth
          onChange={handleChange}
          inputProps={{ maxLength: FILTER_SET_NAME_MAX_LENGTH }}
        />
      </FormDialog>
      <FavoriteFiltersModal closeModal={closeFavoritesModal} isOpen={isFavoritesModalOpen}>
        {isFavoritesModalOpen &&
          favoriteFilters.map((item, index) => {
            return (
              <FavoriteItemsType
                item={item}
                deleteItem={() => deleteFilterSet(item.identifier, favoriteFiltersGlobalKey)}
                applyItem={() => applyFilterSet(item, appliedFiltersGlobalKey, selectedFiltersGlobalKey)}
                isLastItem={index + 1 === favoriteFilters.length}
              />
            )
          })}
      </FavoriteFiltersModal>
      <ShowFiltersModal closeModal={closeShowFiltersModal} isOpen={isShowFiltersModalOpen}>
        {isShowFiltersModalOpen && (
          <>
            <ModalHeader title={strings.homeScreen.labels.showFiltersModalTitle} handleClose={closeShowFiltersModal} />
            <CustomBox label={strings.homeScreen.labels.customBoxLabel}>
              <FilterDisplay
                removeFilter={removeAppliedFilter(filters, appliedFiltersGlobalKey, selectedFiltersGlobalKey)}
              />
            </CustomBox>
          </>
        )}
      </ShowFiltersModal>
    </SearchAreaContainer>
  )
}
