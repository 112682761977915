export const mockConsolidateVirtualSettlementPosition = async () => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const assets = {
        virtualSettlementRequests: [
          {
            issuerAccountCode: '219007',
            side: 'Lender',
            instrumentSymbol: 'LAME4',
            quantity: 4130,
          },
          {
            issuerAccountCode: '9300',
            side: 'Lender',
            instrumentSymbol: 'BBSE3',
            quantity: 125,
          },
        ],
      }
      resolve(assets)
    }, 1000)
  })
}
