import { HttpMethods } from '../../apiService/baseEndpoint'
import { DeleteApprovalInstructionsEndpoint } from '../endpoint'

export interface ApprovalInstructionsDeleteRequest {
  ids: string[]
}

export const deleteApprovalInstructionsRequest = async (input: ApprovalInstructionsDeleteRequest): Promise<any> => {
  const endpoint = new DeleteApprovalInstructionsEndpoint<ApprovalInstructionsDeleteRequest, undefined[]>(
    '',
    HttpMethods.DELETE,
    input
  )
  return endpoint.execute()
}
