import { Filter } from '../../constants/filters/filterTypes'
import { setGlobal } from '../../global/useGlobal'
import { GlobalState } from '../../global/InitialGlobalState'

export const changeStatus = (
  filters: Filter[],
  selectedFilters: Filter[],
  appliedFiltersGlobalKey: keyof GlobalState,
  selectedFiltersGlobalKey: keyof GlobalState
) => (filter: Filter) => {
  const newState = filters
  const newSelectedFilters = selectedFilters
  const targetFilter = newState.find((el) => el === filter)
  const targetSelectedFilter = newSelectedFilters.find((el) => el === filter)
  if (targetFilter) {
    targetFilter.isActive = !targetFilter?.isActive
    setGlobal({
      [appliedFiltersGlobalKey]: JSON.parse(JSON.stringify(newState)),
      [selectedFiltersGlobalKey]: JSON.parse(JSON.stringify(newState)),
    })
  }
  if (targetSelectedFilter) {
    targetSelectedFilter.isActive = !targetSelectedFilter?.isActive
    setGlobal({ [selectedFiltersGlobalKey]: JSON.parse(JSON.stringify(newSelectedFilters)) })
  }
}
