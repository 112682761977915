import { strings } from '../../resources/strings/strings'
import { stockLoanApprovalInstructionRequestType } from '../approvalInstructions/StockLoanApprovalInstructionsRequestType'
import { stockLoanApprovalInstructionStatus } from '../approvalInstructions/StockLoanApprovalInstructionStatus'
import { stockLoanSide } from '../contracts/stockLoanSide'
import { FilterPatterns, FilterSchema, FilterType, InputStyle, AutoCompleteOptions } from './filterTypes'
import { ColumnNames } from '../../constants/columnNames'

export interface ApprovalInstructionsFilterInputs {
  ID: string
  RequestType: string
  AccountCode: string
  InstrumentSymbol: string
  InstructionStatuses: string
  OrderSide: string
  MinReferenceDate: string
  MaxReferenceDate: string
  MinCreationDate: string
  MaxCreationDate: string
  [key: string]: string
}

export enum ApprovalInstructionsFilterNames {
  ID = 'ID',
  RequestType = 'RequestType',
  AccountCode = 'AccountCode',
  InstrumentSymbol = 'InstrumentSymbol',
  InstructionStatuses = 'InstructionStatuses',
  OrderSide = 'OrderSide',
  MinReferenceDate = 'MinReferenceDate',
  MaxReferenceDate = 'MaxReferenceDate',
  MinCreationDate = 'MinCreationDate',
  MaxCreationDate = 'MaxCreationDate',
}

const { filters } = strings.homeScreen

export const ApprovalInstructionsFilterSchema: FilterSchema[] = [
  {
    name: ApprovalInstructionsFilterNames.AccountCode,
    label: 'Account',
    mask: filters.accountMask,
    value: '',
    size: 3,
    inputStyle: InputStyle.autoComplete,
    filterType: FilterType.multiple,
    filterPattern: FilterPatterns.AccountCode,
    autoCompleteOptions: AutoCompleteOptions.accounts,
    columnReference: ColumnNames.IssuerAccountCode,
  },
  {
    name: ApprovalInstructionsFilterNames.InstrumentSymbol,
    label: 'Symbol',
    mask: filters.symbolMask,
    value: '',
    size: 3,
    inputStyle: InputStyle.autoComplete,
    filterType: FilterType.multiple,
    filterPattern: FilterPatterns.Symbol,
    autoCompleteOptions: AutoCompleteOptions.instruments,
    columnReference: ColumnNames.InstrumentSymbol,
  },
  {
    name: ApprovalInstructionsFilterNames.ID,
    label: 'ID',
    value: '',
    size: 6,
    inputStyle: InputStyle.textField,
    options: stockLoanApprovalInstructionRequestType,
    filterType: FilterType.unique,
    filterPattern: FilterPatterns.OnlyLetters,
    columnReference: ColumnNames.Id,
  },
  {
    name: ApprovalInstructionsFilterNames.InstructionStatuses,
    label: 'Status',
    value: '',
    size: 6,
    inputStyle: InputStyle.dropdown,
    options: stockLoanApprovalInstructionStatus,
    filterType: FilterType.unique,
    filterPattern: FilterPatterns.OnlyLetters,
    columnReference: ColumnNames.InternalStatus,
  },
  {
    name: ApprovalInstructionsFilterNames.OrderSide,
    label: 'Side',
    value: '',
    size: 6,
    inputStyle: InputStyle.radio,
    radios: stockLoanSide,
    filterType: FilterType.unique,
    filterPattern: FilterPatterns.Side,
    columnReference: ColumnNames.Side,
  },
  {
    name: ApprovalInstructionsFilterNames.MinReferenceDate,
    label: 'Min Reference Date',
    mask: filters.dateMask,
    value: '',
    size: 3,
    inputStyle: InputStyle.textField,
    filterType: FilterType.unique,
    filterPattern: FilterPatterns.Date,
    placeholder: filters.datePlaceholder,
  },
  {
    name: ApprovalInstructionsFilterNames.MaxReferenceDate,
    label: 'Max Reference Date',
    mask: filters.dateMask,
    value: '',
    size: 3,
    inputStyle: InputStyle.textField,
    filterType: FilterType.unique,
    filterPattern: FilterPatterns.Date,
    placeholder: filters.datePlaceholder,
  },
  {
    name: ApprovalInstructionsFilterNames.MinCreationDate,
    label: 'Min Creation Date',
    mask: filters.dateTimeMask,
    value: '',
    size: 3,
    inputStyle: InputStyle.textField,
    filterType: FilterType.unique,
    filterPattern: FilterPatterns.Datetime,
    placeholder: filters.dateTimePlaceholder,
  },
  {
    name: ApprovalInstructionsFilterNames.MaxCreationDate,
    label: 'Max Creation Date',
    mask: filters.dateTimeMask,
    value: '',
    size: 3,
    inputStyle: InputStyle.textField,
    filterType: FilterType.unique,
    filterPattern: FilterPatterns.Datetime,
    placeholder: filters.dateTimePlaceholder,
  },
  {
    name: ApprovalInstructionsFilterNames.RequestType,
    label: 'Request Type',
    value: '',
    size: 6,
    inputStyle: InputStyle.dropdown,
    options: stockLoanApprovalInstructionRequestType,
    filterType: FilterType.unique,
    filterPattern: FilterPatterns.OnlyLetters,
    columnReference: ColumnNames.RequestType,
  },
]
