import { ColumnNames } from '../columnNames'
import { Instrument } from '../../services/instruments/interfaces/instrumentSymbolsFieldsDTO'
import { TreatedDefaultDatesType } from '../../services/defaultDates/interfaces/TreatedDefaultDatesType'
import { Accounts } from '../../services/accounts/interfaces/accountsFieldsDTO'

export const getAutocompleteColumnsInfo = (
  instrumentInfo?: Instrument,
  defaultDates?: TreatedDefaultDatesType,
  accountsInfo?: Accounts
) => {
  if (instrumentInfo) {
    return [
      {
        name: ColumnNames.LenderReversible,
        content: 'Yes',
      },
      {
        name: ColumnNames.TenderOfferLenderReversible,
        content: 'Yes',
      },
      {
        name: ColumnNames.InterBrokerFee,
        content: instrumentInfo?.refData?.avgInterBrokerFee || '',
      },
      {
        name: ColumnNames.AverageLenderInterest,
        content: instrumentInfo?.refData?.avgLenderInterest || '',
      },
      {
        name: ColumnNames.AverageBorrowerCharge,
        content: instrumentInfo?.refData?.avgBorrowerCharge || '',
      },
      {
        name: ColumnNames.ExpireDate,
        content: defaultDates?.expireDate || '',
      },
      {
        name: ColumnNames.LockUpDate,
        content: defaultDates?.lockUpDate || '',
      },
      {
        name: ColumnNames.ReferenceDate,
        content: defaultDates?.referenceDate || '',
      },
    ]
  } else {
    return [
      {
        name: ColumnNames.IssuerCustodyAccountCode,
        content: accountsInfo?.custodyAccountCode || '',
      },
      {
        name: ColumnNames.IssuerCustodianCode,
        content: accountsInfo?.custodianCode || '',
      },
    ]
  }
}
