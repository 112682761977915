import React, { useEffect } from 'react'
import { CustomChip } from '../CustomChip'
import { IFilterDisplay } from './interfaces'
import { changeStatus } from '../../utils/filterHandlers/changeStatus'
import { useLocation } from 'react-router'
import { useGlobal } from '../../global/useGlobal'
import { Filter } from '../../constants/filters/filterTypes'
import { getGlobalKeys } from '../../utils/getGlobalKeys'

export const FilterDisplayView: React.FC<IFilterDisplay> = ({ removeFilter, isAppliedFilters }) => {
  const { pathname } = useLocation()

  const { appliedFiltersGlobalKey, selectedFiltersGlobalKey } = getGlobalKeys(pathname)

  const [filters] = useGlobal(appliedFiltersGlobalKey)
  const [selectedFilters] = useGlobal(selectedFiltersGlobalKey)

  const filtersToMap: Filter[] = isAppliedFilters ? filters : selectedFilters

  return (
    <>
      {filtersToMap?.map((filter) => (
        <CustomChip
          key={`${filter.name}-${filter.value}`}
          type={filter.label}
          content={filter.value}
          active={filter.isActive}
          changeStatus={() =>
            changeStatus(filters, selectedFilters, appliedFiltersGlobalKey, selectedFiltersGlobalKey)(filter)
          }
          onRemove={() => removeFilter(filter)}
        />
      ))}
    </>
  )
}
