import { useForm } from 'react-simple-hooks-forms'
import { useMemo, useState } from 'react'
import { strings } from '../../resources/strings/strings'
import { resetPasswordRequest } from '../../services/auth/ResetPassword'
import { resetPasswordConfig } from '../../constants/formsConfig/resetPassword'
import { openSnackBar } from '../../services/pageStateService'
import { useGlobal } from '../../global/useGlobal'

export class ResetPasswordVM {
  static initVM() {
    return useMemo(() => new ResetPasswordVM(), [])
  }

  useDataHook() {
    const { resetPasswordScreen: screenStrings } = strings
    const [username] = useGlobal('username')
    const [isPasswordResetDone, setIsPasswordResetDone] = useState(false)
    const [errorMessage, setErrorMessage] = useState({
      email: '',
      oldPassword: '',
      password: '',
      confirmPassword: '',
    })

    const { Form, submit } = useForm({ ...resetPasswordConfig })

    const onSubmit = async () => {
      return await submit(
        (values) => {
          const { password, oldPassword, email } = values
          const payload: { username: string; token: string; password: string } = {
            username: email,
            token: oldPassword,
            password: password,
          }

          resetPasswordRequest(payload)
            .then((res) => {
              openSnackBar(res.message)
              setIsPasswordResetDone(true)
            })
            .catch((err) => openSnackBar(err.error.message, true))
          return payload
        },
        (error) => {
          setErrorMessage({
            email: error.email,
            oldPassword: error.oldPassword,
            password: error.password,
            confirmPassword: error.confirmPassword,
          })
        }
      )
    }

    return {
      Form,
      onSubmit,
      errorMessage,
      screenStrings,
      isPasswordResetDone,
    }
  }
}
