import { AccountsFieldsDTO } from './interfaces/accountsFieldsDTO'

export const mockAccountsRequest = async (): Promise<AccountsFieldsDTO> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const assets = {
        count: 22,
        accounts: [
          {
            accountNumber: '2056639',
            custodianCode: 1027,
            custodyAccountCode: '1040734',
          },
          {
            accountNumber: '75342',
            custodianCode: 1028,
            custodyAccountCode: '1040735',
          },
          {
            accountNumber: '201442',
            custodianCode: 1029,
            custodyAccountCode: '1040736',
          },
          {
            accountNumber: '359658',
            custodianCode: 1030,
            custodyAccountCode: '1040737',
          },
          {
            accountNumber: '887086',
            custodianCode: 1031,
            custodyAccountCode: '1040738',
          },
          {
            accountNumber: '182839',
            custodianCode: 1032,
            custodyAccountCode: '1040739',
          },
          {
            accountNumber: '275598',
            custodianCode: 1033,
            custodyAccountCode: '1040740',
          },
          {
            accountNumber: '196826',
            custodianCode: 1034,
            custodyAccountCode: '1040741',
          },
          {
            accountNumber: '185856',
            custodianCode: 1035,
            custodyAccountCode: '1040742',
          },
          {
            accountNumber: '984236',
            custodianCode: 1036,
            custodyAccountCode: '1040743',
          },
          {
            accountNumber: '1041081',
            custodianCode: 1037,
            custodyAccountCode: '1040744',
          },
          {
            accountNumber: '133414',
            custodianCode: 1038,
            custodyAccountCode: '1040745',
          },
          {
            accountNumber: '538702',
            custodianCode: 1039,
            custodyAccountCode: '1040746',
          },
          {
            accountNumber: '152909',
            custodianCode: 1040,
            custodyAccountCode: '1040747',
          },
          {
            accountNumber: '3100237',
            custodianCode: 1041,
            custodyAccountCode: '1040748',
          },
          {
            accountNumber: '264468',
            custodianCode: 1042,
            custodyAccountCode: '1040749',
          },
          {
            accountNumber: '1040733',
            custodianCode: 1026,
            custodyAccountCode: '1040733',
          },
          {
            accountNumber: '8108',
            custodianCode: 1043,
            custodyAccountCode: '1040750',
          },
          {
            accountNumber: '98018',
            custodianCode: 1044,
            custodyAccountCode: '1040751',
          },
          {
            accountNumber: '551339',
            custodianCode: 1045,
            custodyAccountCode: '1040752',
          },
          {
            accountNumber: '985507',
            custodianCode: 1046,
            custodyAccountCode: '1040753',
          },
          {
            accountNumber: '98423',
            custodianCode: 1047,
            custodyAccountCode: '1040754',
          },
        ],
      }
      resolve(assets)
    }, 1000)
  })
}
