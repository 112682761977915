import { CellValue } from '../../components/jexcel-interfaces'

export const setValueFromCoords = (
  tableRef: any,
  columnIndex: number | undefined,
  rowIndex: number | undefined,
  content?: CellValue | null
) => {
  tableRef?.current?.jexcel.setValueFromCoords!(columnIndex, rowIndex, content, true)
}
