import styled from 'styled-components'
import { colors } from '../../styles/themes/light'
import { baseTheme } from '../../styles/themes/baseTheme'

export const StyledBox = styled.div`
  border: 1px solid ${colors.lightGray};
  margin: 16px;
  padding: 0 16px;
  border-radius: 4px;
  box-sizing: border-box;
  height: 177px;
`

export const StyledLabel = styled.p`
  margin: 4px 0px;
  font-size: ${baseTheme.typography.subtitle2.fontSize};
  line-height: 16px;
  color: ${colors.mediumGray};
`

export const ChipContainer = styled.div`
  display: flex;
  height: 150px;
  box-align: start;
  align-content: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  overflow: auto;
`
