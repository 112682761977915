import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'

export const SearchAreaContainer = styled(Grid)`
  padding: 4px 40px;
  min-height: 64px;
`

export const FilterControls = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`

export const SearchBarContainer = styled.div`
  width: 100%;
  height: 100%;
`
