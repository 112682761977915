import { GlobalState } from '../../global/InitialGlobalState'
import { getGlobal, setGlobal } from '../../global/useGlobal'
import { FilterSet } from '../../global/components/favoriteFiltersState'
import { saveToLocalStorage } from '../localStorageManager'

export const deleteFilterSet = async (filterSetName: string, favoritesGlobalKey: keyof GlobalState) => {
  const currentSets = getGlobal()[favoritesGlobalKey]
  const newSets = currentSets.filter((set: FilterSet) => set.identifier !== filterSetName)
  await setGlobal({
    [favoritesGlobalKey]: newSets,
  })
  saveToLocalStorage(newSets, favoritesGlobalKey)
}
