import { setGlobal } from '../global/useGlobal'
import light from '../styles/themes/light'
import { GlobalState } from '../global/InitialGlobalState'

export const saveAuthInfo = (isLogged: boolean, accessToken?: string) => {
  window.localStorage.setItem('isLogged', isLogged.toString())
  if (accessToken) {
    window.localStorage.setItem('accessToken', accessToken)
  } else {
    window.localStorage.removeItem('accessToken')
  }
}

export const clearPageLayout = (layoutKeys: (string | keyof GlobalState)[]) => {
  layoutKeys.forEach((key) => {
    window.localStorage.removeItem(key as string)
  })
  window.location.reload()
}

export const saveUsernameInfo = (username: string) => {
  window.localStorage.setItem('username', username)
}

export const removeUsernameInfo = () => {
  window.localStorage.removeItem('username')
}

export const getUsernameInfo = () => {
  return window.localStorage.getItem('username') || ''
}

export const getToken = () => {
  return window.localStorage.getItem('accessToken') || ''
}

export const getAuthInfo = () => {
  const isLoggedStr = window.localStorage.getItem('isLogged')
  const isLogged = isLoggedStr === 'true'
  return {
    isLogged,
  }
}

export const logout = () => {
  window.localStorage.setItem('isLogged', 'false')
  window.localStorage.removeItem('accessToken')
  window.localStorage.removeItem('profileInfo')
  window.localStorage.removeItem('username')
  setGlobal({
    accessToken: undefined,
    username: undefined,
    isLoggedIn: false,
    theme: light,
  })
  window.location.reload()
}

export const checkLogin = () => {
  return window.localStorage.getItem('isLogged') === 'true'
}

export const getPersistedTheme = () => {
  return light
}

export const saveProfileInfo = (profileInfo: string[]) => {
  window.localStorage.setItem('profileInfo', JSON.stringify(profileInfo))
}

export const saveToLocalStorage = (content: any, key: string) => {
  window.localStorage.setItem(key, JSON.stringify(content))
}

export const getPersistedData = (globalKey: string) => {
  const persistedData = window.localStorage.getItem(globalKey)
  return persistedData ? JSON.parse(persistedData) : undefined
}
