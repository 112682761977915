import { CellValue, Options } from '../../components/jexcel-interfaces'
import { RefObject } from 'react'
import { ApprovalInstructionsColumnsSchema } from '../../services/dataSchemas/approvalInstructions/interfaces'
import { approvalInstructionsDefaultColumnOrder } from '../../services/dataSchemas/approvalInstructions/approvalInstructionsDefaultColumnOrder'
import { AIRequestTypes, RequestTypeModalTitle } from '../../constants/approvalInstructions/requestType'
import { getPersistedData } from '../../utils/localStorageManager'
import { createApprovalInstructionsOrder } from '../../components/CreateApprovalInstructions/createApprovalInstructionsOrder'
import { updateApprovalInstructionsOrder } from '../../components/UpdateApprovalInstructions/updateApprovalInstructionsOrder'

interface ApprovalInstructionsStateType {
  approvalInstructionsData: CellValue[][]
  approvalInstructionsColumns: (keyof ApprovalInstructionsColumnsSchema)[]
  approvalInstructionsTableRef?: RefObject<{ jexcel: Options } & HTMLDivElement>
  approvalInstructionsColumnWidths?: number[]
  currentApprovalInstructionsPage: number
  approvalInstructionsPaginationArgs: any
  approvalInstructionsAppliedSorting: string[]
}

export const approvalInstructionsState: ApprovalInstructionsStateType = {
  approvalInstructionsData: [],
  approvalInstructionsColumns:
    getPersistedData('approvalInstructionsColumns') || approvalInstructionsDefaultColumnOrder,
  approvalInstructionsTableRef: undefined,
  approvalInstructionsColumnWidths: getPersistedData('approvalInstructionsColumnWidths'),
  currentApprovalInstructionsPage: 1,
  approvalInstructionsPaginationArgs: {},
  approvalInstructionsAppliedSorting: [],
}

interface CreateApprovalInstructionsStateType {
  createApprovalInstructionsTableRef?: RefObject<{ jexcel: Options } & HTMLDivElement>
  createApprovalInstructionsColumnWidths?: number[]
  createApprovalInstructionsColumns: string[]
  createApprovalInstructionsData?: any[]
  isCreateApprovalInstructionsModalOpen: boolean
}

export const createApprovalInstructionsState: CreateApprovalInstructionsStateType = {
  createApprovalInstructionsTableRef: undefined,
  createApprovalInstructionsColumns: createApprovalInstructionsOrder,
  createApprovalInstructionsColumnWidths: getPersistedData('createApprovalInstructionsColumnWidths'),
  createApprovalInstructionsData: undefined,
  isCreateApprovalInstructionsModalOpen: false,
}

interface UpdateApprovalInstructionsStateType {
  updateApprovalInstructionsTableRef?: RefObject<{ jexcel: Options } & HTMLDivElement>
  updateApprovalInstructionsColumnWidths?: number[]
  updateApprovalInstructionsColumns: string[]
  updateApprovalInstructionsData?: any[]
  isUpdateApprovalInstructionsModalOpen: boolean
}

export const updateApprovalInstructionsState: UpdateApprovalInstructionsStateType = {
  updateApprovalInstructionsTableRef: undefined,
  updateApprovalInstructionsColumnWidths: getPersistedData('updateApprovalInstructionsColumnWidths'),
  updateApprovalInstructionsColumns: updateApprovalInstructionsOrder,
  updateApprovalInstructionsData: undefined,
  isUpdateApprovalInstructionsModalOpen: false,
}

interface ResponseApprovalInstructionsStateType {
  responseApprovalInstructionsTableRef?: RefObject<{ jexcel: Options } & HTMLDivElement>
  responseApprovalInstructionsColumnWidths?: number[]
  responseApprovalInstructionsColumns: string[]
  responseApprovalInstructionsData?: any[]
  isResponseApprovalInstructionsModalOpen: boolean
}

export const responseApprovalInstructionsState: ResponseApprovalInstructionsStateType = {
  responseApprovalInstructionsTableRef: undefined,
  responseApprovalInstructionsColumns: createApprovalInstructionsOrder,
  responseApprovalInstructionsColumnWidths: getPersistedData('responseApprovalInstructionsColumnWidths'),
  responseApprovalInstructionsData: undefined,
  isResponseApprovalInstructionsModalOpen: false,
}

interface RequestTypeApprovalInstructionsStateType {
  modalTitle: RequestTypeModalTitle
  requestType: AIRequestTypes | undefined
  requestApprovalInstructionsColumns: string[]
}

export const requestTypeApprovalInstructionsState: RequestTypeApprovalInstructionsStateType = {
  modalTitle: RequestTypeModalTitle.Create,
  requestType: undefined,
  requestApprovalInstructionsColumns: createApprovalInstructionsOrder,
}
