import styled from 'styled-components'
import Modal from '@material-ui/core/Modal'
import { colors } from '../../styles/themes/light'
import { MIN_MODAL_HEIGHT, MIN_MODAL_WIDTH } from '../../constants/dimensions'

export const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ModalContainer = styled.div<any>`
  width: 60%;
  height: fit-content;
  min-height: ${({ filters }) => (filters ? 468 : MIN_MODAL_HEIGHT)}px;
  outline: none;
  min-width: ${MIN_MODAL_WIDTH}px;
  background-color: ${colors.white};
  box-shadow: ${colors.opacityBlack};
`
