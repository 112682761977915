import styled from 'styled-components'
import { colors } from '../../styles/themes/light'
import { baseTheme } from '../../styles/themes/baseTheme'

export const StyledDiv = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: initial;
  background-color: ${({ active }) => (active ? colors.primary : colors.secondary)};
  width: fit-content;
  border-radius: 35px;
  padding: 0 8px;
  font-size: ${baseTheme.typography.body2.fontSize};
  color: ${colors.white};
  margin: 0 8px 8px 0;
  &:last-child {
    margin-right: 0;
  }
  height: 30px;
`

export const StyledType = styled.span`
  margin-right: 4px;
  font-weight: bold;
  text-transform: capitalize;
`

export const StyledContent = styled.span`
  margin-right: 4px;
  letter-spacing: 0.5px;
`
