export enum AIRequestTypes {
  CarryingPosition = 'CarryingPosition',
  RenewalRequest = 'RenewalRequest',
  SettlementRequest = 'SettlementRequest',
  OfferInclusion = 'OfferInclusion',
}

export enum RequestTypeModalTitle {
  Create = 'Create: Approval Instructions',
  CarryingPosition = 'Create (Carrying Approvals): Approval Instructions',
  RenewalRequest = 'Create (Renew Approvals): Approval Instructions',
  SettlementRequest = 'Create (Settle Approvals): Approval Instructions',
  OfferInclusion = 'Create (Add Offer Approvals): Approval Instructions',
}
