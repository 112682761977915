import React from 'react'
import { ClickableTooltip, IconWrapper } from '../WorkspaceMenu/styles'
import { ISvgIconButton } from './interfaces'
import { Link } from 'react-router-dom'
import { StyledLoadingButtonContainer } from './style'
import { LocalLoading } from '../Loading'
import { colors } from '../../styles/themes/dark'

const NavigationButton: React.FC<ISvgIconButton> = ({
  isLoading,
  onClick,
  label,
  route,
  children,
  isButtonLoading,
}) => {
  if (route) {
    return (
      <Link to={route}>
        <ClickableTooltip title={label}>
          <IconWrapper>{children}</IconWrapper>
        </ClickableTooltip>
      </Link>
    )
  } else if (isButtonLoading) {
    return (
      <StyledLoadingButtonContainer>
        <LocalLoading size={40} thickness={2} color={colors.secondary} />
      </StyledLoadingButtonContainer>
    )
  } else {
    return (
      <ClickableTooltip onClick={onClick} title={label}>
        <IconWrapper isLoading={isLoading}>{children}</IconWrapper>
      </ClickableTooltip>
    )
  }
}

export default NavigationButton
