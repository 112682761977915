import React from 'react'
import { IFormDialog } from './interfaces'
import Dialog from '@material-ui/core/Dialog'
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import { strings } from '../../resources/strings/strings'

const FormDialogView: React.FC<IFormDialog> = ({ isOpen, title, text, handleClose, handleAction, children }) => {
  const onClickAction = () => {
    if (handleAction) {
      handleAction()
    }
    handleClose()
  }
  return (
    <>
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{text}</DialogContentText>
          {children}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {strings.general.cancel}
          </Button>
          <Button onClick={onClickAction} color="primary">
            {strings.general.ok}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default FormDialogView
