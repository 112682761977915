import { DataSchema } from '../../services/dataSchemas/interfaces'
import { MIN_COLUMN_WIDTH } from '../../constants/dimensions'
import { intMask } from '../../constants/masks/masks'

export const settleSchema: { [key: string]: DataSchema } = {
  contractCode: {
    title: 'Contract Code',
    type: 'text',
    tooltipName: 'Contract Code',
    mask: '000000000000000000-0',
  },
  instrumentSymbol: {
    title: 'Symbol',
    tooltipName: 'Instrument Symbol',
    type: 'text',
    mask: 'aaaa0',
    readOnly: true,
  },
  effectiveQuantity: {
    title: 'Effective Qty',
    type: 'text',
    tooltipName: 'Effective Quantity',
    readOnly: true,
    width: MIN_COLUMN_WIDTH,
  },
  quantity: {
    title: 'Qty',
    type: 'text',
    tooltipName: 'Quantity',
    mask: intMask,
    width: MIN_COLUMN_WIDTH,
  },
  side: {
    title: 'Side',
    type: 'text',
    tooltipName: 'Side',
    readOnly: true,
  },
  carryingBroker: {
    title: 'Carrying Broker',
    type: 'text',
    tooltipName: 'Carrying Broker',
    readOnly: true,
  },
  accountCode: {
    title: 'Account',
    type: 'text',
    tooltipName: 'Account Code',
    readOnly: true,
  },
  internalStatus: {
    title: 'Status',
    type: 'text',
    tooltipName: 'Internal Status',
    readOnly: true,
  },
  errorRuleIds: {
    title: 'Error Rule IDs',
    type: 'text',
    tooltipName: 'Error Rule IDs',
    readOnly: true,
  },
}
