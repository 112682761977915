export const strings = {
  general: {
    isRequired: 'This field is required.',
    connectionError: 'Network connection lost, please refresh the page.',
    save: 'SAVE',
    cancel: 'CANCEL',
    loadMore: 'Load More',
    loadAll: 'Load All',
    close: 'CLOSE',
    ok: 'OK',
    sortAsc: 'Sort Asc.',
    sortDesc: 'Sort Desc.',
    undoSorting: 'Undo Sorting',
    none: 'None',
    results: 'Results',
    emptyOptions: 'No options',
    apply: 'Apply',
    delete: 'Delete',
    syncingExtranet: 'Logging from Extranet...',
  },
  alerts: {
    tableEmpty: 'The table cannot be empty',
    insertDataInTable: 'Please insert data in table',
    invalidSettle: 'Contract code and quantity are required for settling',
    unableToDownloadTable: "Can't download an empty table",
    unknownError: 'Unknown error. Please try refreshing this page.',
    selectSRData: 'Select the Settlement Requests you want to delete',
    selectAIData: 'Select the Approval Instructions you want to delete',
    selectAIDataToUpdate: 'Select the Approval Instructions you want to update',
    selectADData: 'No divergences selected',
    duplicateSetName: 'This set name is already taken',
    emptyFilterSet: "Can't save an empty set",
    emptyFavorite: "You don't have favorite sets yet!",
    invalidToken: 'Invalid credentials',
    genericError: 'An error has occurred, try again.',
    deleteSuccess: 'Request sent successfully',
    invalidInputLength: 'Invalid input',
    invalidDateInput: 'Invalid date',
    invalidEnumValue: 'Invalid value',
    invalidSide: 'Invalid side',
    appliedFilterDuplicate: 'This filter is already applied',
    selectedFilterDuplicate: 'This filter is already selected',
    noLayoutApplied: 'No layout currently applied!',
    noSymbols: 'Unknown symbol',
    noAccount: 'Unknown account',
  },
  navigationButtonsLabels: {
    settle: 'Settle',
    virtualSettle: 'Virtual Settle',
    contracts: 'Contracts',
    settlementRequests: 'Settlement Requests',
    settlements: 'Settlements',
    approvalInstructions: 'Approval Instructions',
    approvalDivergences: 'Approval Divergences',
    deleteSettlementRequests: 'Delete selected Settlement Requests',
    createApprovalInstructions: 'Create',
    deleteApprovalInstructions: 'Delete',
    updateApprovalInstructions: 'Update',
    carryingApprovalInstructions: 'Carrying Position',
    renewApprovalInstructions: 'Renewal Request',
    settleApprovalInstructions: 'Settlement Request',
    addOfferApprovalInstructions: 'Offer Inclusion',
    createAIFromApprovalDivergences: 'Create Approval Instructions from divergences',
  },
  homeScreen: {
    searchBar: {
      searchBarNoOptions: 'No suggestions for ',
      label: 'Type an Asset Symbol, Expire Date or other filters',
      loading: 'Loading...',
      emptyOptions: 'Start typing for suggestions',
    },
    labels: {
      applyFiltersButton: 'APPLY FILTERS',
      favoriteButton: 'FAVORITE',
      filterControlsButton: 'Filter Controls',
      clearFiltersButton: 'CLEAR FILTERS',
      saveCsvButton: 'Save as .CSV',
      saveXlsxButton: 'Save as .XLSX',
      restoreLayoutButton: 'Restore Layout',
      settleButton: 'SETTLE',
      showFiltersButton: 'SHOW FILTERS',
      showFiltersModalTitle: 'Filters',
      modalFiltersTitle: 'Filter Controls',
      codeInputLabel: 'Contract Code',
      accountInputLabel: 'Account',
      symbolInputLabel: 'Symbol',
      statusInputLabel: 'Status',
      sideInputLabel: 'Side',
      awaitingSettlementInputLabel: 'Awaiting Settlement',
      effectiveInputLabel: 'Effective',
      borrowerInputLabel: 'Borrower',
      lenderInputLabel: 'Lender',
      minExpireDateInputLabel: 'Min Expire Date',
      maxExpireDateInputLabel: 'Max Expire Date',
      minCreationDateInputLabel: 'Min Creation Date',
      maxCreationDateInputLabel: 'Max Creation Date',
      customBoxLabel: 'Selected Filters',
      favoriteButtonLabel: 'Favorites',
      saveButtonLabel: 'Save',
      deleteButtonLabel: 'Delete',
      resultsLabel: 'Results',
      expireDateInputLabel: 'Expire Date',
      settlementDateInputLabel: 'Settlement Date',
      refreshLabel: 'Refresh',
    },
    filters: {
      codeInputName: 'Code',
      accountInputName: 'AccountCode',
      symbolInputName: 'InstrumentSymbol',
      awaitingSettlementInputName: 'AwaitingSettlement',
      effectiveInputName: 'Effective',
      borrowerInputName: 'Borrower',
      lenderInputName: 'Lender',
      minExpireDateInputName: 'MinExpireDate',
      maxExpireDateInputName: 'MaxExpireDate',
      minCreationDateInputName: 'MinCreationDate',
      maxCreationDateInputName: 'MaxCreationDate',
      statusInputName: 'ContractInternalStatuses',
      sideInputName: 'OrderSide',
      searchBarName: 'search',
      codeMask: '999999999999999999-9',
      dateMask: '*+99-99-99',
      dateTimeMask: '*+99-99-99 99:99:99',
      symbolMask: '*aaa9***',
      accountMask: '999999999',
      datePlaceholder: 'YYYY-MM-DD',
      dateTimePlaceholder: 'YYYY-MM-DD HH:mm:ss',
      expireDateInputName: 'ExpireDate',
      settlementDateInputName: 'SettlementDate',
      executionBrokerCode: '9999',
    },
  },
  settleTable: {
    title: 'Create Settlement Request Legs',
  },
  virtualSettle: {
    requestTitle: 'Create Virtual Settlement Requests',
    responseTitle: 'Virtual Settlement Requests',
    successTableName: 'Settlement Request Legs',
    failedTableName: 'Virtual Settlement Requests',
    errorMessage: 'Failed to contact the server, try again',
    errorTitle: 'An error has occurred',
  },
  loginScreen: {
    errors: {
      loginError: 'Unauthorized access. Check your User and Password.',
    },
    labels: {
      usernameInputLabel: 'User',
      passwordInputLabel: 'Password',
      checkboxInputLabel: 'Remember my User',
      forgetPasswordButtonLabel: 'Forgot your password',
      loginButtonLabel: 'Log In',
    },
    messages: {
      loginSuccess: 'Success!',
      expiredLogin: 'Password expired, please reset your password!',
      aboutToExpireNotification: (days?: number) => {
        return days
          ? `Your login is about to expire in ${days} ${days > 1 ? 'days' : 'day'}, please reset your password!`
          : 'Your login will expire today, please reset your password!'
      },
    },
  },
  forgotPasswordScreen: {
    errors: {
      invalidEmail: 'Invalid e-mail.',
    },
    labels: {
      userEmailInputField: 'Email',
      backButtonLabel: 'BACK',
      sendButtonLabel: 'SEND LINK',
      firstStepSubtitle: 'Enter your email to start the password recover process:',
      secondStepTitle: 'Please, check your email inbox!',
      secondStepSubtitle: 'A link was sent with instructions to reset your password',
      confirmButton: 'Ok!',
    },
    messages: {},
  },
  favoriteFilters: {
    dialogTitle: 'Save filter set',
    dialogText: 'Choose a name for this set of filters',
    inputLabel: 'Set name',
  },
  resetPasswordScreen: {
    errors: {
      invalidEmail: 'Please insert a valid e-mail.',
      invalidLength: 'Your password must be between 8 and 20 characters.',
      outOfPattern:
        'Your password must contain at least one letter (an uppercase and an undercase), a number and a special character (@ # $ % & * , .).',
      mustMatch: 'The password must match.',
    },
    labels: {
      usernameInputLabel: 'E-mail',
      oldPasswordInputLabel: 'Old Password',
      passwordInputLabel: 'New Password',
      confirmPasswordInputLabel: 'Repeat New Password',
      submitButtonLabel: 'Ok!',
      secondScreenTitle: 'Your password has been successfully reset!',
      redirectButton: 'BACK TO LOGIN',
    },
    messages: {},
  },
  settlementRequests: {
    deleteDialog: {
      title: 'Delete selected requests?',
      description: 'This action cannot be undone.',
    },
  },
  approvalInstructions: {
    createApprovalInstructions: {
      title: 'Create: Approval Instructions',
    },
    updateApprovalInstructions: {
      title: 'Update: Approval Instructions',
    },
    resultApprovalInstructions: {
      title: 'Approval Instructions Done',
    },
    deleteDialog: {
      title: 'Delete selected approval instructions?',
      description: 'This action cannot be undone.',
    },
  },
}
