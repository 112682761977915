import { RefObject } from 'react'
import { Options } from '../components/jexcel-interfaces'
import { createApprovalInstructionsOrder } from '../components/CreateApprovalInstructions/createApprovalInstructionsOrder'
import { openSnackBar } from '../services/pageStateService'
import { strings } from '../resources/strings/strings'
import { setValueFromCoords } from './jexcelMethods/setValueFromCoords'
import { getAutocompleteColumnsInfo } from '../constants/approvalInstructions/getAutocompleteColumnsInfo'
import { Accounts } from '../services/accounts/interfaces/accountsFieldsDTO'

interface OnAccountInputType {
  tableRef: RefObject<{ jexcel: Options } & HTMLDivElement> | undefined
  content: string
  rowIndex: number
  columnIndex: number
  accounts: Accounts[] | undefined
  autoCompleteColumns?: boolean
}

export const onAccountInput = (input: OnAccountInputType) => {
  const { columnIndex, content, accounts, rowIndex, tableRef, autoCompleteColumns } = input
  const account = content

  if (!tableRef) return
  if (accounts?.some((value) => value.accountNumber === account)) {
    if (!autoCompleteColumns) return

    const accountsInfo = accounts?.find((item) => {
      return item.accountNumber === account
    })

    const columnsInfo = getAutocompleteColumnsInfo(undefined, undefined, accountsInfo)

    columnsInfo.forEach((info) => {
      const index = createApprovalInstructionsOrder.indexOf(info.name)
      setValueFromCoords(tableRef, index, rowIndex, info.content.toString())
    })
  } else {
    tableRef?.current?.jexcel.setValueFromCoords!(columnIndex, rowIndex, '')
    openSnackBar(strings.alerts.noAccount, undefined, true)
  }
}
