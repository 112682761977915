export interface StockLoanSettlementInternalStatusType {
  confirmed: string
  discarded: string
  projected: string
  unset: string
  [key: string]: string
}

export const stockLoanSettlementInternalStatus: StockLoanSettlementInternalStatusType = {
  confirmed: 'Confirmed',
  discarded: 'Discarded',
  projected: 'Projected',
  unset: 'Unset',
}
