import { strings } from '../../resources/strings/strings'
import validate from 'validate.js'

const { resetPasswordScreen, general } = strings

const constraints = {
  email: {
    format: {
      pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
      message: `^${resetPasswordScreen.errors.invalidEmail}`,
    },
  },
  oldPassword: {
    presence: {
      message: `^${general.isRequired}`,
    },
  },
  password: {
    length: {
      maximum: 20,
      tooLong: `^${resetPasswordScreen.errors.invalidLength}`,
      minimum: 8,
      tooShort: `^${resetPasswordScreen.errors.invalidLength}`,
    },
    presence: {
      message: `^${general.isRequired}`,
    },
    format: {
      pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.,])[A-Za-z\d@$!%*?&.,]{8,}$/,
      message: `^${resetPasswordScreen.errors.outOfPattern}`,
    },
  },
  confirmPassword: {
    equality: {
      attribute: 'password',
      message: `^${resetPasswordScreen.errors.mustMatch}`,
    },
    presence: {
      message: `^${general.isRequired}`,
    },
  },
}

const validator = (values: any) => {
  return validate(values, constraints)
}

export const resetPasswordConfig = {
  formName: 'resetPassword',
  initialValues: {},
  validator,
}
