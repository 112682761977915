import { SettlementRequestsFieldsDTO } from './interfaces/settlementRequestsFieldsDTO'

export const mockGetSettlementRequests = async (): Promise<SettlementRequestsFieldsDTO> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const assets = {
        paginationSummary: {
          count: 99,
          totalCount: 99,
          pageNum: 1,
          pageSize: 100,
          sorting: null,
        },
        settlementRequestLegs: [
          {
            id: 'd9d6f2ff0c3441e08c0f544a6fd04057',
            exchangeTransactionId: '2019-12-05-1-3700',
            originTransactionId: '2019-12-05-1-989',
            contractCode: '201911270023940772-1',
            quantity: 100,
            side: 'Borrower',
            externalStatus: 'Completed',
            internalStatus: 'Confirmed',
            originType: 'Manual',
            nextPendingApproval: null,
            nextPendingApprovingParticipant: null,
            creationDate: '2019-12-05T14:58:28.927Z',
            lastUpdatetime: '2019-12-06T14:49:51.827Z',
            contractLeg: {
              code: '201911270023940772-1',
              instrumentSymbol: 'BEEF3',
              quantity: 100,
              originalQuantity: 100,
              effectiveQuantity: 100,
              projectedQuantity: 100,
              settledQuantity: 0,
              coveredQuantity: 0,
              uncoveredQuantity: 100,
              renewalQuantity: 0,
              requestedRenewalQuantity: 0,
              requestedSettledQuantity: 100,
              totalVolume: null,
              basePrice: 13.8,
              brokerageFee: null,
              side: 'Borrower',
              paymentType: 'Netting',
              marketModality: 'OtcSecuritiesLending',
              securityLendingContractType: 'RegularDirect',
              masterAccountCode: '98018',
              masterOrSelfAliasOrName: 'NOME DE CLIENTE',
              issuerAccountCode: '98018',
              issuerAliasOrName: 'NOME DE CLIENTE',
              issuerExecutionBrokerCode: '85',
              issuerCarryingBrokerCode: '1026',
              issuerCustodianCode: '1026',
              issuerCustodyAccountCode: '98018',
              issuerPortfolio: '21016',
              negotiationFee: 19.5,
              lenderReversible: true,
              tenderOfferLenderReversible: true,
              internalStatus: 'Finalized',
              tradeDate: '2019-11-27T00:00:00Z',
              startDate: '2019-11-27T00:00:00Z',
              lockUpDate: '2019-11-28T00:00:00Z',
              expireDate: '2020-01-09T00:00:00Z',
              creationDate: '2019-11-27T19:43:50.937Z',
              lastExchangeUpdate: '2019-12-05T14:58:46Z',
            },
            errorRuleIds: '',
          },
          {
            id: 'f63837a6697a4cceaff42f195ba266c5',
            exchangeTransactionId: '2019-12-05-1-4084',
            originTransactionId: '2019-12-04-1-7178',
            contractCode: '201911270023940815-1',
            quantity: 2920,
            side: 'Borrower',
            externalStatus: 'Completed',
            internalStatus: 'Confirmed',
            originType: 'Manual',
            nextPendingApproval: null,
            nextPendingApprovingParticipant: null,
            creationDate: '2019-12-05T15:06:36.94Z',
            lastUpdatetime: '2019-12-06T14:49:52Z',
            contractLeg: {
              code: '201911270023940815-1',
              instrumentSymbol: 'BEEF3',
              quantity: 2920,
              originalQuantity: 2920,
              effectiveQuantity: 2920,
              projectedQuantity: 2920,
              settledQuantity: 0,
              coveredQuantity: 0,
              uncoveredQuantity: 2920,
              renewalQuantity: 0,
              requestedRenewalQuantity: 0,
              requestedSettledQuantity: 2920,
              totalVolume: null,
              basePrice: 13.8,
              brokerageFee: null,
              side: 'Borrower',
              paymentType: 'Netting',
              marketModality: 'OtcSecuritiesLending',
              securityLendingContractType: 'RegularDirect',
              masterAccountCode: '98018',
              masterOrSelfAliasOrName: 'NOME DE CLIENTE',
              issuerAccountCode: '98018',
              issuerAliasOrName: 'NOME DE CLIENTE',
              issuerExecutionBrokerCode: '85',
              issuerCarryingBrokerCode: '1026',
              issuerCustodianCode: '1026',
              issuerCustodyAccountCode: '98018',
              issuerPortfolio: '21016',
              negotiationFee: 17.73,
              lenderReversible: true,
              tenderOfferLenderReversible: true,
              internalStatus: 'Finalized',
              tradeDate: '2019-11-27T00:00:00Z',
              startDate: '2019-11-27T00:00:00Z',
              lockUpDate: '2019-11-28T00:00:00Z',
              expireDate: '2020-01-09T00:00:00Z',
              creationDate: '2019-11-27T19:43:50.187Z',
              lastExchangeUpdate: '2019-12-06T16:20:21.177Z',
            },
            errorRuleIds: '',
          },
          {
            id: '8d0c8db3e51b4314a07ea50009864f19',
            exchangeTransactionId: '2019-12-05-1-9463',
            originTransactionId: '2019-12-05-1-3680',
            contractCode: '201911270023940648-1',
            quantity: 200,
            side: 'Borrower',
            externalStatus: 'Completed',
            internalStatus: 'Confirmed',
            originType: 'Manual',
            nextPendingApproval: null,
            nextPendingApprovingParticipant: null,
            creationDate: '2019-12-05T19:25:18.23Z',
            lastUpdatetime: '2019-12-06T14:50:09.953Z',
            contractLeg: {
              code: '201911270023940648-1',
              instrumentSymbol: 'BEEF3',
              quantity: 200,
              originalQuantity: 200,
              effectiveQuantity: 200,
              projectedQuantity: 200,
              settledQuantity: 0,
              coveredQuantity: 0,
              uncoveredQuantity: 200,
              renewalQuantity: 0,
              requestedRenewalQuantity: 0,
              requestedSettledQuantity: 200,
              totalVolume: null,
              basePrice: 13.8,
              brokerageFee: null,
              side: 'Borrower',
              paymentType: 'Netting',
              marketModality: 'OtcSecuritiesLending',
              securityLendingContractType: 'RegularDirect',
              masterAccountCode: '98018',
              masterOrSelfAliasOrName: 'NOME DE CLIENTE',
              issuerAccountCode: '98018',
              issuerAliasOrName: 'NOME DE CLIENTE',
              issuerExecutionBrokerCode: '85',
              issuerCarryingBrokerCode: '1026',
              issuerCustodianCode: '1026',
              issuerCustodyAccountCode: '98018',
              issuerPortfolio: '21016',
              negotiationFee: 19.5,
              lenderReversible: true,
              tenderOfferLenderReversible: true,
              internalStatus: 'Finalized',
              tradeDate: '2019-11-27T00:00:00Z',
              startDate: '2019-11-27T00:00:00Z',
              lockUpDate: '2019-11-28T00:00:00Z',
              expireDate: '2020-01-09T00:00:00Z',
              creationDate: '2019-11-27T19:43:45.14Z',
              lastExchangeUpdate: '2019-12-05T19:25:21.307Z',
            },
            errorRuleIds: '',
          },
          {
            id: 'aad43eebc03847ca9b7727207eef4ea6',
            exchangeTransactionId: '2019-12-09-1-6340',
            originTransactionId: null,
            contractCode: '201912050023981906-1',
            quantity: 312,
            side: 'Borrower',
            externalStatus: 'Completed',
            internalStatus: 'Confirmed',
            originType: 'Manual',
            nextPendingApproval: null,
            nextPendingApprovingParticipant: null,
            creationDate: '2019-12-09T16:36:42.513Z',
            lastUpdatetime: '2019-12-10T15:04:19.21Z',
            contractLeg: {
              code: '201912050023981906-1',
              instrumentSymbol: 'MDIA3',
              quantity: 312,
              originalQuantity: 312,
              effectiveQuantity: 312,
              projectedQuantity: 312,
              settledQuantity: 0,
              coveredQuantity: 0,
              uncoveredQuantity: 312,
              renewalQuantity: 0,
              requestedRenewalQuantity: 0,
              requestedSettledQuantity: 312,
              totalVolume: null,
              basePrice: 33.27,
              brokerageFee: null,
              side: 'Borrower',
              paymentType: 'Netting',
              marketModality: 'OtcSecuritiesLending',
              securityLendingContractType: 'RegularLenderOffer',
              masterAccountCode: '81048',
              masterOrSelfAliasOrName: 'NOME DE CLIENTE',
              issuerAccountCode: '201442',
              issuerAliasOrName: 'NOME DE CLIENTE',
              issuerExecutionBrokerCode: '129',
              issuerCarryingBrokerCode: '1026',
              issuerCustodianCode: '1026',
              issuerCustodyAccountCode: '201442',
              issuerPortfolio: '21016',
              negotiationFee: 0.5,
              lenderReversible: false,
              tenderOfferLenderReversible: true,
              internalStatus: 'Finalized',
              tradeDate: '2019-12-05T00:00:00Z',
              startDate: '2019-12-05T00:00:00Z',
              lockUpDate: '2019-12-06T00:00:00Z',
              expireDate: '2021-11-23T00:00:00Z',
              creationDate: '2019-12-05T21:08:36.75Z',
              lastExchangeUpdate: '2019-12-09T16:36:49Z',
            },
            errorRuleIds: '',
          },
          {
            id: 'f844535ec5bb41909f8c77368aff618f',
            exchangeTransactionId: '2019-12-09-1-10689',
            originTransactionId: null,
            contractCode: '201911270023939316-1',
            quantity: 2700,
            side: 'Borrower',
            externalStatus: 'Completed',
            internalStatus: 'Confirmed',
            originType: 'Manual',
            nextPendingApproval: null,
            nextPendingApprovingParticipant: null,
            creationDate: '2019-12-09T22:04:47.343Z',
            lastUpdatetime: '2019-12-10T14:55:53.243Z',
            contractLeg: {
              code: '201911270023939316-1',
              instrumentSymbol: 'GNDI3',
              quantity: 19300,
              originalQuantity: 150000,
              effectiveQuantity: 19300,
              projectedQuantity: 19300,
              settledQuantity: 130700,
              coveredQuantity: 0,
              uncoveredQuantity: 17500,
              renewalQuantity: 0,
              requestedRenewalQuantity: 0,
              requestedSettledQuantity: 1800,
              totalVolume: null,
              basePrice: 59.03,
              brokerageFee: null,
              side: 'Borrower',
              paymentType: 'Netting',
              marketModality: 'OtcSecuritiesLending',
              securityLendingContractType: 'RegularDirect',
              masterAccountCode: '81048',
              masterOrSelfAliasOrName: 'NOME DE CLIENTE',
              issuerAccountCode: '201442',
              issuerAliasOrName: 'NOME DE CLIENTE',
              issuerExecutionBrokerCode: '16',
              issuerCarryingBrokerCode: '1026',
              issuerCustodianCode: '1026',
              issuerCustodyAccountCode: '201442',
              issuerPortfolio: '21016',
              negotiationFee: 0.13,
              lenderReversible: true,
              tenderOfferLenderReversible: true,
              internalStatus: 'Finalized',
              tradeDate: '2019-11-27T00:00:00Z',
              startDate: '2019-11-27T00:00:00Z',
              lockUpDate: '2019-11-28T00:00:00Z',
              expireDate: '2020-04-06T00:00:00Z',
              creationDate: '2019-11-27T19:23:26.47Z',
              lastExchangeUpdate: '2020-01-20T19:16:04Z',
            },
            errorRuleIds: '',
          },
        ],
      }
      resolve(assets)
    }, 1000)
  })
}
