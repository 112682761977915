import React from 'react'
import { ISelectOption } from './interfaces'
import { StyledSelectOption, StyledLabel, StyledContent } from './styles'
import { strings } from '../../resources/strings/strings'

const SelectOption: React.FC<ISelectOption> = ({ isFocused, onClick, label, content }) => {
  return (
    <StyledSelectOption onClick={onClick} isFocused={isFocused}>
      <StyledLabel>{label === strings.general.emptyOptions ? label : `${label}:`}</StyledLabel>
      <StyledContent>{content}</StyledContent>
    </StyledSelectOption>
  )
}

export default SelectOption
