import { setGlobal } from 'reactn'
import { displayError } from '../../utils/displayError'
import { FeatureManager } from '../FeatureManager'
import { AccountsFieldsDTO } from './interfaces/accountsFieldsDTO'
import { mockAccountsRequest } from './mockEndpoint'
import { accountsRequest } from './request'

export const getAccounts = async (): Promise<any> => {
  try {
    const response: AccountsFieldsDTO = FeatureManager.shouldMockAccounts
      ? await mockAccountsRequest()
      : await accountsRequest()

    setGlobal({
      accounts: response.accounts,
    })
  } catch (error) {
    displayError(error.error)
  }
}
