import styled from 'styled-components'
import Modal from '@material-ui/core/Modal'
import { colors } from '../../styles/themes/light'
import { FF_MODAL_WIDTH, MIN_MODAL_HEIGHT } from '../../constants/dimensions'

export const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const ModalContainer = styled.div<any>`
  width: ${FF_MODAL_WIDTH}px;
  max-height: 70%;
  min-height: ${({ filters }) => (filters ? 468 : MIN_MODAL_HEIGHT)}px;
  outline: none;
  background-color: ${colors.white};
  box-shadow: ${colors.opacityBlack};
  overflow-y: auto;
`
