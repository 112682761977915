import { DateTimeInputs } from '../constants/filters/filterTypes'
import { DateTime } from '../utils/currentDateToString'
import { addPrecedingZero } from './addPrecedingZero'

const MAX_DATE_FILTER_NAME = 'Max'
const DATETIMES_WITHOUT_SECONDS = ['14:30:', '14:30']

export const getFullDateString = (dateString: string, filterName?: string, shouldGetCurrentTime?: boolean) => {
  const isDateTimeInput = filterName && filterName in DateTimeInputs

  if (!isDateTimeInput) {
    return dateString
  }

  const parts = dateString.split(/[\s]/)
  const date = parts[0]
  let hour = parts[1]

  // If dateString comes from shortcut (D+n), it will not have a space to split, so parts[1] will be undefined.
  // If user only types the date, there will be a space to split due to our mask, but parts[1] wil be equal to an empty string.
  if (!hour || hour === '') {
    if (shouldGetCurrentTime) {
      const currentDate = new Date()
      const currentHour = addPrecedingZero(currentDate.getHours())
      const currentMinute = addPrecedingZero(currentDate.getMinutes())
      const currentSecond = addPrecedingZero(currentDate.getSeconds())
      hour = `${currentHour}:${currentMinute}:${currentSecond}`
    } else {
      hour = filterName?.startsWith(MAX_DATE_FILTER_NAME) ? DateTime.LastSecond : DateTime.FirstSecond
    }
  }

  // User can also give HH:MM without seconds, so we need to fill it
  const isDateWithoutSeconds = DATETIMES_WITHOUT_SECONDS.some((dateTime) => hour.length === dateTime.length)
  isDateWithoutSeconds && (hour += '00')

  const fullDate = `${date} ${hour}`
  return fullDate
}

export const getAllFullDates = (input: string, filterName: string) => {
  return [getFullDateString(input, filterName), getFullDateString(input, filterName, true)]
}
