import styled from 'styled-components'
import { Typography } from '@material-ui/core'

export const GlobalLoadingWrapper = styled.div`
  position: absolute;
  top: 40%;
  left: 45%;
  margin: auto;
  transform: scale(2);
`

export const LocalLoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledLoadMessage = styled(Typography)`
  height: 64px;
`
