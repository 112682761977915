import { Filter } from '../constants/filters/filterTypes'
import { mapStringToISODate } from './mapStringToISODate'

export const formatDateFilter = (filters: Filter[] | undefined, filterName: string) => {
  if (!filters) {
    return
  }
  const filterByName = filters.filter(({ name }) => name === filterName)
  const filterValue = filterByName.map((filter) => filter.value)
  return mapStringToISODate(filterValue.join())
}
