interface LoginStateType {
  isLoggedIn: boolean
  shouldShowLoginError: boolean
  username?: string
  accessToken?: string
}

export const loginState: LoginStateType = {
  isLoggedIn: false,
  shouldShowLoginError: false,
  username: undefined,
  accessToken: undefined,
}
