import { DATE_PATTERN, DATE_SHORTCUT_PATTERN } from '../../constants/filters/filterTypes'

export const isValidDate = (input: string) => {
  const isDateFormat = DATE_PATTERN.test(input)
  const inputYear = parseInt(input.slice(0, 4))
  const currentYear = new Date().getFullYear()
  const isReasonableYear = inputYear <= currentYear + 15 && inputYear >= currentYear - 20
  const isDateShortcut = DATE_SHORTCUT_PATTERN.test(input)

  return (isDateFormat && isReasonableYear) || isDateShortcut
}
