import { ColumnNames } from '../../constants/columnNames'

export const virtualSettleColumnOrder: ColumnNames[] = [
  ColumnNames.IssuerAccountCode,
  ColumnNames.Side,
  ColumnNames.InstrumentSymbol,
  ColumnNames.Quantity,
]

export const succeededResponseColumnOrder: ColumnNames[] = [
  ColumnNames.ContractCode,
  ColumnNames.InstrumentSymbol,
  ColumnNames.EffectiveQuantity,
  ColumnNames.Quantity,
  ColumnNames.Side,
  ColumnNames.IssuerCarryingBrokerCode,
  ColumnNames.IssuerAccountCode,
  ColumnNames.InternalStatus,
  ColumnNames.ErrorRuleIds,
]

export const failedResponseColumnOrder: ColumnNames[] = [
  ColumnNames.IssuerAccountCode,
  ColumnNames.Side,
  ColumnNames.InstrumentSymbol,
  ColumnNames.Quantity,
  ColumnNames.RejectMessage,
]

export const consolidatedVirtualSettleColumnOrder: ColumnNames[] = [
  ColumnNames.IssuerAccountCode,
  ColumnNames.Side,
  ColumnNames.InstrumentSymbol,
  ColumnNames.Quantity,
  ColumnNames.ContractCode,
]
