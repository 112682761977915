import { RefObject } from 'react'
import { Options } from '../../components/jexcel-interfaces'
import { settleColumnOrder } from '../../components/SettleTable/settleColumnOrder'
import { getPersistedData } from '../../utils/localStorageManager'

interface SettleStateType {
  settleData?: any[]
  isSettleModalOpen: boolean
  settleTableRef?: RefObject<{ jexcel: Options } & HTMLDivElement>
  settleColumnWidths?: number[]
  settleColumns: string[]
}

export const settleState: SettleStateType = {
  settleData: undefined,
  isSettleModalOpen: false,
  settleColumnWidths: getPersistedData('settleColumnWidths'),
  settleColumns: settleColumnOrder,
}
