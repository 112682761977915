import { SettlementsFilterSchema, SettlementsFilterNames } from '../filters/settlementsFilters'
import mountDefaultFilters from '../../utils/mountDefaultFilter'
import { currentDateToString } from '../../utils/currentDateToString'

const settlementsDefaultConfig = [
  {
    name: SettlementsFilterNames.MinSettlementDate,
    value: currentDateToString(),
  },
  {
    name: SettlementsFilterNames.MaxSettlementDate,
    value: currentDateToString(),
  },
]

export const settlementsDefaultFilters = mountDefaultFilters(SettlementsFilterSchema, ...settlementsDefaultConfig)
