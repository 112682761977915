import React, { useCallback } from 'react'
import {
  DATE_PATTERN,
  DATE_SHORTCUT_PATTERN,
  Filter,
  FilterPatterns,
  FilterSchema,
} from '../../constants/filters/filterTypes'
import { ICustomFiltersContent } from './interfaces'
import { CustomFiltersContentView } from './view'
import { getMinLengthFromPattern } from '../../utils/filterHandlers/getMinLengthFromPattern'
import { openSnackBar } from '../../services/pageStateService'
import { strings } from '../../resources/strings/strings'
import { mapStringToISODate } from '../../utils/mapStringToISODate'
import { useEffect } from 'reactn'
import { applyFilters } from '../../utils/filterHandlers/applyFilters'
import { useLocation } from 'react-router'
import { getGlobalKeys } from '../../utils/getGlobalKeys'
import { useGlobal } from '../../global/useGlobal'
import { addFilterHandler } from '../../utils/filterHandlers/addFilterHandler'
import { getShortcutDate } from '../../utils/getShortcutDate'
import { getFullDateString } from '../../utils/getFullDateString'

export const CustomFiltersContent: React.FC<ICustomFiltersContent> = ({
  filtersSchema,
  values,
  setValues,
  state,
  setState,
  handleClose,
}) => {
  const { pathname } = useLocation()

  const { appliedFiltersGlobalKey, selectedFiltersGlobalKey, isFilterModalOpenGlobalKey } = getGlobalKeys(pathname)

  const [isModalOpen, setIsModalOpen] = useGlobal(isFilterModalOpenGlobalKey)

  const closeModal = useCallback(() => {
    setIsModalOpen(false)
  }, [setIsModalOpen])

  const handleApplyFilters = useCallback(() => {
    applyFilters(state, closeModal, appliedFiltersGlobalKey, selectedFiltersGlobalKey)
  }, [state, closeModal, appliedFiltersGlobalKey, selectedFiltersGlobalKey])

  const onSelect = (name: string) => (event: any, value: any) => {
    setValues({ [name]: value })
  }

  const addFilter = (filterSchema: FilterSchema) => {
    let filterValue = values[filterSchema.name]
    if (!filterValue) {
      return
    }

    const filterMinLength = getMinLengthFromPattern(filterSchema.filterPattern)
    if (filterMinLength && filterValue.length < filterMinLength) {
      return openSnackBar(strings.alerts.invalidInputLength, true)
    }

    if (filterSchema.filterPattern === FilterPatterns.Date) {
      const isShortcutDate = DATE_SHORTCUT_PATTERN.test(filterValue)
      const isValidDate = DATE_PATTERN.test(filterValue)

      if (isShortcutDate) {
        filterValue = getShortcutDate(filterValue)
      }

      filterValue = getFullDateString(filterValue, filterSchema.name)

      if ((!isValidDate && !isShortcutDate) || !mapStringToISODate(filterValue)) {
        return openSnackBar(strings.alerts.invalidDateInput, true)
      }
    }

    const { oldFilterIndex, newFilter, isAlreadyApplied, isFilterUnique, hasOldFilter, newState } = addFilterHandler({
      schema: filterSchema,
      newValue: filterValue,
      filterState: state,
    })

    if (isAlreadyApplied) {
      return openSnackBar(strings.alerts.selectedFilterDuplicate, true)
    }

    if (isFilterUnique && hasOldFilter) {
      newState.splice(oldFilterIndex, 1, newFilter)
      setState(newState)
      values[filterSchema.name] = ''
      return
    }
    newState.push(newFilter)
    setState(newState)
    values[filterSchema.name] = ''
  }

  const removeFilter = (filter: Filter) => {
    const newState = state
    const filterIndex = newState.indexOf(filter)
    if (filterIndex === -1) {
      return
    }
    newState.splice(filterIndex, 1)
    setState(newState)
  }

  const clearFilters = () => {
    setState([])
  }

  const handleEnter = useCallback(
    (e: KeyboardEvent) => {
      e.stopPropagation()
      const isInputFocused = document.activeElement?.tagName === 'INPUT'
      if (e.key.toUpperCase() === 'ENTER') {
        if (!isInputFocused) handleApplyFilters()
      }
    },
    [handleApplyFilters]
  )

  const handleChange = (regexMask?: RegExp) => (event: any) => {
    const value = event?.target?.value
    if (regexMask) {
      return setValues({ [event?.target?.name]: regexMask.test(value) ? value : value.slice(0, -1) })
      // This prevents user from typing something that does not fit mask, but does not handle pasting
    } else {
      setValues({ [event?.target?.name]: event?.target?.value })
    }
  }

  useEffect(() => {
    window.addEventListener('keypress', handleEnter)
    return () => {
      window.removeEventListener('keypress', handleEnter)
    }
  }, [handleEnter])

  return (
    <CustomFiltersContentView
      filtersSchema={filtersSchema}
      values={values}
      handleChange={handleChange}
      handleClose={handleClose}
      addFilter={addFilter}
      removeFilter={removeFilter}
      clearFilters={clearFilters}
      applyFilters={handleApplyFilters}
      onSelect={onSelect}
    />
  )
}
