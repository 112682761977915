import styled from 'styled-components'
import { Paper, Button } from '@material-ui/core'
import { backgroundImage } from '../../resources/assets'

export const ResetPasswordWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(${backgroundImage});
  background-size: cover;
`

export const Container = styled(Paper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  padding: 16px 54px 34px 54px;
`

export const ActionAreaWrapper = styled.div`
  margin-top: 46px;
`

export const ButtonWrapper = styled.div`
  margin: 0 auto;
`

export const FormWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  min-height: 250px;
  width: 100%;
`

export const ConfirmButton = styled(Button)`
  width: 148px;
`

export const Title = styled.div`
  text-align: center;
  margin: 0 auto;
  color: #1b70ac;
`

export const Logo = styled.img`
  margin-bottom: 40px;
  padding: 24px 0;
`
