import { BaseEndpoint, HttpMethods } from '../apiService/baseEndpoint'
import { config } from '../../constants/config'

const { BTG_CONTRACTS_URL } = config

export class SettlementRequestEndpoint<Params = undefined, Body = undefined> extends BaseEndpoint<Body, Params> {
  constructor(path: string, method: HttpMethods, params?: Params, body?: Body) {
    super({
      method,
      url: BTG_CONTRACTS_URL,
      path: `${path}extranet/stock-loan/settlement-request-legs`,
      auth: true,
    })

    this.body = body
    this.params = params
  }
}

export class DeleteSettlementRequestEndpoint<Params = undefined, Body = undefined> extends BaseEndpoint<Body, Params> {
  constructor(path: string, method: HttpMethods, params?: Params, body?: Body) {
    super({
      method,
      url: BTG_CONTRACTS_URL,
      path: `${path}extranet/stock-loan/settlement-request-legs`,
      auth: true,
    })

    this.body = body
    this.params = params
  }
  protected mountUrl(): string {
    let partialUrl = `${this.url}/${this.path}`
    if (!this.params || !('ids' in this.params)) {
      return partialUrl
    } else {
      partialUrl += '?'
      //@ts-ignore
      this.params.ids.forEach((param: string) => {
        partialUrl += `ids=${encodeURIComponent(param)}&`
      })
      return partialUrl
    }
  }
}
