import { ColumnNames } from '../../constants/columnNames'

export const createApprovalInstructionsOrder = [
  ColumnNames.ExternalId,
  ColumnNames.RequestType,
  ColumnNames.InstrumentSymbol,
  ColumnNames.Quantity,
  ColumnNames.Side,
  ColumnNames.IssuerAccountCode,
  ColumnNames.NegotiationFee,
  ColumnNames.CommissionFee,
  ColumnNames.InterBrokerFee,
  ColumnNames.AverageLenderInterest,
  ColumnNames.AverageBorrowerCharge,
  ColumnNames.LenderReversible,
  ColumnNames.TenderOfferLenderReversible,
  ColumnNames.LockUpDate,
  ColumnNames.ExpireDate,
  ColumnNames.IssuerCustodianCode,
  ColumnNames.IssuerCustodyAccountCode,
  ColumnNames.IssuerPortfolio,
  ColumnNames.ReferenceDate,
  ColumnNames.TargetBrokerCode,
  ColumnNames.InternalStatus,
  ColumnNames.DeskId,
  ColumnNames.Memo,
  ColumnNames.Feedback,
]
