import React from 'react'
import { StyledContextMenuItem } from './style'
import { IContextMenuItem } from './interfaces'
import { Typography } from '@material-ui/core'

const ContextMenuItem: React.FC<IContextMenuItem> = ({ label, onClick }) => {
  return (
    <StyledContextMenuItem onClick={onClick}>
      <Typography>{label}</Typography>
    </StyledContextMenuItem>
  )
}

export default ContextMenuItem
