import styled from 'styled-components'
import { Paper, Button } from '@material-ui/core'
import { backgroundImage } from '../../resources/assets'

export const ForgotPasswordWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(${backgroundImage});
  background-size: cover;
`

export const Container = styled(Paper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 54px 34px 54px;
`

export const MockCaptcha = styled.div`
  width: 352px;
  height: 113px;
  display: flex;
  justify-content: center;
  border: 1px solid lightblue;
  margin: 40px auto 24px auto;
  opacity: 0;
`

export const ActionAreaWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ButtonWrapper = styled.div`
  margin: 0 auto;
`

export const Title = styled.div`
  text-align: center;
  margin: 0 auto;
  margin-top: 56px;
  margin-bottom: 24px;
  color: #1b70ac;
`

export const Subtitle = styled.div`
  width: 352px;
  height: 48px;
  margin-top: 56px;
  margin-bottom: 16px;
  text-align: left;
`

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const ConfirmButton = styled(Button)`
  width: 148px;
`

export const Logo = styled.img``
