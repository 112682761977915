import { ILocalLoading, IGlobalLoading } from './interfaces'
import { GlobalLoadingView, LocalLoadingView } from './view'
import React from 'react'

const GlobalLoading: React.FC<IGlobalLoading> = ({ props, isSyncing }) => {
  return <GlobalLoadingView {...props} isSyncing={isSyncing} />
}

export const LocalLoading: React.FC<ILocalLoading> = ({ value, size, thickness, color: newColor }) => {
  return (
    <span style={{ color: newColor }}>
      <LocalLoadingView value={value || undefined} size={size} thickness={thickness} />
    </span>
  )
}

export default GlobalLoading
