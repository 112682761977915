import styled from 'styled-components'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { colors } from '../../styles/themes/light'
import { baseTheme } from '../../styles/themes/baseTheme'
import { RadioGroup } from '@material-ui/core'

export const StyledFieldset = styled.fieldset`
  height: 48px;
  margin: 0;
  padding: 0 5px 0 12px;
  border-radius: 4px;
  border-color: ${colors.lightGray};
  border-width: 1px;
`

export const StyledLegend = styled.legend`
  margin: 0px;
  padding: 0px;
  font-size: ${baseTheme.typography.subtitle2.fontSize};
  color: ${colors.mediumGray};
`

export const ContainerCheckbox = styled.div`
  height: 33px;
  margin-bottom: 0;
  padding-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const StyledControlLabel = styled(FormControlLabel)`
  .MuiTypography-body1 {
    font-size: 12px;
  }
`
export const StyledRadioGroup = styled(RadioGroup)`
  width: 70%;
  justify-content: space-between;
`
