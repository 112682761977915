import React from 'react'
import FormDialogView from './view'
import { IFormDialog } from './interfaces'

const FormDialog: React.FC<IFormDialog> = ({ children, handleAction, handleClose, isOpen, text, title }) => {
  return (
    <FormDialogView text={text} handleClose={handleClose} isOpen={isOpen} title={title} handleAction={handleAction}>
      {children}
    </FormDialogView>
  )
}

export default FormDialog
