import { strings } from '../../resources/strings/strings'
import { stockLoanContractStatus } from '../contracts/stockLoanContractStatus'
import { FilterPatterns, FilterSchema, FilterType, InputStyle, AutoCompleteOptions } from './filterTypes'
import { stockLoanSide } from '../contracts/stockLoanSide'
import { contractCodeMask } from '../masks/masks'
import { ColumnNames } from '../../constants/columnNames'
import { stockLoanSettlementInternalStatus } from '../settlements/stockLoanSettlementInternalStatus'

export interface SettlementsFilterInputs {
  ContractCode: string
  Account: string
  InstrumentSymbol: string
  MinSettlementDate: string
  MaxSettlementDate: string
  MinCreationDate: string
  MaxCreationDate: string
  MinExpireDate: string
  MaxExpireDate: string
  ContractInternalStatuses: string
  InternalStatus: string
  [key: string]: string
}

export enum SettlementsFilterNames {
  ContractCode = 'ContractCode',
  Account = 'Account',
  InstrumentSymbol = 'InstrumentSymbol',
  OrderSide = 'OrderSide',
  MinSettlementDate = 'MinSettlementDate',
  MaxSettlementDate = 'MaxSettlementDate',
  MinExpireDate = 'MinExpireDate',
  MaxExpireDate = 'MaxExpireDate',
  MinCreationDate = 'MinCreationDate',
  MaxCreationDate = 'MaxCreationDate',
  ContractInternalStatuses = 'ContractInternalStatuses',
  InternalStatus = 'InternalStatus',
}

const { filters } = strings.homeScreen

export const SettlementsFilterSchema: FilterSchema[] = [
  {
    name: SettlementsFilterNames.Account,
    label: 'Account',
    mask: filters.accountMask,
    value: '',
    size: 3,
    inputStyle: InputStyle.autoComplete,
    filterType: FilterType.multiple,
    filterPattern: FilterPatterns.AccountCode,
    autoCompleteOptions: AutoCompleteOptions.accounts,
    columnReference: ColumnNames.IssuerAccountCode,
  },
  {
    name: SettlementsFilterNames.InstrumentSymbol,
    label: 'Symbol',
    mask: filters.symbolMask,
    value: '',
    size: 3,
    inputStyle: InputStyle.autoComplete,
    filterType: FilterType.multiple,
    filterPattern: FilterPatterns.Symbol,
    autoCompleteOptions: AutoCompleteOptions.instruments,
    columnReference: ColumnNames.InstrumentSymbol,
  },
  {
    name: SettlementsFilterNames.ContractCode,
    label: 'Contract Code',
    regexMask: contractCodeMask,
    value: '',
    size: 6,
    inputStyle: InputStyle.textField,
    filterType: FilterType.multiple,
    filterPattern: FilterPatterns.ContractCode,
    columnReference: ColumnNames.ContractCode,
  },
  {
    name: SettlementsFilterNames.ContractInternalStatuses,
    label: 'Contract Status',
    value: '',
    size: 6,
    inputStyle: InputStyle.dropdown,
    options: stockLoanContractStatus,
    filterType: FilterType.unique,
    filterPattern: FilterPatterns.OnlyLetters,
  },
  {
    name: SettlementsFilterNames.OrderSide,
    label: 'Side',
    value: '',
    size: 6,
    inputStyle: InputStyle.radio,
    radios: stockLoanSide,
    filterType: FilterType.unique,
    filterPattern: FilterPatterns.Side,
    columnReference: ColumnNames.Side,
  },
  {
    name: SettlementsFilterNames.InternalStatus,
    label: 'Settlement Status',
    value: '',
    size: 6,
    inputStyle: InputStyle.dropdown,
    options: stockLoanSettlementInternalStatus,
    filterType: FilterType.unique,
    filterPattern: FilterPatterns.OnlyLetters,
  },
  {
    name: SettlementsFilterNames.MinExpireDate,
    label: 'Min Expire Date',
    mask: filters.dateMask,
    value: '',
    size: 3,
    inputStyle: InputStyle.textField,
    filterType: FilterType.unique,
    placeholder: filters.datePlaceholder,
    filterPattern: FilterPatterns.Date,
  },
  {
    name: SettlementsFilterNames.MaxExpireDate,
    label: 'Max Expire Date',
    mask: filters.dateMask,
    value: '',
    size: 3,
    inputStyle: InputStyle.textField,
    filterType: FilterType.unique,
    placeholder: filters.datePlaceholder,
    filterPattern: FilterPatterns.Date,
  },
  {
    name: SettlementsFilterNames.MinCreationDate,
    label: 'Min Creation Date',
    mask: filters.dateTimeMask,
    value: '',
    size: 3,
    inputStyle: InputStyle.textField,
    filterType: FilterType.unique,
    placeholder: filters.dateTimePlaceholder,
    filterPattern: FilterPatterns.Datetime,
  },
  {
    name: SettlementsFilterNames.MaxCreationDate,
    label: 'Max Creation Date',
    mask: filters.dateTimeMask,
    value: '',
    size: 3,
    inputStyle: InputStyle.textField,
    filterType: FilterType.unique,
    placeholder: filters.dateTimePlaceholder,
    filterPattern: FilterPatterns.Datetime,
  },
  {
    name: SettlementsFilterNames.MinSettlementDate,
    label: 'Min Settlement Date',
    mask: filters.dateMask,
    value: '',
    size: 3,
    inputStyle: InputStyle.textField,
    filterType: FilterType.unique,
    placeholder: filters.datePlaceholder,
    filterPattern: FilterPatterns.Date,
  },
  {
    name: SettlementsFilterNames.MaxSettlementDate,
    label: 'Max Settlement Date',
    mask: filters.dateMask,
    value: '',
    size: 3,
    inputStyle: InputStyle.textField,
    filterType: FilterType.unique,
    placeholder: filters.datePlaceholder,
    filterPattern: FilterPatterns.Date,
  },
]
