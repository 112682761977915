import React, { useEffect } from 'react'
import Table from '../../components/CustomTable'
import { WorkspaceAppbar } from '../../components/WorkspaceAppbar'
import { WorkspaceWrapper } from '../../components/WorkspaceWrapper'
import { buildTableSchema } from '../../utils/schemaBuilder'
import { useGlobal } from '../../global/useGlobal'
import { settlementRequestsSchema } from '../../services/dataSchemas/settlementRequests/schema'
import { createGetSettlementRequests } from '../../services/settlementRequests/get/createGetSettlementRequests'
import { useLegacyState } from '../../utils/hooks/useLegacyState'
import {
  SettlementRequestsFilterInputs,
  SettlementRequestsFilterSchema,
} from '../../constants/filters/settlementRequestsFilters'
import { CustomFiltersContent } from '../../components/CustomFiltersContent'
import { CustomModal } from '../../components/CustomModal'
import { usePromise } from '../../utils/hooks/usePromise'

const SettlementRequests: React.FC = () => {
  const [sorting, setSorting] = useGlobal('settlementRequestsAppliedSorting')
  const [selectedFilters, setSelectedFilters] = useGlobal('settlementRequestsSelectedFilters')
  const [settlementRequestsPaginationArgs] = useGlobal('settlementRequestsPaginationArgs')
  const [currentSettlementsRequestPage, setCurrentSettlementRequestsPage] = useGlobal('currentSettlementRequestsPage')
  const [tableColumns] = useGlobal('settlementRequestsColumns')
  const [filters] = useGlobal('settlementRequestsAppliedFilters')
  const [isLoading] = useGlobal('isDataLoading')
  const [isFilterModalOpen, setIsFilterModalOpen] = useGlobal('settlementRequestsFilterModalOpen')
  const [tableData] = useGlobal('settlementRequestsData')

  const closeFilterModal = () => setIsFilterModalOpen(false)
  const [values, setValues] = useLegacyState<SettlementRequestsFilterInputs>({
    Account: '',
    InstrumentSymbol: '',
    RequestInternalStatuses: '',
    OrderSide: '',
    MinCreationDate: '',
    MaxCreationDate: '',
  })

  useEffect(() => {
    createGetSettlementRequests()
  }, [filters, sorting])

  const loadMore = async () => {
    const nextSettlementsRequestPage = currentSettlementsRequestPage + 1
    await createGetSettlementRequests(nextSettlementsRequestPage)
    await setCurrentSettlementRequestsPage(nextSettlementsRequestPage)
  }

  const { loading: isLoadingMore, request: doLoadMore } = usePromise(loadMore, [currentSettlementsRequestPage], {})

  const loadAll = async () => {
    await createGetSettlementRequests(undefined, true)
  }

  const { loading: isLoadingAll, request: doLoadAll } = usePromise(loadAll, [], {})

  const totalCount = settlementRequestsPaginationArgs?.totalCount
  const currentCount = tableData.length

  const hasPagination = totalCount > currentCount

  const paginationOptions = {
    doLoadMore,
    doLoadAll,
    isLoadingMore,
    isLoadingAll,
    totalCount,
  }

  return (
    <>
      <WorkspaceAppbar />
      <WorkspaceWrapper>
        <Table
          hasContextMenu
          hasCheckboxColumn
          tableDataSchema={settlementRequestsSchema as any}
          tableRefGlobalKey={'settlementRequestsTableRef'}
          tableColumnsGlobalKey={'settlementRequestsColumns'}
          columnWidthsGlobalKey={'settlementRequestsColumnWidths'}
          dataGlobalKey={'settlementRequestsData'}
          isLoading={isLoading}
          hasFooter={true}
          hasPagination={hasPagination}
          paginationOptions={paginationOptions}
          sorting={sorting}
          setSorting={setSorting}
          persistColumnOrder
        />
        <CustomModal closeModal={closeFilterModal} isOpen={isFilterModalOpen} filters>
          <CustomFiltersContent
            filtersSchema={SettlementRequestsFilterSchema}
            values={values}
            setValues={setValues}
            state={selectedFilters}
            setState={setSelectedFilters}
            handleClose={closeFilterModal}
          />
        </CustomModal>
      </WorkspaceWrapper>
    </>
  )
}

export default SettlementRequests
