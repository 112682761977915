export const mapStringToISODate = (dateString: string) => {
  if (!dateString) return
  const parts = dateString.replace('T', ' ').split(/[-\s:]/) // Take dash, space or colon. Ex: '2020-10-10 12:34:56' -> ['2020','10','10','12','34','56']

  const year = Number(parts[0])
  const month = Number(parts[1]) - 1
  const day = Number(parts[2])
  const hours = parts[3] ? Number(parts[3]) : 0
  const minutes = parts[4] ? Number(parts[4]) : 0
  const seconds = parts[5] ? Number(parts[5]) : 0

  const newDate = new Date(Date.UTC(year, month, day, hours, minutes, seconds)).toISOString().replace(/\.\d{3}Z$/, '')
  const dateForCheck = new Date(newDate)

  const isValidDate = dateForCheck.getDate() === day && dateForCheck.getMonth() === month

  return isValidDate ? newDate : undefined
}
