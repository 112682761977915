import React, { useEffect } from 'react'
import { WorkspaceAppbar } from '../../components/WorkspaceAppbar'
import { CustomModal } from '../../components/CustomModal'
import SettleContent from '../../components/SettleTable'
import { ContractsContainer } from './style'
import { WorkspaceWrapper } from '../../components/WorkspaceWrapper'
import { useGlobal } from '../../global/useGlobal'
import { getContracts } from '../../services/contracts/getContracts'
import VirtualSettleContent from '../../components/VirtualSettle'
import { useLegacyState } from '../../utils/hooks/useLegacyState'
import { ContractsFilterInputs, ContractsFilterSchema } from '../../constants/filters/contractsFilters'
import { CustomFiltersContent } from '../../components/CustomFiltersContent'
import Table from '../../components/CustomTable'
import { contractsSchema } from '../../services/dataSchemas/contracts/schema'
import { usePromise } from '../../utils/hooks/usePromise'

interface IContracts {}

const ContractsView: React.FC<IContracts> = () => {
  const [isFilterModalOpen, setIsFilterModalOpen] = useGlobal('contractsFilterModalOpen')
  const [isSettleModalOpen, setIsSettleModalOpen] = useGlobal('isSettleModalOpen')
  const [isVirtualSettleModalOpen, setIsVirtualSettleModalOpen] = useGlobal('isVirtualSettleModalOpen')
  const [filters] = useGlobal('contractsAppliedFilters')
  const [isLoading] = useGlobal('isDataLoading')
  const [tableData] = useGlobal('contractsTableData')
  const [paginationArgs] = useGlobal('contractsPaginationArgs')
  const [currentPage, setCurrentPage] = useGlobal('currentContractsPage')
  const [sorting, setSorting] = useGlobal('contractsAppliedSorting')

  const closeSettleModal = () => setIsSettleModalOpen(false)
  const closeVirtualSettleModal = () => setIsVirtualSettleModalOpen(false)
  const closeFilterModal = () => setIsFilterModalOpen(false)
  const [selectedFilters, setSelectedFilters] = useGlobal('contractsSelectedFilters')
  const [values, setValues] = useLegacyState<ContractsFilterInputs>({
    ContractCode: '',
    AccountCode: '',
    InstrumentSymbol: '',
    OrderSide: '',
    ContractInternalStatuses: '',
    MinExpireDate: '',
    MaxExpireDate: '',
    MinCreationDate: '',
    MaxCreationDate: '',
    ExecutionBrokerCode: '',
  })

  const loadMore = async () => {
    const nextContractsPage = currentPage + 1
    await getContracts(nextContractsPage)
    await setCurrentPage(nextContractsPage)
  }

  const { loading: isLoadingMore, request: doLoadMore } = usePromise(loadMore, [currentPage], {})

  const loadAll = async () => {
    await getContracts(undefined, true)
  }

  const { loading: isLoadingAll, request: doLoadAll } = usePromise(loadAll, [], {})

  useEffect(() => {
    getContracts()
  }, [filters, sorting])

  const totalCount = paginationArgs?.totalCount
  const currentCount = tableData.length

  const hasPagination = totalCount > currentCount

  const paginationOptions = {
    doLoadMore,
    doLoadAll,
    isLoadingMore,
    isLoadingAll,
    totalCount,
  }

  return (
    <ContractsContainer>
      <WorkspaceAppbar />
      <WorkspaceWrapper>
        <Table
          hasContextMenu
          hasCheckboxColumn
          tableDataSchema={contractsSchema as any}
          tableRefGlobalKey={'contractsTableRef'}
          tableColumnsGlobalKey={'contractsColumns'}
          columnWidthsGlobalKey={'contractsColumnWidths'}
          dataGlobalKey={'contractsTableData'}
          isLoading={isLoading}
          hasFooter
          hasPagination={hasPagination}
          paginationOptions={paginationOptions}
          sorting={sorting}
          setSorting={setSorting}
          persistColumnOrder
        />
        <CustomModal closeModal={closeFilterModal} isOpen={isFilterModalOpen} filters={true}>
          <CustomFiltersContent
            filtersSchema={ContractsFilterSchema}
            values={values}
            setValues={setValues}
            state={selectedFilters}
            setState={setSelectedFilters}
            handleClose={closeFilterModal}
          />
        </CustomModal>
        <CustomModal closeModal={closeSettleModal} isOpen={isSettleModalOpen}>
          <SettleContent handleClose={closeSettleModal} />
        </CustomModal>
        <CustomModal closeModal={closeVirtualSettleModal} isOpen={isVirtualSettleModalOpen}>
          <VirtualSettleContent handleClose={closeVirtualSettleModal} />
        </CustomModal>
      </WorkspaceWrapper>
    </ContractsContainer>
  )
}

export default ContractsView
