import { HttpMethods } from '../apiService/baseEndpoint'
import { SettlementsEndpoint } from './endpoint'

export interface SettlementsRequest {
  filters?: { filterType: string; value: string | string[] }
}

export const settlementsRequest = async (input: any): Promise<any> => {
  const endpoint = new SettlementsEndpoint<SettlementsRequest, undefined>('', HttpMethods.GET, input)
  return endpoint.execute()
}
