import SelectOption from '../SelectOption'
import React from 'react'

const SearchBarCustomOption = (props: any) => {
  const { data, isFocused } = props
  const onClick = () => data.action()

  return <SelectOption onClick={onClick} label={data.label} content={data.content} isFocused={isFocused} />
}

export default SearchBarCustomOption
