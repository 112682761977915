import { strings } from '../../resources/strings/strings'
import validate from 'validate.js'

const { forgotPasswordScreen } = strings
const constraints = {
  email: {
    format: {
      pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
      message: `^${forgotPasswordScreen.errors.invalidEmail}`,
    },
  },
}

const validator = (values: any) => {
  return validate(values, constraints)
}

export const forgotPasswordConfig = {
  initialValues: {
    email: '',
  },
  formName: 'forgotPassword',
  validator,
}
