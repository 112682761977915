import SettleColumnIndexes from '../constants/settle/SettleColumnIndexes'
import {
  ConstractLegs,
  CreateSettlementRequestResponse,
} from '../services/settlementRequests/post/interfaces/createSettlementRequestResponse'
import { RefObject } from 'react'
import { Options } from '../components/jexcel-interfaces'

export const updateSettleTableWithResponse = (
  response: CreateSettlementRequestResponse,
  settleTableRef: RefObject<{ jexcel: Options } & HTMLDivElement>
) => {
  const contractLegCodes = settleTableRef.current?.jexcel.getColumnData!(0).filter(Boolean)

  response?.contractLegs?.forEach((value: ConstractLegs) => {
    const index = contractLegCodes.indexOf(value.contractLegCode)

    if (index !== -1) {
      settleTableRef.current?.jexcel.setValueFromCoords!(SettleColumnIndexes.ContractCode, index, value.contractLegCode)
      settleTableRef.current?.jexcel.setValueFromCoords!(
        SettleColumnIndexes.Qty,
        index,
        value.quantity === 0 ? '0' : value.quantity
      )
      settleTableRef.current?.jexcel.setValueFromCoords!(SettleColumnIndexes.Side, index, value.side, true)
      settleTableRef.current?.jexcel.setValueFromCoords!(
        SettleColumnIndexes.InternalStatus,
        index,
        value.internalStatus,
        true
      )
      settleTableRef.current?.jexcel.setValueFromCoords!(
        SettleColumnIndexes.ErrorRuleIds,
        index,
        value.errorRuleIds,
        true
      )
    }
  })
}
