import React from 'react'
import { ForgotPasswordVM } from './ForgotPasswordVM'
import btgLogo from '../../resources/assets/logo/btg-logo-login.png'
import { ForgotPasswordWrapper, Container, Logo } from './style'
import { ConfirmEmailScreen } from './components/ConfirmEmailScreen'
import { SentEmailBox } from './components/SentEmailBox'

interface IForgotPassword {
  history?: any
}

const ForgotPassword: React.FC<IForgotPassword> = ({ history }) => {
  const viewModel = ForgotPasswordVM.initVM()
  const { Form, onSubmit, isEmailSent, errorMessage, screenStrings } = viewModel.useDataHook()

  const handleBack = () => {
    history.goBack()
  }

  return (
    <ForgotPasswordWrapper>
      <Container>
        <Logo src={btgLogo} alt={'btg-logo'} />
        {isEmailSent ? (
          <SentEmailBox screenStrings={screenStrings} />
        ) : (
          <ConfirmEmailScreen
            Form={Form}
            onSubmit={onSubmit}
            errorMessage={errorMessage}
            screenStrings={screenStrings}
            handleBack={handleBack}
          />
        )}
      </Container>
    </ForgotPasswordWrapper>
  )
}

export default ForgotPassword
