import { Routes } from '../routes'
import { getContracts } from '../services/contracts/getContracts'
import { getSettlements } from '../services/settlements/getSettlements'
import { getApprovalDivergences } from '../services/approvalDivergences/getApprovalDivergences'
import { createGetSettlementRequests } from '../services/settlementRequests/get/createGetSettlementRequests'
import { getApprovalInstructions } from '../services/approvalInstructions/get/getApprovalInstructions'

export const refreshData = (pathname: string) => {
  switch (pathname) {
    case Routes.contracts:
      getContracts()
      break
    case Routes.settlements:
      getSettlements()
      break
    case Routes.approvalDivergences:
      getApprovalDivergences()
      break
    case Routes.settlementRequests:
      createGetSettlementRequests()
      break
    case Routes.approvalInstructions:
      getApprovalInstructions()
      break
    default:
      return
  }
}
