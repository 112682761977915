import { BaseEndpoint, HttpMethods } from '../../apiService/baseEndpoint'
import { config } from '../../../constants/config'

const { BTG_TOKEN_URL } = config

export class LoginEndpoint<Params = undefined, Body = undefined> extends BaseEndpoint<Body, Params> {
  constructor(path: string, method: HttpMethods, params?: Params, body?: Body) {
    super({
      method,
      url: BTG_TOKEN_URL,
      path: `${path}RequestToken`,
      auth: false,
    })

    this.body = body
    this.params = params
  }
}
