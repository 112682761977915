import React from 'react'
import { ActionAreaWrapper, ButtonWrapper, ConfirmButton, Subtitle, Title } from '../style'
import Typography from '@material-ui/core/Typography'

interface ISentEmailBox {
  screenStrings: any
}

export const SentEmailBox: React.FC<ISentEmailBox> = ({ screenStrings }) => {
  return (
    <div>
      <Title>
        <Typography variant="h4">{screenStrings.labels.secondStepTitle}</Typography>
      </Title>
      <Subtitle>
        <Typography variant="subtitle1">{screenStrings.labels.secondStepSubtitle}</Typography>
      </Subtitle>
      <ActionAreaWrapper>
        <ButtonWrapper>
          <ConfirmButton color="primary" variant="contained" href="/login">
            {screenStrings.labels.confirmButton}
          </ConfirmButton>
        </ButtonWrapper>
      </ActionAreaWrapper>
    </div>
  )
}
