import React, { useEffect } from 'react'
import CreateApprovalInstructions from '../../components/CreateApprovalInstructions'
import { CustomFiltersContent } from '../../components/CustomFiltersContent'
import { CustomModal } from '../../components/CustomModal'
import Table from '../../components/CustomTable'
import { WorkspaceAppbar } from '../../components/WorkspaceAppbar'
import { WorkspaceWrapper } from '../../components/WorkspaceWrapper'
import {
  ApprovalDivergencesFilterInputs,
  ApprovalDivergencesFilterSchema,
} from '../../constants/filters/approvalDivergencesFilters'
import { useGlobal } from '../../global/useGlobal'
import { getApprovalDivergences } from '../../services/approvalDivergences/getApprovalDivergences'
import { approvalDivergencesSchema } from '../../services/dataSchemas/approvalDivergences/schema'
import { useLegacyState } from '../../utils/hooks/useLegacyState'
import { usePromise } from '../../utils/hooks/usePromise'
import { useWindowSize } from '../../utils/hooks/useWindowSize'
import { ApprovalDivergencesContainer } from './style'

interface IApprovalDivergences {}

const ApprovalDivergencesView: React.FC<IApprovalDivergences> = () => {
  const [isFilterModalOpen, setIsFilterModalOpen] = useGlobal('approvalDivergencesFilterModalOpen')
  const [isCreateApprovalInstructionsModalOpen, setIsCreateApprovalInstructionsModalOpen] = useGlobal(
    'isCreateApprovalInstructionsModalOpen'
  )
  const { windowHeight } = useWindowSize()

  const [filters] = useGlobal('approvalDivergencesAppliedFilters')
  const [sorting, setSorting] = useGlobal('approvalDivergencesAppliedSorting')
  const [selectedFilters, setSelectedFilters] = useGlobal('approvalDivergencesSelectedFilters')
  const [currentApprovalDivergencesPage, setApprovalDivergencesPage] = useGlobal('currentApprovalDivergencesPage')
  const [approvalDivergencesPaginationArgs] = useGlobal('approvalDivergencesPaginationArgs')
  const [isLoading] = useGlobal('isDataLoading')
  const [tableData] = useGlobal('approvalDivergencesData')

  const closeFilterModal = () => setIsFilterModalOpen(false)
  const closeCreateAIModal = () => setIsCreateApprovalInstructionsModalOpen(false)

  const [values, setValues] = useLegacyState<ApprovalDivergencesFilterInputs>({
    accountCode: '',
    instrumentSymbol: '',
    requestType: '',
    requestInternalStatuses: '',
    orderSide: '',
    expireDate: '',
  })

  useEffect(() => {
    getApprovalDivergences()
  }, [filters, sorting])

  const loadMore = async () => {
    const nextApprovalDivergencesPage = currentApprovalDivergencesPage + 1
    await getApprovalDivergences(nextApprovalDivergencesPage)
    await setApprovalDivergencesPage(nextApprovalDivergencesPage)
  }

  const { loading: isLoadingMore, request: doLoadMore } = usePromise(loadMore, [currentApprovalDivergencesPage], {})

  const loadAll = async () => {
    await getApprovalDivergences(undefined, true)
  }

  const { loading: isLoadingAll, request: doLoadAll } = usePromise(loadAll, [], {})

  const tableHeight = windowHeight - 160 // 160 is the table header height

  const totalCount = approvalDivergencesPaginationArgs?.totalCount
  const currentCount = tableData.length

  const hasPagination = totalCount > currentCount

  const paginationOptions = {
    doLoadMore,
    doLoadAll,
    isLoadingMore,
    isLoadingAll,
    totalCount,
  }

  return (
    <ApprovalDivergencesContainer>
      <WorkspaceAppbar />
      <WorkspaceWrapper>
        <Table
          tableRefGlobalKey={'approvalDivergencesTableRef'}
          tableColumnsGlobalKey={'approvalDivergencesColumns'}
          columnWidthsGlobalKey={'approvalDivergencesColumnWidths'}
          tableDataSchema={approvalDivergencesSchema as any}
          dataGlobalKey={'approvalDivergencesData'}
          containerHeight={tableHeight}
          tableHeight={tableHeight}
          hasCheckboxColumn
          hasContextMenu
          hasPagination={hasPagination}
          paginationOptions={paginationOptions}
          sorting={sorting}
          setSorting={setSorting}
          hasFooter
          isLoading={isLoading}
          persistColumnOrder
        />
        <CustomModal closeModal={closeFilterModal} isOpen={isFilterModalOpen} filters={true}>
          <CustomFiltersContent
            filtersSchema={ApprovalDivergencesFilterSchema}
            values={values}
            setValues={setValues}
            state={selectedFilters}
            setState={setSelectedFilters}
            handleClose={closeFilterModal}
          />
        </CustomModal>
        <CustomModal closeModal={closeCreateAIModal} isOpen={isCreateApprovalInstructionsModalOpen}>
          <CreateApprovalInstructions handleClose={closeCreateAIModal} />
        </CustomModal>
      </WorkspaceWrapper>
    </ApprovalDivergencesContainer>
  )
}

export default ApprovalDivergencesView
