import * as React from 'react'
import { Redirect } from 'react-router'
import { Routes } from '../../routes'

export const loginHandler = (Component: React.ComponentType, isLogged: boolean) => (props: any) => {
  if (!isLogged) {
    return <Redirect to={Routes.login} />
  }
  return <Component {...props} />
}

export const unauthHandler = (Component: React.ComponentType, isLogged: boolean) => (props: any) => {
  return !isLogged ? <Component {...props} /> : <Redirect to={Routes.contracts} />
}

export const openHandler = (Component: React.ComponentType) => (props: any) => {
  return <Component {...props} />
}

export const defaultRedirect = (isLogged: boolean) => (): any => {
  return !isLogged ? <Redirect to={Routes.login} /> : <Redirect to={Routes.contracts} />
}
