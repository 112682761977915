import { HttpMethods } from '../../apiService/baseEndpoint'
import { ApprovalInstructionsEndpoints } from '../endpoint'

export interface ApprovalInstructionsRequest {
  filters: { filterType: string; value: string | string[] }
}

export const approvalInstructionsRequest = async (input: any): Promise<any> => {
  const endpoint = new ApprovalInstructionsEndpoints<ApprovalInstructionsRequest, undefined>('', HttpMethods.GET, input)
  return endpoint.execute()
}
